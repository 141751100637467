import React, {useContext, useEffect} from 'react';
import {FormContext, useSetFieldValue, validateField} from './DwForm';
import Select from 'react-select';
import {SingleValue} from 'react-select/dist/declarations/src/types';
import {useIsMounted} from "../../common/isMounted";

type Props = {
    id: string;
};

const OPTIONS = [
    {value: true, label: 'да'},
    {value: false, label: 'нет'},
];

const BooleanSelect: React.FC<Props> = ({id}) => {
    const context = useContext(FormContext);
    const {config, model} = context.state;
    const setValue = useSetFieldValue();
    const fieldConfig = config.field(id),
        value = fieldConfig.getter(model);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [value]);

    const onChange = (newValue: SingleValue<{ value: boolean; label: string }>) =>
        setValue(id, newValue?.value);

    return (
        <div className={'form-group'}>
            <Select
                options={OPTIONS}
                defaultValue={value ? OPTIONS[0] : OPTIONS[1]}
                placeholder='(выбрать)'
                onChange={onChange}
            />
        </div>
    );
}

export default BooleanSelect;
