export enum CrmUserField {
    ID = 'id',
    FIRST_NAME = 'firstName',
    MID_NAME = 'midName',
    LAST_NAME = 'lastName',
    FIO = 'fio',
    EMAIL = 'email',
    PASSWORD = 'password',
    PHONE = 'phone',
    PASSPORT_NUMBER = 'passportNumber',
    PASSPORT_ALIEN = 'passportAlien',
    PASSPORT_DATE = 'passportDate',
    PASSPORT_DEPARTMENT = 'passportDepartment',
    LICENCE_NUMBER = 'licenceNumber',
    LICENCE_DATE = 'licenceDate',
    BIRTH_DATE = 'birthDate',
    INN = 'inn',
    SNILS = 'snils',
    STATUS = 'status',
    ACTIVE = 'active',
    COMMENT = 'comment',
    ATTACHMENTS = 'attachments',
    ROLES = "roles",
    DRIVER_CLIENTS = 'driverClients'
}
