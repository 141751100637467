import React from 'react';
import {generatePath} from 'react-router-dom';
import {Config} from '../../table/config';
import {DwTable} from '../../table/DwTable';
import {PATH_STORAGE_ASSORTMENT_OPERATION, PATH_VARIABLE_ASSORTMENT_OPERATION_ID,} from '../../../routerPaths';
import {AssortmentOperationField} from './assortmentOperationField';
import {ColumnType} from '../../table/columnType';
import assortmentOperations from '../../../api/assortmentOperations';
import {AssortmentOperationListModel} from '../../../model/assortment-operation/assortmentOperationListModel';

const config = Config.builder<AssortmentOperationListModel>()
    .column((builder) => builder.id())
    .column((builder) => builder.dateTime('Дата', AssortmentOperationField.DT))
    .column((builder) =>
        builder
            .label('Тип')
            .field(AssortmentOperationField.TYPE)
            .type(ColumnType.OPERATION_TYPE)
            .sortable(false)
            .cls('type')
    )
    .column((builder) =>
        builder
            .text('Откуда', AssortmentOperationField.STORAGE_FROM)
            .getter((model) => model.storageFrom ?? model.clientAddressFrom)
            .sortable(false)
    )
    .column((builder) =>
        builder
            .text('Куда', AssortmentOperationField.STORAGE_TO)
            .getter((model) => model.storageTo ?? model.clientAddressTo)
            .sortable(false)
    )
    .column((builder) =>
        builder
            .integer('Номер рейса', AssortmentOperationField.RUN)
            .getter((model) => (model.run <= 0 ? '' : model.run))
            .sortable(false)
    )
    .column((builder) =>
        builder
            .integer('Номер заказа', AssortmentOperationField.QUOTE)
            .getter((model) => (model.quote <= 0 ? '' : model.quote))
            .sortable(false)
    )
    .column((builder) =>
        builder
            .text('Наименование склада', AssortmentOperationField.ASSORTMENT_VALUE)
            .sortable(false)
    )
    .column((builder) =>
        builder
            .text('Наименование склада назначения', AssortmentOperationField.ASSORTMENT_TO_VALUE)
            .sortable(false)
    )
    .column((builder) => builder.text('Тара', AssortmentOperationField.CATEGORY_VALUE))
    .column((builder) => builder.integer('Кол-во', AssortmentOperationField.QUANTITY))
    .column((builder) => builder.money('Вес', AssortmentOperationField.WEIGHT))
    .column((builder) =>
        builder.text('Комментарий', AssortmentOperationField.COMMENT).sortable(false)
    )
    .formUrl(
        generatePath(PATH_STORAGE_ASSORTMENT_OPERATION, {[PATH_VARIABLE_ASSORTMENT_OPERATION_ID]: ''})
    )
    .load((ctx) => assortmentOperations.list(ctx.state.request))
    .id('assortment-operations')
    .defaultHidden([])
    .build();

const AssortmentOperations: React.FC = () => {
    return (
        <div className='assortment-operations d-flex'>
            <DwTable header='Операции Склад Номенклатура' config={config}/>
        </div>
    );
};

export default AssortmentOperations;
