import React from 'react';
import { generatePath } from 'react-router-dom';
import { PATH_CP_COMPANY, PATH_VARIABLE_COMPANY_ID } from '../../../routerPaths';
import { DwTable } from '../../table/DwTable';
import { Config } from '../../table/config';
import companies from '../../../api/companies';
import { CompanyField } from './companyField';

import type { CompanyListModel } from '../../../model/company/companyListModel';
import ListClients from '../../form/list/ListClients';
import { ClientField } from '../client/clientField';

const config = Config.builder<CompanyListModel>()
        .column((builder) => builder.id().filterable(false))
        .column((builder) =>
            builder.text('Краткое наименование Организации', CompanyField.NAME)
        )
        .column((builder) =>
            builder.text('Полное наименование Организации', CompanyField.FULL_NAME)
        )
        .column((builder) =>
            builder.text('ИНН', CompanyField.INN).cls('inn').sortable(false).width('150px')
        )
        .column((builder) =>
            builder.text('КПП', CompanyField.KPP).cls('kpp').sortable(false)
        )
        .column((builder) =>
            builder
                .text('ОГРН', CompanyField.OGRN)
                .cls('ogrn')
                .sortable(false)
                .width('150px')
        )
        .column((builder) =>
            builder.text('Телефон', CompanyField.PHONE).cls('phone').sortable(false)
        )
        .column((builder) =>
            builder.text('Почтовый адрес', CompanyField.POSTAL_ADDRESS).sortable(false)
        )
        .column((builder) =>
            builder.text('Фактический адрес', CompanyField.ACTUAL_ADDRESS).sortable(false)
        )
        .column((builder) =>
            builder.text('Юридический адрес', CompanyField.ADDRESS).sortable(false)
        )
        .column((builder) =>
            builder.text('ФИО Руководителя', CompanyField.HEAD).sortable(false)
        )
        .column((builder) =>
            builder
                .text('Должность руководителя', CompanyField.HEAD_POSITION)
                .filterable(false)
                .sortable(false)
        )
        .column((builder) =>
            builder.text('Действует на основании', CompanyField.BASIS).sortable(false)
        )
        .column((builder) =>
            builder
                .text('В лице', CompanyField.HEAD_GENITIVE)
                .filterable(false)
                .sortable(false)
        )
        .formUrl(generatePath(PATH_CP_COMPANY, { [PATH_VARIABLE_COMPANY_ID]: '' }))
        .load((ctx) => companies.list(ctx.state.request))
        .id('companies')
        .defaultHidden([
            CompanyField.KPP,
            CompanyField.ADDRESS,
            CompanyField.ACTUAL_ADDRESS,
            CompanyField.HEAD_POSITION,
            CompanyField.HEAD_GENITIVE,
            CompanyField.BASIS,
            ClientField.COMPANY
        ])
        .build();

const Companies: React.FC = () => {
    return (
        <div className='companies d-flex'>
            <DwTable header='Организации' config={config} />
        </div>
    );
};

const ActiveCompanySelector: React.FC = () => {
    return (
        <div className='active-company'>
            <ListClients id={''} />
        </div>
    );
};

export default Companies;
