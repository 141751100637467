import {Action, ActionType} from './action';
import {State} from './context';

export const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
    switch (action.type) {
        case ActionType.MODEL:
            return {...state, model: {...action.model!}, changes: {...state.emptyModel}};
        case ActionType.SET:
            const setter = action.config?.setter ?? action.setter;
            const {model, changes} = state;
            if (setter) {
                setter(model, action.value);
                setter(changes, action.value);
                return {...state, model: {...model}, changes: {...changes}};
            }
            return state;
        case ActionType.ERROR:
            return {...state, error: action.value!};
        case ActionType.ERRORS:
            return {...state, errors: action.errors!};
        case ActionType.FIELD_ERROR:
            const id = action.id!;
            const value = action.value!;
            if (value || state.errors.has(id)) {
                const newErrors = new Map(state.errors);
                if (value) {
                    newErrors.set(id, value);
                } else {
                    newErrors.delete(id);
                }
                return {...state, errors: newErrors};
            } else {
                return state;
            }
        default:
            throw new Error('Non-implemented action type in reducer');
    }
};
