import { get, post } from './rest';
import { QuoteModel } from '../component/quotes/model/quoteModel';
import { ApiResponse } from './apiResponse';
import { QuoteListModel } from '../component/quotes/model/quoteListModel';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { Option } from '../component/control/option';
import { QuoteOptionsRequest } from '../component/quotes/model/quoteOptionsRequest';
import { BaseOptionRequest } from './baseOptionRequest';

const PATH_BASE = 'quotes';

const quotes = {
    get: (id: number) => get<QuoteModel>(`${PATH_BASE}/${id}`),
    save: (model: QuoteModel) => post<ApiResponse>(PATH_BASE, model),
    list: (model: ListRequest) => post<PagedResponse<QuoteListModel>>(`${PATH_BASE}/list`, model),
    domesticQuoteList: (model: ListRequest) =>
        post<PagedResponse<QuoteListModel>>(`${PATH_BASE}/domestic-quote-list`, model),
    runOptions: (request: QuoteOptionsRequest) =>
        post<Option[]>(`${PATH_BASE}/options/run`, request),
    assortmentOptions: (request: BaseOptionRequest) =>
        post<Option[]>(`${PATH_BASE}/options/assortment`, request),
};

export default quotes;
