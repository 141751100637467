import { lengthValidator, requiredValidator } from './simpleValidators';
import accounts from "../../api/accounts";

export const numberValidator = async (id: number, number: string) => {
    if (!number) {
        return requiredValidator(number);
    }
    const isNumberUnique = await accounts.isNumberUnique(id, number);
    if (!isNumberUnique) {
        return Promise.resolve('р/счет уже существует');
    }
    return lengthValidator(number, 20);
};

export const correspondentNumberValidator = (correspondentNumber: string) => {
    if (!correspondentNumber) {
        return requiredValidator(correspondentNumber);
    }
    return lengthValidator(correspondentNumber, 20);
};

export const bikValidator = (bik: string) => {
    if (!bik) {
        return requiredValidator(bik);
    }
    return lengthValidator(bik, 9);
};