import React, { useContext } from 'react';
import { UiState } from '../../model/uiState';
import { RootContext } from '../layout/ContextWrapper';

export enum VIEW_TYPE {
    TABLE = 'table',
    CARDS = 'cards'
}

export const ViewModeToggle: React.FC = () => {
    const { state: { uiState: { assortmentViewModeTable: isTableView } }, setUiState, } = useContext(RootContext);
    return (
        <div className='view-mode-toggle'>
            <button className='btn btn-circle btn-info d-flex justify-content-center align-items-center'
                    onClick={() => setUiState({ assortmentViewModeTable: !isTableView } as UiState)}>
                <span className='icon'>
                    {!isTableView && <i className={`fa fa-table`} />}
                    {isTableView &&
                        <>
                            <i className={`fa fa-square`} />
                            <i className={`fa fa-square`} />
                        </>
                    }
                </span>
            </button>
        </div>  
    );
};