import React from 'react';
import { TmcCategory } from '../../model/tmc/tmcModel';
import { Option } from '../control/option';
import FormSelect from './FormSelect';
import tmc from '../../api/tmc';

type Props = {
    id: string;
    tmcCategories?: TmcCategory[];
    readOnly?: boolean;
    nullable?: boolean;
};

export const Tmc: React.FC<Props> = ({ tmcCategories, ...rest }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        tmc.options({
            filter: inputValue,
            tmcCategories,
        }).then((options) => callback(options));
    };
    return <FormSelect {...{...rest, loadOptions}} />;
};
