import React from 'react';
import ListSelect from './base/ListSelect';
import { Option } from '../../control/option';
import addresses from '../../../api/addresses';
import { ClientAddressRequest } from '../../../model/client/address';
import { formatOptionLabel } from '../ClientStorages';
import storages from '../../../api/storages';

type Props = {
    id: string;
    clientId?: number;
    onChange?: (newValue: any, rowNum: number) => void;
    showAddress?: boolean;
};
type PropsBase = Props & {
    loadOptionsFn: (request: ClientAddressRequest) => Promise<Option[]>;
};

const BaseListClientStorages: React.FC<PropsBase> = ({ clientId, loadOptionsFn, showAddress = false, ...rest }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        loadOptionsFn({
            filter: inputValue,
            clientId: clientId,
        }).then((options) => callback(options));
    };
    return (
        <ListSelect {...rest} loadOptions={loadOptions} cacheOptions={false} formatOptionLabel={showAddress ? formatOptionLabel : undefined} />
    );
};

export const ListClientStorages: React.FC<Props> = (props) => (
    <BaseListClientStorages {...props} loadOptionsFn={addresses.storageOptionsForClient}/>
);

export const ListStorages: React.FC<Props> = (props) => (
    <BaseListClientStorages {...props} loadOptionsFn={storages.options}/>
);
