import { RunPointBase, RunPointModel } from '../model/runPointModel';
import util from '../../../common/util';
import { QuoteModel } from '../../quotes/model/quoteModel';
import { calcCargoPrice } from '../../quotes/model/shipmentDetailModel';
import { ProxyModel } from '../../../model/proxyModel';
import { DEFAULT_OPTION } from '../../control/option';

const routeUtil = {
    findFirstLoading: (route: RunPointModel[]): RunPointModel | null => {
        const sortedRoute = (route ?? [])
            .filter(rp => rp.isLoading && rp.date && rp.timeFrom)
            .sort(routeUtil.sort);
        const routeLength = sortedRoute?.length;
        return routeLength > 0 ? sortedRoute[0] : null;
    },

    findLastUnloading: (route: RunPointModel[]): RunPointModel | null => {
        const sortedRoute = (route ?? [])
            .filter(rp => !rp.isLoading && rp.date && rp.timeFrom)
            .sort(routeUtil.sort);
        const routeLength = sortedRoute?.length;
        return routeLength > 0 ? sortedRoute[routeLength - 1] : null;
    },

    createBasePoint: (isLoading: boolean): RunPointModel => ({
        isLoading,
        duration: 24,
        timeFrom: new Date(new Date().setHours(6, 0)),
    } as RunPointModel),

    createLoadingQuotePoint: (quote: QuoteModel): RunPointModel => {
        const shipmentDetails = quote.shipmentDetails;
        return {
            ...routeUtil.createBasePoint(true),
            base: RunPointBase.QUOTE,
            quoteId: quote.id,
            quoteNumber: quote.clientQuoteNumber,
            date: quote.loadingDate,
            client: quote.carrier,
            address: quote.loadingAddress,
            storage: quote.loadingStorage,
            contact: quote.carrierThirdParty ? quote.carrierContact : quote.carrierCompanyContact,
            contactPhone: quote.carrierThirdParty ? quote.carrierContactPhone : quote.carrierCompanyContactPhone,
            weight: util.countTotal(shipmentDetails, ({ weight = 0 }) => weight),
            tareQuantity: util.countTotal(shipmentDetails, ({ tareQuantity = 0 }) => tareQuantity),
            cargoPrice: calcCargoPrice(shipmentDetails),
        } as RunPointModel;
    },

    createUnloadingQuotePoint: (quote: QuoteModel): RunPointModel => {
        const shipmentDetails = quote.shipmentDetails;
        return {
            ...routeUtil.createBasePoint(false),
            base: RunPointBase.QUOTE,
            quoteId: quote.id,
            quoteNumber: quote.clientQuoteNumber,
            date: quote.unloadingDate,
            client: quote.clientConsignee,
            address: quote.unloadingAddress,
            storage: quote.unloadingStorage,
            contact: quote.clientContact,
            contactPhone: quote.clientContactPhone,
            weight: util.countTotal(shipmentDetails, ({ weight = 0 }) => weight),
            tareQuantity: util.countTotal(shipmentDetails, ({ tareQuantity = 0 }) => tareQuantity),
            cargoPrice: calcCargoPrice(shipmentDetails),
        } as RunPointModel;
    },

    createClientPoint: (isLoading: boolean): RunPointModel => {
        return {
            ...routeUtil.createBasePoint(isLoading),
            base: RunPointBase.CLIENT,
        };
    },

    createCompanyPoint: (isLoading: boolean): RunPointModel => {
        return {
            ...routeUtil.createBasePoint(isLoading),
            base: RunPointBase.COMPANY,
        };
    },

    isQuoteUniqueForRoute: (route: RunPointModel[], quoteId: number) =>
        route.every(rp => rp.quoteId !== quoteId),

    group: (runPoints: RunPointModel[]): ProxyModel<RunPointModel>[] => {
        if (!runPoints) return [];

        const groupedQuoteLoading = runPoints
            .filter(runPoint => runPoint.isLoading && runPoint.base === RunPointBase.QUOTE)
            .reduce((grouped: { [key: string]: ProxyModel<RunPointModel> }, runPoint) => {
                const {
                    date,
                    timeFrom,
                    duration,
                    address,
                    storage,
                    client,
                    company,
                    description
                } = runPoint;

                const addressId = address?.value > 0 ? address?.value : -1;
                const storageId = storage?.value > 0 ? address?.value : -1;
                const clientId = client?.value > 0 ? client?.value : -1;
                const companyId = company?.value > 0 ? company?.value : -1;

                const groupKey = `${util.formatDate(date)}-${util.formatTime(timeFrom)}-${duration}-${util.toString(description)}`
                    + `-${addressId}-${storageId}-${clientId}-${companyId}`;

                const existingGroup = grouped[groupKey];

                if (!existingGroup) {
                    grouped[groupKey] = {
                        ...runPoint,
                        refs: [runPoint]
                    };
                } else {
                    existingGroup.refs.push(runPoint);

                    if (existingGroup.id !== runPoint.id) {
                        existingGroup.id = -1;
                    }

                    // Aggregate fields with potential conflicts
                    if (existingGroup.quoteId !== runPoint.quoteId) {
                        existingGroup.quoteId = -1;
                    }

                    if (existingGroup.quoteNumber !== runPoint.quoteNumber) {
                        existingGroup.quoteNumber = '-';
                    }

                    if (existingGroup.contact?.value !== runPoint.contact?.value) {
                        existingGroup.contact = DEFAULT_OPTION;
                    }

                    if (existingGroup.contactPhone !== runPoint.contactPhone) {
                        existingGroup.contactPhone = '-';
                    }

                    existingGroup.weight += runPoint.weight;
                    existingGroup.tareQuantity += runPoint.tareQuantity;
                    existingGroup.cargoPrice += runPoint.cargoPrice;
                }

                return grouped;
            }, {});

        const nonGroupedRunPoints = runPoints
            .filter(rp => !rp.isLoading || rp.base !== RunPointBase.QUOTE)
            .map(rp => ({
                ...rp,
                refs: [rp]
            } as ProxyModel<RunPointModel>));

        return [...Object.values(groupedQuoteLoading), ...nonGroupedRunPoints];
    },

    sort: (a: RunPointModel, b: RunPointModel): number => {
        if (a.isLoading !== b.isLoading) {
            return a.isLoading ? -1 : 1;
        }
        return util.dateTimeSort(a?.date, a?.timeFrom, b?.date, b?.timeFrom)
    },
    
};

export default routeUtil;
