import React, { useContext, useState } from 'react';
import { MOBILE_DEVICE_MAX_WIDTH } from '../../common/const';
import profileImageMale from '../../img/undraw_profile_m.svg';
import profileImageFemale from '../../img/undraw_profile_f.svg';
import { RootContext } from './ContextWrapper';
import './header.scss';
import auth from '../../api/auth';
import { useNavigate } from 'react-router-dom';
import { PATH_LOGIN } from '../../routerPaths';
import ClickOutsideWrapper from './ClickOutsideWrapper';
import TestModeBanner from './TestModeBanner';

const Header: React.FC = () => {
    const { state, setUiState } = useContext(RootContext);
    const profile = state.profile;
    const isFemale = profile.firstName.trimEnd().toLowerCase().endsWith('а');
    const [showDropdown, setShowDropdown] = useState(false);
    const navigate = useNavigate();

    const onClickProfile = () => {
        if (!showDropdown) {
            setShowDropdown(true);
        }
    };

    const onLogout = async () => {
        await auth.logout();
        localStorage.removeItem(auth.TOKEN);
        navigate(PATH_LOGIN);
    };

    const toggleMobileSidebar = () =>
        setUiState({
            ...state.uiState,
            sidebarVisible:
                !state.uiState.sidebarVisible && window.innerWidth < MOBILE_DEVICE_MAX_WIDTH,
        });

    return (
        <nav className='container-fluid d-flex navbar navbar-expand navbar-light topbar static-top'>
            {!state.uiState.sidebarVisible && (
                <button
                    id='sidebarToggleTop'
                    className='btn btn-link d-md-none rounded-circle ml-2 mt-1'
                    onClick={toggleMobileSidebar}
                >
                    <i className='fa fa-bars'> </i>
                </button>
            )}

            <form className='d-none d-sm-inline-block form-inline mw-100 navbar-search'>
                <div className='input-group input-group-sm'>
                    <input
                        type='text'
                        className='form-control small'
                        placeholder='поиск...'
                        aria-label='Search'
                        aria-describedby='basic-addon2'
                    />
                    <div className='input-group-append'>
                        <button className='btn btn-primary' type='button'>
                            <i className='fas fa-search fa-sm'> </i>
                        </button>
                    </div>
                </div>
            </form>

            <TestModeBanner />

            <ul className='navbar-nav ml-auto'>
                <div className='topbar-divider d-none d-sm-block'></div>
                <li className='nav-item dropdown no-arrow'>
                    <a
                        className='nav-link dropdown-toggle'
                        id='userDropdown'
                        role='button'
                        href='#/'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        onClick={onClickProfile}
                    >
                        <span className='mr-2 d-none d-lg-inline text-gray-600 small'>
                            {profile.email}
                        </span>
                        <img
                            className='img-profile rounded-circle'
                            alt='profile'
                            src={isFemale ? profileImageFemale : profileImageMale}
                        />
                    </a>
                    <ClickOutsideWrapper
                        listen={showDropdown}
                        callback={() => setShowDropdown(false)}
                        className={`dropdown-menu dropdown-menu-right shadow animated--grow-in ${showDropdown ? 'show' : ''}`}
                        aria-labelledby='userDropdown'
                    >
                        <div className='dropdown-divider'></div>
                        <a
                            className='dropdown-item'
                            href='#/'
                            data-toggle='modal'
                            data-target='#logoutModal'
                            onClick={onLogout}
                        >
                            <i className='fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400'> </i>
                            Выход
                        </a>
                        <div className='dropdown-divider'></div>
                    </ClickOutsideWrapper>
                </li>
            </ul>
        </nav>
    );
};

export default Header;
