import React, { ElementType, HTMLAttributes } from 'react';
import { useHoverRef } from '../../common/mouseHover';

const DEFAULT_TAG = 'div';

type WithoutListenerProps = HTMLAttributes<HTMLOrSVGElement> & {
    readonly as?: ElementType;
};

type WithListenerProps = WithoutListenerProps & {
    readonly onMouseEnter: () => void;
    readonly onMouseLeave: () => void;
};

type HoverWrapperProps = WithListenerProps & {
    readonly listen?: boolean;
};

const WithListener: React.FC<WithListenerProps> = ({
    onMouseEnter,
    onMouseLeave,
    as: Tag = DEFAULT_TAG,
    children,
    className,
    ...otherProps
}) => {
    const { ref } = useHoverRef(onMouseEnter, onMouseLeave);

    return (
        <Tag ref={ref} {...otherProps} className={`${className ?? ''} hover-listener`}>
            {children}
        </Tag>
    );
};

const WithoutListener: React.FC<WithoutListenerProps> = ({
    as: Tag = DEFAULT_TAG,
    children,
    ...otherProps
}) => {
    return <Tag {...otherProps}>{children}</Tag>;
};

const HoverWrapper: React.FC<HoverWrapperProps> = ({
    listen = false,
    onMouseEnter,
    onMouseLeave,
    ...otherProps
}) => {
    return (
        <>
            {listen ? (
                <WithListener
                    {...otherProps}
                    as={otherProps.as}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            ) : (
                <WithoutListener {...otherProps} as={otherProps.as} />
            )}
        </>
    );
};

export default HoverWrapper;
