import React, { MouseEvent, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { RootContext } from '../../layout/ContextWrapper';
import util from '../../../common/util';
import { PATH_VARIABLE_ASSORTMENT_ID, PATH_VARIABLE_STORAGE_ID } from '../../../routerPaths';
import { StorageDistributionContext } from './StorageDistributionTable';

type Props = {
    quoteId: number,
    tareId: number;
    tareQuoteTotal: number,
    tareQuoteDistributed: number,
    tareTotalDistributed: number,
    tareTotalAssembled: number,
};

export const DistributionButtons: React.FC<Props> = ({
    quoteId,
    tareId,
    tareQuoteTotal,
    tareQuoteDistributed,
    tareTotalDistributed,
    tareTotalAssembled
}) => {
    const { state: { uiState: { assortmentViewModeTable: isTableView } } } = useContext(RootContext);
    const { setCompleted } = useContext(StorageDistributionContext);

    const {
        [PATH_VARIABLE_STORAGE_ID]: storageIdParam,
        [PATH_VARIABLE_ASSORTMENT_ID]: assortmentIdParam,
    } = useParams();

    const storageId = util.toNumber(storageIdParam ?? '') ?? -1,
        assortmentId = util.toNumber(assortmentIdParam ?? '') ?? -1;

    const onMinus = (e: MouseEvent) => setCompletedWrapper(e, getValueToSet(tareQuoteDistributed - 1));
    const onPlus = (e: MouseEvent) => setCompletedWrapper(e, getValueToSet(tareQuoteDistributed + 1));

    const getValueToSet = useCallback((value: number) => {
        if (value <= 0) return 0;

        const toDistribute = tareTotalAssembled - tareTotalDistributed;
        const delta = value - tareQuoteDistributed;

        if (value <= tareQuoteTotal && (tareTotalDistributed + delta <= tareTotalAssembled)) {
            return value;
        } else {
            const maxAllowedValue = tareQuoteDistributed + toDistribute;
            return Math.max(0, Math.min(tareQuoteTotal, maxAllowedValue));
        }
    }, [tareQuoteTotal, tareQuoteDistributed, tareTotalDistributed, tareTotalAssembled]);

    const setCompletedWrapper = useCallback((e: React.MouseEvent, value: number) => {
        e.preventDefault();
        e.stopPropagation();
        if (value !== tareQuoteDistributed) {
            setCompleted({storageId, assortmentId, quoteId, tareTmcId: tareId, distributed: value});
        }
    }, [storageId, assortmentId, quoteId, tareId, tareQuoteDistributed, setCompleted]);

    return (
        <div className={`d-flex ${isTableView ? 'justify-content-between' : 'align-items-center'}`}>
            <button className={`btn ${isTableView ? 'btn-xs' : 'btn-lg'} btn-danger`} onClick={onMinus}>
                <i className='fas fa-minus' />
            </button>
            {isTableView
                ? <span className='text-center px-1'>{tareQuoteDistributed}</span>
                : (
                    <span className='flex-fill text-lg text-center'>
                        <div className='text-sm text-muted'>Готово (%):</div>
                        {tareQuoteDistributed}&nbsp;
                        <span className='text-muted'>({util.calcPercent(tareQuoteDistributed, tareQuoteTotal)}%)</span>
                    </span>
                )
            }
            <button className={`btn ${isTableView ? 'btn-xs' : 'btn-lg'} btn-success`} onClick={onPlus}>
                <i className='fas fa-plus' />
            </button>
        </div>
    );
};
