import React, { ReactElement, MouseEvent } from 'react';

type Props = {
    onClick: (e: MouseEvent, isUp: boolean) => void;
    isFirst: boolean;
    isLast: boolean;
};

export const ShiftButtons: (props: Props) => ReactElement<Props> = ({
    onClick,
    isFirst,
    isLast,
}) => {
    return (
        <>
            {!isFirst && (
                <button
                    className='btn btn-xs btn-primary'
                    onClick={(e: MouseEvent) => onClick(e, true)}
                >
                    <i className='icon fas fa-arrow-up' />
                </button>
            )}
            {!isLast && (
                <button
                    className='btn btn-xs btn-primary'
                    onClick={(e: MouseEvent) => onClick(e, false)}
                >
                    <i className='icon fas fa-arrow-down' />
                </button>
            )}
        </>
    );
};
