import React from 'react';
import FormSelect from './FormSelect';
import contracts from '../../api/contracts';

import type { Option } from '../control/option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import type { ContractType } from '../../model/enums/ContractType';

type Props = {
    id: string;
    clientId: number;
    companyId: number;
    contractTypes: ContractType[];
    onChange?: (newValue: SingleValue<Option>) => void;
};

const Contracts: React.FC<Props> = ({ id, clientId, companyId, contractTypes, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        contracts
            .options({
                filter: inputValue,
                clientId,
                companyId,
                contractTypes,
            })
            .then((options) => callback(options));
    };
    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
        />
    );
};

export default Contracts;
