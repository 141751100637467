import {deleteRequest, get, post, put} from './rest';

import type {ApiResponse} from './apiResponse';
import type {CompanyModel} from '../model/company/companyModel';
import type {CompanyListModel} from '../model/company/companyListModel';
import type {ListRequest} from '../component/table/listRequest';
import type {PagedResponse} from './pagedResponse';
import {ListAttachmentModel} from '../model/attachment';

const PATH_BASE = 'companies';

const companies = {
    get: (id: number) => get<CompanyModel>(`${PATH_BASE}/${id}`),
    getCurrent: () => get<CompanyModel>(`${PATH_BASE}/find/current`),
    save: (model: CompanyModel) =>
        model.id > 0 ? put<number>(PATH_BASE, model) : post<number>(PATH_BASE, model),
    list: (model: ListRequest) => post<PagedResponse<CompanyListModel>>(`${PATH_BASE}/list`, model),
    isInnKppUnique: (id: number, inn: string, kpp: string) =>
        post<ApiResponse>(`${PATH_BASE}/inn-kpp/unique`, {id, inn, kpp}),
    delete: (id: number) => deleteRequest<ApiResponse>(`${PATH_BASE}/${id}`),
    getSuggestion: (model: CompanyModel) => post<CompanyModel>(`${PATH_BASE}/suggestion`, model),
    saveStampAndFacsimile: (companyId: number, model: ListAttachmentModel) =>
        post<ApiResponse>(`${PATH_BASE}/${companyId}/facsimile`, model),
    getStampAndFacsimile: (companyId: number) =>
        get<ListAttachmentModel>(`${PATH_BASE}/${companyId}/facsimile`),
};

export default companies;
