import { AddressModel, RegionIsoRequest } from '../model/address';
import { Option } from '../component/control/option';
import { BaseOptionRequest } from './baseOptionRequest';
import { deleteRequest, get, post } from './rest';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { ApiResponse } from './apiResponse';
import {
    ClientAddressRequest,
    ClientAddressListModel,
    ClientAddressModel,
} from '../model/client/address';

const PATH_BASE = 'addresses';

const addresses = {
    getAddresses: (filter: BaseOptionRequest) => post<Option[]>(`${PATH_BASE}/options`, filter),
    getSuggestion: (model: AddressModel) => post<AddressModel>(`${PATH_BASE}/suggestion`, model),
    getById: (id: number) => get<AddressModel>(`${PATH_BASE}/address/${id}`),
    getCountriesIso: (request: BaseOptionRequest) =>
        post<Option[]>(`${PATH_BASE}/countries-iso`, request),
    getRegionsIso: (request: RegionIsoRequest) =>
        post<Option[]>(`${PATH_BASE}/region-iso`, request),

    listForClient: (model: ListRequest, clientId: number) =>
        post<PagedResponse<ClientAddressListModel>>(`${PATH_BASE}/client/${clientId}/list`, model),
    getForClient: (id: number, clientId: number) =>
        get<ClientAddressModel>(`${PATH_BASE}/client/${clientId}/${id}`),
    saveClientAddress: (model: ClientAddressModel) => post<ApiResponse>(`${PATH_BASE}/client`, model),
    deleteClientAddress: (clientId: number, clientAddressId: number) =>
        deleteRequest<ApiResponse>(`${PATH_BASE}/client/${clientId}/${clientAddressId}`),
    storageOptionsForClient: (request: Partial<ClientAddressRequest>) =>
        post<Option[]>(`${PATH_BASE}/options/storage/client`, request),
};

export default addresses;
