import React from 'react';
import options from '../../../api/options';
import { DictionaryType, Option } from '../../control/option';
import ListSelect from './base/ListSelect';

type Props = {
    id: string;
    dictionaryType: DictionaryType;
    filterCallback?: (option: Option) => boolean;
};

const ListOptions: React.FC<Props> = ({ id, dictionaryType, filterCallback }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        options
            .options({
                filter: inputValue,
                dictionaryType: dictionaryType,
            })
            .then((options) => (filterCallback ? options.filter(filterCallback) : options))
            .then((options) => callback(options));
    };
    return <ListSelect id={id} loadOptions={loadOptions} cacheOptions={true} />;
};

export default ListOptions;
