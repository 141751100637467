import { RefObject, useEffect, useRef } from 'react';


export const useClickOutside = <T extends HTMLElement>(callback: () => void, ref: RefObject<T>) => {

    const handleClickOutside = (e: MouseEvent | TouchEvent) => {
        if (ref?.current && !ref?.current?.contains(e.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        document.addEventListener('touchend', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
            document.removeEventListener('touchend', handleClickOutside, true);
        };
    });

    return { ref };
}

export const useClickOutsideRef = <T extends HTMLElement>(callback: () => void) => {
    return useClickOutside(callback, useRef<T>(null));
}