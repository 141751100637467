export const FIELD_ID = 'id';
export const FIELD_TYPE = 'type';
export const FIELD_STATUS = 'status';
export const FIELD_NUMBER = 'number';
export const FIELD_SIGNED = 'signed';
export const FIELD_BEGIN_DATE = 'beginDate';
export const FIELD_END_DATE = 'endDate';
export const FIELD_MAIN = 'main';
export const FIELD_HAS_ADDITIONAL = 'hasAdditional';
export const FIELD_DOCUMENTS = 'documents';
export const FIELD_PAYMENT_DELAY = 'paymentDelay';
export const FIELD_DELAY_TYPE = 'delayType';
export const FIELD_PAYMENT_TYPE = 'paymentType';
export const FIELD_PAYMENT_METHOD = 'paymentMethod';
export const FIELD_TTN_DAYS = 'ttnDays';
export const FIELD_CURRENCY = 'currency';
export const FIELD_REQUIREMENTS = 'requirements';
export const FIELD_DOWNTIME_COST = 'downtimeCost';

export const FIELD_COMPANY = 'company';
export const FIELD_COMPANY_HEAD_POSITION = 'companyHeadPosition';
export const FIELD_COMPANY_HEAD_GENITIVE = 'companyHeadGenitive';
export const FIELD_COMPANY_BASIS = 'companyBasis';
export const FIELD_COMPANY_ACCOUNT = 'companyAccount';

export const FIELD_CLIENT = 'client';
export const FIELD_CLIENT_HEAD_POSITION = 'clientHeadPosition';
export const FIELD_CLIENT_HEAD_GENITIVE = 'clientHeadGenitive';
export const FIELD_CLIENT_BASIS = 'clientBasis';
export const FIELD_CLIENT_ACCOUNT = 'clientAccount';

export const FIELD_ATTACHMENTS = 'attachments';
