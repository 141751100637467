import React from "react";
import { StorageAssemblyItemsProps, getValueToSet } from "./StorageAssemblyTable";
import { Outlet } from "react-router-dom";
import util from "../../../common/util";

export const AssemblyTable: React.FC<StorageAssemblyItemsProps> = ({ assortment, tasks, setCompletedAssortment, onTaskRowClick }) => {
    return (
        <>
            <table className="table table-assembly-task table-striped table-hover">
                <thead>
                    <tr>
                        <th>Заказчик</th>
                        <th>Тара</th>
                        <th>В заказах</th>
                        <th>Ед. измер.</th>
                        <th>Вес</th>
                        <th>%</th>
                        <th>Готово</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks.map(({
                        tareTmc: {
                            value: tareId,
                            label: tareLabel
                        },
                        weight = 0,
                        tareCollectedTotal = 0,
                        tareDistributedShipped = 0,
                        tareQuotesTotal = 0,
                        client: {
                            label: clientLabel,
                            value: clientId
                        }
                    }) => (
                        <tr key={`atr-${clientId}-${tareId}`} onClick={() => onTaskRowClick(clientId, tareId)}>
                            <td>{clientLabel}</td>
                            <td>{tareLabel?.length ? tareLabel : '-'}</td>
                            <td>{tareQuotesTotal}</td>
                            <td>-</td>
                            <td>{weight}</td>
                            <td>{util.calcPercent(tareCollectedTotal - tareDistributedShipped, tareQuotesTotal)}%</td>
                            <td>
                                <div className="d-flex justify-content-between">
                                    <button className="btn btn-xs btn-danger" onClick={(e) => setCompletedAssortment(e, clientId, tareId, getValueToSet(tareCollectedTotal - 1))}>
                                        <i className="fas fa-minus" />
                                    </button>
                                    <span className="text-center px-1">
                                        {tareCollectedTotal - tareDistributedShipped}
                                    </span>
                                    <button className="btn btn-xs btn-success" onClick={(e) => setCompletedAssortment(e, clientId, tareId, getValueToSet(tareCollectedTotal + 1))}>
                                        <i className="fas fa-plus" />
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <Outlet />
        </>
    );
};
