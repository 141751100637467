export enum ClientAddressField {
    ID = 'id',
    CLIENT_ID = 'clientId',
    NAME = 'name',
    ADDRESS = 'address',
    ADDRESS_ID = 'addressId',
    SPECIFIED_ADDRESS = 'specifiedAddress',
    LONGITUDE = 'longitude',
    LATITUDE = 'latitude',
    CADASTRAL_NUMBER = "cadastralNumber",
    TYPE = "type",
    ADDRESS_CITY = "city",
    ADDRESS_STREET = "street"
}

export enum AddressField {
    ID = 'id',
    COUNTRY_ISO = 'countryIso',
    REGION_ISO = 'regionIso',
    STREET = 'addressStreet',
    CITY = 'addressCity',
    LATITUDE = 'addresslatitude',
    LONGITUDE = 'addresslongitude',
    FIAS_ID = 'fiasId',
}
