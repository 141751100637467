import React from 'react';
import { StorageType } from '../../../model/enums/StorageType';
import NumberInput from '../../form/NumberInput';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import { Storages } from '../../form/ClientStorages';
import { ProductionLineField } from './productionLineField';
import { useFieldValue } from '../../form/DwForm';

const ProductionLine: React.FC = () => {
    const productionLineId = useFieldValue(ProductionLineField.ID);
    const isNew: boolean = !productionLineId;

    return (
        <>
            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-3'>
                            Номер линии
                            <NumberInput id={ProductionLineField.LINE_NUMBER}/>
                        </div>

                        <div className='col-3'>
                            Склад производства
                            <Storages id={ProductionLineField.STORAGE} readOnly={!isNew} types={[StorageType.STORAGE]}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center'>
                <SaveAndCancelButtonGroup/>
            </div>
        </>
    );
};

export default ProductionLine;
