import React, { useMemo } from "react";
import {DwTable, useRefreshContextAction} from "../../table/DwTable";
import {Config} from "../../table/config";
import {ColumnType} from "../../table/columnType";
import storages from "../../../api/storages";
import {AssemblyPlanModel} from "./assemblyPlanModel";
import {PagedResponse} from "../../../api/pagedResponse";
import {StorageAssemblyNav} from "../StorageAssemblyNav";
import {OptionFilter} from "../../table/filter";
import {StorageAssemblyLineItemModel} from "../assembly-task/storageAssemblyModel";
import {StompWebSocketProvider} from "../../../websockets/StompWebSocketProvider";
import {WebSocketSubscriptionUrl} from "../../../common/const";
import { TableRefreshWrapper } from "../../table/TableRefreshWrapper";
import { useWsSubscription } from "../../../websockets/useWsSubscribtion";

const config = Config.builder<AssemblyPlanModel>()
    .column((builder) => builder.text('Наименование склада', 'assortmentName'))
    .column((builder) => builder.client('Заказчик', 'clientName'))
    .column((builder) => builder.integer('Заказов', 'quotes'))
    .column((builder) => builder.integer('Резерв Бинов по Заказам', 'binReserve'))
    .column((builder) => builder.text('Тара', 'tare'))
    .column((builder) => builder.integer('В заказах', 'inQuotes'))
    .column((builder) => builder.integer('Вес по заказам', 'weight'))
    .column((builder) => builder.integer('Готово', 'ready'))
    .column((builder) => builder.decimal('% Готовности', 'readyPercentage'))
    .column((builder) =>
        builder.label('Линия').field('line').type(ColumnType.PRODUCTION_LINE).width('120px')
            .editable()
            .editableColProps((row, rowIdx, context) => {
                const filter = context.state?.request?.filters?.find(it => it.field === 'storage') as OptionFilter;
                return {
                    storageId: filter?.value?.value
                }
            })
            .onChangeRow((value, idx, row) => 
                storages.changeAssemblyLine({
                    lineId: value.value,
                    assortmentId: row.assortmentId,
                    clientId: row.clientId ?? -1,
                    tareId: row.tareId ?? -1
                } as StorageAssemblyLineItemModel))
    )
    .load((ctx) => storages.assemblyPlan(ctx.state.request).then(it => {
        return {totalRecords: -1, data: it} as PagedResponse<AssemblyPlanModel>;
    }))
    .outerFilter((builder) => builder.field('storage').type(ColumnType.STORAGE))
    .id('assembly-plan')
    .withAdd(false)
    .withSearch(false)
    .editable(true)
    .sortable(false)
    .filterable(false)
    .columnsSelector(false)
    .onClick(() => null)
    .build();

export const AssemblyPlan: React.FC = () => {
    return (
        <StompWebSocketProvider>
            <TableRefreshWrapper>
                <AssemblyPlanInner />
            </TableRefreshWrapper>
        </StompWebSocketProvider>
    );
}

const AssemblyPlanInner: React.FC = () => {
    const {refresh} = useRefreshContextAction();
    useWsSubscription(useMemo(() => ({
        endpoint: WebSocketSubscriptionUrl.STORAGE_ASSEMBLY,
        onMessage: (_) => refresh()
    }), [refresh]));
    return (
        <div>
            <StorageAssemblyNav/>
            <div className='assembly-plan d-flex'>
                    <DwTable header='План сборки' config={config} />
            </div>
        </div>
    );
};

export default AssemblyPlan;
