import { get, post } from './rest';
import fileUtil from '../common/fileUtil';
import { FileType } from '../model/fileType';

const PATH_BASE = 'docs';

const docs = {
    runsBriefPdf: (runIds: number[]) => post<Blob>(`${PATH_BASE}/runs/brief/pdf`, runIds).then((blob) =>
        fileUtil.blob2File(blob, `Бриф_по_рейсам_${runIds.join('_')}.pdf`)),
    carrierContractPdf: (clientId: number, contractId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/client/${clientId}/carrier-contract/${contractId}/${fileType}`).then((blob) =>
        fileUtil.blob2File(blob, `Договор_с_Перевозчиком_${contractId}.${fileType}`)),
    runCarrierAgreementPdf: (runId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/run/${runId}/carrier-agreement/${fileType}`).then((blob) =>
            fileUtil.blob2File(blob, `Договор-заявка_с_Перевозчиком_по_рейсу_${runId}.${fileType}`)),
    runCarrierTransportationAgreementPdf: (runId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/run/${runId}/carrier-transportation-agreement/${fileType}`).then((blob) =>
            fileUtil.blob2File(blob, `Заявка_к_договору_с_Перевозчиком_по_рейсу_${runId}.${fileType}`)),
    talman: (shipmentDetailId: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/talman/${shipmentDetailId}/${fileType}`).then((blob) =>
            fileUtil.blob2File(blob, `Тальманская_расписка_${shipmentDetailId}.${fileType}`)),
    palletPassport: (id: number, fileType: FileType) =>
        get<Blob>(`${PATH_BASE}/pallet-passport/${id}/${fileType}`).then((blob) =>
            fileUtil.blob2File(blob, `Паспорт_паллеты_${id}.${fileType}`)),
    talmanPalletPassports: (runId: number) =>
        get<Blob>(`${PATH_BASE}/talman-pallet-passport/${runId}/pdf`).then((blob) =>
            fileUtil.blob2File(blob, `Список_Тальманских_расписок_по_рейсу_${runId}.pdf`)),
};

export default docs;
