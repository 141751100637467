import React, { useContext } from 'react';
import { FormContext } from './DwForm';

const ContextError: React.FC = () => {
    const context = useContext(FormContext);
    return (
        <React.Fragment>
            {!!context.state.error && (
                <div className='alert alert-danger' role='alert'>
                    {context.state.error}
                </div>
            )}
        </React.Fragment>
    );
};

export default ContextError;
