export enum AssortmentOperationField {
    ID = 'id',
    DT = 'dt',
    TYPE = 'type',
    STORAGE_FROM = 'storageFrom',
    CLIENT_ADDRESS_FROM = 'clientAddressFrom',
    STORAGE_TO = 'storageTo',
    CLIENT_ADDRESS_TO = 'clientAddressTo',
    ASSORTMENT = 'assortment',
    ASSORTMENT_TO = 'assortmentTo',
    CATEGORY = 'category',
    QUANTITY = 'quantity',
    WEIGHT = 'weight',
    COMMENT = 'comment',
    RUN = 'run',
    QUOTE = 'quote',
    ASSORTMENT_VALUE = 'assortmentValue',
    ASSORTMENT_TO_VALUE = 'assortmentToValue',
    CATEGORY_VALUE = 'categoryValue',
}
