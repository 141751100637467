import { ReactElement } from 'react';
import { Tooltip } from 'react-tooltip';

type Props = {
    rowId: number;
    tableId: string;
    column: string;
    value: string;
};

const MAX_LENGTH = 50;

export const TableTooltip: (props: Props) => ReactElement<Props> = ({
    rowId,
    tableId,
    column,
    value,
}) => {
    if (!value || value.length < MAX_LENGTH) {
        return <>{value}</>;
    }
    const id = `${tableId}-${column}-${rowId}`;
    return (
        <>
            <a data-tooltip-id={id} data-tooltip-content={value}>
                {value.substring(0, MAX_LENGTH)}...
            </a>
            <Tooltip id={id} />
        </>
    );
};
