import './componentLink.scss';

import type { MouseEvent } from 'react';
import React from 'react';
import { generatePath, Link } from 'react-router-dom';
import { Direction } from '../../common/const';
import {
    PATH_CP_CLIENT,
    PATH_CP_CLIENT_CONSIGNEE,
    PATH_CP_CLIENT_CONTACT,
    PATH_CP_CLIENT_CONTRACT,
    PATH_CP_DRIVER,
    PATH_CP_STORAGE_CONTACT,
    PATH_CP_VEHICLE,
    PATH_QUOTE,
} from '../../routerPaths';
import util from '../../common/util';

export enum ComponentLinkType {
    QUOTE,
    CLIENT,
    CLIENT_CONTACT,
    CLIENT_CONTRACT,
    STORAGE_CONTACT,
    DRIVER,
    VEHICLE,
    CLIENT_CONSIGNEE,
}

type Props = {
    type: ComponentLinkType;
    entityIdChain: number[];
    className?: string;
};

const pathOrNull = (ids: number[], path: string): string | null => {
    return ids.find((id) => id === null || id === undefined || id < 1) ? null : path;
};

const LINK_FUNCTION_MAP = {
    [ComponentLinkType.QUOTE]: (ids: number[]) =>
        pathOrNull(
            [ids[0]],
            generatePath(PATH_QUOTE, {
                direction: Direction.EXTERNAL,
                quoteId: util.stringOrEmpty(ids[0]),
            })
        ),
    [ComponentLinkType.CLIENT]: (ids: number[]) =>
        pathOrNull(
            [ids[0]],
            generatePath(PATH_CP_CLIENT, { clientId: util.stringOrEmpty(ids[0]) })
        ),
    [ComponentLinkType.CLIENT_CONSIGNEE]: (ids: number[]) =>
        pathOrNull(
            [ids[0], ids[1]],
            generatePath(PATH_CP_CLIENT_CONSIGNEE, {
                clientId: util.stringOrEmpty(ids[0]),
                clientConsigneeId: util.stringOrEmpty(ids[1]),
            })
        ),
    [ComponentLinkType.CLIENT_CONTACT]: (ids: number[]) =>
        pathOrNull(
            [ids[0], ids[1]],
            generatePath(PATH_CP_CLIENT_CONTACT, {
                clientId: util.stringOrEmpty(ids[0]),
                contactId: util.stringOrEmpty(ids[1]),
            })
        ),
    [ComponentLinkType.CLIENT_CONTRACT]: (ids: number[]) =>
        pathOrNull(
            [ids[0], ids[1]],
            generatePath(PATH_CP_CLIENT_CONTRACT, {
                clientId: util.stringOrEmpty(ids[0]),
                contractId: util.stringOrEmpty(ids[1]),
            })
        ),
    [ComponentLinkType.STORAGE_CONTACT]: (ids: number[]) =>
        pathOrNull([ids[0], ids[1]],
            generatePath(PATH_CP_STORAGE_CONTACT, {
                storageId: util.stringOrEmpty(ids[0]),
                contactId: util.stringOrEmpty(ids[1]),
        })
    ),
    [ComponentLinkType.DRIVER]: (ids: number[]) =>
        pathOrNull(
            [ids[0]],
            generatePath(PATH_CP_DRIVER, { driverId: util.stringOrEmpty(ids[0]) })
        ),
    [ComponentLinkType.VEHICLE]: (ids: number[]) =>
        pathOrNull(
            [ids[0]],
            generatePath(PATH_CP_VEHICLE, { vehicleId: util.stringOrEmpty(ids[0]) })
        ),
};

const handleInactiveLinkClick = (event: MouseEvent): void => {
    event.preventDefault();
};

const ComponentLink: React.FC<Props> = ({ type, entityIdChain, className }) => {
    if (entityIdChain.some(id => !id)) {
        return <></>;
    }
    const linkFunction = LINK_FUNCTION_MAP[type];
    const pathTo = linkFunction ? linkFunction(entityIdChain) : null;
    return (
        <Link
            to={pathTo ?? '#'}
            target='_blank'
            rel='noopener noreferrer'
            onClick={(event) => (!pathTo ? handleInactiveLinkClick(event) : null)}
            className={`${className ?? ''} ${pathTo ? 'active-link' : 'inactive-link'}`}
        >
            <i className='link fas fa-fw fa-link'></i>
        </Link>
    );
};

export default ComponentLink;
