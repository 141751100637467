import React from 'react';
import { Outlet } from 'react-router';
import { generatePath } from 'react-router-dom';
import users from '../../../../api/users';
import util from '../../../../common/util';
import {
    crmUserEmailValidator,
    crmUserPassportNumberValidator,
    crmUserPasswordValidator,
    crmUserPhoneValidator
} from '../../../../common/validation/crmUserValidation';
import {
    PATH_CP_USER,
    PATH_CP_USER_HISTORY,
    PATH_CP_USERS,
    PATH_VARIABLE_USER_ID,
} from '../../../../routerPaths';
import { Role } from '../../../../model/role';
import ContextError from '../../../form/ContextError';
import { DwForm, useFieldValue } from '../../../form/DwForm';
import DwFormTabs from '../../../form/DwFormTabs';
import { FormConfig } from '../../../form/formConfig';
import { CrmUserField } from '../crmUserField';

import type { CrmUserModel } from '../../../../model/crmUser/crmUserModel';
import { FieldType } from '../../../form/fieldType';
import { AttachmentModel } from '../../../../model/attachment';
import { individualInnValidator } from '../../../../common/validation/commonValidators';
import { requiredListValidator } from '../../../../common/validation/simpleValidators';

const formConfig = FormConfig.builder<CrmUserModel>()
    .number(
        CrmUserField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .text(
        CrmUserField.LAST_NAME,
        (m) => m.lastName,
        (m, v) => (m.lastName = v)
    )
    .text(
        CrmUserField.FIRST_NAME,
        (m) => m.firstName,
        (m, v) => (m.firstName = v)
    )
    .text(
        CrmUserField.MID_NAME,
        (m) => m.midName,
        (m, v) => (m.midName = v)
    )
    .text(
        CrmUserField.EMAIL,
        (m) => m.email,
        (m, v) => (m.email = v),
        crmUserEmailValidator
    )
    .text(
        CrmUserField.PHONE,
        (m) => m.phone,
        (m, v) => (m.phone = v),
        crmUserPhoneValidator
    )
    .field(
        CrmUserField.PASSWORD,
        FieldType.PASSWORD,
        (m) => m.password,
        (m, v) => (m.password = v),
        crmUserPasswordValidator
    )
    .text(
        CrmUserField.PASSPORT_NUMBER,
        (m) => m.passportNumber,
        (m, v) => (m.passportNumber = v),
        (m) => crmUserPassportNumberValidator(m, false)
    )
    .boolean(
        CrmUserField.PASSPORT_ALIEN,
        (m) => m.passportAlien,
        (m, v) => (m.passportAlien = v)
    )
    .dateTime(
        CrmUserField.PASSPORT_DATE,
        (m) => m.passportDate,
        (m, v) => (m.passportDate = v)
    )
    .text(
        CrmUserField.PASSPORT_DEPARTMENT,
        (m) => m.passportDepartment,
        (m, v) => (m.passportDepartment = v)
    )
    .text(
        CrmUserField.LICENCE_NUMBER,
        (m) => m.licenceNumber,
        (m, v) => (m.licenceNumber = v)
    )
    .dateTime(
        CrmUserField.LICENCE_DATE,
        (m) => m.licenceDate,
        (m, v) => (m.licenceDate = v)
    )
    .dateTime(
        CrmUserField.BIRTH_DATE,
        (m) => m.birthDate,
        (m, v) => (m.birthDate = v)
    )
    .text(
        CrmUserField.INN,
        (m) => m.inn,
        (m, v) => (m.inn = v),
        (m) => individualInnValidator(m, false)
    )
    .text(
        CrmUserField.SNILS,
        (m) => m.snils,
        (m, v) => (m.snils = v)
    )
    .boolean(
        CrmUserField.ACTIVE,
        (m) => m.active,
        (m, v) => (m.active = v)
    )
    .text(
        CrmUserField.COMMENT,
        (m) => m.comment,
        (m, v) => (m.comment = v)
    )
    .list<Role>(
        CrmUserField.ROLES,
        (m) => m.roles,
        (m, v) => (m.roles = v),
        (m) => requiredListValidator(m.roles)
    )
    .list<AttachmentModel>(
        CrmUserField.ATTACHMENTS,
        (m) => m.attachments,
        (m, v) => (m.attachments = v)
    )
    .load((id: number) => users.get(id))
    .submit((model) => users.save(model))
    .redirectUrl(PATH_CP_USERS)
    .idPathVariableName(PATH_VARIABLE_USER_ID)
    .build();

const UserForm: React.FC = () => (
    <DwForm config={formConfig}>
        <UserLayout />
    </DwForm>
);

const UserLayout: React.FC = () => {
    const userId = useFieldValue(CrmUserField.ID);
    const firstName = useFieldValue(CrmUserField.FIRST_NAME) ?? '';
    const midName = useFieldValue(CrmUserField.MID_NAME) ?? '';
    const lastName = useFieldValue(CrmUserField.LAST_NAME) ?? '';
    const userCroppedName = `${lastName} ${util.cropName(firstName, true)}${util.cropName(midName, true)}`;
    const isNew = !userId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новый пользователь' : userCroppedName,
                        path: generatePath(PATH_CP_USER, {
                            [PATH_VARIABLE_USER_ID]: util.stringOrEmpty(userId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_CP_USER_HISTORY, {
                            [PATH_VARIABLE_USER_ID]: util.stringOrEmpty(userId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError />
            <Outlet />
        </>
    );
};

export default UserForm;
