import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { router } from './router';
import { RouterProvider } from 'react-router-dom';
import './vendor/sb-admin-2/scss/sb-admin-2.scss';
import CheckBrowser from './component/layout/CheckBrowser';
import { ContextWrapper } from './component/layout/ContextWrapper';

import 'react-tooltip/dist/react-tooltip.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <React.StrictMode>
        <CheckBrowser />
        <ContextWrapper>
            <RouterProvider router={router} />
        </ContextWrapper>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
