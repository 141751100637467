import React from 'react';
import BaseListDtPicker from './base/BaseListDtPicker';

type Props = {
    id: string;
    readOnly?: boolean;
    placeholder?: string;
    showTimeSelect?: boolean;
    minDateTime?: Date;
    timeIntervals?: number;
    onChange?: (newValue: Date, rowNum: number) => void;
    valueToDateTransformer?: (value: any) => Date;
    dateToValueTransformer?: (date: Date) => any;
};

const ListDtPicker: React.FC<Props> = ({
    id,
    readOnly = false,
    placeholder = '',
    showTimeSelect = false,
    minDateTime,
    timeIntervals,
    onChange,
    valueToDateTransformer = (v) => v,
    dateToValueTransformer = (d) => d
}) => (
    <BaseListDtPicker
        id={id}
        readOnly={readOnly}
        placeholder={placeholder}
        showTimeSelect={showTimeSelect}
        onChange={onChange}
        valueToDateTransformer={valueToDateTransformer}
        dateToValueTransformer={dateToValueTransformer}
        minDateTime={minDateTime}
        timeIntervals={timeIntervals}
    />
);

export default ListDtPicker;
