import React from 'react';
import {Outlet} from 'react-router';
import {generatePath} from 'react-router-dom';
import util from '../../../common/util';
import {
    PATH_STORAGE_ASSORTMENT_OPERATION,
    PATH_STORAGE_ASSORTMENT_OPERATION_HISTORY,
    PATH_STORAGE_ASSORTMENT_OPERATIONS,
    PATH_VARIABLE_ASSORTMENT_OPERATION_ID,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import {DwForm, useFieldValue} from '../../form/DwForm';
import DwFormTabs from '../../form/DwFormTabs';
import {FormConfig} from '../../form/formConfig';
import {
    notNullablePositiveDecimalValidator,
    notNullablePositiveIntValidator,
    requiredOperationTypeValidator,
    requiredValidator,
} from '../../../common/validation/simpleValidators';
import {AssortmentOperationModel} from '../../../model/assortment-operation/assortmentOperationModel';
import {AssortmentOperationField} from './assortmentOperationField';
import assortmentOperations from '../../../api/assortmentOperations';
import {OperationType} from '../../../model/enums/OperationType';
import {PROMISE_OK} from '../../../common/const';

const formConfig = FormConfig.builder<AssortmentOperationModel>()
    .number(
        AssortmentOperationField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .dateTime(
        AssortmentOperationField.DT,
        (m) => m.dt,
        (m, v) => (m.dt = v)
    )
    .option(
        AssortmentOperationField.TYPE,
        (m) => m.type,
        (m, v) => (m.type = v),
        (m) => requiredOperationTypeValidator(m.storageTo, m.type)
    )
    .option(
        AssortmentOperationField.STORAGE_FROM,
        (m) => m.storageFrom,
        (m, v) => (m.storageFrom = v)
    )
    .option(
        AssortmentOperationField.CLIENT_ADDRESS_FROM,
        (m) => m.clientAddressFrom,
        (m, v) => (m.clientAddressFrom = v)
    )
    .option(
        AssortmentOperationField.STORAGE_TO,
        (m) => m.storageTo,
        (m, v) => (m.storageTo = v)
    )
    .option(
        AssortmentOperationField.CLIENT_ADDRESS_TO,
        (m) => m.clientAddressTo,
        (m, v) => (m.clientAddressTo = v)
    )
    .requiredOption(
        AssortmentOperationField.CATEGORY,
        (m) => m.category,
        (m, v) => (m.category = v)
    )
    .requiredOption(
        AssortmentOperationField.ASSORTMENT,
        (m) => m.assortment,
        (m, v) => (m.assortment = v)
    )
    .option(
        AssortmentOperationField.ASSORTMENT_TO,
        (m) => m.assortmentTo,
        (m, v) => (m.assortmentTo = v),
        (m) => m.type?.value === OperationType.MISGRADING
            ? requiredValidator(m.assortmentTo)
            : Promise.resolve(PROMISE_OK)
    )
    .number(
        AssortmentOperationField.QUANTITY,
        (m) => m.quantity,
        (m, v) => (m.quantity = v),
        (m) => notNullablePositiveIntValidator(m.quantity)
    )
    .number(
        AssortmentOperationField.WEIGHT,
        (m) => m.weight,
        (m, v) => (m.weight = v),
        (m) => notNullablePositiveDecimalValidator(m.weight)
    )
    .option(
        AssortmentOperationField.RUN,
        (m) => m.run,
        (m, v) => (m.run = v)
    )
    .option(
        AssortmentOperationField.QUOTE,
        (m) => m.quote,
        (m, v) => (m.quote = v)
    )
    .text(
        AssortmentOperationField.COMMENT,
        (m) => m.comment,
        (m, v) => (m.comment = v)
    )
    .load((id) => assortmentOperations.get(id))
    .submit(assortmentOperations.save)
    .redirectUrl(PATH_STORAGE_ASSORTMENT_OPERATIONS)
    .idPathVariableName(PATH_VARIABLE_ASSORTMENT_OPERATION_ID)
    .build();

const AssortmentOperationForm: React.FC = () => {
    return (
        <DwForm config={formConfig}>
            <AssortmentOperationLayout/>
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const AssortmentOperationLayout: React.FC = () => {
    const assortmentOperationId = useFieldValue(AssortmentOperationField.ID);
    const isNew: boolean = !assortmentOperationId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: 'Операция',
                        path: generatePath(PATH_STORAGE_ASSORTMENT_OPERATION, {
                            [PATH_VARIABLE_ASSORTMENT_OPERATION_ID]:
                                util.stringOrEmpty(assortmentOperationId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_STORAGE_ASSORTMENT_OPERATION_HISTORY, {
                            [PATH_VARIABLE_ASSORTMENT_OPERATION_ID]:
                                util.stringOrEmpty(assortmentOperationId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError/>
            <Outlet/>
        </>
    );
};

export default AssortmentOperationForm;
