import clsx from 'clsx';
import React, { ChangeEvent, ReactElement, useContext } from 'react';
import DwFormTabs from '../form/DwFormTabs';
import { Config } from './config';
import { ColumnsSelector } from './ColumnsSelector';
import { TableContext } from './DwTable';
import { ActionType } from './action';
import { useNavigate } from 'react-router-dom';
import { OuterFilters } from './OuterFilters';

type Props = {
    label: string;
    config: Config<any>;
};

const Caption: (props: Props) => ReactElement<Props> = (props) => {
    return (
        <div className='card-header d-flex flex-row'>
            <div
                className={clsx('align-self-start', { 'mr-auto': props.config.tabs.length === 0 })}
            >
                {props.config.outerFilters.length ? <OuterFilters config={props.config} /> : null}
            </div>

            {props.config.tabs.length > 0 && (
                <div className='header-tabs mr-auto'>
                    <DwFormTabs items={props.config.tabs} />
                </div>
            )}
            <div className='align-self-center title'>{props.label}</div>
            <div className='ml-auto align-self-end'>
                <CaptionControls {...props} />
            </div>
        </div>
    );
};

const CaptionControls: (props: Props) => ReactElement<Props> = (props) => {
    const context = useContext(TableContext);
    const filterCount = context.state.request?.filters?.length ?? 0;
    const navigate = useNavigate();
    const onAdd = () => {
        navigate(props.config.formUrl);
    };
    const onSearch = (event: ChangeEvent<any>) => {
        context.dispatch({ type: ActionType.SEARCH, value: event.target.value });
    };
    const onResetFilters = () => {
        context.dispatch({ type: ActionType.RESET_FILTERS });
    };
    const renderDefaults = () => {
        return (
            <>
                {filterCount > 0 &&
                    <button type='button' className='btn btn-outline-secondary btn-sm mr-2' onClick={onResetFilters}>
                        <i className="fas fa-filter" /> ({filterCount}) сбросить
                    </button>
                }
                {props.config.withSearch && <input
                    placeholder='поиск...'
                    type='text'
                    className='search form-control form-control-sm'
                    value={context.state.request.search}
                    onChange={onSearch}
                />}
                {props.config.columnsSelector && <ColumnsSelector config={props.config} />}
                {props.config.withAdd
                    ? (
                        <button type='button' className='btn btn-outline-primary btn-sm' onClick={onAdd}>
                            добавить
                        </button>
                    ) : (
                        <></>
                    )}
            </>
        );
    };
    return (
        <>
            {renderDefaults()}
            {props.config.captionControls.map((control, index) => (
                <React.Fragment key={index * index}>{control(context)}</React.Fragment>
            ))}
        </>
    );
};

export { Caption };
