import { Option } from '../../component/control/option';

export enum QuoteStatus {
    PRELIMINARY = 1,
    CANCELLED = 2,
    READY = 3,
    ASSEMBLED = 4,
    COMPLETED = 5,
}

export const quoteStatusFilterCallback = (option: Option) =>
    ![QuoteStatus.ASSEMBLED, QuoteStatus.COMPLETED].includes(option.value);
export const quoteStatusEDIFilterCallback = (option: Option) =>
    option.value === QuoteStatus.READY;