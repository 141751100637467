import React from 'react';
import { generatePath } from 'react-router-dom';
import users from '../../../../api/users';
import { PATH_CP_USER, PATH_VARIABLE_USER_ID } from '../../../../routerPaths';
import { Config } from '../../../table/config';
import { DwTable } from '../../../table/DwTable';
import { CrmUserField } from '../crmUserField';

import type { CrmUserListModel } from '../../../../model/crmUser/crmUserListModel';
import { ColumnType } from '../../../table/columnType';

const UserList: React.FC = () => {
    const config = Config.builder<CrmUserListModel>()
        .column((builder) => builder.id())
        .column((builder) => builder.text('ФИО', CrmUserField.FIO))
        .column((builder) => builder.text('E-mail', CrmUserField.EMAIL))
        .column((builder) => builder.text('Телефон', CrmUserField.PHONE))
        .column((builder) =>
            builder
                .label('Роль')
                .field(CrmUserField.ROLES)
                .type(ColumnType.ROLE)
                .sortable(false)
        )
        .column((builder) => builder.boolean('Активен', CrmUserField.ACTIVE).cls('boolean'))
        .column((builder) => builder.text('Комментарий', CrmUserField.COMMENT))
        .formUrl(generatePath(PATH_CP_USER, { [PATH_VARIABLE_USER_ID]: '' }))
        .load((ctx) => users.list(ctx.state.request))
        .id('users')
        .defaultHidden([
            CrmUserField.COMMENT,
        ])
        .build();

    return (
        <div className='users d-flex'>
            <DwTable header='Пользователи' config={config} />
        </div>
    );
};

export default UserList;
