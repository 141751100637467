import { Option } from '../component/control/option';

export enum AttachmentType {
    CLIENT = 'CLIENT',
    CONTRACT = 'CONTRACT',
    VEHICLE = 'VEHICLE',
    CRMUSER = 'CRMUSER',
    COMPANY = 'COMPANY',
    RUN = 'RUN',
}

export enum AttachmentSubtype {
    RUN_CLIENT = 1,
    RUN_CARRIER = 2,
    RUN_INNER = 3,
}

export const AttachmentSubtypeNames: Map<AttachmentSubtype, string> = new Map([
    [AttachmentSubtype.RUN_CLIENT, 'Для Заказчика'],
    [AttachmentSubtype.RUN_CARRIER, 'Для Перевозчика'],
    [AttachmentSubtype.RUN_INNER, 'Внутренние'],
]);

export type AttachmentModel = {
    id: number;
    created: Date;
    fileName: string;
    createdBy: Option;
    kind?: Option;
    subtype?: Option;
};

export type AttachmentFileRequest = {
    attachmentType: AttachmentType;
    parentId: number;
    fileName: string;
};

export type ListAttachmentModel = {
    attachments: AttachmentModel[];
};