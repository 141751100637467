import polyline from '@mapbox/polyline';

const API_BASE_URL = 'https://router.project-osrm.org';
const API_PROFILE = 'driving';

/**
 * Fetches and decodes a polyline from OSRM based on provided coordinates or encoded polyline.
 * @param {number[][] | string} input - Array of coordinate pairs or encoded polyline string.
 * @returns {Promise<number[][]>} - Decoded polyline coordinates.
 */
const getPolylineFromOSRM = async (input: number[][] | string): Promise<number[][]> => {
    if (!input || input.length === 0) {
        return [];
    }

    const url: string = generateRequestUrl(input);

    try {
        const response = await fetch(url);
        const data = await response.json();
        const encodedPolyline = data.routes?.[0]?.geometry;

        if (encodedPolyline) {
            return polyline.decode(encodedPolyline as string);
        }
    } catch (err) {
        console.error('Error fetching route:', err);
        window.alert('Ошибка загрузки маршрута');
    }

    return [];
};

/**
 * Generates the appropriate request URL based on input type.
 * @param {number[][] | string} input - Array of coordinate pairs or encoded polyline string.
 * @returns {string} - The request URL.
 */
const generateRequestUrl = (input: number[][] | string): string => {
    if (typeof input === 'string') {
        return `${API_BASE_URL}/route/v1/${API_PROFILE}/polyline(${input})?overview=false`;
    }

    const coordinates = input.map(([longitude, latitude]) => `${longitude},${latitude}`).join(';');
    return `${API_BASE_URL}/route/v1/${API_PROFILE}/${coordinates}?overview=full`;
};

export default getPolylineFromOSRM;
