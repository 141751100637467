import React from 'react';
import { Option } from '../control/option';
import edi from '../../api/edi';
import FormSelect from './FormSelect';
import { MultiValue, SingleValue } from 'react-select';

type Props = {
    id: string;
    ediMessageId: number;
    onChange?: (newValue: any) => void;
};

const ListEdiQuotesOptions: React.FC<Props> = ({ id, ediMessageId, onChange }) => {
    const loadOptions = (filter: string, callback: (options: Option[]) => void) => {
        edi.quoteOptions({ filter, ediMessageId }).then(callback);
    };
    return (
        <FormSelect id={id}
            cacheOptions={false}
            loadOptions={loadOptions}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void} />
    );
};

export default ListEdiQuotesOptions;