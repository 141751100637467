import React from 'react';
import ListSelect from './base/ListSelect';
import { Option } from '../../control/option';
import contacts from '../../../api/contacts';

type Props = {
    id: string;
    storageId: number;
    onChange?: (newValue: any, rowNum: number) => void;
};

const ListStorageContacts: React.FC<Props> = ({ id, storageId, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        contacts
            .storageOptions({
                filter: inputValue,
                storageId,
            })
            .then((options) => callback(options));
    };
    return (
        <ListSelect id={id} loadOptions={loadOptions} cacheOptions={false} onChange={onChange} />
    );
};

export default ListStorageContacts;
