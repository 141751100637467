export enum EdiPartyField {
    ID = 'id',
    NAME = 'name',
    GLN = 'gln',
    INN = 'inn',
    KPP = 'kpp',
    ORDRSP = 'ordrsp'
}

export type EdiPartyListModel = {
    id: number;
    name: string;
    gln: string;
    inn: string;
    kpp: string;
    ordrsp: boolean;
};
