import React from 'react';
import clients from '../../api/clients';
import FormSelect from './FormSelect';

import type { Option } from '../control/option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';

type Props = {
    id: string;
    clientId: number;
    onChange?: (newValue: SingleValue<Option>) => void;
    nullable?: boolean;
};

const ActiveDrivers: React.FC<Props> = (props) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void): void => {
        clients
            .findActiveDrivers({
                filter: inputValue,
                clientId: props.clientId,
            })
            .then((options) => callback(options));
    };
    return (
        <FormSelect
            id={props.id}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={
                props.onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void
            }
            nullable={props.nullable}
        />
    );
};

export default ActiveDrivers;
