import { useContext, useEffect, useState } from 'react';
import { WsContext } from './StompWebSocketProvider';
import { StompSubscription } from '@stomp/stompjs';

interface WsSubscriptionProps<T> {
    endpoint: string;
    onMessage?: (message: WsMessage<T>) => void;
    listen?: boolean;
}

// we use wrapper here to be able to differentiate each message in case a simple data type recieved (like bool)
export interface WsMessage<T> {
    message: T;
}

export const useWsSubscription = <T,>({ endpoint, onMessage = () => {}, listen = true }: WsSubscriptionProps<T>) => {
    const { subscribe } = useContext(WsContext);
    useEffect(() => {
        let subscription: StompSubscription | undefined;
        let mounted = listen;
        if (listen && subscribe) {
            subscribe(endpoint, (message: T) => {
                mounted && onMessage({message});
            });
        }
        return () => {
            mounted = false;
            subscription?.unsubscribe();
        };
    }, [endpoint, listen, onMessage, subscribe]);
};

