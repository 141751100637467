import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from '../../layout/Modal';
import storages from '../../../api/storages';
import { StorageDistributionContext } from './StorageDistributionTable';
import { RootContext } from '../../layout/ContextWrapper';
import { PATH_VARIABLE_STORAGE_ID } from '../../../routerPaths';
import util from '../../../common/util';
import { QuoteModel } from '../../quotes/model/quoteModel';
import { StorageDistributionModel } from './storageDistributionModel';

/*
small optimization of API calls.

since there is a possibility that some shipment_details are still not in the assembly,
and therefore not on distribution - we still need to make a call to the server to check the distribution process in the database.
but this small optimization allows not to make a call to the api after every distributionModels update.
 */
const hasAnyDistributedQuote = (distributionModels: StorageDistributionModel[]): boolean => {
    const quoteMap = new Map<number, { tareTotal: number, tareDistributed: number }>();

    for (const distributionModel of distributionModels) {
        for (const client of distributionModel.clients) {
            for (const quote of client.quotes) {
                if (!quoteMap.has(quote.id)) {
                    quoteMap.set(quote.id, { tareTotal: 0, tareDistributed: 0 });
                }
                const aggregatedQuote = quoteMap.get(quote.id)!;
                aggregatedQuote.tareTotal += quote.tareTotal;
                aggregatedQuote.tareDistributed += quote.tareDistributed;
            }
        }
    }

    for (const { tareTotal, tareDistributed } of Array.from(quoteMap.values())) {
        if (tareTotal <= tareDistributed) {
            return true;
        }
    }

    return false;
};

export const ReadyToLoadConfirmModal: React.FC = () => {
    const { distributionData, incrementReload } = useContext(StorageDistributionContext);
    const { state: { uiState: { assortmentViewModeTable: isTableView } } } = useContext(RootContext);
    const { [PATH_VARIABLE_STORAGE_ID]: storageIdParam } = useParams();
    const storageId = util.toNumber(storageIdParam ?? '') ?? -1;
    const [show, setShow] = useState(false);
    const [quoteReadyToLoad, setQuoteReadyToLoad] = useState<QuoteModel | null>(null);
    const [updated, setUpdated] = useState<boolean>(false);

    useEffect(() => {
        const { distributionModels } = distributionData || {};
        const shouldFetchQuote = distributionModels?.length && hasAnyDistributedQuote(distributionModels);
        if (shouldFetchQuote) {
            storages.findAnyQuoteReadyToLoad(storageId)
                .then((quote) => {
                    setQuoteReadyToLoad(quote);
                    setShow(quote?.id > 0);
                });
        }
    }, [distributionData, storageId]);

    const onClose = (e: React.MouseEvent) => {
        e.preventDefault();
        if (updated) {
            incrementReload();
        }
        setShow(false);
        setUpdated(false);
        setQuoteReadyToLoad(null);
    };

    const onUpdate = (e: React.MouseEvent) => {
        e.preventDefault();
        const { id: quoteId } = quoteReadyToLoad ?? {};
        if (!quoteId) return;
        storages.setQuoteReadyToLoad(quoteId)
            .then(() => setUpdated(true));
    };

    const renderQuestionMessage = () => {
        const { id, clientQuoteNumber } = quoteReadyToLoad ?? {};
        const quoteNumber = clientQuoteNumber ?? id;
        return (
            <>
                <div>
                    <div>Заказ <span className='font-weight-bold'>{quoteNumber}</span></div>
                    {renderQuoteSummary()}
                </div>
                <div className='text-center mt-2'>
                    <button className={`btn ${isTableView ? 'btn-sm' : 'btn-lg'} btn-primary px-3`} onClick={onUpdate}>
                        Перевести на отгрузку
                    </button>
                    &nbsp;
                    <button className={`btn ${isTableView ? 'btn-sm' : 'btn-lg'} btn-secondary px-3`} onClick={onClose}>
                        Выйти
                    </button>
                </div>
            </>
        );
    };

    const renderSuccessMessage = () => {
        const { id, clientQuoteNumber } = quoteReadyToLoad ?? {};
        const quoteNumber = clientQuoteNumber ?? id;
        if (updated) {
            return (
                <div>
                    <div>Заказ&nbsp;<span className='font-weight-bold'>{quoteNumber}</span>&nbsp;<span className='text-success'>был переведен</span> в зону отгрузки.</div>
                    {renderQuoteSummary()}
                </div>
            );
        }
        return (
            <div>Ошибка! Заказ {quoteNumber} <span className='text-danger'>не был переведен</span> в зону отгрузки.</div>
        );
    };

    const renderQuoteSummary = () => {
        const { unloadingStorage, unloadingAddressName, unloadingAddress, shipmentDetails } = quoteReadyToLoad ?? {};
        return (
            <>
                <div>Склад разгрузки: {unloadingStorage?.label ?? unloadingAddressName ?? unloadingAddress?.label}</div>
                <div>Номенклатура по заказу:</div>
                {(shipmentDetails ?? []).map((sd, index) => (
                    <div key={sd.id}>
                        {`${index + 1}. ${sd.companyAssortment?.label}, ${sd.weight}кг`}
                    </div>
                ))}
            </>
        );
    };

    return (
        <Modal show={show} onClose={onClose} title='Перевод заказа в зону отгрузки'>
            {updated ? renderSuccessMessage() : renderQuestionMessage()}
        </Modal>
    );
}