import React from 'react';
import contacts from '../../api/contacts';
import FormSelect from './FormSelect';

import type { Option } from '../control/option';

type Props = {
    id: string;
    storageId: number;
    readOnly?: boolean;
};

const StorageContacts: React.FC<Props> = ({ id, storageId, readOnly }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        contacts
            .storageOptions({
                filter: inputValue,
                storageId,
            })
            .then((options) => callback(options));
    };

    return (
        <FormSelect id={id} loadOptions={loadOptions} cacheOptions={false} readOnly={readOnly} />
    );
};

export default StorageContacts;
