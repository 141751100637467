import util from './util';

const PREFIX = 'Logistic_';

export default class Cache {
    static findInStorage = <T>(key: string): T | null => {
        const rawValue = localStorage.getItem(PREFIX + key);
        return rawValue ? (JSON.parse(rawValue) as T) : null;
    };

    static get = <T>(key: string, initial: T): T => {
        const stored = this.findInStorage(key);
        return stored ? util.deepMerge(initial, stored) : initial;
    };

    static set = <T>(key: string, value: T) => {
        localStorage.setItem(PREFIX + key, JSON.stringify(value));
    };
}
