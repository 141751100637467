export enum CompanyField {
    ID = 'id',
    CUSTOMER_ID = 'customerId',
    PARENT_ID = 'parentId',
    NAME = 'name',
    FULL_NAME = 'fullName',
    INN = 'inn',
    KPP = 'kpp',
    OGRN = 'ogrn',
    PHONE = 'phone',
    POSTAL_ADDRESS = 'postalAddress',
    ACTUAL_ADDRESS = 'actualAddress',
    ADDRESS = 'address',
    HEAD = 'head',
    HEAD_POSITION = 'headPosition',
    HEAD_GENITIVE = 'headGenitive',
    BASIS = 'basis',
}
