import React, { MouseEvent, useCallback, useContext, useEffect, useMemo } from 'react';
import util from '../../../common/util';
import { Config } from '../../table/config';
import { RemoveButton } from '../../table/RemoveButton';
import { FormContext, useFieldValue, useSetFieldValue, validateField } from '../../form/DwForm';
import { useIsMounted } from '../../../common/isMounted';
import { EditableDwTable } from '../../table/EditableDwTable';
import { PalletPassportListModel } from '../model/talman/palletPassportModel';
import { PalletPassportField } from './palletPassportField';
import { TalmanModel } from '../model/talman/talmanModel';
import { TalmanField } from './talmanField';
import docs from '../../../api/docs';
import { FileType } from '../../../model/fileType';
import clsx from 'clsx';

type Props = {
    id: string;
};

const onDownload = (e: MouseEvent, id: number) => {
    e.preventDefault();
    docs.palletPassport(id, FileType.PDF).then();
};

const onChangeRow = (
    newValue: any,
    rowNum: number,
    row: PalletPassportListModel,
    field: PalletPassportField,
    palletPassports: PalletPassportListModel[],
    onChange: (palletPassports: PalletPassportListModel[]) => void
): Promise<void> => {
    return new Promise<void>((resolve) => {
        if (rowNum === 0) {
            const newPalletPassports = [...palletPassports];
            newPalletPassports
                .filter(pp => !pp[field] || pp[field] === row[field])
                .forEach(pp => {
                    pp[field] = newValue;
                });
            onChange(newPalletPassports);
        }
        resolve();
    });
};

const useFormConfig = (
    id: string,
    palletPassportsRemaining: number,
    palletPassports: PalletPassportListModel[],
    onChange: (palletPassports: PalletPassportListModel[]) => void,
    onAdd: (e: React.UIEvent<HTMLElement> | React.KeyboardEvent) => void,
    onDelete: (e: MouseEvent, row: PalletPassportListModel, index: number) => void,
) =>
    useMemo(
        () =>
            Config.builder<PalletPassportListModel>()
                .column((builder) => builder.position('#').cls('id'))
                .column((builder) => builder.integer('Количество мест', PalletPassportField.BOXES).editable().cls('number'))
                .column((builder) => builder.decimal('Фактический вес брутто, кг', PalletPassportField.GROSS_WEIGHT).editable().cls('number'))
                .column((builder) =>
                    builder
                        .decimal('Вес пустой коробки', PalletPassportField.BOX_WEIGHT)
                        .editable()
                        .onChangeRow((newValue, rowNum, row) =>
                            onChangeRow(newValue, rowNum, row, PalletPassportField.BOX_WEIGHT, palletPassports, onChange))
                        .cls('number')
                )
                .column((builder) =>
                    builder
                        .decimal('Вес пустого поддона + скрепл. материалов', PalletPassportField.PALLET_WEIGHT)
                        .editable()
                        .onChangeRow((newValue, rowNum, row) =>
                            onChangeRow(newValue, rowNum, row, PalletPassportField.PALLET_WEIGHT, palletPassports, onChange))
                        .cls('number')
                )
                .column((builder) => builder.label('Фактический вес нетто, кг').getter(calcNetWeight).cls('number'))
                .column((builder) =>
                    builder
                        .getter((model) => {
                            if (model.id > 0) {
                                return (
                                    <button className='btn btn-xs btn-primary' onClick={(e) => onDownload(e, model.id)}>
                                        <i className='icon fa fa-download'/>
                                    </button>
                                );
                            }
                            return <></>;
                        })
                        .cls('text-center actions')
                        .width('20px')
                )
                .column((builder) =>
                    builder
                        .getter((model, index) => (
                            <RemoveButton onClick={(e: MouseEvent) => onDelete(e, model, index!)}/>
                        ))
                        .cls('text-center actions')
                        .width('20px')
                )
                .id(id)
                .captionControl(() => (
                    <button
                        className={clsx(
                            'btn btn-outline-primary btn-sm',
                            {
                                'disabled': palletPassportsRemaining <= 0
                            }
                        )}
                        onClick={e => palletPassportsRemaining > 0 ? onAdd(e) : e.preventDefault()}
                    >
                        добавить
                    </button>
                ))
                .build(),
        [id, palletPassportsRemaining, palletPassports, onChange, onAdd, onDelete]
    );

const calcNetWeight = (model: PalletPassportListModel) => {
    const { grossWeight, boxes, boxWeight, palletWeight } = model;
    if (grossWeight > 0) {
        let netWeight = grossWeight;
        if (boxes > 0 && boxWeight > 0) {
            netWeight -= boxes * boxWeight;
        }
        if (palletWeight > 0) {
            netWeight -= palletWeight;
        }
        return netWeight > 0 ? parseFloat(netWeight.toFixed(2)) : 0;
    }
    return 0;
};

const calcTotalNetWeight = (palletPassports: PalletPassportListModel[]) => palletPassports.reduce(
    (total, passport) => total + Number(calcNetWeight(passport)), 0
).toFixed(2);

export const PalletPassportTable: React.FC<Props> = ({id}) => {
    const context = useContext(FormContext);
    const model = context.state.model as TalmanModel;
    const setValue = useSetFieldValue();
    const fieldConfig = context.state.config.field(id);
    const palletPassportsDataValue = fieldConfig.getter(model);
    const palletPassportsData: PalletPassportListModel[] = useMemo(() => palletPassportsDataValue ?? [], [palletPassportsDataValue]);
    const clientAssortmentBoxes = useFieldValue(TalmanField.CLIENT_ASSORTMENT_BOXES);
    const maxPalletPassports = useFieldValue(TalmanField.MAX_PALLET_PASSPORTS);
    const palletPassportsRemaining = useMemo(() => (maxPalletPassports ?? 0) - palletPassportsData.length, [palletPassportsData, maxPalletPassports]);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [palletPassportsData]);

    const onChange = useCallback((palletPassports: PalletPassportListModel[]) => setValue(id, palletPassports), [id, setValue]);

    const onDelete = (e: MouseEvent, row: PalletPassportListModel, index: number) => {
        e.preventDefault();
        const palletPassports = [...palletPassportsData];
        palletPassports.splice(index, 1);
        onChange(palletPassports);
    };

    const onAdd = useCallback((e: React.UIEvent<HTMLElement> | React.KeyboardEvent): void => {
        e.preventDefault();
        const { boxWeight: defaultBoxWeight, palletWeight: defaultPalletWeight } = palletPassportsData[0] || {};
        const palletPassport: Partial<PalletPassportListModel> = {
            boxes: clientAssortmentBoxes,
            boxWeight: defaultBoxWeight,
            palletWeight: defaultPalletWeight,
        };
        onChange([...palletPassportsData, palletPassport as PalletPassportListModel]);
    }, [palletPassportsData, onChange, clientAssortmentBoxes]);

    const config = useFormConfig(id, palletPassportsRemaining, palletPassportsData, onChange, onAdd, onDelete);

    return (
        <div className='pallet-passports'>
            <EditableDwTable config={config} header={'Паспорта паллет'}>
                <tr className={'summary-row'}>
                    <td className='text-center'>Итого</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.BOXES)}</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.GROSS_WEIGHT, 2)}</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.BOX_WEIGHT, 2)}</td>
                    <td>{util.sumOverObjectProps(palletPassportsData, PalletPassportField.PALLET_WEIGHT, 2)}</td>
                    <td>{parseFloat(calcTotalNetWeight(palletPassportsData))}</td>
                    <td />
                </tr>
            </EditableDwTable>
        </div>
    );
};