import React from 'react';
import { AttachmentType } from '../../../model/attachment';
import AttachmentsTable from './AttachmentsTable';
import UploadArea from './UploadArea';

type Props = {
    id: string;
    attachmentType: AttachmentType;
    parentId: number;
};

const AttachmentsArea: React.FC<Props> = (props) => {
    return (
        <div className={'form-group attachments-area'}>
            <h6><strong>Загрузка скан-копий документов</strong></h6>
            <AttachmentsTable {...props} />
            <UploadArea {...props} />
        </div>
    );
};

export default AttachmentsArea;
