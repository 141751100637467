import { post } from './rest';
import { LoginModel } from '../component/layout/login/loginModel';
import { LoginResponse } from './loginResponse';

const auth = {
    login: (model: LoginModel) => post<LoginResponse>('auth', model),
    logout: () => post<void>('auth/logout', {}),
    TOKEN: 'token',
};

export default auth;
