import React from 'react';
import users from '../../api/users';
import FormSelect from './FormSelect';

import type { Role } from '../../model/role';
import type { Option } from '../control/option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';

type Props = {
    id: string;
    role: Role;
    companyId?: number;
    onChange?: (newValue: SingleValue<Option>) => void;
    nullable?: boolean;
};

const Users: React.FC<Props> = (props) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        users
            .options({
                filter: inputValue,
                role: props.role,
                companyId: props.companyId,
            })
            .then((options) => callback(options));
    };
    return (
        <FormSelect
            id={props.id}
            loadOptions={loadOptions}
            onChange={props.onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
            cacheOptions={false}
            nullable={props.nullable}
        />
    );
};

export default Users;
