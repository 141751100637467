import React, { type MouseEvent, useCallback, useContext, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { PATH_CP_CLIENT_ADDRESSES, PATH_VARIABLE_CLIENT_ADDRESS_ID } from '../../../../routerPaths';
import ContextError from '../../../form/ContextError';
import { DwForm, FormContext, useFieldValue, useSetFieldValue } from '../../../form/DwForm';
import StringInput from '../../../form/StringInput';
import { FormConfig } from '../../../form/formConfig';
import { ClientFormContext } from '../ClientForm';
import util from '../../../../common/util';
import { useRefreshContextAction } from '../../../table/DwTable';
import { AddressField, ClientAddressField } from './clientAddressFields';
import addresses from '../../../../api/addresses';
import { ClientAddressModel } from '../../../../model/client/address';
import { FieldType } from '../../../form/fieldType';
import { Context } from '../../../form/context';
import { AddressModel } from '../../../../model/address';
import {
    addressValidator,
    latitudeValidator,
    longitudeValidator,
} from '../../../../common/validation/commonValidators';
import IsoOptions from '../../../form/IsoOptions';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';
import Options from '../../../form/Options';
import { DictionaryType, Option } from '../../../control/option';
import { showSuccessToast, showWarningToast } from '../../../control/showToast';
import { Addresses } from './Addresses';

const useFormConfig = (refresh: () => void, onSubmit: (model: ClientAddressModel, nullifiedFields?: string[]) => Promise<any>, load: (id: number) => Promise<ClientAddressModel>, redirectUrl: string, pathVariableName: string) =>
    useMemo(
        () =>
            FormConfig.builder<ClientAddressModel>()
                .number(
                    ClientAddressField.ID,
                    (m) => m.id,
                    (m, v) => (m.id = v)
                )
                .requiredText(
                    ClientAddressField.NAME,
                    (m) => m.name,
                    (m, v) => (m.name = v)
                )
                .text(
                    ClientAddressField.SPECIFIED_ADDRESS,
                    (m) => m.specifiedAddress?.value!,
                    (m, v) => (m.specifiedAddress = {value: !v?.trim().length ? null : v})
                )
                .number(
                    ClientAddressField.LATITUDE,
                    (m) => m.latitude?.value!,
                    (m, v) => (m.latitude = {value: v === 0 ? null : v}),
                    (m: ClientAddressModel) => latitudeValidator(m.latitude?.value)
                )
                .number(
                    ClientAddressField.LONGITUDE,
                    (m) => m.longitude?.value!,
                    (m, v) => (m.longitude = {value: v === 0 ? null : v}),
                    (m: ClientAddressModel) => longitudeValidator(m.longitude?.value)
                )
                .option(
                    ClientAddressField.TYPE,
                    (m) => m.type,
                    (m, v) => (m.type = v)
                )
                .text(
                    ClientAddressField.CADASTRAL_NUMBER,
                    (m) => m.cadastralNumber,
                    (m, v) => (m.cadastralNumber = v)
                )
                .field(
                    ClientAddressField.ADDRESS,
                    FieldType.OBJECT,
                    (m) => m.address ?? {},
                    (m, v) => (m.address = v),
                    (m: ClientAddressModel) => addressValidator(m.address)
                )
                .number(
                    ClientAddressField.ADDRESS_ID,
                    (m) => m.address?.id,
                    (m, v) => (m.address = { ...m.address, id: v })
                )
                .text(
                    AddressField.CITY,
                    (m) => m.address?.city,
                    (m, v) => (m.address = { ...m.address, city: v })
                )
                .text(
                    AddressField.STREET,
                    (m) => m.address?.street,
                    (m, v) => (m.address = { ...m.address, street: v })
                )
                .option(
                    AddressField.COUNTRY_ISO,
                    (m) => m.address?.countryIso,
                    (m, v) => (m.address = { ...m.address, countryIso: v })
                )
                .option(
                    AddressField.REGION_ISO,
                    (m) => m.address?.regionIso,
                    (m, v) => (m.address = { ...m.address, regionIso: v })
                )
                .number(
                    AddressField.LATITUDE,
                    (m) => m.address?.latitude,
                    (m, v) => (m.address = { ...m.address, latitude: v }),
                    (m: ClientAddressModel) => latitudeValidator(m.address?.latitude)
                )
                .number(
                    AddressField.LONGITUDE,
                    (m) => m.address?.longitude,
                    (m, v) => (m.address = { ...m.address, longitude: v }),
                    (m: ClientAddressModel) => longitudeValidator(m.address?.longitude)
                )
                .load(load)
                .submit(onSubmit)
                .idPathVariableName(pathVariableName)
                .redirectUrl(redirectUrl)
                .afterSubmit((_) => refresh())
                .build(),
        [load, onSubmit, pathVariableName, redirectUrl, refresh]
    );

const ClientAddress: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const redirectUrl = useMemo(() => generatePath(PATH_CP_CLIENT_ADDRESSES, {
        clientId: util.stringOrEmpty(clientId),
    }), [clientId])
    const onSubmit = useCallback((model: ClientAddressModel) => addresses.saveClientAddress({ ...model, clientId }), [clientId]);
    const load = useCallback((id: number) => id && clientId ? addresses.getForClient(id, clientId) : Promise.resolve({} as ClientAddressModel), [clientId]);
    return (
        <ClientAddressFormInner {...{onSubmit, load, redirectUrl, pathVariableName: PATH_VARIABLE_CLIENT_ADDRESS_ID,  isCompany: false}}/>
    );
};

const valueOrPlaceholder = (value: string) => (value?.length ? value : '-');
const emptyAddress = { city: '', street: '' } as AddressModel;

const ClientAddressLayout: React.FC<{isCompany: boolean}> = ({isCompany}) => {
    const context = useContext(FormContext) as Context<ClientAddressModel>;
    const {
        state: { model, errors }, resetErrors
    } = context;
    const setValue = useSetFieldValue();
    const addressError = errors.get(ClientAddressField.ADDRESS);
    const address = useMemo(() => model.address ?? emptyAddress, [model]);
    const currentCountryISO = useFieldValue(AddressField.COUNTRY_ISO);
    const hasIdOrFiasId = address?.id > 0 || address?.fiasId;
    const onAddressLookupChange = (option: Option) => {
        if (option.value) {
            addresses
                .getById(option.value)
                .then(address => {
                    setValue(ClientAddressField.ADDRESS, address);
                    resetErrors();
                })
        }
    }

    const onGetSuggestionClick = (event: React.MouseEvent) => {
        event.preventDefault();
        addresses.getSuggestion(address).then((suggestion) => {
            if (suggestion?.fiasId || suggestion.id) {
                showSuccessToast('Адрес найден');
                setValue(ClientAddressField.ADDRESS, suggestion);
            } else {
                showWarningToast('Адрес не найден');
            }
        });
    };

    const onClean = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            setValue(ClientAddressField.ADDRESS, null);
            setValue(ClientAddressField.LATITUDE, null);
            setValue(ClientAddressField.LONGITUDE, null);
            setValue(ClientAddressField.SPECIFIED_ADDRESS, null);
        },
        [setValue]
    );

    return (
        <div>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row align-items-center'>
                        <div className='col-4'>
                            Название
                            <StringInput id={ClientAddressField.NAME} />
                        </div>
                    </div>
                    <div className='row align-items-center'>
                        <hr className='col-1 mx-2' />
                        <div className='col-auto'>Адрес</div>
                        <hr className='col mx-2' />
                    </div>
                    {hasIdOrFiasId ? (
                        <>
                            <div className='row align-items-center'>
                                <div className='col-12'>
                                    Адрес{address?.fiasId ? ' из DaData' : ''}
                                    <div className='form-group form-control-sm'>
                                        {valueOrPlaceholder(
                                            [address.city, address.street]
                                                .filter((it) => it)
                                                .join(', ')
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-3'>
                                    ISO код страны/региона
                                    <div className='form-group form-control-sm'>
                                        {valueOrPlaceholder([address?.countryIso, address?.regionIso]
                                            .filter(iso => iso?.label)
                                            .map(iso => valueOrPlaceholder(iso.label))
                                            .join(' / '))}
                                    </div>
                                </div>
                                <div className='col-3'>
                                    Широта, долгота
                                    <div className='form-group form-control-sm'>
                                        {`${valueOrPlaceholder(address?.latitude ? String(address?.latitude) : '')}, ${valueOrPlaceholder(address?.longitude ? String(address?.longitude) : '')}`}
                                    </div>
                                </div>
                                <div className='col'>
                                    ФИАС
                                    <div className='form-group form-control-sm'>
                                        {valueOrPlaceholder(address.fiasId)}
                                    </div>
                                </div>
                                <div className='col-auto d-flex justify-content-end'>
                                    <button className='btn btn-sm btn-outline-danger' onClick={onClean}>
                                        Сбросить
                                    </button>
                                </div>
                            </div>
                        </>) : (<>
                            <div className='row align-items-center'>
                                <div className='col-3'>
                                    Город (населенный пункт)
                                    {hasIdOrFiasId ? (
                                        <div className='form-group form-control-sm'>
                                            {valueOrPlaceholder(address.city)}
                                        </div>
                                    ) : (
                                        <StringInput id={AddressField.CITY} />
                                    )}
                                </div>
                                <div className='col-3'>
                                    Адрес
                                    {hasIdOrFiasId ? (
                                        <div className='form-group form-control-sm'>
                                            {valueOrPlaceholder(address.street)}
                                        </div>
                                    ) : (
                                        <StringInput id={AddressField.STREET} />
                                    )}
                                </div>
                                {!hasIdOrFiasId && <>
                                    <div className='col-auto'>
                                        из DaData
                                        <div className='form-group'>
                                            <button className='btn btn-sm btn-primary' onClick={onGetSuggestionClick}>
                                                Заполнить
                                            </button>
                                        </div>
                                    </div>
                                    <div className='col-auto'>
                                        или
                                    </div>
                                    <div className='col'>
                                        Выбрать существующий адрес
                                        <Addresses onChange={onAddressLookupChange} />
                                    </div>
                                </>}
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-3'>
                                    ISO код страны
                                    <IsoOptions
                                        id={AddressField.COUNTRY_ISO}
                                        onChange={(f) => setValue(AddressField.REGION_ISO, {})}
                                    />
                                </div>
                                <div className='col-3'>
                                    ISO код региона
                                    <IsoOptions
                                        id={AddressField.REGION_ISO}
                                        isRegion={true}
                                        country={currentCountryISO?.label}
                                    />
                                </div>
                                <div className='col-3'>
                                    Широта
                                    <StringInput id={AddressField.LATITUDE} clearable/>
                                </div>
                                <div className='col-3'>
                                    Долгота
                                    <StringInput id={AddressField.LONGITUDE} clearable/>
                                </div>
                            </div>
                        </>)}
                    {!!addressError && (
                        <div className='row align-items-center'>
                            <div className='col alert alert-danger' role='alert'>
                                {addressError}
                            </div>
                        </div>
                    )}
                    {(hasIdOrFiasId || isCompany) && <>
                        <div className='row align-items-center'>
                            <hr className='col-1 mx-2' />
                            <div className='col-auto'>Уточнения к адресу</div>
                            <hr className='col mx-2' />
                        </div>
                        {hasIdOrFiasId &&
                            <div className='row align-items-center'>
                                <div className='col-6'>
                                    Уточненный адрес / комментарий
                                    <StringInput id={ClientAddressField.SPECIFIED_ADDRESS} />
                                </div>
                                <div className='col-3'>
                                    Широта
                                    <StringInput id={ClientAddressField.LATITUDE} clearable/>
                                </div>
                                <div className='col-3'>
                                    Долгота
                                    <StringInput id={ClientAddressField.LONGITUDE} clearable/>
                                </div>
                            </div>}
                        {isCompany &&
                            <div className='row align-items-center'>
                                <div className='col-3'>
                                    Тип
                                    <Options id={ClientAddressField.TYPE} dictionaryType={DictionaryType.STORAGE_TYPE} />
                                </div>
                                <div className='col-3'>
                                    Кадастровый номер
                                    <StringInput id={ClientAddressField.CADASTRAL_NUMBER} />
                                </div>
                            </div>}
                    </>}
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};

type ClientAddressFormInnerProps = {
    onSubmit: (model: ClientAddressModel) => Promise<any>,
    load: (id: number) => Promise<ClientAddressModel>,
    redirectUrl: string,
    pathVariableName: string,
    isCompany: boolean
}

export const ClientAddressFormInner: React.FC<ClientAddressFormInnerProps> = ({onSubmit, load, redirectUrl, pathVariableName, isCompany}) => {
    const { refresh } = useRefreshContextAction();
    const formConfig = useFormConfig(refresh, onSubmit, load, redirectUrl, pathVariableName);
    return (
        <DwForm config={formConfig}>
            <ClientAddressLayout {...{isCompany}}/>
            <ContextError />
        </DwForm>
    );
};

export default ClientAddress;
