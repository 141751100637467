import React from 'react';
import StringInput from '../../form/StringInput';
import { AssortmentField } from './assortmentFields';
import Options from '../../form/Options';
import { DictionaryType } from '../../control/option';
import NumberInput from '../../form/NumberInput';
import CheckboxInput from '../../form/CheckboxInput';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';

const Assortment: React.FC = () => {
    return (
        <div>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-4'>
                            Наименование
                            <StringInput id={AssortmentField.VALUE} />
                        </div>
                        <div className='col-4'>
                            <CheckboxInput id={AssortmentField.ACTIVE} label={'Активен'} />
                        </div>
                        <div className='col' />
                        <div className='col-2'>
                            НДС
                            <Options id={AssortmentField.NDS_MODE} dictionaryType={DictionaryType.NDS_MODE} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-4'>
                            Сорт
                            <Options
                                id={AssortmentField.GRADE}
                                dictionaryType={DictionaryType.GRADE}
                            />
                        </div>
                        <div className='col-4'>
                            Единица измерения
                            <Options
                                id={AssortmentField.UNIT}
                                dictionaryType={DictionaryType.UNIT}
                            />
                        </div>
                        <div className='col-4'>
                            Количество
                            <NumberInput id={AssortmentField.QUANTITY} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};

export default Assortment;
