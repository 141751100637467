import type { MouseEvent } from 'react';
import React, { useCallback, useContext } from 'react';
import accounts from '../../../api/accounts';
import { FormContext, useSetFieldValue } from '../../form/DwForm';
import StringInput from '../../form/StringInput';
import { AccountField } from './accountField';
import type { AccountModel } from '../../../model/account';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import { showSuccessToast, showWarningToast } from '../../control/showToast';

const AccountForm: React.FC = () => {
    const context = useContext(FormContext);
    const { model } = context.state;
    const setValue = useSetFieldValue();

    const updateFormValues = useCallback(
        (account: AccountModel) => {
            if (Object.keys(account)?.length > 0) {
                setValue(AccountField.BIK, account.bik);
                setValue(AccountField.BANK, account.bank);
                setValue(AccountField.CORRESPONDENT_NUMBER, account.correspondentNumber);
            }
        },
        [setValue]
    );

    const onSuggestion = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const suggest = async () => {
                const account: AccountModel = await accounts.getSuggestion(model as AccountModel);

                if (Object.keys(account)?.length !== 0) {
                    updateFormValues(account);
                    showSuccessToast('Банк найден');
                } else {
                    showWarningToast('Банк не найден');
                }
            };

            suggest().then();
        },
        [model, updateFormValues]
    );

    const onClean = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const emptyModel = {
                bik: '',
                bank: '',
                correspondentNumber: '',
            } as AccountModel;
            updateFormValues(emptyModel);
        },
        [updateFormValues]
    );

    return (
        <div>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row align-items-center'>
                        <div className='col-lg-3'>
                            Р/Счет
                            <StringInput id={AccountField.NUMBER} />
                        </div>
                        <div className='col-lg-3'>
                            Банк
                            <StringInput id={AccountField.BANK} />
                        </div>
                        <div className='col-lg-3'>
                            БИК
                            <StringInput id={AccountField.BIK} />
                        </div>
                        <div className='col-lg-3'>
                            Корр. счет
                            <StringInput id={AccountField.CORRESPONDENT_NUMBER} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center'>
                <button className='btn btn-sm btn-primary' onClick={onSuggestion}>
                    Заполнить
                </button>
                &nbsp;
                <SaveAndCancelButtonGroup />
                &nbsp;
                <button className='btn btn-sm btn-outline-primary' onClick={onClean}>
                    Очистить
                </button>
            </div>
        </div>
    );
};

export default AccountForm;
