import React from 'react';
import { Outlet } from 'react-router';
import { generatePath } from 'react-router-dom';
import users from '../../../../api/users';
import util from '../../../../common/util';
import {
    crmUserPasswordValidator,
    crmUserPhoneValidator,
    crmUserEmailValidator,
    crmUserPassportNumberValidatorForRole,
    crmUserPassportDateValidatorForRole,
    crmUserInnValidatorForRole
} from '../../../../common/validation/crmUserValidation';
import {
    PATH_CP_DRIVER,
    PATH_CP_DRIVER_HISTORY,
    PATH_CP_DRIVERS,
    PATH_VARIABLE_DRIVER_ID,
} from '../../../../routerPaths';
import { Role } from '../../../../model/role';
import ContextError from '../../../form/ContextError';
import { DwForm, useFieldValue } from '../../../form/DwForm';
import DwFormTabs from '../../../form/DwFormTabs';
import { FormConfig } from '../../../form/formConfig';
import { CrmUserField } from '../crmUserField';

import type { CrmUserModel } from '../../../../model/crmUser/crmUserModel';
import type { Option } from '../../../control/option';
import { FieldType } from '../../../form/fieldType';
import { AttachmentModel } from '../../../../model/attachment';

const formConfig = FormConfig.builder<CrmUserModel>()
    .number(
        CrmUserField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .requiredText(
        CrmUserField.LAST_NAME,
        (m) => m.lastName,
        (m, v) => (m.lastName = v)
    )
    .text(
        CrmUserField.FIRST_NAME,
        (m) => m.firstName,
        (m, v) => (m.firstName = v)
    )
    .text(
        CrmUserField.MID_NAME,
        (m) => m.midName,
        (m, v) => (m.midName = v)
    )
    .text(
        CrmUserField.EMAIL,
        (m) => m.email,
        (m, v) => (m.email = v),
        crmUserEmailValidator
    )
    .text(
        CrmUserField.PHONE,
        (m) => m.phone,
        (m, v) => (m.phone = v),
        crmUserPhoneValidator
    )
    .field(
        CrmUserField.PASSWORD,
        FieldType.PASSWORD,
        (m) => m.password,
        (m, v) => (m.password = v),
        (m) => crmUserPasswordValidator(m as CrmUserModel)
    )
    .text(
        CrmUserField.PASSPORT_NUMBER,
        (m) => m.passportNumber,
        (m, v) => (m.passportNumber = v),
        (m) => crmUserPassportNumberValidatorForRole(m, Role.DRIVER, true)
    )
    .boolean(
        CrmUserField.PASSPORT_ALIEN,
        (m) => m.passportAlien,
        (m, v) => (m.passportAlien = v)
    )
    .dateTime(
        CrmUserField.PASSPORT_DATE,
        (m) => m.passportDate,
        (m, v) => (m.passportDate = v),
        (m) => crmUserPassportDateValidatorForRole(m, Role.DRIVER, true)
)
    .requiredText(
        CrmUserField.PASSPORT_DEPARTMENT,
        (m) => m.passportDepartment,
        (m, v) => (m.passportDepartment = v)
    )
    .text(
        CrmUserField.LICENCE_NUMBER,
        (m) => m.licenceNumber,
        (m, v) => (m.licenceNumber = v)
    )
    .dateTime(
        CrmUserField.LICENCE_DATE,
        (m) => m.licenceDate,
        (m, v) => (m.licenceDate = v)
    )
    .dateTime(
        CrmUserField.BIRTH_DATE,
        (m) => m.birthDate,
        (m, v) => (m.birthDate = v)
    )
    .text(
        CrmUserField.INN,
        (m) => m.inn,
        (m, v) => (m.inn = v),
        (m) => crmUserInnValidatorForRole(m, Role.DRIVER, false)
    )
    .text(
        CrmUserField.SNILS,
        (m) => m.snils,
        (m, v) => (m.snils = v)
    )
    .requiredOption(
        CrmUserField.STATUS,
        (m) => m.status,
        (m, v) => (m.status = v)
    )
    .list<Option>(
        CrmUserField.DRIVER_CLIENTS,
        (m) => m.driverClients,
        (m, v) => (m.driverClients = v)
    )
    .boolean(
        CrmUserField.ACTIVE,
        (m) => m.active,
        (m, v) => (m.active = v)
    )
    .text(
        CrmUserField.COMMENT,
        (m) => m.comment,
        (m, v) => (m.comment = v)
    )
    .list<AttachmentModel>(
        CrmUserField.ATTACHMENTS,
        (m) => m.attachments,
        (m, v) => (m.attachments = v)
    )
    .load((id: number) => users.getForRole(id, Role.DRIVER))
    .submit((model) => users.saveWithRole(model, Role.DRIVER))
    .redirectUrl(PATH_CP_DRIVERS)
    .idPathVariableName(PATH_VARIABLE_DRIVER_ID)
    .build();

const DriverForm: React.FC = () => (
    <DwForm config={formConfig}>
        <DriverLayout />
    </DwForm>
);

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const DriverLayout: React.FC = () => {
    const driverId = useFieldValue(CrmUserField.ID);
    const firstName = useFieldValue(CrmUserField.FIRST_NAME) ?? '';
    const midName = useFieldValue(CrmUserField.MID_NAME) ?? '';
    const lastName = useFieldValue(CrmUserField.LAST_NAME) ?? '';
    const driverCroppedName = `${lastName} ${util.cropName(firstName, true)}${util.cropName(midName, true)}`;
    const isNew = !driverId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новый водитель' : driverCroppedName,
                        path: generatePath(PATH_CP_DRIVER, {
                            [PATH_VARIABLE_DRIVER_ID]: util.stringOrEmpty(driverId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_CP_DRIVER_HISTORY, {
                            [PATH_VARIABLE_DRIVER_ID]: util.stringOrEmpty(driverId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError />
            <Outlet />
        </>
    );
};

export default DriverForm;
