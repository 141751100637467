import React from 'react';
import { generatePath } from 'react-router-dom';
import {
    PATH_STORAGE_ASSEMBLY,
    PATH_STORAGE_ASSEMBLY_PLAN,
    PATH_STORAGE_DISTRIBUTION,
    PATH_STORAGE_LOADING,
    PATH_STORAGE_QUOTES,
    PATH_VARIABLE_PRODUCTION_LINE_ID,
    PATH_VARIABLE_STORAGE_ID,
} from '../../routerPaths';
import DwFormTabs from '../form/DwFormTabs';

export const StorageAssemblyNav = () => (
    <div className='px-2 px-md-0'>
        <DwFormTabs
            items={[
                {
                    name: 'Заказы склада',
                    path: PATH_STORAGE_QUOTES,
                },
                {
                    name: 'План сборки',
                    path: PATH_STORAGE_ASSEMBLY_PLAN,
                },
                {
                    name: 'Сборка',
                    path: generatePath(PATH_STORAGE_ASSEMBLY, {
                        [PATH_VARIABLE_PRODUCTION_LINE_ID]: '',
                    }),
                },
                {
                    name: 'Распределение',
                    path: generatePath(PATH_STORAGE_DISTRIBUTION, {
                        [PATH_VARIABLE_STORAGE_ID]: '',
                    }),
                },
                {
                    name: 'Погрузка',
                    path: PATH_STORAGE_LOADING,
                },
            ]}
        />
    </div>
);
