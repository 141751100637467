import React from 'react';
import {generatePath} from 'react-router-dom';
import {Config} from '../../table/config';
import {DwTable} from '../../table/DwTable';
import {PATH_STORAGE_TMC_OPERATION, PATH_VARIABLE_TMC_OPERATION_ID} from '../../../routerPaths';
import {TmcOperationListModel} from '../../../model/tmcoperation/tmcOperationListModel';
import {TmcOperationField} from './tmcOperationField';
import tmcOperations from '../../../api/tmcOperations';
import {ColumnType} from '../../table/columnType';

const config = Config.builder<TmcOperationListModel>()
    .column((builder) => builder.id())
    .column((builder) => builder.dateTime('Дата', TmcOperationField.DT))
    .column((builder) =>
        builder
            .label('Операция')
            .field(TmcOperationField.TYPE)
            .type(ColumnType.OPERATION_TYPE)
    )
    .column((builder) =>
        builder
            .supplier('Поставщик', TmcOperationField.CLIENT)
            .getter((model) => model.client)
    )
    .column((builder) =>
        builder
            .storage('Склад', TmcOperationField.CLIENT_ADDRESS)
            .getter((model) => model.clientAddress)
    )
    .column((builder) => builder.tmc('ТМЦ', TmcOperationField.TMC_NAME))
    .column((builder) =>
        builder
            .label('Ед. изм.')
            .field(TmcOperationField.UNIT)
            .type(ColumnType.UNIT)
            .sortable(false)
    )
    .column((builder) => builder.integer('Кол-во', TmcOperationField.QUANTITY))
    .column((builder) => builder.integer('Вес', TmcOperationField.WEIGHT))
    .column((builder) => builder.money('Цена', TmcOperationField.PRICE))
    .column((builder) =>
        builder
            .money('Стоимость', TmcOperationField.TOTAL_COST)
            .getter((model) => Number((model.price ?? 0) * (model.quantity ?? 0)).toFixed(2))
    )
    .column((builder) =>
        builder
            .text('Номер рейса', TmcOperationField.RUN)
            .getter((model) => (model.run <= 0 ? '' : model.run))
    )
    .column((builder) =>
        builder
            .text('Номер заказа', TmcOperationField.QUOTE)
            .getter((model) => (model.quote <= 0 ? '' : model.quote))
    )
    .column((builder) => builder.text('Комментарий', TmcOperationField.COMMENT).sortable(false))
    .formUrl(generatePath(PATH_STORAGE_TMC_OPERATION, {[PATH_VARIABLE_TMC_OPERATION_ID]: ''}))
    .load((ctx) => tmcOperations.list(ctx.state.request))
    .id('tmc-operations')
    .defaultHidden([])
    .build();

const TmcOperations: React.FC = () => {
    return (
        <div className='tmc-operations d-flex'>
            <DwTable header='Операции ТМЦ' config={config}/>
        </div>
    );
};

export default TmcOperations;
