import { get, post, put } from './rest';

import type { Profile } from '../model/profile';
import type { Option } from '../component/control/option';
import type { CrmUserOptionsRequest } from '../model/role';
import type { ListRequest } from '../component/table/listRequest';
import type { CrmUserListModel } from '../model/crmUser/crmUserListModel';
import type { CrmUserModel } from '../model/crmUser/crmUserModel';
import type { PagedResponse } from './pagedResponse';
import { BaseOptionRequest } from './baseOptionRequest';
import { ApiResponse } from './apiResponse';

const BASE_URL: string = 'users';

const users = {
    roles: (request: BaseOptionRequest) => post<Option[]>(`${BASE_URL}/roles/options`, request),
    current: () => get<Profile>(`${BASE_URL}/current`),
    currentOption: () => get<Option>(`${BASE_URL}/current/option`),
    options: (request: CrmUserOptionsRequest) => post<Option[]>(`${BASE_URL}/options`, request),
    list: (model: ListRequest) => post<PagedResponse<CrmUserListModel>>(`${BASE_URL}/list`, model),
    listByRole: (model: ListRequest, roleName: string) => post<PagedResponse<CrmUserListModel>>(`${BASE_URL}/list/${roleName}`, model),
    get: (id: number) => get<CrmUserModel>(`${BASE_URL}/${id}`),
    getForRole: (id: number, roleName: string) => get<CrmUserModel>(`${BASE_URL}/${roleName}/${id}`),
    save: (model: CrmUserModel) => model.id > 0 ? put<number>(BASE_URL, model) : post<number>(BASE_URL, model),
    saveWithRole: (model: CrmUserModel, roleName: string) =>
        model.id > 0 ? put<number>(`${BASE_URL}/${roleName}`, model) : post<number>(`${BASE_URL}/${roleName}`, model),

    isPhoneUnique: (id: number, phone: string) =>
        post<ApiResponse>(`${BASE_URL}/phone/unique`, { id, param: phone }),
    isEmailUnique: (id: number, email: string) =>
        post<ApiResponse>(`${BASE_URL}/email/unique`, { id, param: email }),
    isInnUniqueForRole: (id: number, inn: string, roleName: string) =>
        post<ApiResponse>(`${BASE_URL}/inn/unique`, { id, inn, roleName }),
    isPassportUniqueForRole: (id: number, number: string, date: Date, roleName: string) =>
        post<ApiResponse>(`${BASE_URL}/passport/unique`, { id, number, date, roleName }),
};

export default users;
