import React, { ChangeEvent, useContext, useEffect } from 'react';
import { FormContext, useSetFieldValue, validateField } from './DwForm';
import FormInvalidText from './FormInvalidText';
import { useIsMounted } from '../../common/isMounted';

type Props = {
    id: string;
    label?: string;
    className?: string;
    onChange?: (newValue: boolean) => void;
    disabled?: boolean;
};

const CheckboxInput: React.FC<Props> = ({ id, label, className, onChange, disabled }) => {
    const context = useContext(FormContext);
    const { config, model, errors } = context.state;
    const setValue = useSetFieldValue();
    const fieldConfig = config.field(id),
        error = errors.get(id),
        value = fieldConfig.getter(model) || false;

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [value]);

    const onChangeHandler = async (event: ChangeEvent<any>) => {
        const isChecked = event.target.checked;
        if (onChange) {
            onChange(isChecked);
        }
        setValue(id, isChecked);
    };

    return (
        <div className={'custom-control custom-checkbox'}>
            <div className={'form-group'}>
                <input
                    type='checkbox'
                    id={id}
                    className={`custom-control-input ${className ?? ''}`}
                    checked={value}
                    onChange={onChangeHandler}
                    disabled={disabled}
                />
                <label className='custom-control-label' htmlFor={id}>
                    {label}
                </label>
                <FormInvalidText error={error} />
            </div>
        </div>
    );
};

export default CheckboxInput;
