import React from 'react';
import BaseListDtPicker from './base/BaseListDtPicker';

type Props = {
    id: string;
    onChange?: (newValue: Date, rowNum: number) => void;
};

const ListTimePicker: React.FC<Props> = ({ id, onChange }) => (
    <BaseListDtPicker id={id} onChange={onChange} showTimeSelect showTimeSelectOnly />
);

export default ListTimePicker;
