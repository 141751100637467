import React from 'react';
import { EditableTableContext } from '../../table/EditableDwTable';

type Props = {
    id: string;
    placeholder?: string;
};

const ListDwStringInput: React.FC<Props> = ({ id, placeholder }) => (
    <EditableTableContext.Consumer>
        {(tableContext) => {
            const { activeCellGetter: getter, activeCellSetter: setter } = tableContext;
            const value = getter(id);
            return (
                <div className='form-group'>
                    <input
                        type={'text'}
                        onBlur={(event) => setter(id, event.target.value)}
                        defaultValue={value ?? ''}
                        className={'form-control form-control-sm'}
                        placeholder={placeholder}
                    />
                </div>
            );
        }}
    </EditableTableContext.Consumer>
);


export default ListDwStringInput;
