import React from 'react';
import AjaxSelect from '../control/AjaxSelect';
import quotes from '../../api/quotes';

import type { Option } from '../control/option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';

type Props = {
    runStatusId: number;
    linkedQuoteIds: number[];
    clientId: number;
    loadingDate?: Date;
    loadingAddress?: Option;
    onChange: (newValue: SingleValue<Option>) => void;
};

const QuoteOptions: React.FC<Props> = ({
    runStatusId,
    linkedQuoteIds,
    clientId,
    loadingDate,
    loadingAddress,
    onChange,
}) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        quotes
            .runOptions({
                runStatusId,
                linkedQuoteIds,
                clientId,
                loadingDate,
                loadingAddress,
                filter: inputValue,
            })
            .then((options) => callback(options));
    };
    return (
        <div className='form-group quote-options d-inline-block'>
            <AjaxSelect
                onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
                loadOptions={loadOptions}
                cacheOptions={false}
                value={{ value: -1, label: '(добавить заказ)' }}
            />
        </div>
    );
};

export default QuoteOptions;
