import { BaseOptionRequest } from '../api/baseOptionRequest';

export enum Role {
    ANGEL = 'ANGEL',
    ADMIN = 'ADMIN',
    LOGISTICIAN = 'LOGISTICIAN',
    LOGISTICIAN_HEAD = 'LOGISTICIAN_HEAD',
    RUSTLER = 'RUSTLER',
    COMMERCIAL = 'COMMERCIAL',
    COMMERCIAL_HEAD = 'COMMERCIAL_HEAD',
    STORAGE_OPERATOR = 'STORAGE_OPERATOR',
    STORAGE_FOREMAN = 'STORAGE_FOREMAN',
    STORAGE_HEAD = 'STORAGE_HEAD',
    DRIVER = 'DRIVER',
    KPP = 'KPP',
    ACCOUNTANT = 'ACCOUNTANT',
    ECONOMIST = 'ECONOMIST',
    SHIPPING_OPERATOR = 'SHIPPING_OPERATOR',
}

export type CrmUserOptionsRequest = BaseOptionRequest & {
    role: Role;
    companyId?: number;
};
