import React from 'react';
import quotes from '../../../api/quotes';
import { Option } from '../../control/option';
import { BaseOptionRequest } from '../../../api/baseOptionRequest';
import runs from '../../../api/runs';
import FormSelect from '../../form/FormSelect';

type PropsBase = {
    fieldId: string;
    apiCall: (filter: BaseOptionRequest) => Promise<Option[]>;
};

type Props = {
    fieldId: string;
};

const OptionsBase: React.FC<PropsBase> = ({
    fieldId,
    apiCall
}) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        apiCall({ filter: inputValue }).then((options) => callback(options));
    };
    return (
            <FormSelect
                id={fieldId}
                loadOptions={loadOptions}
                cacheOptions={false}
            />
    );
};

export const QuotesForAssortmentOptions: React.FC<Props> = ({ fieldId }) => (
    <OptionsBase
        fieldId={fieldId}
        apiCall={quotes.assortmentOptions}
    />
);

export const RunsForAssortmentOptions: React.FC<Props> = ({ fieldId }) => (
    <OptionsBase
        fieldId={fieldId}
        apiCall={runs.assortmentOptions}
    />
);
