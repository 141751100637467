import React, {ReactElement, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Caption} from './Caption';
import {Header} from './Header';
import {Body} from './Body';
import ColumnResizer from 'column-resizer';
import './dwtable.scss';
import './editable-dwtable.scss';
import {reducer} from './reducer';
import {Context, initialRefreshState, initialState, RefreshContext, RefreshContextAction} from './context';
import {Config} from './config';
import {FilterPopup} from './FilterPopup';
import {Pagination} from './Pagination';
import {ActionType} from './action';
import {ApiResponse, handleApiError} from '../../api/apiResponse';

type Props = {
    header: string;
    config: Config<any>;
};

const RESIZER_OPTIONS = {
    resizeMode: 'overflow',
    liveDrag: true,
    draggingClass: 'rangeDrag',
};

export const CACHE_KEY = 'DwTable_';

const TableContext = React.createContext<Context>({} as Context);
const DwTable: (props: Props) => ReactElement<Props> = ({
                                                            config,
                                                            header,
                                                        }) => {
    const {refreshState} = useRefreshContext();
    const {signal} = useMemo(() => refreshState ?? initialRefreshState, [refreshState]);
    const id = config.id;
    const initState = useMemo(() => initialState(config), [config]);
    const [state, dispatch] = React.useReducer(reducer, initState);
    const [data, setData] = useState<any[]>([]);
    const [totalRecords, setTotalRecords] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const table = useRef(null);
    const [resizer, setResizer] = useState<any>(null);
    const refresh = useMemo(() => () => dispatch({type: ActionType.REFRESH}), [dispatch]);
    const ctx = useMemo(
        () =>
            ({
                state,
                dispatch,
                refresh 
            } as Context),
        [state, dispatch, refresh]
    );

    useEffect(() => {
        if (initialRefreshState.signal !== signal) {
            refresh();
        }
    }, [refresh, signal]);

    useEffect(() => {
        setLoading(true);
        config.load(ctx).then((res) => {
            setData(res.data);
            setTotalRecords(res.totalRecords);
            setLoading(false);
        });
    }, [ctx, config]);

    useEffect(() => {
        if (!resizer) {
            setResizer(new ColumnResizer(table.current, RESIZER_OPTIONS));
        }
    }, [resizer]);

    useEffect(() => {
        if (resizer) {
            const store = resizer.store[id];
            if (store) {
                resizer.reset({...RESIZER_OPTIONS, disable: loading});
                resizer.store[id] = store;
            }
        }
    }, [id, loading, resizer]);

    const columns = useMemo(
        () => config.orderedVisibleColumns(state.request.hidden),
        [config, state.request.hidden]
    );

    return (
        <div className={`card d-flex dw-table ${config.editable ? 'editable-dw-table' : ''}`}>
            <TableContext.Provider value={ctx}>
                <Caption label={header} config={config}/>
                <div className='card-body'>
                    <table
                        ref={table}
                        id={id}
                        className='table table-sm table-striped table-bordered table-hover'
                    >
                        <Header columns={columns}/>
                        <Body columns={columns} config={config} data={data}/>
                    </table>
                    <Pagination totalRecords={totalRecords} pageInfo={ctx.state.request.pageInfo}/>
                    {state.showFilterFor && <FilterPopup/>}
                </div>
            </TableContext.Provider>
        </div>
    );
};

const useRefreshContext: () => RefreshContext = () => useContext(TableRefreshContext);
const useRefreshContextAction: () => RefreshContextAction = () => useContext(TableRefreshContextAction);

const TableRefreshContext = React.createContext<RefreshContext>({} as RefreshContext);
const TableRefreshContextAction = React.createContext<RefreshContextAction>({} as RefreshContextAction);

const onRowDelete: (
    refresh: (() => void) | undefined,
    delFunc: () => Promise<ApiResponse>
) => void = (refresh = () => {}, delFunc = () => Promise.resolve({} as ApiResponse)) => {
    if (window.confirm('Удалить запись?')) {
        const res = delFunc();
        res.then((res) => {
            if (res.error) {
                handleApiError(res.error);
            } else {
                refresh();
            }
        });
    }
};

export {DwTable, TableContext, TableRefreshContext, TableRefreshContextAction, useRefreshContextAction, onRowDelete};
