import { AbstractEntity } from '../../../model/abstractEntity';
import { Option } from '../../control/option';

export enum RunPointType {
    LOADING = 'LOADING',
    UNLOADING = 'UNLOADING',
}

export const RunPointTypeNames: Map<RunPointType, string> = new Map([
    [RunPointType.LOADING, 'Загрузка'],
    [RunPointType.UNLOADING, 'Разгрузка'],
]);

export enum RunPointBase {
    QUOTE = 'QUOTE',
    CLIENT = 'CLIENT',
    COMPANY = 'COMPANY',
}

export type RunPointModel = AbstractEntity & {
    isLoading: boolean;
    quoteId: number;
    quoteNumber: string;
    date: Date;
    timeFrom: Date;
    duration: number;
    client: Option;
    company: Option;
    address: Option;
    storage: Option;
    contact: Option;
    contactPhone: string;
    weight: number;
    tareQuantity: number;
    cargoPrice: number;
    description: string;
    base: RunPointBase;
};
