import React, { MouseEvent } from 'react';
import AjaxSelect from '../../../control/AjaxSelect';
import { EditableTableContext } from '../../../table/EditableDwTable';

import type { Option } from '../../../control/option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import { FormatOptionLabelMeta } from 'react-select';

type Props = {
    id: string;
    loadOptions: (inputValue: string, callback: (options: Option[]) => void) => void;
    cacheOptions?: boolean;
    readOnly?: boolean;
    onChange?: (newValue: SingleValue<Option>, rowNum: number) => void;
    formatOptionLabel?: ((data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => React.ReactNode);
    nullable?: boolean;
};

const ListSelect: React.FC<Props> = ({ id, loadOptions, onChange, nullable, readOnly, ...rest }) => (
    <EditableTableContext.Consumer>
        {(tableContext) => {
            const {
                activeCellGetter: getter,
                activeCellSetter: setter,
                editingRowNum,
            } = tableContext;
            const value = getter(id),
            clearable = !readOnly && nullable;

            const onChangeHandler = (newValue: SingleValue<Option>) => {
                setter(id, newValue);
                if (onChange) {
                    onChange(newValue, editingRowNum);
                }
            };

            const onResetEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                clearable && onChangeHandler({ value: -1, label: '' });
            };

            return (
                <div className='form-group'>
                    <div className={nullable ? 'input-group' : ''}>
                        <AjaxSelect
                            {...rest}
                            onChange={onChangeHandler as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
                            loadOptions={loadOptions}
                            value={value}
                            isMenuFixed={true}
                            readOnly={readOnly}
                        />
                        {clearable && value?.value > 0 &&
                            <div className="input-group-append">
                                <button className='btn btn-sm btn-outline-secondary' onClick={onResetEventHandler} tabIndex={-1}>
                                    <i className="fas fa-times" />
                                </button>
                            </div>}
                    </div>
                </div>
            );
        }}
    </EditableTableContext.Consumer>
);

export default ListSelect;
