const CheckBrowser: React.FC = () => {
    return window.chrome ? (
        <></>
    ) : (
        <div
            className='d-flex justify-content-center alert alert-warning browser-alert'
            role='alert'
        >
            В настоящий момент корректное поведение CRM обеспечивается только в браузерах Chrome
            последних версий
        </div>
    );
};

export default CheckBrowser;
