import React from 'react';
import util from '../../common/util';

type Props = {
    errors: Map<string, string>;
    fieldTranslations: Record<string, string>;
};

const GroupedErrorMessages: React.FC<Props> = ({ errors, fieldTranslations }) => {
    const groupedErrors = groupByError(errors);

    if (groupedErrors.size === 0) {
        return null;
    }

    return (
        <div className='alert alert-danger ml-0 mr-0 mb-3' role='alert'>
            <table className='table-sm'>
                <tbody>
                    {Array.from(groupedErrors.entries()).map(([error, fields]) => (
                        <tr key={`error_${error}_row`}>
                            <td>{`${util.capitalizeFirstChar(error)} :`}</td>
                            <td>
                                {fields
                                    .map((field) => fieldTranslations[field] ?? field)
                                    .join(', ')}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

const groupByError = (map: Map<string, string>): Map<string, string[]> => {
    const grouped = new Map<string, string[]>();

    map.forEach((error, field) => {
        const fields = grouped.get(error) ?? [];
        fields.push(field);
        grouped.set(error, fields);
    });

    return grouped;
};

export default GroupedErrorMessages;
