import React from 'react';
import { generatePath } from 'react-router-dom';
import users from '../../../../api/users';
import { PATH_CP_DRIVER } from '../../../../routerPaths';
import { Role } from '../../../../model/role';
import { ColumnType } from '../../../table/columnType';
import { Config } from '../../../table/config';
import { DwTable } from '../../../table/DwTable';
import { CrmUserField } from '../crmUserField';

import type { CrmUserListModel } from '../../../../model/crmUser/crmUserListModel';

const DriverList: React.FC = () => {
    const config = Config.builder<CrmUserListModel>()
        .column((builder) => builder.id())
        .column((builder) => builder.text('ФИО', CrmUserField.FIO).sortable(false))
        .column((builder) => builder.text('E-mail', CrmUserField.EMAIL).sortable(false))
        .column((builder) =>
            builder.text('Телефон', CrmUserField.PHONE).sortable(false).filterable(false)
        )
        .column((builder) =>
            builder.text('Номер паспорта', CrmUserField.PASSPORT_NUMBER).sortable(false)
        )
        .column((builder) =>
            builder
                .boolean('Паспорт не РФ', CrmUserField.PASSPORT_ALIEN)
                .sortable(false)
                .filterable(false)
                .cls('boolean')
        )
        .column((builder) =>
            builder
                .date('Дата выдачи паспорта', CrmUserField.PASSPORT_DATE)
                .sortable(false)
                .filterable(false)
        )
        .column((builder) =>
            builder
                .text('Кем выдан паспорт', CrmUserField.PASSPORT_DEPARTMENT)
                .sortable(false)
                .filterable(false)
        )
        .column((builder) =>
            builder.text('Вод. удостоверение', CrmUserField.LICENCE_NUMBER).sortable(false)
        )
        .column((builder) =>
            builder
                .date('Дата выдачи вод. удостоверения', CrmUserField.LICENCE_DATE)
                .sortable(false)
                .filterable(false)
        )
        .column((builder) => builder.text('ИНН', CrmUserField.INN).sortable(false))
        .column((builder) =>
            builder.text('СНИЛС', CrmUserField.SNILS).sortable(false).filterable(false)
        )
        .column((builder) =>
            builder
                .label('Согласование')
                .field(CrmUserField.STATUS)
                .type(ColumnType.APPROVAL_STATUS)
                .cls('approval')
        )
        .column((builder) =>
            builder.boolean('Активен', CrmUserField.ACTIVE).sortable(false).cls('boolean')
        )
        .column((builder) =>
            builder.text('Комментарий', CrmUserField.COMMENT).sortable(false).filterable(false)
        )
        .formUrl(generatePath(PATH_CP_DRIVER, { driverId: '' }))
        .load((ctx) => users.listByRole(ctx.state.request, Role[Role.DRIVER]))
        .id('drivers')
        .defaultHidden([
            CrmUserField.EMAIL,
            CrmUserField.PASSPORT_ALIEN,
            CrmUserField.INN,
            CrmUserField.SNILS,
            CrmUserField.COMMENT,
            CrmUserField.ROLES
        ])
        .build();

    return (
        <div className='drivers d-flex'>
            <DwTable header='Водители' config={config} />
        </div>
    );
};

export default DriverList;
