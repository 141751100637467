import React from 'react';
import AjaxSelect from '../control/AjaxSelect';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../control/option';
import storages from '../../api/storages';
import { StorageType } from '../../model/enums/StorageType';

type Props = {
    value: Option;
    types: StorageType[];
    onChange?: (option: SingleValue<Option>) => void;
};

export const StorageSelect = ({ value, types, onChange }: Props) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        storages.options({
            filter: inputValue,
            typeIds: types,
        }).then((options) => callback(options));
    };

    const onChangeHandler = async (option: SingleValue<Option>) => {
        if (onChange) {
            onChange(option);
        }
    };

    return (
        <AjaxSelect
            onChange={onChangeHandler as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
            loadOptions={loadOptions}
            value={value}
        />
    );
}