import './sidebar.scss';
import './companyLogo.scss';

import React, { useContext, useEffect, useState } from 'react';
import { MOBILE_DEVICE_MAX_WIDTH } from '../../common/const';
import { RootContext } from './ContextWrapper';
import type { NavItem } from './sidebarData';
import { items } from './sidebarData';
import { NavLink, useMatch } from 'react-router-dom';

import companyLogo from '../../img/company-logo-sidebar.png';
import { PATH_BASE } from '../../routerPaths';
import { Role } from '../../model/role';
import { Profile } from '../../model/profile';
import ClickOutsideWrapper from './ClickOutsideWrapper';

type SidebarDropdownProps = {
    item: NavItem;
    level: number;
    collapsed: boolean;
    isMobile?: boolean;
};

const hasItemAccess = (profile: Profile, item: NavItem) => {
    const itemRoles = item.roles,
        profileRoles = new Set(profile.roles);
    if (!itemRoles.length || !profileRoles.size || profileRoles.has(Role.ANGEL) != null) {
        return true;
    }
    return itemRoles.some((role) => profileRoles.has(role));
};

const SidebarLogo: React.FC = () => (
    <a href={PATH_BASE} className='brand-logo'>
        <img src={companyLogo} alt='Логотип Агросоюз Романовский' className='sidebar-brand-logo' />
    </a>
);

const SidebarDropdown: React.FC<SidebarDropdownProps> = ({ item, collapsed, level, isMobile }) => {
    const { items: clilds } = item;
    return (
        <div className={`collapse ${collapsed ? '' : 'show'}`}>
            <ul className='collapse-inner rounded'>
                {clilds.map((child) => (
                    <SidebarItem
                        key={child.path}
                        item={child}
                        level={level + 1}
                        isMobile={isMobile}
                    />
                ))}
            </ul>
        </div>
    );
};

const SidebarItem: React.FC<{ item: NavItem; level?: number; isMobile?: boolean }> = ({
    item,
    level = 1,
    isMobile,
}) => {
    const { path, icon, label, items: itemItems, targetBlank } = item,
        {
            state: { uiState },
            setUiState,
        } = useContext(RootContext),
        hasChildren = itemItems?.length > 0,
        isActive = useMatch({ path: item.path, end: false });
    const [collapsed, setCollapsed] = useState(() =>
        isMobile ? true : uiState.sidebarToggled || !isActive
    );

    const toggleMobileSidebar = () =>
        setUiState({
            ...uiState,
            sidebarVisible: !uiState.sidebarVisible && window.innerWidth < MOBILE_DEVICE_MAX_WIDTH,
        });

    const onNavClick = (e: React.MouseEvent) => {
        if (hasChildren) {
            e.preventDefault();
            setCollapsed(!collapsed);
        } else {
            toggleMobileSidebar();
        }
    };
    return (
        <>
            {level === 1 && <hr className='sidebar-divider my-0' />}
            <li className={`nav-item level-${level}`}>
                <NavLink
                    to={path}
                    className={`nav-link ${!collapsed ? '' : 'collapsed'}`}
                    data-toggle={hasChildren ? 'collapse' : null}
                    target={targetBlank ? '_blank' : ''}
                    onClick={onNavClick}
                >
                    <>
                        {level === 1 && <i className={`fas fa-fw ${!!icon ? `fa-${icon}` : ''}`} />}
                        <span>{label}</span>
                    </>
                </NavLink>
                {hasChildren && (
                    <ClickOutsideWrapper
                        listen={uiState.sidebarToggled && !collapsed && level === 1}
                        callback={() => setCollapsed(true)}
                    >
                        <SidebarDropdown {...{ item, level, collapsed, setCollapsed, isMobile }} />
                    </ClickOutsideWrapper>
                )}
            </li>
        </>
    );
};

const Sidebar: React.FC = () => {
    const {
            state: { uiState, profile },
            setUiState,
        } = useContext(RootContext),
        itemsToRender = items.filter((it) => hasItemAccess(profile, it));
    const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_DEVICE_MAX_WIDTH);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < MOBILE_DEVICE_MAX_WIDTH);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const onSidebarToggledClick = (e: React.MouseEvent) => {
        setUiState({
            ...uiState,
            sidebarToggled: !uiState.sidebarToggled,
        });
    };

    const toggleMobileSidebar = () =>
        setUiState({
            ...uiState,
            sidebarVisible: !uiState.sidebarVisible && window.innerWidth < MOBILE_DEVICE_MAX_WIDTH,
        });

    return (
        <ClickOutsideWrapper callback={toggleMobileSidebar} listen={uiState.sidebarVisible}>
            <ul
                className={`navbar-nav sidebar sidebar-light accordion
                ${uiState.sidebarVisible ? 'sidebar-visible' : ''}
                ${isMobile ? 'isMobile' : ''}
                ${!isMobile && uiState.sidebarToggled ? 'toggled' : ''}`}
            >
                {isMobile ? (
                    <div className='d-flex justify-content-center'>
                        <button
                            id='sidebarToggleTop'
                            className='btn btn-link rounded-circle text-center mt-2'
                            onClick={toggleMobileSidebar}
                        >
                            <i className='fa fa-times'> </i>
                        </button>
                    </div>
                ) : (
                    <li className={'nav-item'}>
                        <SidebarLogo />
                    </li>
                )}

                {itemsToRender.map((item) => (
                    <SidebarItem key={item.path} item={item} isMobile={isMobile} />
                ))}
                {!isMobile && (
                    <div className='text-center d-none d-md-inline pt-3'>
                        <button
                            className='rounded-circle border-0'
                            id='sidebarToggle'
                            onClick={onSidebarToggledClick}
                        >
                            {' '}
                        </button>
                    </div>
                )}
            </ul>
        </ClickOutsideWrapper>
    );
};

export default Sidebar;
