import React from 'react';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import ListSelect from './base/ListSelect';
import { Option } from '../../control/option';
import productionLines from "../../../api/productionLine";

type Props = {
    id: string;
    storageId: number;
    onChange?: (newValue: SingleValue<Option>, rowNum: number) => void;
};

const ListLines: React.FC<Props> = ({ id, storageId, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        productionLines
            .allOptions({
                filter: inputValue,
                storageId: storageId,
            })
            .then((options) => callback(options));
    };
    return (
        <ListSelect id={id} loadOptions={loadOptions} onChange={onChange} />
    );
};

export default ListLines;
