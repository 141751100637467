import React, { useContext, useMemo } from 'react';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { Config } from '../../../table/config';
import contacts from '../../../../api/contacts';
import { ContactListModel } from '../../../../model/contact/contactListModel';
import { NavigateFunction, Outlet, generatePath } from 'react-router-dom';
import { ClientFormContext } from '../ClientForm';
import { PATH_CP_CLIENT_CONTACT } from '../../../../routerPaths';
import util from '../../../../common/util';
import * as ContactFields from './clientContactFields';
import { RemoveButton } from '../../../table/RemoveButton';

const ClientContacts: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const config = useMemo(() => {
        return Config.builder<ContactListModel>()
            .column((builder) => builder.id().sortable(false).filterable(false))
            .column((builder) => builder.text('Имя', ContactFields.FIELD_FIO).sortable(false))
            .column((builder) =>
                builder
                    .text('Эл. почта', ContactFields.FIELD_EMAIL)
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder.text('Телефон', ContactFields.FIELD_PHONE).sortable(false).filterable(false)
            )
            .column((builder) =>
                builder
                    .cls('text-center')
                    .sortable(false)
                    .filterable(false)
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () => contacts.delete(model.id))
                            }
                        />
                    ))
                    .width('45px')
            )
            .columnsSelector(false)
            .load((ctx) => contacts.listForClient(ctx.state.request, clientId))
            .formUrl(
                generatePath(PATH_CP_CLIENT_CONTACT, {
                    clientId: util.stringOrEmpty(clientId),
                    contactId: '',
                })
            )
            .onClick((id: number, navigate: NavigateFunction) =>
                navigate(
                    generatePath(PATH_CP_CLIENT_CONTACT, {
                        clientId: util.stringOrEmpty(clientId),
                        contactId: util.stringOrEmpty(id),
                    })
                )
            )
            .id(`client_${clientId}_contacts`)
            .build();
    }, [clientId]);

    return (
        <>
            <div className='contracts d-flex mb-3'>
                <DwTable header='Контакты' config={config} />
            </div>
            <Outlet />
        </>
    );
};

export default ClientContacts;
