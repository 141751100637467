import React from 'react';
import assortments from "../../../api/assortments";
import { Option } from '../../control/option';
import clientAssortments from '../../../api/clientAssortments';
import ListSelect from './base/ListSelect';
import { SingleValue } from 'react-select/dist/declarations/src/types';

type Props = {
    id: string;
    clientId: number;
    consigneeId?: number;
    onChange?: (newValue: SingleValue<Option>, rowNum: number) => void;
};

export const ListClientAssortments: React.FC<Props> = ({ id, clientId, consigneeId, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clientAssortments
            .clientOptions({
                filter: inputValue,
                clientId,
                consigneeId,
            })
            .then((options) => callback(options));
    };
    return (
        <ListSelect id={id} loadOptions={loadOptions} onChange={onChange} cacheOptions={false} />
    );
};

export const ListCompanyAssortments: React.FC<Props> = ({ id, clientId, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clientAssortments
            .companyOptions({
                filter: inputValue,
                clientId: clientId,
            })
            .then((options) => callback(options));
    };
    return (
        <ListSelect id={id} loadOptions={loadOptions} onChange={onChange} cacheOptions={false} />
    );
};

export const ListAllCompanyAssortments: React.FC<Props> = ({ id, clientId }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clientAssortments
            .allCompanyOptions({
                filter: inputValue,
                clientId: clientId,
            })
            .then((options) => callback(options));
    };
    return (
        <ListSelect id={id} loadOptions={loadOptions} cacheOptions={false} />
    );
};

export const ListAssortments: React.FC<{ id: string }> = ({ id }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        assortments
            .options({
                filter: inputValue,
            })
            .then((options) => callback(options));
    };
    return <ListSelect id={id} loadOptions={loadOptions} cacheOptions={false} />;
};
