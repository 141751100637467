import React from 'react';
import { Outlet } from 'react-router';
import { generatePath, Navigate, useParams } from 'react-router-dom';
import { Direction } from '../../common/const';

type Props = {
    redirectTo: string;
};

const DefaultDirectionRedirect: React.FC<Props> = ({ redirectTo }) => {
    const { direction } = useParams<{ direction: string }>();

    if (!direction) {
        return (
            <Navigate to={generatePath(redirectTo, { direction: Direction.EXTERNAL })} replace />
        );
    }

    return <Outlet />;
};

export default DefaultDirectionRedirect;
