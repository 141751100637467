import React from 'react';
import {Outlet} from 'react-router';
import {generatePath} from 'react-router-dom';
import tmcOperations from '../../../api/tmcOperations';
import util from '../../../common/util';
import {TmcOperationModel} from '../../../model/tmcoperation/tmcOperationModel';
import {
    PATH_STORAGE_TMC_OPERATION,
    PATH_STORAGE_TMC_OPERATION_HISTORY,
    PATH_STORAGE_TMC_OPERATIONS,
    PATH_VARIABLE_TMC_OPERATION_ID,
} from '../../../routerPaths';
import ContextError from '../../form/ContextError';
import {DwForm, useFieldValue} from '../../form/DwForm';
import DwFormTabs from '../../form/DwFormTabs';
import {FormConfig} from '../../form/formConfig';
import {
    notNullablePositiveDecimalValidator,
    notNullablePositiveIntValidator,
    requiredOperationTypeValidator,
} from '../../../common/validation/simpleValidators';
import {TmcOperationField} from './tmcOperationField';

const formConfig = FormConfig.builder<TmcOperationModel>()
    .number(
        TmcOperationField.ID,
        (m) => m.id,
        (m, v) => (m.id = v)
    )
    .dateTime(
        TmcOperationField.DT,
        (m) => m.dt,
        (m, v) => (m.dt = v)
    )
    .option(
        TmcOperationField.TYPE,
        (m) => m.type,
        (m, v) => (m.type = v),
        (m) => requiredOperationTypeValidator(m.storageTo, m.type)
    )
    .requiredOption(
        TmcOperationField.CLIENT_ADDRESS,
        (m) => m.clientAddress,
        (m, v) => (m.clientAddress = v)
    )
    .requiredOption(
        TmcOperationField.CLIENT,
        (m) => m.client,
        (m, v) => (m.client = v),
    )
    .requiredOption(
        TmcOperationField.TMC,
        (m) => m.tmc,
        (m, v) => (m.tmc = v)
    )
    .requiredOption(
        TmcOperationField.UNIT,
        (m) => m.unit,
        (m, v) => (m.unit = v)
    )
    .number(
        TmcOperationField.QUANTITY,
        (m) => m.quantity,
        (m, v) => (m.quantity = v),
        (m) => notNullablePositiveIntValidator(m.quantity)
    )
    .number(
        TmcOperationField.PRICE,
        (m) => m.price,
        (m, v) => (m.price = v),
        (m) => notNullablePositiveDecimalValidator(m.price)
    )
    .number(
        TmcOperationField.WEIGHT,
        (m) => m.weight,
        (m, v) => (m.weight = v),
        (m) => notNullablePositiveDecimalValidator(m.price)
    )
    .text(
        TmcOperationField.COMMENT,
        (m) => m.comment,
        (m, v) => (m.comment = v)
    )
    .option(
        TmcOperationField.RUN,
        (m) => m.run,
        (m, v) => (m.run = v)
    )
    .option(
        TmcOperationField.QUOTE,
        (m) => m.quote,
        (m, v) => (m.quote = v)
    )
    .load((id) => tmcOperations.get(id))
    .submit(tmcOperations.save)
    .redirectUrl(PATH_STORAGE_TMC_OPERATIONS)
    .idPathVariableName(PATH_VARIABLE_TMC_OPERATION_ID)
    .build();

const TmcOperationForm: React.FC = () => {
    return (
        <DwForm config={formConfig}>
            <TmcOperationLayout/>
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const TmcOperationLayout: React.FC = () => {
    const tmcOperationId = useFieldValue(TmcOperationField.ID);
    const isNew: boolean = !tmcOperationId;

    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: 'ТМЦ',
                        path: generatePath(PATH_STORAGE_TMC_OPERATION, {
                            [PATH_VARIABLE_TMC_OPERATION_ID]: util.stringOrEmpty(tmcOperationId),
                        }),
                        end: true,
                    },
                    {
                        name: 'История',
                        path: generatePath(PATH_STORAGE_TMC_OPERATION_HISTORY, {
                            [PATH_VARIABLE_TMC_OPERATION_ID]: util.stringOrEmpty(tmcOperationId),
                        }),
                        end: true,
                        disabled: isNew,
                    },
                ]}
            />
            <ContextError/>
            <Outlet/>
        </>
    );
};

export default TmcOperationForm;
