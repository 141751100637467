import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import './ajaxSelect.scss';
import clsx from 'clsx';

import type { Option } from './option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import { FormatOptionLabelMeta } from 'react-select';

type Props = {
    error?: string;
    value?: Option;
    loadOptions: (inputValue: string, callback: (options: Option[]) => void) => void;
    onChange: (newValue: SingleValue<Option> | MultiValue<Option>) => void;
    cacheOptions?: boolean;
    readOnly?: boolean;
    isMulti?: boolean;
    isMenuFixed?: boolean;
    formatOptionLabel?: ((data: Option, formatOptionLabelMeta: FormatOptionLabelMeta<Option>) => React.ReactNode);
};

const AjaxSelect: React.FC<Props> = (props) => {
    const [loading, setLoading] = useState(false);
    const [defaultOptions, setDefaultOptions] = useState<Option[]>([]);

    const onFocus = () => {
        if (!defaultOptions.length || props.cacheOptions === false) {
            setLoading(true);

            props.loadOptions('', (options) => {
                setDefaultOptions(options);
                setLoading(false);
            });
        }
    };

    return (
        <AsyncSelect
            cacheOptions
            classNames={{
                container: (state) =>
                    clsx('rs-container form-control form-control-sm', {
                        'error-border': !!props.error,
                        'focus': !!state.isFocused,
                        'multiple': !!props.isMulti
                    }),
                menu: (_) => 'menu',
                singleValue: (state) =>
                    clsx('single-value', {
                        disabled: !!state.isDisabled,
                    }),
                control: (_) => 'control',
                valueContainer: (_) => 'value-container',
                indicatorsContainer: (_) => 'indicators-container',
                loadingMessage: (_) => 'loading-message',
                noOptionsMessage: (_) => 'no-options-message',
                multiValue: (_) => 'm-1 p-1 bg-light rounded border'
            }}
            classNamePrefix='rs'
            loadOptions={props.loadOptions}
            defaultOptions={defaultOptions}
            onFocus={onFocus}
            onChange={props.onChange}
            isLoading={loading}
            placeholder={'(выбрать)'}
            value={props.value}
            isDisabled={props.readOnly}
            menuPlacement={'auto'}
            menuPosition={props.isMenuFixed ? 'fixed' : undefined}
            unstyled
            isMulti={props.isMulti}
            formatOptionLabel={props.formatOptionLabel}
        />
    );
};

export default AjaxSelect;
