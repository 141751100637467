import React, { ChangeEvent, HTMLInputTypeAttribute } from 'react';
import { EditableTableContext } from '../../../table/EditableDwTable';

type Props = {
    id: string;
    placeholder?: string;
    inputType: HTMLInputTypeAttribute;
    onChange?: (newValue: any, rowNum: number) => void;
};

const ListInput: React.FC<Props> = ({ id, placeholder, inputType, onChange }) => (
    <EditableTableContext.Consumer>
        {(tableContext) => {
            const { activeCellGetter: getter, activeCellSetter: setter, editingRowNum } = tableContext;
            const value = getter(id);

            const onChangeHandler = (event: ChangeEvent<any>) => {
                const inputValue = event.target.value;
                const newValue = 'number' === inputType ? Number(inputValue) : inputValue;
                if (onChange) {
                    onChange(newValue, editingRowNum);
                }
                setter(id, newValue);
            };

            return (
                <div className='form-group'>
                    <input
                        type={inputType}
                        onChange={onChangeHandler}
                        value={value ?? ''}
                        className={'form-control form-control-sm'}
                        placeholder={placeholder}
                    />
                </div>
            );
        }}
    </EditableTableContext.Consumer>
);

export default ListInput;
