import React from 'react';
import { BaseOptionRequest } from '../../api/baseOptionRequest';
import { RegionIsoRequest } from '../../model/address';
import FormSelect from './FormSelect';
import addresses from '../../api/addresses';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../control/option';

type Props = {
    id: string;
    isRegion?: boolean;
    country?: string;
    onChange?: (newValue: SingleValue<Option>) => void;
};

const IsoOptions: React.FC<Props> = ({ id, isRegion = false, country, onChange }) => {
    const fetchCountryOptions = async (inputValue: string) => {
        const filter: BaseOptionRequest = { filter: inputValue };
        return await addresses.getCountriesIso(filter);
    };

    const fetchRegionOptions = async (inputValue: string) => {
        if (!country) {
            return Promise.resolve([]);
        }
        const filter: RegionIsoRequest = { filter: inputValue, countryIso: country };
        return await addresses.getRegionsIso(filter);
    };

    const loadOptions = async (inputValue: string, callback: (options: Option[]) => void) => {
        const fetchOptions = isRegion ? fetchRegionOptions : fetchCountryOptions;
        const res: Option[] = await fetchOptions(inputValue);
        return callback(res);
    };
    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
        />
    );
};

export default IsoOptions;
