export enum ClientConsigneeField {
    ID = 'id',
    CLIENT_ID = 'clientId',
    CONSIGNEE = 'consignee',
    INN = 'inn',
    OGRN = 'ogrn',
    KPP = 'kpp',
    ACTUAL_ADDRESS = 'actualAddress',
    PHONE = 'phone',
    EMAIL = 'email',
}
