import React, { ElementType, HTMLAttributes } from 'react';
import { useScrollRef } from '../../common/scroll';

const DEFAULT_TAG = 'div';

type WithoutListenerProps = HTMLAttributes<HTMLOrSVGElement> & {
    readonly as?: ElementType;
}
type WithListenerProps = WithoutListenerProps & {
    readonly callback: () => void;
}
type ScrollWrapperProps = WithListenerProps & {
    readonly listen?: boolean;
}

const WithListener: React.FC<WithListenerProps> = ({ callback, as: Tag = DEFAULT_TAG, children, className, ...otherProps }) => {
    const { ref } = useScrollRef(callback);
    return (
        <Tag ref={ref} {...otherProps} className={`${className ?? ''} scroll-listener`}>
            {children}
        </Tag>
    );
}

const WithoutListener: React.FC<WithoutListenerProps> = ({ as: Tag = DEFAULT_TAG, children, ...otherProps }) => {
    return (
        <Tag {...otherProps}>
            {children}
        </Tag>
    );
}

const ScrollWrapper: React.FC<ScrollWrapperProps> = ({ listen = false, callback, ...otherProps }) => {
    if (listen) {
        return <WithListener {...{ callback, ...otherProps }} />;
    } else {
        return <WithoutListener {...otherProps} />;
    }
}

export default ScrollWrapper;