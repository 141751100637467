import React from 'react';
import { createSearchParams, generatePath, useNavigate } from 'react-router-dom';
import util from '../../common/util';
import { SEARCH_PARAM_SOURCE_ID } from '../../routerPaths';

type Props = {
    id: number;
    basePath: string;
    idPathVariableName: string;
};

const CopyButton: React.FC<Props> = ({ id, basePath, idPathVariableName }) => {
    const navigate = useNavigate();
    const handleCopy = (id: number) => {
        navigate({
            pathname: generatePath(basePath, { [idPathVariableName]: '' }),
            search: createSearchParams({
                [SEARCH_PARAM_SOURCE_ID]: util.toString(id) ?? ''
            }).toString()
        });
    };
    return (
        <button className='btn btn-sm btn-light copy-button' onClick={() => handleCopy(id)}>
            <i className='icon far fa-copy' />
        </button>
    );
};

export default CopyButton;
