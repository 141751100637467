import React from 'react';
import { TmcCategory } from '../../../model/tmc/tmcModel';
import { Option } from '../../control/option';
import ListSelect from './base/ListSelect';
import tmc from '../../../api/tmc';

type Props = {
    id: string;
    tmcCategories?: TmcCategory[];
    nullable?: boolean;
};

const ListTmc: React.FC<Props> = ({ id, tmcCategories, nullable }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        tmc.options({
            filter: inputValue,
            tmcCategories,
        }).then((options) => callback(options));
    };
    return <ListSelect id={id} loadOptions={loadOptions} nullable={nullable}/>;
};

export default ListTmc;
