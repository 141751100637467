import { get, post, put } from './rest';

import type { PagedResponse } from './pagedResponse';
import type { TmcListModel } from '../model/tmc/tmcListModel';
import type { ListRequest } from '../component/table/listRequest';
import { TmcOptionsRequest, TmcModel } from '../model/tmc/tmcModel';
import { Option } from '../component/control/option';

const PATH_BASE = 'tmc';

const tmc = {
    list: (model: ListRequest) => post<PagedResponse<TmcListModel>>(`${PATH_BASE}/list`, model),
    get: (id: number) => get<TmcModel>(`${PATH_BASE}/${id}`),
    save: (model: TmcModel) =>
        model.id > 0 ? put<number>(PATH_BASE, model) : post<number>(PATH_BASE, model),
    options: (request: TmcOptionsRequest) => post<Option[]>(`${PATH_BASE}/options`, request),
};

export default tmc;
