import { Action } from './action';
import React from 'react';
import { ListRequest } from './listRequest';
import { Column } from './column';
import Cache from '../../common/cache';
import { CACHE_KEY } from './DwTable';
import { Config } from './config';

export enum Direction {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type Sort = {
    field: string;
    dir: Direction;
};

export type State = {
    id: string;
    request: ListRequest;
    showFilterFor?: Column<any, any>;
    position?: Position;
    order: number[];
    selectedRows: number[];
};

export type Position = {
    x: number;
    y: number;
};

export const initialState = (config: Config<any>): State => {
    const id = config.id;
    return Cache.get(CACHE_KEY + id, {
        id,
        request: {
            sort: { field: '', dir: Direction.ASC },
            filters: [],
            hidden: [...config.defaultHidden],
            pageInfo: { page: 0, pageSize: 10 },
            search: '',
        },
        position: { x: -1, y: -1 },
        order: [],
        selectedRows: [],
    });
};

export type Context = {
    state: State;
    dispatch: React.Dispatch<Action>;
    refresh: () => void;
};

export const initialRefreshState: RefreshState = {
    signal: 0,
};

export type RefreshState = {
    signal: number;
};

export type RefreshContext = {
    refreshState: RefreshState;
};

export type RefreshContextAction = {
    refresh: () => void;
};
