export enum StorageContactField {
    ID = 'id',
    FIO = 'fio',
    POSITION = 'position',
    BASIS = 'basis',
    EXECUTIVE = 'executive',
    PHONE = 'phone',
    EMAIL = 'email',
    MAIN = 'main',
}
