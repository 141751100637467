import React, { useContext, useMemo } from 'react';
import { Outlet } from 'react-router';
import { generatePath } from 'react-router-dom';
import contacts from '../../../../api/contacts';
import util from '../../../../common/util';
import { PATH_CP_STORAGE_CONTACT } from '../../../../routerPaths';
import { Config } from '../../../table/config';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { RemoveButton } from '../../../table/RemoveButton';
import { StorageFormContext } from '../StorageForm';
import { StorageContactField } from './storageContactField';

import type { ContactListModel } from '../../../../model/contact/contactListModel';
import type { NavigateFunction } from 'react-router-dom';

const StorageContacts: React.FC = () => {
    const {
        storage: { id: storageId },
    } = useContext(StorageFormContext);
    const config = useMemo(() => {
        return Config.builder<ContactListModel>()
            .column((builder) => builder.id().filterable(false))
            .column((builder) => builder.text('Имя', StorageContactField.FIO).sortable(false))
            .column((builder) =>
                builder
                    .text('Эл. почта', StorageContactField.EMAIL)
                    .sortable(false)
                    .filterable(false))
            .column((builder) =>
                builder
                    .text('Телефон', StorageContactField.PHONE)
                    .sortable(false)
                    .filterable(false))
            .column((builder) =>
                builder
                    .cls('text-center')
                    .sortable(false)
                    .filterable(false)
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () => contacts.delete(model.id))
                            }
                        />
                    ))
                    .width('45px')
            )
            .columnsSelector(false)
            .load((ctx) => contacts.listForStorage(ctx.state.request, storageId))
            .formUrl(
                generatePath(PATH_CP_STORAGE_CONTACT, {
                    storageId: util.stringOrEmpty(storageId),
                    contactId: '',
                })
            )
            .onClick((id: number, navigate: NavigateFunction) =>
                navigate(
                    generatePath(PATH_CP_STORAGE_CONTACT, {
                        storageId: util.stringOrEmpty(storageId),
                        contactId: util.stringOrEmpty(id),
                    })
                )
            )
            .id(`storage_${storageId}_contacts`)
            .build();
    }, [storageId]);

    return (
        <>
            <div className='contacts d-flex mb-3'>
                <DwTable header='Контакты' config={config} />
            </div>
            <Outlet />
        </>
    );
};

export default StorageContacts;
