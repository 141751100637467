import { Option } from '../control/option';

export enum FilterType {
    INTEGER = 'INTEGER',
    DECIMAL = 'DECIMAL',
    STRING = 'STRING',
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    BOOLEAN = 'BOOLEAN',
    OPTION = 'OPTION',
}

export interface RangeFilter<T> {
    from?: T;
    to?: T;
    empty?: boolean;
}

export class Filter {
    field: string;
    type: FilterType;

    constructor(type: FilterType, field: string) {
        this.field = field;
        this.type = type;
    }

    static isNotEmpty(filter: Filter): boolean {
        if (filter instanceof StringFilter || filter instanceof BooleanFilter)
            return !!filter.value;
        else if (filter instanceof OptionFilter) return filter.value.value > 0;
        else if (
            filter instanceof DateFilter ||
            filter instanceof DateTimeFilter ||
            filter instanceof IntegerFilter ||
            filter instanceof DecimalFilter
        )
            return !!filter.from || !!filter.to;
        return true;
    }
}

export class StringFilter extends Filter {
    value: string = '';
    constructor(field: string) {
        super(FilterType.STRING, field);
    }
}

export class OptionFilter extends Filter {
    value: Option = { label: '(выбрать)' } as Option;
    constructor(field: string) {
        super(FilterType.OPTION, field);
    }
}

export class BooleanFilter extends Filter {
    value: boolean = false;
    constructor(field: string) {
        super(FilterType.BOOLEAN, field);
    }
}

export class IntegerFilter extends Filter implements RangeFilter<number> {
    from?: number;
    to?: number;
    empty?: boolean;
    constructor(field: string) {
        super(FilterType.INTEGER, field);
    }
}

export class DecimalFilter extends Filter implements RangeFilter<number> {
    from?: number;
    to?: number;
    constructor(field: string) {
        super(FilterType.DECIMAL, field);
    }
}

export class DateFilter extends Filter implements RangeFilter<Date> {
    from?: Date;
    to?: Date;
    constructor(field: string) {
        super(FilterType.DATE, field);
    }
}

export class DateTimeFilter extends Filter implements RangeFilter<Date> {
    from?: Date;
    to?: Date;
    constructor(field: string) {
        super(FilterType.DATE_TIME, field);
    }
}
