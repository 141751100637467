import React, { useContext, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import { PROMISE_OK } from '../../../../common/const';
import util from '../../../../common/util';
import { requiredValidator } from '../../../../common/validation/simpleValidators';
import { Unit } from '../../../../model/enums/Unit';
import StringInput from '../../../form/StringInput';
import { DwForm } from '../../../form/DwForm';
import { FormConfig } from '../../../form/formConfig';
import ContextError from '../../../form/ContextError';
import { ClientFormContext } from '../ClientForm';
import { PATH_CP_CLIENT_ASSORTMENT, PATH_VARIABLE_ASSORTMENT_ID } from '../../../../routerPaths';
import { useRefreshContextAction } from '../../../table/DwTable';
import { ClientAssortmentModel } from '../../../../model/assortment';
import { Field } from './field';
import clientAssortments from '../../../../api/clientAssortments';
import CheckboxInput from '../../../form/CheckboxInput';
import TextInput from '../../../form/TextInput';
import NumberInput from '../../../form/NumberInput';
import Assortments from '../../../form/Assortments';
import { Tmc } from '../../../form/Tmc';
import { TmcCategory } from '../../../../model/tmc/tmcModel';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';
import Options from '../../../form/Options';
import { DictionaryType } from '../../../control/option';

const useFormConfig = (
    refresh: () => void,
    clientId: number
) =>
    useMemo(
        () =>
            FormConfig.builder<ClientAssortmentModel>()
                .text(
                    Field.CLIENT_NAME,
                    (m) => m.clientName,
                    (m, v) => (m.clientName = v)
                )
                .requiredOption(
                    Field.NAME,
                    (m) => m.name,
                    (m, v) => (m.name = v)
                )
                .text(
                    Field.NUMBER,
                    (m) => m.number,
                    (m, v) => (m.number = v)
                )
                .text(
                    Field.UNIT_BARCODE,
                    (m) => m.unitBarcode,
                    (m, v) => (m.unitBarcode = v)
                )
                .text(
                    Field.PACKAGE_BARCODE,
                    (m) => m.packageBarcode,
                    (m, v) => (m.packageBarcode = v)
                )
                .boolean(
                    Field.USE_NET,
                    (m) => m.useNet,
                    (m, v) => (m.useNet = v)
                )
                .boolean(
                    Field.USE_TALMAN,
                    (m) => m.useTalman,
                    (m, v) => (m.useTalman = v)
                )
                .boolean(
                    Field.USE_PASSPORT,
                    (m) => m.usePassport,
                    (m, v) => (m.usePassport = v)
                )
                .text(
                    Field.COMMENT,
                    (m) => m.comment,
                    (m, v) => (m.comment = v)
                )
                .number(
                    Field.WEIGHT_FROM,
                    (m) => m.weightFrom,
                    (m, v) => (m.weightFrom = v),
                    (m) =>
                        m.unit?.value === Unit.PIECE
                            ? requiredValidator(m.weightFrom)
                            : Promise.resolve(PROMISE_OK)
                )
                .number(
                    Field.WEIGHT_TO,
                    (m) => m.weightTo,
                    (m, v) => (m.weightTo = v),
                    (m) =>
                        m.unit?.value === Unit.PIECE
                            ? requiredValidator(m.weightTo)
                            : Promise.resolve(PROMISE_OK)
                )
                .number(
                    Field.WEIGHT_MIN,
                    (m) => m.weightMin,
                    (m, v) => (m.weightMin = v)
                )
                .number(
                    Field.WEIGHT_AVG,
                    (m) => m.weightAvg,
                    (m, v) => (m.weightAvg = v)
                )
                .number(
                    Field.BOXES,
                    (m) => m.boxes,
                    (m, v) => (m.boxes = v)
                )
                .option(
                    Field.PACKAGE_TMC,
                    (m) => m.packageTmc,
                    (m, v) => (m.packageTmc = v)
                )
                .option(
                    Field.TARE_TMC,
                    (m) => m.tareTmc,
                    (m, v) => (m.tareTmc = v)
                )
                .text(
                    Field.TEMPERATURE_REGIME,
                    (m) => m.temperatureRegime,
                    (m, v) => (m.temperatureRegime = v)
                )
                .option(
                    Field.UNIT,
                    (m) => m.unit,
                    (m, v) => (m.unit = v)
                )
                .load((id) =>
                    id ? clientAssortments.get(id) : Promise.resolve({ clientId } as ClientAssortmentModel)
                )
                .emptyModel({ clientId } as ClientAssortmentModel)
                .submit((model) => clientAssortments.save(model))
                .idPathVariableName(PATH_VARIABLE_ASSORTMENT_ID)
                .redirectUrl(
                    generatePath(PATH_CP_CLIENT_ASSORTMENT, { clientId: util.toString(clientId) })
                )
                .afterSubmit((_) => refresh())
                .build(),
        [refresh, clientId]
    );

export const ClientAssortmentForm: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const { refresh } = useRefreshContextAction();

    const formConfig = useFormConfig(refresh, clientId);

    return (
        <DwForm config={formConfig}>
            <ClientAssortmentLayout />
            <ContextError />
        </DwForm>
    );
};

const ClientAssortmentLayout: React.FC = () => {
    return (
        <div>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row align-items-center'>
                        <div className='col-lg-3'>
                            Наименование контрагента <StringInput id={Field.CLIENT_NAME} />
                        </div>
                        <div className='col-lg-3'>
                            Наименование склада <Assortments id={Field.NAME} />
                        </div>
                        <div className='col-lg-2'>
                            Артикул <StringInput id={Field.NUMBER} />
                        </div>
                        <div className='col-lg-2'>
                            Штрих-код единицы <StringInput id={Field.UNIT_BARCODE} />
                        </div>
                        <div className='col-lg-2'>
                            Штрих-код упаковки <StringInput id={Field.PACKAGE_BARCODE} />
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className='col'>
                            Начальный калибр <NumberInput id={Field.WEIGHT_FROM} />
                        </div>
                        <div className='col'>
                            Конечный калибр <NumberInput id={Field.WEIGHT_TO} />
                        </div>
                        <div className='col'>
                            Единица измерения
                            <Options id={Field.UNIT} dictionaryType={DictionaryType.UNIT} />
                        </div>
                        <div className='col'>
                            Упаковка
                            <Tmc
                                id={Field.PACKAGE_TMC}
                                tmcCategories={[TmcCategory.PACKAGE, TmcCategory.PACKAGE_OR_TARE]}
                                nullable
                            />
                        </div>
                        <div className='col'>
                            Квант <NumberInput id={Field.WEIGHT_MIN} />
                        </div>
                        <div className='col'>
                            Упаковка/Тара <NumberInput id={Field.BOXES} />
                        </div>
                        <div className='col'>
                            Тара
                            <Tmc
                                id={Field.TARE_TMC}
                                tmcCategories={[TmcCategory.TARE, TmcCategory.PACKAGE_OR_TARE]}
                                nullable
                            />
                        </div>
                        <div className='col'>
                            Вес ед. <NumberInput id={Field.WEIGHT_AVG} />
                        </div>
                    </div>

                    <div className='row align-items-center'>
                        <div className='col-lg-2'>
                            Температурный режим
                            <StringInput id={Field.TEMPERATURE_REGIME} />
                        </div>
                        <div className='col-lg-1'>
                            Сетка <CheckboxInput id={Field.USE_NET} /> <br />
                        </div>
                        <div className='col-lg-2'>
                            Тальманская расписка <CheckboxInput id={Field.USE_TALMAN} /> <br />
                        </div>
                        <div className='col-lg-1'>
                            Паспорт паллет <CheckboxInput id={Field.USE_PASSPORT} /> <br />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            Комментарий <TextInput id={Field.COMMENT} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};
