import { requiredValidator } from './simpleValidators';
import { ShipmentDetailModel } from '../../component/quotes/model/shipmentDetailModel';
import { QuoteModel } from '../../component/quotes/model/quoteModel';
import { PROMISE_OK } from '../const';

const notNullableLoadingDtValidator = (loadingDate: Date, unloadingDate: Date) => {
    if (!loadingDate) {
        return requiredValidator(loadingDate);
    }
    return Promise.resolve(
        isUnloadingDateEarlierLoadingDate(loadingDate, unloadingDate)
            ? 'дата погрузки должна быть раньше или равна дате разгрузки'
            : PROMISE_OK
    );
};

const notNullableUnloadingDtValidator = (unloadingDate: Date, loadingDate: Date) => {
    if (!unloadingDate) {
        return requiredValidator(unloadingDate);
    }
    return Promise.resolve(
        isUnloadingDateEarlierLoadingDate(loadingDate, unloadingDate)
            ? 'дата разгрузки должна быть позже или равна дате погрузки'
            : PROMISE_OK
    );
};

const isUnloadingDateEarlierLoadingDate = (loadingDate: Date, unloadingDate: Date): boolean => {
    const loadingDateStartOfDay: number = new Date(loadingDate).setHours(0, 0, 0, 0);
    const unloadingDateStartOfDay: number = new Date(unloadingDate).setHours(0, 0, 0, 0);
    return unloadingDateStartOfDay < loadingDateStartOfDay;
};

const shipmentDetailsValidator = (quote: QuoteModel, isEdi?: boolean) => {
    const isCarrierThirdParty = quote.carrierThirdParty;
    const isValid = (quote.shipmentDetails ?? []).every(
        (sd: ShipmentDetailModel) =>
            sd.clientAssortment?.value > 0 &&
            sd.companyAssortment?.value > 0 &&
            (isEdi || sd.weight > 0) &&
            sd.sellPrice > 0 &&
            (isCarrierThirdParty ? sd.buyPrice > 0 : true)
    );
    const err = isCarrierThirdParty
        ? `укажите наименование заказчика, наименование склада,${isEdi ? '' : ' вес,'} цену и закуп для всех единиц заказа`
        : `укажите наименование заказчика, наименование склада${isEdi ? '' : ', вес'} и цену для всех единиц заказа`;
    return Promise.resolve(isValid ? PROMISE_OK : err);
};

const domesticShipmentDetailsValidator = (quote: QuoteModel) => {
    const isValid = (quote.shipmentDetails ?? []).every(
        (sd: ShipmentDetailModel) => {
            if (sd.tmc) {
                return sd.tmc?.value > 0 && sd.weight > 0;
            }

            return sd.companyAssortment?.value > 0 && sd.weight > 0;
        }
    );
    return Promise.resolve(
        isValid ? PROMISE_OK : 'укажите наименование склада и вес для всех единиц заказа'
    );
};

export {
    notNullableLoadingDtValidator,
    notNullableUnloadingDtValidator,
    shipmentDetailsValidator,
    domesticShipmentDetailsValidator,
};
