import React, { useContext, useEffect, useMemo } from 'react';
import {
    PATH_CP_COMPANIES,
    PATH_VARIABLE_COMPANY_ID
} from '../../../../routerPaths';

import UploadArea from '../../../form/attachments/UploadArea';
import AttachmentsTable from '../../../form/attachments/AttachmentsTable';
import { AttachmentModel, AttachmentType, ListAttachmentModel } from '../../../../model/attachment';
import { CompanyFormContext } from '../CompanyForm';
import { DwForm, FormContext, useSetFieldValue, validateField } from '../../../form/DwForm';
import ContextError from '../../../form/ContextError';
import { FormConfig } from '../../../form/formConfig';
import companies from '../../../../api/companies';
import { useBaseConfigBuilder } from '../../../form/attachments/configBuilder';
import { AttachmentField } from '../../../form/attachments/attachmentField';
import { ColumnType } from '../../../table/columnType';
import { companyAttachmentsValidator } from '../../../../common/validation/attachmentValidators';
import { useIsMounted } from '../../../../common/isMounted';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';

const ATTACHMENTS_FIELD = 'attachments';

export const useFormConfig = (companyId: number) =>
    useMemo(() =>
            FormConfig.builder<ListAttachmentModel>()
                .list<AttachmentModel>(
                    ATTACHMENTS_FIELD,
                    (m) => m.attachments,
                    (m, v) => (m.attachments = v),
                    (m) => companyAttachmentsValidator(m.attachments),
                )
                .load(companies.getStampAndFacsimile)
                .submit((model) => companies.saveStampAndFacsimile(companyId, model))
                .idPathVariableName(PATH_VARIABLE_COMPANY_ID)
                .redirectUrl(PATH_CP_COMPANIES)
                .build(),
        [companyId]
    );

const CompanyAttachmentsArea: React.FC = () => {
    const {
        company: { id: companyId }
    } = useContext(CompanyFormContext);
    const formConfig = useFormConfig(companyId);

    return (
        <DwForm config={formConfig}>
            <CompanyAttachmentsAreaForm companyId={companyId} />
            <ContextError />
        </DwForm>
    );
};

type Props = {
    companyId: number;  
};

const CompanyAttachmentsAreaForm: React.FC<Props> = ({ companyId }) => {
    const context = useContext(FormContext);
    const {state: {model}} = context;
    const setValue = useSetFieldValue();
    const id = ATTACHMENTS_FIELD;
    const attachmentType = AttachmentType.COMPANY;
    const fieldConfig = context.state.config.field(id);
    const attachmentsValue = fieldConfig.getter(model);
    const attachments = useMemo(() => (attachmentsValue ?? []) as AttachmentModel[], [attachmentsValue]);
    const props = {
        id,
        attachmentType,
        parentId: companyId,
    };

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [attachments]);

    const onChange = (value: AttachmentModel[]) => setValue(id, value);

    const tableConfig = useBaseConfigBuilder(ATTACHMENTS_FIELD, attachmentType, companyId, attachments, onChange)
        .column((builder) =>
            builder
                .label('Вид файла')
                .field(AttachmentField.KIND)
                .type(ColumnType.ATTACHMENT_KIND)
                .editable()
                .columnPosition(3)
        )
        .build();

    return (
        <div className='company'>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className={'form-group attachments-area'}>
                                <AttachmentsTable {...props} config={tableConfig} />
                                <UploadArea {...props}
                                    filesLimit={3} // stamp, facsimile of the director, facsimile of the chief accountant
                                    acceptedFileTypes={{ 'image/*': ['.png'] }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
}

export default CompanyAttachmentsArea;
