import React, { useContext, useEffect } from 'react';
import { FormConfig } from '../../form/formConfig';
import TestModeBanner from '../TestModeBanner';
import { LoginModel } from './loginModel';
import { FieldType } from '../../form/fieldType';
import ContextError from '../../form/ContextError';
import auth from '../../../api/auth';
import Input from '../../form/Input';
import { DwForm, useDwSubmit } from '../../form/DwForm';
import { RootContext } from '../ContextWrapper';

import companyLogo from '../../../img/company-logo.png';
import { PATH_BASE } from '../../../routerPaths';
import { LoginResponse } from '../../../api/loginResponse';
import route from '../../../common/route';

import './login.scss';

const formConfig = FormConfig.builder<LoginModel>()
    .requiredText(
        'login',
        (m) => m.login,
        (m, v) => (m.login = v)
    )
    .requiredField(
        'password',
        FieldType.PASSWORD,
        (m) => m.password,
        (m, v) => (m.password = v)
    )
    .submit(auth.login)
    .redirectUrl(PATH_BASE)
    .build();

const LoginInner: React.FC = () => {
    const dwSubmit = useDwSubmit();
    return (
        <>
            <ContextError />
            <Input
                id='login'
                placeholder='Введите логин или e-mail...'
                classNames={['form-control-user']}
                autoFocus={true}
            />
            <Input id='password' placeholder='Пароль' classNames={['form-control-user']} />
            <a
                href={PATH_BASE}
                className='btn btn-primary btn-user btn-block w-50 mx-auto'
                onClick={dwSubmit}
            >
                Войти
            </a>
        </>
    );
};

const Login: React.FC = () => {
    const {
        state: { busy },
        setBusy,
        setProfile,
    } = useContext(RootContext);
    route.setBusy = setBusy;

    useEffect(() => {
        if (busy) {
            setBusy(false);
        }
    }, [busy, setBusy]);

    const config = formConfig.withAfterSubmit((response: LoginResponse) => {
        setProfile(response.profile);
        localStorage.setItem(auth.TOKEN, response.token);
    });

    return (
        <div className='container login'>
            <div className='row justify-content-center'>
                <div className='col-xl-6 col-lg-12 col-md-9'>
                    <div className='card o-hidden border-0 shadow-lg my-5'>
                        <div className='card-body'>
                            <div className=''>
                                <div className='login-logo'>
                                    <img
                                        src={companyLogo}
                                        alt='Agrosoyuz brand logo'
                                        className='login-brand-logo'
                                    />
                                </div>
                                <hr className='my-4 bg-primary' />
                                <div className='flex justify-content-center pb-2 pl-5 pr-5 mr-4 ml-4'>
                                    <h1 className='text-center h4 text-gray-900 mb-2'>
                                        Вход в систему
                                        <TestModeBanner />
                                    </h1>
                                    <DwForm className='user' config={config}>
                                        <LoginInner />
                                    </DwForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
