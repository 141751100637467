import React, { ElementType, HTMLAttributes } from "react";
import { useClickOutsideRef } from "../../common/clickOutside";

const DEFAULT_TAG = 'div';

type WithoutListenerProps = HTMLAttributes<HTMLOrSVGElement> & {
    readonly as?: ElementType;
}
type WithListenerProps = WithoutListenerProps & {
    readonly callback: () => void;
}
type ClickOutsideWrapperProps = WithListenerProps & {
    readonly listen?: boolean;
}

const WithListener: React.FC<WithListenerProps> = ({ callback, as: Tag = DEFAULT_TAG, children, className, ...otherProps }) => {
    const { ref } = useClickOutsideRef(callback);
    return (
        <Tag ref={ref} {...otherProps} className={`${className ?? ''} click-listener`}>
            {children}
        </Tag>
    );
}

const WithoutListener: React.FC<WithoutListenerProps> = ({ as: Tag = DEFAULT_TAG, children, ...otherProps }) => {
    return (
        <Tag {...otherProps}>
            {children}
        </Tag>
    );
}

const ClickOutsideWrapper: React.FC<ClickOutsideWrapperProps> = ({ listen = false, callback, ...otherProps }) => {
    if (listen) {
        return <WithListener {...{ callback, ...otherProps }} />;
    } else {
        return <WithoutListener {...otherProps} />;
    }
}

export default ClickOutsideWrapper;