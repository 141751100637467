import { NavigateFunction, Params, generatePath, matchRoutes, useLocation } from 'react-router-dom';
import { router } from '../router';

type RouteMatch = {
    path: string,
    pathVariableName: string,
    params: Params<string>
}
type PathFromNewEntityProps = {
    routeMatch: RouteMatch,
    entityId: number,
    defaultUrl: string
}

export function generatePathFromNewEntity({ routeMatch, entityId, defaultUrl }: PathFromNewEntityProps): string {
    const entityIdNumber = Number(entityId);
    if (entityIdNumber && entityIdNumber > 0 && routeMatch?.path) {
        return generatePath(
            routeMatch.path,
            { ...(routeMatch.params ?? {}), [routeMatch.pathVariableName]: entityId }
        );
    }
    return defaultUrl;
}

export const useMatchingRoute = (pathVariableName: string) => {
    const { pathname } = useLocation();
    const matchedRoutes = matchRoutes(router.routes, pathname);
    if (matchedRoutes) {
        const pathVarRegEx = new RegExp(`:${pathVariableName}\\?*\\/*$`);
    for (let index = matchedRoutes.length - 1; index >= 0; index--) {
        const element = matchedRoutes[index];
        if (element.route?.path && pathVarRegEx.test(element.route?.path)) {
            return {
                path: element?.route?.path?.replace('\\?*\\/*$', ''),
                params: element?.params,
                pathVariableName
            } as RouteMatch;
        }
      }
    }
    return {} as RouteMatch;
}

type Route = {
    navigate: NavigateFunction;
    setBusy: (busy: boolean) => void;
    pushError: (error: string) => void;
};

const route: Route = {
    navigate: () => {},
    setBusy: () => {},
    pushError: () => {},
};

export default route;
