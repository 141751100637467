import React from 'react';
import { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import { Option } from '../control/option';
import edi from '../../api/edi';
import FormSelect from './FormSelect';

type Props = {
    id: string;
    onChange?: (newValue: SingleValue<Option>, rowNum: number) => void;
};

const EdiPartyOptions: React.FC<Props> = ({ id, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        edi
            .partyOptions({ filter: inputValue })
            .then((options) => callback(options));
    };
    return (
        <FormSelect id={id} loadOptions={loadOptions} onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void} />
    );
};

export default EdiPartyOptions;
