import {Option} from '../../component/control/option';
import {ClientKind, type ClientModel} from '../../model/client/clientModel';
import {ContractType} from '../../model/enums/ContractType';
import {
    decimalValidator,
    emailValidator as emailSimpleValidator,
    positiveIntValidator,
    requiredValidator,
} from './simpleValidators';
import clients from '../../api/clients';
import {type ContractModel} from '../../model/contract/contractModel';
import contracts from '../../api/contracts';
import {innValidator, kppValidator, ogrnValidator, phoneValidator} from './commonValidators';
import {PROMISE_OK} from '../const';

const isAlien = (model: ClientModel) => {
    return model?.alien || false;
};

const isAccredited = (model: ClientModel) => {
    return model?.accredited || false;
};

const clientInnValidator = async (model: ClientModel): Promise<string> => {
    const { id, inn, kpp, kind } = model;

    const checkInnUniqueness = async () => {
        const isInnKppUnique = await clients.isInnKppUnique(id, inn, kpp);
        return Promise.resolve(isInnKppUnique ? PROMISE_OK : 'ИНН уже зарегистрирован в системе');
    };

    if (kind?.value === ClientKind.PHYSICAL) {
        if (inn) {
            return (await checkInnUniqueness()) || (await innValidator(model));
        }
        return Promise.resolve(PROMISE_OK);
    }

    return (await checkInnUniqueness()) || (await innValidator(model, isAlien(model)));
};

const clientKppValidator = (model: ClientModel): Promise<string> => {
    const {kpp, kind} = model;

    if (kind?.value === ClientKind.PHYSICAL) {
        return Promise.resolve(kpp ? 'у физ. лица не существует КПП' : PROMISE_OK);
    }

    if (!isAlien(model)) {
        return kppValidator(model);
    }
    return Promise.resolve(PROMISE_OK);
};

const clientOgrnValidator = (model: ClientModel): Promise<string> => {
    const { ogrn, kind } = model;

    if (kind?.value === ClientKind.PHYSICAL) {
        return Promise.resolve(ogrn ? 'у физ. лица не существует ОГРН' : PROMISE_OK);
    }

    return ogrnValidator(model, isAlien(model));
};

const atiRatingValidator = (model: ClientModel) => {
    if (!isAlien(model)) {
        return decimalValidator(model.atiRating);
    }
    return Promise.resolve(PROMISE_OK);
};

const atiCodeValidator = (model: ClientModel) => {
    if (!isAlien(model)) {
        return positiveIntValidator(model.atiCode);
    }
    return Promise.resolve(PROMISE_OK);
};

const emailValidator = (model: ClientModel) => {
    const email = model.email;
    if (!isAlien(model)) {
        return emailSimpleValidator(email);
    }
    return Promise.resolve(PROMISE_OK);
};

const fullNameValidator = (model: ClientModel) => {
    if (isAccredited(model)) {
        return requiredValidator(model.fullName);
    }
    return Promise.resolve(PROMISE_OK);
};

const clientPhoneValidator = (model: ClientModel, required?: boolean) => {
    if (!isAlien(model)) {
        return phoneValidator(model, required);
    }
    return Promise.resolve(PROMISE_OK);
};

const headValidator = (model: ClientModel) => {
    if (isAccredited(model)) {
        return requiredValidator(model.head);
    }
    return Promise.resolve(PROMISE_OK);
};

const mainContractUniqueValidator = (model: ContractModel) => {
    const {id, client, type, main} = model;
    if (client && type && main) {
        return contracts
            .isMainUnique(id, client.value, type.value)
            .then((response) =>
                response ? PROMISE_OK : 'нельзя создать два основных договора одного вида'
            );
    }
    return Promise.resolve(PROMISE_OK);
};

const accountValidator = (model: ContractModel, option: Option) => {
    const contractTypeId: number = model.type?.value;
    const validTypes: ContractType[] = [ContractType.CLIENT, ContractType.CARRIER];
    return validTypes.includes(contractTypeId)
        ? Promise.resolve(PROMISE_OK)
        : requiredValidator(option);
};

export {
    clientInnValidator,
    clientKppValidator,
    clientOgrnValidator,
    atiRatingValidator,
    atiCodeValidator,
    emailValidator,
    fullNameValidator,
    clientPhoneValidator,
    headValidator,
    mainContractUniqueValidator,
    accountValidator,
};
