export class ApiResponse {
    error: string = '';
    message: string = '';
    success: () => boolean = () => !!this.error;
}

enum BackendApiErrors {
    FILE_NOT_FOUND = 'File not found',
    INTERNAL_SERVER_ERROR = 'Server error',
    SQL_REFERENCE_ERROR = 'There are some references in other entities',
    DATA_ACCESS_ERROR = 'Data access error',
}

enum ClientApiErrors {
    FILE_NOT_FOUND = 'Файл не найден',
    INTERNAL_SERVER_ERROR = 'Внутренняя ошибка сервера',
    SQL_REFERENCE_ERROR = 'Есть связи в других каталогах',
    DATA_ACCESS_ERROR = 'Ошибка доступа к данным',
}

const API_ERRORS = new Map<string, string>([
    [BackendApiErrors.FILE_NOT_FOUND, ClientApiErrors.FILE_NOT_FOUND],
    [BackendApiErrors.INTERNAL_SERVER_ERROR, ClientApiErrors.INTERNAL_SERVER_ERROR],
    [BackendApiErrors.SQL_REFERENCE_ERROR, ClientApiErrors.SQL_REFERENCE_ERROR],
    [BackendApiErrors.DATA_ACCESS_ERROR, ClientApiErrors.DATA_ACCESS_ERROR],
]);

export const handleApiError = (apiError: string) => {
    const defaultMessage = ClientApiErrors.INTERNAL_SERVER_ERROR;
    const errorMessage: string = API_ERRORS.get(apiError) || defaultMessage;

    return window.alert(errorMessage);
};
