export enum TmcOperationField {
    ID = 'id',
    DT = 'dt',
    TYPE = 'type',
    CLIENT_ADDRESS = 'clientAddress',
    CLIENT = 'client',
    TMC = 'tmc',
    TMC_NAME = 'tmcName',
    UNIT = 'unit',
    QUANTITY = 'quantity',
    PRICE = 'price',
    WEIGHT = 'weight',
    TOTAL_COST = 'totalCost',
    COMMENT = 'comment',
    RUN = 'run',
    QUOTE = 'quote',
}
