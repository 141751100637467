import React from 'react';
import Input from './Input';

type Props = {
    id: string;
    placeholder?: string;
    readOnly?: boolean;
    onChange?: (newValue: number) => void;
};

const NumberInput: React.FC<Props> = ({ id, placeholder, readOnly, onChange }) => (
    <Input id={id} placeholder={placeholder} onChange={onChange} readOnly={readOnly} />
);

export default NumberInput;
