import type { MouseEvent } from 'react';
import React, { useCallback, useContext, useMemo } from 'react';
import companies from '../../../api/companies';
import { kppValidator, ogrnValidator } from '../../../common/validation/commonValidators';
import { CompanyInnValidator } from '../../../common/validation/companyValidators';
import type { CompanyModel } from '../../../model/company/companyModel';
import { PATH_CP_COMPANIES, PATH_VARIABLE_COMPANY_ID } from '../../../routerPaths';
import { showSuccessToast, showWarningToast } from '../../control/showToast';
import ClientBasisOptions from '../../form/ClientBasisOptions';
import ContextError from '../../form/ContextError';
import { DwForm, FormContext, useSetFieldValue } from '../../form/DwForm';
import { FormConfig } from '../../form/formConfig';
import StringInput from '../../form/StringInput';
import { CompanyField } from './companyField';
import { CompanyFormContext } from './CompanyForm';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';

export const useFormConfig = (company: CompanyModel) =>
    useMemo(
        () =>
            FormConfig.builder<CompanyModel>()
                .number(
                    CompanyField.ID,
                    (model) => model.id,
                    (model, value) => (model.id = value)
                )
                .requiredText(
                    CompanyField.NAME,
                    (model) => model.name,
                    (model, value) => (model.name = value)
                )
                .requiredText(
                    CompanyField.FULL_NAME,
                    (model) => model.fullName,
                    (model, value) => (model.fullName = value)
                )
                .text(
                    CompanyField.INN,
                    (model) => model.inn,
                    (model, value) => (model.inn = value),
                    (model) => CompanyInnValidator(model as CompanyModel)
                )
                .text(
                    CompanyField.KPP,
                    (model) => model.kpp,
                    (model, value) => (model.kpp = value),
                    (model) => kppValidator(model)
                )
                .text(
                    CompanyField.OGRN,
                    (model) => model.ogrn,
                    (model, value) => (model.ogrn = value),
                    (model) => ogrnValidator(model)
                )
                .requiredText(
                    CompanyField.PHONE,
                    (model) => model.phone,
                    (model, value) => (model.phone = value)
                )
                .requiredText(
                    CompanyField.POSTAL_ADDRESS,
                    (model) => model.postalAddress,
                    (model, value) => (model.postalAddress = value)
                )
                .requiredText(
                    CompanyField.ACTUAL_ADDRESS,
                    (model) => model.actualAddress,
                    (model, value) => (model.actualAddress = value)
                )
                .requiredText(
                    CompanyField.ADDRESS,
                    (model) => model.address,
                    (model, value) => (model.address = value)
                )
                .requiredText(
                    CompanyField.HEAD,
                    (model) => model.head,
                    (model, value) => (model.head = value)
                )
                .requiredText(
                    CompanyField.HEAD_POSITION,
                    (model) => model.headPosition,
                    (model, value) => (model.headPosition = value)
                )
                .requiredText(
                    CompanyField.HEAD_GENITIVE,
                    (model) => model.headGenitive,
                    (model, value) => (model.headGenitive = value)
                )
                .option(
                    CompanyField.BASIS,
                    (model) => model.basis,
                    (model, value) => (model.basis = value)
                )
                .load((_) => Promise.resolve(company))
                .submit(companies.save)
                .idPathVariableName(PATH_VARIABLE_COMPANY_ID)
                .redirectUrl(PATH_CP_COMPANIES)
                .build(),
        [company]
    );

const Company: React.FC = () => {
    const { company } = useContext(CompanyFormContext);
    const formConfig = useFormConfig(company);

    return (
        <DwForm config={formConfig}>
            <CompanyInner />
            <ContextError />
        </DwForm>
    );
};

const CompanyInner: React.FC = () => {
    const context = useContext(FormContext);
    const { model } = context.state;
    const setValue = useSetFieldValue();

    const updateFormValues = useCallback(
        (company: CompanyModel) => {
            if (Object.keys(company)?.length > 0) {
                setValue(CompanyField.FULL_NAME, company.fullName);
                setValue(CompanyField.NAME, company.name);
                setValue(CompanyField.INN, company.inn);
                setValue(CompanyField.OGRN, company.ogrn);
                setValue(CompanyField.KPP, company.kpp);
                setValue(CompanyField.ADDRESS, company.address);
                setValue(CompanyField.HEAD, company.head);
                setValue(CompanyField.HEAD_POSITION, company.headPosition);
                setValue(CompanyField.HEAD_GENITIVE, company.headGenitive);
                setValue(CompanyField.BASIS, company.basis);
                setValue(CompanyField.PHONE, company.phone);
            }
        },
        [setValue]
    );

    const onSuggestion = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const suggest = async () => {
                const company: CompanyModel = await companies.getSuggestion(model as CompanyModel);

                if (Object.keys(company).length !== 0) {
                    updateFormValues(company);
                    showSuccessToast('Организация найдена');
                } else {
                    showWarningToast('Организация не найдена');
                }
            };

            suggest().then();
        },
        [model, updateFormValues]
    );

    const onClean = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();

            const emptyModel = {
                fullName: '',
                name: '',
                inn: '',
                ogrn: '',
                kpp: '',
                address: '',
                head: '',
                headPosition: '',
                headGenitive: '',
                basis: {},
                phone: '',
            } as CompanyModel;
            updateFormValues(emptyModel);
        },
        [updateFormValues]
    );

    return (
        <div className='company'>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            Краткое наименование Организации
                            <StringInput id={CompanyField.NAME} />
                        </div>

                        <div className='col-lg-4'>
                            Полное наименование Организации
                            <StringInput id={CompanyField.FULL_NAME} />
                        </div>

                        <div className='col-lg-4'>
                            Телефон
                            <StringInput id={CompanyField.PHONE} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4'>
                            ИНН
                            <StringInput id={CompanyField.INN} />
                        </div>

                        <div className='col-lg-4'>
                            ОГРН
                            <StringInput id={CompanyField.OGRN} />
                        </div>

                        <div className='col-lg-4'>
                            КПП
                            <StringInput id={CompanyField.KPP} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-4'>
                            Почтовый адрес
                            <StringInput id={CompanyField.POSTAL_ADDRESS} />
                        </div>

                        <div className='col-lg-4'>
                            Фактический адрес
                            <StringInput id={CompanyField.ACTUAL_ADDRESS} />
                        </div>

                        <div className='col-lg-4'>
                            Юридический адрес
                            <StringInput id={CompanyField.ADDRESS} />
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-3'>
                            Должность руководителя
                            <StringInput id={CompanyField.HEAD_POSITION} />
                        </div>

                        <div className='col-lg-3'>
                            ФИО Руководителя
                            <StringInput id={CompanyField.HEAD} />
                        </div>

                        <div className='col-lg-3'>
                            В лице
                            <StringInput id={CompanyField.HEAD_GENITIVE} />
                        </div>
                        <div className='col-lg-3'>
                            Действует на основании
                            <ClientBasisOptions id={CompanyField.BASIS} />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center'>
                <button className='btn btn-sm btn-primary' onClick={onSuggestion}>
                    Заполнить
                </button>
                &nbsp;
                <SaveAndCancelButtonGroup />
                &nbsp;
                <button className='btn btn-sm btn-outline-primary' onClick={onClean}>
                    Очистить
                </button>
            </div>
        </div>
    );
};

export default Company;
