import contacts from '../../api/contacts';
import { ContactModel } from '../../model/contact/contactModel';
import { PROMISE_OK } from '../const';

export const mainContactUniqueValidator = async (clientId: number, model: ContactModel) => {
    const { id, main } = model;
    if (clientId && main) {
        const response = await contacts.isClientContactMainUnique({ clientId, contactId: id });
        return response ? PROMISE_OK : 'у клиента уже есть основной контакт';
    }

    return Promise.resolve(PROMISE_OK);
};

export const mainStorageUniqueValidator = async (storageId: number, model: ContactModel) => {
    const { id, main } = model;
    if (storageId && main) {
        const response = await contacts.isStorageContactMainUnique({ storageId, contactId: id });
        return response ? PROMISE_OK : 'у склада уже есть основной контакт';
    }

    return Promise.resolve(PROMISE_OK);
};
