import {deleteRequest, get, post, put} from './rest';

import type {ApiResponse} from './apiResponse';
import type {PagedResponse} from './pagedResponse';
import type {VehicleListModel} from '../model/vehicle/vehicleListModel';
import type {ListRequest} from '../component/table/listRequest';
import type {Option} from '../component/control/option';
import type {VehicleOptionsRequest, VehicleModel} from '../model/vehicle/vehicleModel';

const PATH_BASE = 'vehicles';

const vehicles = {
    options: (request: VehicleOptionsRequest) => post<Option[]>(`${PATH_BASE}/options`, request),
    list: (model: ListRequest) => post<PagedResponse<VehicleListModel>>(`${PATH_BASE}/list`, model),
    delete: (id: number) => deleteRequest<ApiResponse>(`${PATH_BASE}/${id}`),
    get: (id: number) => get<VehicleModel>(`${PATH_BASE}/${id}`),
    save: (model: VehicleModel) =>
        model.id > 0 ? put<number>(PATH_BASE, model) : post<number>(PATH_BASE, model),
};

export default vehicles;
