import React from 'react';
import {generatePath, useNavigate} from 'react-router-dom';
import { Direction } from '../../../common/const';
import {useFieldValue} from '../../form/DwForm';
import NumberInput from '../../form/NumberInput';
import TextInput from '../../form/TextInput';
import util from '../../../common/util';
import Options from '../../form/Options';
import {DictionaryType, Option} from '../../control/option';
import {TmcOperationField} from './tmcOperationField';
import { Storages } from '../../form/ClientStorages';
import { Tmc } from '../../form/Tmc';
import {PATH_QUOTE, PATH_RUN, PATH_VARIABLE_QUOTE_ID, PATH_VARIABLE_RUN_ID} from '../../../routerPaths';
import {OperationType} from '../../../model/enums/OperationType';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import Clients from '../../form/Clients';
import { ClientType } from '../../../model/client/clientModel';

const OptionInfo: React.FC<{ label: string, option: Option, navigateTo: string }> = ({ label, option, navigateTo }) => {
    const navigate = useNavigate();
    return (
        <>
            {option && option.value > 0 && (
                <div className='col-lg-2'>
                    {label}:
                    <button onClick={() => navigate(navigateTo)} className='btn btn-sm btn-link pt-0' >
                        {option.label ? option.label : option.value}
                    </button>
                </div>
            )}
        </>
    );
}

const TmcOperation: React.FC = () => {

    const valueQuantity = useFieldValue(TmcOperationField.QUANTITY);
    const valuePrice = useFieldValue(TmcOperationField.PRICE);
    const valueDt = useFieldValue(TmcOperationField.DT);
    const valueRun = useFieldValue(TmcOperationField.RUN);
    const valueQuote = useFieldValue(TmcOperationField.QUOTE);
    const pathRun = generatePath(PATH_RUN, {
        direction: Direction.EXTERNAL,
        [PATH_VARIABLE_RUN_ID]: util.stringOrEmpty(valueRun?.value),
    });
    const pathQuote = generatePath(PATH_QUOTE, {
        direction: Direction.EXTERNAL,
        [PATH_VARIABLE_QUOTE_ID]: util.stringOrEmpty(valueQuote?.value),
    });

    const operationTypeFilter = (option: Option) => {
        return option.value !== OperationType.MISGRADING;
    };

    return (
        <>
            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-2'>
                            Операция
                            <Options
                                id={TmcOperationField.TYPE}
                                dictionaryType={DictionaryType.OPERATION_TYPE}
                                filterCallback={operationTypeFilter}
                            />
                        </div>
                        <div className='col-lg-3'>
                            Поставщик
                            <Clients id={TmcOperationField.CLIENT} clientTypes={[ClientType.SUPPLIER]} />
                        </div>
                        <div className='col-lg-3'>
                            Склад
                            <Storages id={TmcOperationField.CLIENT_ADDRESS} />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            ТМЦ
                            <Tmc id={TmcOperationField.TMC} />
                        </div>
                        <div className='col'>
                            Единица измерения
                            <Options id={TmcOperationField.UNIT} dictionaryType={DictionaryType.UNIT} />
                        </div>
                        <div className='col'>
                            Количество
                            <NumberInput id={TmcOperationField.QUANTITY} />
                        </div>
                        <div className='col'>
                            Вес
                            <NumberInput id={TmcOperationField.WEIGHT}/>
                        </div>
                        <div className='col'>
                            Цена
                            <NumberInput id={TmcOperationField.PRICE}/>
                        </div>
                        <div className='col'>
                            Стоимость
                            <div>{Number((valueQuantity ?? 0) * (valuePrice ?? 0)).toFixed(2)}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-2'>
                            Дата операции: {util.formatDate(valueDt ?? new Date())}
                        </div>
                        <OptionInfo label={'Рейс'} option={valueRun} navigateTo={pathRun} />
                        <OptionInfo label={'Заказ'} option={valueQuote} navigateTo={pathQuote} />
                    </div>
                </div>
            </div>

            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <TextInput id={TmcOperationField.COMMENT}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </>
    );
};

export default TmcOperation;
