import { Option } from '../../component/control/option';

export enum EdiMessageField {
    ID = 'id',
    ADDRESS = 'address',
    CONSIGNEE = 'consignee',
    SELLER = 'seller',
    BUYER = 'buyer',
    AMOUNT = 'amount',
    NUMBER = 'number',
    DT = 'dt',
    PARTY = 'party',
    RESPONSE_DT = 'responseDt',
    REQUESTED_DELIVERY_DT = 'requestedDeliveryDt',
    QUOTE = 'quote',
    QUOTE_ID = 'quoteId',
    QUOTE_NUMBER = 'quoteNumber',
    RUN_NUMBER = 'runNumber',
    EVENT_POINTER = 'eventPointer',
    NET_AMOUNT = "netAmount"
}

export type EdiMessageModel = {
    id: number;
    quote: Option;
};
