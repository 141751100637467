import React, { useCallback, useContext, useMemo } from 'react';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import QuoteOptions from '../QuoteOptions';
import { Option } from '../../control/option';
import { FormContext, useFieldValue, useSetFieldValue } from '../../form/DwForm';
import { RunModel } from '../model/runModel';
import { RunField } from '../runField';
import { RunPointModel } from '../model/runPointModel';
import { Dropdown, DropdownItem } from '../../layout/Dropdown';
import quotes from '../../../api/quotes';
import { QuoteModel } from '../../quotes/model/quoteModel';
import routeUtil from './routeUtil';

type Props = {
    id: string;
};

export const RouteBtns: React.FC<Props> = ({ id }) => {
    const context = useContext(FormContext);
    const model = context.state.model as RunModel;
    const setValue = useSetFieldValue();
    const fieldRouteValue = useFieldValue(id);
    const routeData: RunPointModel[] = useMemo(() => fieldRouteValue ?? [], [fieldRouteValue]);

    const valueClient = useFieldValue(RunField.CLIENT);
    const linkedQuoteIds = useMemo(() => Array.from(new Set(routeData.filter(rp => rp.quoteId > 0).map(rp => rp.quoteId))), [routeData]);
    const firstLoading = useMemo(() => routeUtil.findFirstLoading(routeData), [routeData]);

    const onChange = useCallback((route: RunPointModel[]) => {
        setValue(id, route);
    }, [id, setValue]);

    const onAddQuoteBased = useCallback((quoteOption: SingleValue<Option>) => {
        const quoteId = quoteOption?.value;
        if (quoteId && routeUtil.isQuoteUniqueForRoute(routeData, quoteId)) {
            quotes.get(quoteId).then((quote: QuoteModel) => {
                const loading = routeUtil.createLoadingQuotePoint(quote);
                const unloading = routeUtil.createUnloadingQuotePoint(quote);
                onChange([...routeData, loading, unloading]);
            });
        }
    }, [routeData, onChange]);

    const onAddClientBased = useCallback((isLoading: boolean) => {
        const point = routeUtil.createClientPoint(isLoading);
        onChange([...routeData, point]);
    }, [routeData, onChange]);

    const onAddCompanyBased = useCallback((isLoading: boolean) => {
        const point = routeUtil.createCompanyPoint(isLoading);
        onChange([...routeData, point]);
    }, [routeData, onChange]);

    return (
        <>
            <QuoteOptions
                runStatusId={model.status?.value}
                linkedQuoteIds={linkedQuoteIds}
                clientId={valueClient?.value}
                loadingDate={firstLoading?.date}
                loadingAddress={firstLoading?.address}
                onChange={onAddQuoteBased}
            />
            &nbsp;
            <DropdownBtn
                label={'+загрузка'}
                onClickClient={() => onAddClientBased(true)}
                onClickCompany={() => onAddCompanyBased(true)}
            />
            &nbsp;
            <DropdownBtn
                label={'+разгрузка'}
                onClickClient={() => onAddClientBased(false)}
                onClickCompany={() => onAddCompanyBased(false)}
            />
        </>
    );
};

type DropdownBtnProps = {
    label: string;
    onClickClient: () => void;
    onClickCompany: () => void;
};

const DropdownBtn: React.FC<DropdownBtnProps> = ({ label, onClickClient, onClickCompany }) => (
    <Dropdown label={label}>
        <DropdownItem label={'по клиенту'} onClick={onClickClient} />
        <DropdownItem label={'по организации'} onClick={onClickCompany} />
    </Dropdown>
);
