import React from 'react';
import { Polyline } from 'react-leaflet';
import { getIconType } from './getMarkerIcon';
import { RouteMarkers } from './MapComponent';
import RouteMarker from './RouteMarker';

type Props = {
    positions: Array<[number, number]>;
    markers?: RouteMarkers[];
    color: string;
};

const RouteWithMarkers: React.FC<Props> = ({ positions, markers, color }) => {
    return (
        <>
            <Polyline positions={positions} color={color} />
            {markers?.map((marker, index) => {
                const { city, street } = marker.address;
                const [latitude, longitude] = marker.latLngTuple;

                return (
                    <RouteMarker
                        key={`marker_${index}`}
                        longitude={longitude}
                        latitude={latitude}
                        iconType={getIconType(index, markers.length - 1)}
                        tooltip={`${index + 1}. ${city} ${street}`}
                    />
                );
            })}
        </>
    );
};

export default RouteWithMarkers;
