import React, { createContext, useEffect, useMemo, useState } from 'react';
import { generatePath, Outlet, useParams } from 'react-router-dom';
import companies from '../../../api/companies';
import util from '../../../common/util';
import {
    PATH_CP_COMPANY,
    PATH_CP_COMPANY_ACCOUNTS,
    PATH_CP_COMPANY_ADDRESSES,
    PATH_CP_COMPANY_FACSIMILE,
    PATH_CP_COMPANY_HISTORY,
    PATH_VARIABLE_COMPANY_ID,
} from '../../../routerPaths';
import DwFormTabs from '../../form/DwFormTabs';
import FormSkeleton from '../../form/FormSkeleton';

import type { CompanyModel } from '../../../model/company/companyModel';
import { useFormVersion } from '../../form/DwForm';
import { TableRefreshWrapper } from '../../table/TableRefreshWrapper';

type SharedCompanyContext = {
    company: CompanyModel;
};

export const CompanyFormContext = createContext<SharedCompanyContext>({} as SharedCompanyContext);

const CompanyForm: React.FC = () => {
    const [company, setCompany] = useState<CompanyModel>({} as CompanyModel);
    const companyId = util.toNumber(useParams()[PATH_VARIABLE_COMPANY_ID]!)!;
    const { formVersion } = useFormVersion();

    useEffect(() => {
        let shouldUpdate = true;
        if (companyId > 0) {
            companies.get(Number(companyId)).then((company) => shouldUpdate && setCompany(company));
        } else {
            setCompany({} as CompanyModel);
        }
        return () => { shouldUpdate = false; }
    }, [companyId, formVersion]);

    const companyFormContextValue = useMemo(() => ({ company }), [company]);

    const isNewCompany = !(company.id > 0);
    const companyIdPath = util.stringOrEmpty(companyId);

    if (companyId > 0 && !company.id) {
        return (
            <div className='form-wrapper d-flex flex-column flex-sm-fill'>
                <div>
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1'} />
                </div>
                <div className='d-flex h-100 mt-2'>
                    <FormSkeleton className={'card-body'} />
                </div>
            </div>
        );
    }

    return (
        <CompanyFormContext.Provider value={companyFormContextValue}>
            <TableRefreshWrapper>
                <div className='form-wrapper'>
                    <DwFormTabs
                        items={[
                            {
                                name: isNewCompany ? 'Новая организация' : company?.name,
                                path: generatePath(PATH_CP_COMPANY, {
                                    [PATH_VARIABLE_COMPANY_ID]: companyIdPath,
                                }),
                                end: true,
                            },
                            {
                                name: 'Р/Счета',
                                path: generatePath(PATH_CP_COMPANY_ACCOUNTS, {
                                    [PATH_VARIABLE_COMPANY_ID]: companyIdPath,
                                }),
                                disabled: isNewCompany,
                            },
                            {
                                name: 'Адреса',
                                path: generatePath(PATH_CP_COMPANY_ADDRESSES, {
                                    [PATH_VARIABLE_COMPANY_ID]: companyIdPath,
                                }),
                                disabled: isNewCompany,
                            },
                            {
                                name: 'Печать и факсимиле',
                                path: generatePath(PATH_CP_COMPANY_FACSIMILE, {
                                    [PATH_VARIABLE_COMPANY_ID]: companyIdPath,
                                }),
                                disabled: isNewCompany,
                            },
                            {
                                name: 'История',
                                path: generatePath(PATH_CP_COMPANY_HISTORY, {
                                    [PATH_VARIABLE_COMPANY_ID]: companyIdPath,
                                }),
                                disabled: isNewCompany,
                                end: true,
                            },
                        ]}
                    />

                    <Outlet />
                </div>
            </TableRefreshWrapper>
        </CompanyFormContext.Provider>
    );
};

export default CompanyForm;
