export enum RunStatus {
    PRELIMINARY = 1,
    NOT_APPROVED = 2,
    APPROVED = 3,
    LOADING = 4,
    READY_TO_LOAD = 11,
    LOADED = 5,
    ON_WAY = 6,
    UNLOADING = 7,
    UNLOADED = 8,
    DATE_CHANGED = 12,
    CANCELLED = 9,
    FAILED = 10,
}

export const RUN_STATUS = new Map<RunStatus, string>([
    [RunStatus.PRELIMINARY, 'Предварительный'],
    [RunStatus.NOT_APPROVED, 'Не согласован'],
    [RunStatus.APPROVED, 'Согласован'],
    [RunStatus.LOADING, 'На погрузке'],
    [RunStatus.READY_TO_LOAD, 'Готов к отгрузке'],
    [RunStatus.LOADED, 'Погрузился'],
    [RunStatus.ON_WAY, 'В Пути'],
    [RunStatus.UNLOADING, 'На разгрузке'],
    [RunStatus.UNLOADED, 'Разгрузился'],
    [RunStatus.DATE_CHANGED, 'Изменена дата'],
    [RunStatus.CANCELLED, 'Отмена'],
    [RunStatus.FAILED, 'Срыв'],
]);

export const ALL_RUN_STATUS_IDS = Object.values(RunStatus);
