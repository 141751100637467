import React, { createContext, useCallback, useMemo, useState } from 'react';
import integrations from '../../../../api/integrations';

interface EdiIntegrationContextType {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    onShow: () => void;
}

const EdiIntegrationContext = createContext<EdiIntegrationContextType>({
    show: false,
    setShow: () => {},
    onShow: () => {}
});

const EdiIntegrationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [show, setShow] = useState(false);
    const [busy, setBusy] = useState(false);

    const onShow = useCallback(async () => {
        setShow(!show);
        if (!busy) {
            setBusy(true);
            await integrations.fetchFromEdi();
            setBusy(false);
        }
    }, [busy, show]);

    const contextValue = useMemo(() => ({ show, setShow, onShow }), [show, setShow, onShow]);

    return (
        <EdiIntegrationContext.Provider value={contextValue}>
            {children}
        </EdiIntegrationContext.Provider>
    );
};

export { EdiIntegrationProvider, EdiIntegrationContext };
