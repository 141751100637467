import { Config } from '../../table/config';
import React, { useContext, useEffect, useMemo } from 'react';
import { AttachmentModel, AttachmentType } from '../../../model/attachment';
import { EditableDwTable } from '../../table/EditableDwTable';
import { FormContext, useSetFieldValue, validateField } from '../DwForm';
import { useBaseConfigBuilder } from './configBuilder';
import { useIsMounted } from '../../../common/isMounted';

type Props = {
    id: string;
    attachmentType: AttachmentType;
    parentId: number;
    config?: Config<AttachmentModel>
};

const AttachmentsTable: React.FC<Props> = ({
    id,
    attachmentType,
    parentId,
    config: customConfig,
}) => {
    const context = useContext(FormContext);
    const model = context.state.model;
    const setValue = useSetFieldValue();
    const fieldConfig = context.state.config.field(id);
    const attachmentsValue = fieldConfig.getter(model);
    const attachments = useMemo(() => attachmentsValue || [], [attachmentsValue]);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [attachments]);

    const onChange = (value: AttachmentModel[]) => {
        setValue(id, value);
    };

    const config = useBaseConfigBuilder(id, attachmentType, parentId, attachments, onChange).build();
    return <EditableDwTable config={customConfig ?? config} />;
};

export default AttachmentsTable;
