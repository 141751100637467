import type { SingleValue } from 'react-select/dist/declarations/src/types';
import { ContractType } from '../../../model/enums/ContractType';
import type { Option } from '../../control/option';
import { CARRIER_CONTRACT_DEPENDENT_FIELDS, CLIENT_CONTRACT_DEPENDENT_FIELDS } from '../quoteField';

type SetContractFunction<T extends Option> = (
    contract: T,
    client: Option,
    fieldMap: Map<string, string>
) => void;

type Props<T extends Option> = {
    newValue: SingleValue<T>;
    valueType: ContractType;
    setContract: SetContractFunction<T>;
    valueClient?: Option;
    valueCarrier?: Option;
};

const onChangeContract = <T extends Option>({
    newValue,
    valueType,
    setContract,
    valueClient,
    valueCarrier,
}: Props<T>) => {
    setContract(
        newValue!,
        valueType === ContractType.CLIENT ? valueClient! : valueCarrier!,
        valueType === ContractType.CLIENT
            ? CLIENT_CONTRACT_DEPENDENT_FIELDS
            : CARRIER_CONTRACT_DEPENDENT_FIELDS
    );
};

export default onChangeContract;
