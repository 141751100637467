import React from 'react';
import settings from '../../../api/settings';
import {showSuccessToast} from '../../control/showToast';
import ContextError from '../../form/ContextError';
import {DwForm} from '../../form/DwForm';
import {FormConfig} from '../../form/formConfig';
import StringInput from '../../form/StringInput';
import {ToastMessage} from "./const";
import {SaveButton} from '../../form/SaveButtonGroup';

type DadataModel = {
    apiKey: string;
    secretKey: string;
}

const DADATA_API_KEY = 'dadata.apiKey';
const DADATA_SECRET_KEY = 'dadata.secretKey';

const save = (model: DadataModel) => {
    return settings.update([
        {key: DADATA_API_KEY, value: model.apiKey},
        {key: DADATA_SECRET_KEY, value: model.secretKey},
    ]);
};

const load = async (): Promise<DadataModel> => {
    const data = await settings.get([DADATA_API_KEY, DADATA_SECRET_KEY])
    return {
        apiKey: data.get(DADATA_API_KEY),
        secretKey: data.get(DADATA_SECRET_KEY),
    } as DadataModel;
};

const formConfig = FormConfig.builder<DadataModel>()
    .requiredText(
        "apiKey",
        (model) => model.apiKey,
        (model, value) => (model.apiKey = value)
    )
    .requiredText(
        "secretKey",
        (model) => model.secretKey,
        (model, value) => (model.secretKey = value)
    )
    .load((_) => load())
    .submit((model) => save(model))
    .afterSubmit(() => showSuccessToast(ToastMessage.SETTINGS_SAVED))
    .idPathVariableName('')
    .build();

const SettingsFormInner: React.FC = () => {
    return (
        <>
            <StringInput id='apiKey' placeholder='api-ключ' />
            <StringInput id='secretKey' placeholder='секретный ключ' />
            <ContextError />

            <div className="text-center">
                <SaveButton />
            </div>
        </>
    );
}

const SettingsForm: React.FC = () => {
    return (
        <div className="form card dadata-settings w-50 mb-3">
            <div className="card-header d-flex flex-row">
                <div className="align-self-center">
                    Интеграция с сервисом DaData
                </div>
            </div>
            <div className="card-body">
                <DwForm config={formConfig}>
                    <SettingsFormInner />
                </DwForm>
            </div>
        </div>
    );
}

export default SettingsForm;
