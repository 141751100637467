import 'leaflet/dist/leaflet.css';
import L, { LatLngTuple } from 'leaflet';

import React, { useEffect, useMemo, useState } from 'react';
import { MapContainer } from 'react-leaflet';
import addresses from '../../../api/addresses';
import encodePolyline from '../../../api/external/encodePolyline';
import getPolylineFromOSRM from '../../../api/external/getPolylineFromOSRM';
import geolocations from '../../../api/geolocations';
import { AddressModel } from '../../../model/address';
import { RunPointModel } from '../../runs/model/runPointModel';
import MapContent from './MapContent';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

const MAP_LOADING_MESSAGE = 'Карта загружается...';
const NO_ROUTE_MESSAGE = 'Маршрут не доступен или не найден';
const showLoadingMessage = () => <div>{MAP_LOADING_MESSAGE}</div>;
const showNoRouteMessage = () => <div>{NO_ROUTE_MESSAGE}</div>;
const endOfTheDay = new Date().setHours(23, 59);

type Props = {
    runId: number;
    truck: string;
    driverId: number;
    created: Date;
    routePoints: RunPointModel[];
};

export type RouteMarkers = {
    address: AddressModel;
    latLngTuple: number[];
};

const MapComponent: React.FC<Props> = ({ runId, truck, driverId, created, routePoints }) => {
    const [routePolyline, setRoutePolyline] = useState<Array<[number, number]>>();
    const [routeMarkers, setRouteMarkers] = useState<RouteMarkers[]>();

    const [driversPolyline, setDriversPolyline] = useState<Array<[number, number]>>();
    const [driverMarkers, setDriverMarkers] = useState<number[][]>();

    const mockModel = useMemo(
        () => ({
            runId,
            userId: driverId,
            from: created,
            to: new Date(endOfTheDay),
        }),
        [runId]
    );

    useEffect(() => {
        const fetchDriversRoute = async () => {
            const res = await geolocations.list(mockModel);
            if (res) {
                const resMarkers = res.map((marker) => [
                    Number(marker.longitude),
                    Number(marker.latitude),
                ]);
                setDriverMarkers(resMarkers);

                const encodedPolyline = encodePolyline(resMarkers);
                const polylineData = await getPolylineFromOSRM(encodedPolyline);
                setDriversPolyline(polylineData as [number, number][]);
            }
        };

        fetchDriversRoute();
    }, [runId]);

    useEffect(() => {
        const fetchRoutePoints = async () => {
            if (routePoints) {
                const points = routePoints.map(async (point) => {
                    const { value: addressId } = point.address;
                    const address = await addresses.getById(addressId);
                    const { longitude, latitude } = address;
                    return {
                        address,
                        latLngTuple: [Number(longitude), Number(latitude)],
                    };
                });

                const resolvedPoints = await Promise.all(points);
                const polylineData = await getPolylineFromOSRM(
                    resolvedPoints.map((point) => point.latLngTuple)
                );

                setRouteMarkers(resolvedPoints);
                setRoutePolyline(polylineData as [number, number][]);
            }
        };

        fetchRoutePoints();
    }, [routePoints]);

    if (!routePolyline) {
        return showLoadingMessage();
    }

    if (routePolyline.length === 0) {
        return showNoRouteMessage();
    }

    const driversCurrentPosition =
        driverMarkers && driverMarkers.length > 0 ? driverMarkers[driverMarkers.length - 1] : null;

    return (
        <MapContainer id='map' className='leaflet-map-container'>
            <MapContent
                routeMarkers={routeMarkers as RouteMarkers[]}
                routePolyline={routePolyline}
                driverMarkers={driverMarkers}
                driversPolyline={driversPolyline}
                truck={truck}
                {...(driversCurrentPosition && {
                    driversCurrentPosition: driversCurrentPosition as LatLngTuple,
                })}
            />
        </MapContainer>
    );
};

export default MapComponent;
