import { toast } from 'react-toastify';

export enum ToastType {
    INFO = 'info',
    SUCCESS = 'success',
    WARNING = 'warning',
    ERROR = 'error',
}

const DEFAULT_AUTOCLOSE = 1500;

const showToast = (text: string, type: ToastType, autoClose: number = DEFAULT_AUTOCLOSE) =>
    toast[type](text, {
        position: 'top-right',
        autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        toastId: text,
    });

const showSuccessToast = (text: string, autoClose: number = DEFAULT_AUTOCLOSE) =>
    showToast(text, ToastType.SUCCESS, autoClose);

const showWarningToast = (text: string, autoClose: number = DEFAULT_AUTOCLOSE) =>
    showToast(text, ToastType.WARNING, autoClose);

export { showToast, showSuccessToast, showWarningToast };
