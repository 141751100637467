import { Profile } from '../../model/profile';
import { UiState } from '../../model/uiState';

export enum ActionType {
    BUSY,
    PROFILE,
    UI_STATE,
    PUSH_ERROR,
}

export type Action = {
    type: ActionType;
    busy?: boolean;
    profile?: Profile;
    uiState?: UiState;
    error?: string;
};
