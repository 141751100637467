import { deleteRequest, get, post } from './rest';
import { Option } from '../component/control/option';
import { ContractOptionsRequest } from '../model/contract/contract';
import { ApiResponse } from './apiResponse';
import { PagedResponse } from './pagedResponse';
import { ContractModel } from '../model/contract/contractModel';
import { ListRequest } from '../component/table/listRequest';
import { ContractListModel } from '../model/contract/contractListModel';
import { ContractRequest } from '../model/contract/contractRequest';
import { ContractType } from '../model/enums/ContractType';

const contracts = {
    options: (request: ContractOptionsRequest) => post<Option[]>('contracts/options', request),
    getForClient: (id: number, clientId: number) =>
        get<ContractModel>(`contracts/${id}?clientId=${clientId}`),
    getDefaultMain: (request: ContractRequest) => post<ContractModel>(`contracts/default`, request),
    save: (model: ContractModel) => post<ApiResponse>('contracts', model),
    listForClient: (model: ListRequest, clientId: number) =>
        post<PagedResponse<ContractListModel>>(`contracts/list?clientId=${clientId}`, model),
    delete: (id: number) => deleteRequest<ApiResponse>(`contracts/${id}`),
    isMainUnique: (id: number, clientId: number, type: ContractType) =>
        post<ApiResponse>('contracts/main/unique', { id, clientId, type }),
    isForClientAndCompany: (id: number, clientId: number, companyId: number) =>
        get<boolean>(`contracts/${id}/${clientId}/${companyId}/exists`),
};

export default contracts;
