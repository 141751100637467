import React from 'react';

type Props = {
    error: string | undefined;
    inline?: boolean;
};

const FormInvalidText = (props: Props) => (
    <>{!!props.error && <div className={`mt-0 invalid-feedback ${props.inline && 'inline'}`}>{props.error}</div>}</>
);

export default FormInvalidText;
