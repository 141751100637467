import { get, post } from './rest';
import { ApiResponse } from './apiResponse';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { TalmanListModel, TalmanModel } from '../component/runs/model/talman/talmanModel';

const PATH_BASE = 'talman';

const talmans = {
    get: (shipmentDetailId: number) => get<TalmanModel>(`${PATH_BASE}/${shipmentDetailId}`),
    save: (shipmentDetailId: number, model: TalmanModel) => post<ApiResponse>(`${PATH_BASE}/${shipmentDetailId}`, model),
    list: (request: ListRequest, runId: number) => post<PagedResponse<TalmanListModel>>(`${PATH_BASE}/${runId}/list`, request),
};

export default talmans;