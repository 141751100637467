import { Action, ActionType } from './action';
import { State } from './ContextWrapper';
import { Profile } from '../../model/profile';

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.BUSY:
            return { ...state, busy: action.busy! };
        case ActionType.PROFILE:
            return { ...state, profile: { ...new Profile(), ...action.profile! } };
        case ActionType.UI_STATE: {
            const newState = { ...state, uiState: { ...action.uiState! } };
            localStorage.setItem('uiState', JSON.stringify(newState.uiState));
            return newState;
        }
        case ActionType.PUSH_ERROR:
            return { ...state, error: action.error ?? '' };
        default:
            throw new Error('Non-implemented action type in reducer');
    }
};
