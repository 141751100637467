import React from 'react';
import { STAGE_UI_HOST } from '../../common/const';

const TestModeBanner: React.FC = () => {
    return window.location.host === STAGE_UI_HOST ? (
        <div className='test-mode-banner'>ТЕСТ</div>
    ) : (
        <></>
    );
};

export default TestModeBanner;
