import React from 'react';
import ListInput from './base/ListInput';

type Props = {
    id: string;
    placeholder?: string;
    onChange?: (newValue: number, rowNum: number) => void;
};

const ListNumberInput: React.FC<Props> = ({ id, placeholder, onChange }) => (
    <ListInput id={id} inputType={'number'} placeholder={placeholder} onChange={onChange} />
);

export default ListNumberInput;
