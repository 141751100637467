import React, { useContext, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import accounts from '../../../../api/accounts';
import { AccountModel } from '../../../../model/account';
import {
    PATH_CP_COMPANY_ACCOUNTS,
    PATH_VARIABLE_COMPANY_ACCOUNT_ID,
} from '../../../../routerPaths';
import ContextError from '../../../form/ContextError';
import { DwForm } from '../../../form/DwForm';
import { FormConfig } from '../../../form/formConfig';
import util from '../../../../common/util';
import { useRefreshContextAction } from '../../../table/DwTable';
import {
    bikValidator,
    correspondentNumberValidator,
    numberValidator
} from '../../../../common/validation/accountValidators';
import AccountForm from '../../account/AccountForm';
import { CompanyFormContext } from '../CompanyForm';
import { AccountField } from '../../account/accountField';

const useFormConfig = (refresh: () => void, companyId: number) =>
    useMemo(
        () => FormConfig.builder<AccountModel>()
                .number(
                    AccountField.ID,
                    (m) => m.id,
                    (m, v) => (m.id = v),
                )
                .text(
                    AccountField.NUMBER,
                    (m) => m.number,
                    (m, v) => (m.number = v),
                    (m) => numberValidator(m.id, m.number)
                )
                .text(
                    AccountField.CORRESPONDENT_NUMBER,
                    (m) => m.correspondentNumber,
                    (m, v) => (m.correspondentNumber = v),
                    (m) => correspondentNumberValidator(m.correspondentNumber)
                )
                .requiredText(
                    AccountField.BANK,
                    (m) => m.bank,
                    (m, v) => (m.bank = v)
                )
                .text(
                    AccountField.BIK,
                    (m) => m.bik,
                    (m, v) => (m.bik = v),
                    (m) => bikValidator(m.bik)
                )
                .load((id) =>
                    id && companyId
                        ? accounts.getForCompany(id, companyId)
                        : Promise.resolve({} as AccountModel)
                )
                .submit((model) => {
                    return accounts.saveForCompany(model, companyId);
                })
                .idPathVariableName(PATH_VARIABLE_COMPANY_ACCOUNT_ID)
                .redirectUrl(
                    generatePath(PATH_CP_COMPANY_ACCOUNTS, {
                        companyId: util.stringOrEmpty(companyId),
                    })
                )
                .afterSubmit((_) => refresh())
                .build(),
        [refresh, companyId]
    );

const CompanyAccount: React.FC = () => {
    const {
        company: { id: companyId }
    } = useContext(CompanyFormContext);
    const { refresh } = useRefreshContextAction();
    const formConfig = useFormConfig(refresh, companyId);
    return (
        <DwForm config={formConfig}>
            <AccountForm />
            <ContextError />
        </DwForm>
    );
};

export default CompanyAccount;
