import React, { ReactElement, useContext } from 'react';
import { TableContext } from './DwTable';
import { ActionType } from './action';

type Props = {
    id: number;
};

export const RowSelector: (props: Props) => ReactElement<Props> = ({ id }) => {
    const tableContext = useContext(TableContext);
    const isChecked = tableContext.state.selectedRows.includes(id);
    const onChange = () => {
        tableContext.dispatch({ type: ActionType.SELECT_ROW, id });
    };
    return (
        <div className='custom-control custom-checkbox'>
            <div className='form-check pr-2'>
                <input
                    id={`${id}`}
                    type='checkbox'
                    className='custom-control-input'
                    checked={isChecked}
                    onChange={onChange}
                />
                <label className='custom-control-label' htmlFor={`${id}`} />
            </div>
        </div>
    );
};
