import React, {ReactElement, useCallback, useContext, useMemo} from 'react';
import {TableContext} from './DwTable';
import {Filter, FilterType, OptionFilter, StringFilter} from './filter';
import {ActionType} from './action';
import {FormConfig} from '../form/formConfig';
import {FieldType} from '../form/fieldType';
import {DwForm, useDwSubmit, useSetFieldValue} from '../form/DwForm';
import {ColumnType} from './columnType';
import {Storages} from '../form/ClientStorages';
import {Column} from './column';

type FilterFormProps = {
    column: Column<any, any>;
};

const getFilterType = (col: Column<any, any>): FilterType => {
    switch (col.type) {
        case ColumnType.STORAGE:
            return FilterType.OPTION;
        default:
            return FilterType.STRING;
    }
};

const getFilterModel = (col: Column<any, any>, filters: Filter[]): Filter => {
    return filters.find((it) => it.field === col.field) ?? createFilter(col, getFilterType(col));
};

const createFilter = (column: Column<any, any>, filterType: FilterType): Filter => {
    switch (filterType) {
        case FilterType.OPTION:
            return new OptionFilter(column.field);
        default:
            return new StringFilter(column.field);
    }
};

const StoragesFilterInner: ({id}: {id: string}) => ReactElement<FilterFormProps> = ({id}) => {
    const dwSubmit = useDwSubmit();
    return (<Storages id={id} onChange={() => dwSubmit()}/>);
}

export const OuterFilterForm: (props: FilterFormProps) => ReactElement<FilterFormProps> = (
    props
) => {
    const tableContext = useContext(TableContext);
    const column = props.column;
    const model = useMemo(
        () => getFilterModel(column, tableContext.state.request.filters),
        [column, tableContext.state.request.filters]
    );

    const renderStorages = () => {
        return (
            <OuterFilter type={FieldType.OPTION} fields={['value']} model={model} col={column}>
                <StoragesFilterInner id={'value'}/>
            </OuterFilter>
        );
    };

    const renderControl = () => {
        switch (column.type) {
            case ColumnType.STORAGE:
                return renderStorages();
            default:
                return <></>;
        }
    };

    return renderControl();
};

type FilterProps<T> = {
    type: FieldType;
    fields: string[];
    model: T;
    children: React.ReactElement[] | React.ReactElement;
    col: Column<any, any>;
};

const OuterFilter: <T>(props: FilterProps<T>) => ReactElement<FilterProps<T>> = (props) => {
    const tableContext = useContext(TableContext);
    const {children, model, fields, type, col} = props;

    const onChangeFilter = (filter: Filter) =>
        new Promise((resolve) => {
            if (Filter.isNotEmpty(filter)) {
                tableContext.dispatch({
                    type: ActionType.ADD_FILTER,
                    filter: filter,
                });
            }
            resolve('');
        });

    const config = FormConfig.builder<any>()
        .submit(onChangeFilter)
        .initialModelGetter((c) => Promise.resolve(model));

    fields.forEach((id) => {
        config.field(
            id,
            type,
            (m) => m[id],
            (m, v) => (m[id] = v)
        );
    });

    return (
        <DwForm config={config.build()}>
            <OuterFilterLayout col={col} fields={fields}>
                {children}
            </OuterFilterLayout>
        </DwForm>
    );
};

type LayoutProps = {
    col: Column<any, any>;
    fields: string[];
    children: React.ReactElement[] | React.ReactElement;
};

const OuterFilterLayout: (props: LayoutProps) => ReactElement<LayoutProps> = (props) => {
    const tableContext = useContext(TableContext);
    const {filters} = tableContext.state.request;
    const {children, col, fields} = props;
    const setValue = useSetFieldValue();
    const resetModel = useCallback(() => {
        fields.forEach((field) => setValue(field, null));
    }, [fields, setValue]);

    const isFiltered = useCallback(
        (col: Column<any, any>) => {
            return filters.some((filter) => filter.field === col.field);
        },
        [filters]
    );

    const onRemoveFilter = (e: React.MouseEvent, col: Column<any, any>) => {
        e.preventDefault();
        e.stopPropagation();
        tableContext.dispatch({
            type: ActionType.REMOVE_FILTER,
            column: col,
        });
        resetModel();
    };

    return (
        <div className='d-flex flex-row align-items-center'>
            <span className='mb-0'>{children}</span>
            <span>
                {isFiltered(col) && (
                    <i
                        className='fas fa-fw fa-times text-danger'
                        onClick={(e) => onRemoveFilter(e, col)}
                        style={{cursor: 'pointer'}}
                    />
                )}
            </span>
        </div>
    );
};
