import { AbstractEntity } from './abstractEntity';
import { Role } from './role';

export class Profile extends AbstractEntity {
    email: string = '';
    phone: string = '';
    firstName: string = '';
    midName: string = '';
    lastName: string = '';
    roles: Role[] = [];
}
