import React from 'react';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import { Option } from '../../control/option';
import ListClients from './ListClients';
import { ClientType } from '../../../model/client/clientModel';

type Props = {
    id: string;
    onChange?: (newValue: SingleValue<Option>, rowNum: number) => void;
};

const ListCompanies: React.FC<Props> = (props) => <ListClients {...props} clientTypes={[ClientType.ORGANIZATION]}/>;

export default ListCompanies;
