import React from 'react';
import SaveButtonGroup from './SaveButtonGroup';
import CancelButton from './CancelButton';

const SaveAndCancelButtonGroup: React.FC = () => {
    return (
        <>
            <SaveButtonGroup />
            &nbsp;
            <CancelButton />
        </>
    );
};

export default SaveAndCancelButtonGroup;