import { get } from './rest';
import { ApiResponse } from './apiResponse';
import { RestUrlPrefix } from '../common/const';

const PATH_BASE = `${RestUrlPrefix.WS}/integrations`;

const integrations = {
    fetchFromEdi: () => get<ApiResponse>(`${PATH_BASE}/edi`),
};

export default integrations;
