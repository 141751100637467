import React, { ChangeEvent } from 'react';
import { EditableTableContext } from '../../table/EditableDwTable';

type Props = {
    id: string;
    label?: string;
    className?: string;
};

const ListCheckboxInput: React.FC<Props> = ({ id, label, className }) => (
    <EditableTableContext.Consumer>
        {(tableContext) => {
            const { activeCellGetter: getter, activeCellSetter: setter } = tableContext;
            const value = getter(id) || false;

            const onChange = (event: ChangeEvent<any>) => {
                const isChecked = event.target.checked;
                setter(id, isChecked);
            };

            return (
                <div className={'custom-control custom-checkbox'}>
                    <div className={'form-group'}>
                        <input
                            type='checkbox'
                            id={id}
                            className={`custom-control-input ${className ?? ''}`}
                            checked={value}
                            onChange={onChange}
                        />
                        <label className='custom-control-label' htmlFor={id}>
                            {label}
                        </label>
                    </div>
                </div>
            );
        }}
    </EditableTableContext.Consumer>
);

export default ListCheckboxInput;
