import companies from '../../api/companies';
import { innValidator } from './commonValidators';

import type { CompanyModel } from '../../model/company/companyModel';

export const CompanyInnValidator = async (model: CompanyModel) => {
    const { id, inn, kpp } = model;
    const isInnKppUnique = await companies.isInnKppUnique(id, inn, kpp);

    if (!isInnKppUnique) {
        return 'ИНН уже зарегистрирован в системе';
    }

    return await innValidator(model);
};
