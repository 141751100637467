import { Config } from '../../table/config';
import React, { MouseEvent, useMemo } from 'react';
import { RemoveButton } from '../../table/RemoveButton';
import { AttachmentModel, AttachmentType } from '../../../model/attachment';
import attachmentFiles from '../../../api/attachments';
import { AttachmentField } from './attachmentField';

export const useBaseConfigBuilder = (
    id: string,
    attachmentType: AttachmentType,
    parentId: number,
    attachments: AttachmentModel[],
    onChange: (value: AttachmentModel[]) => void,
) => {
    return useMemo(() =>
            Config.builder<AttachmentModel>()
                .column((builder) =>
                    builder
                        .position('#')
                        .cls('id')
                )
                .column((builder) => builder.text('Имя файла', AttachmentField.FILE_NAME))
                .column((builder) =>
                    builder
                        .text('ФИО прикрепившего', AttachmentField.CREATED_BY)
                        .cls('text-center')
                )
                .column((builder) => builder.dateTime('Дата', AttachmentField.CREATED))
                .column((builder) =>
                    builder
                        .getter((model) => (
                            <button
                                className='btn btn-xs btn-primary'
                                onClick={(e: MouseEvent) => onDownload(e, model, attachmentType, parentId)}
                            >
                                <i className='icon fa fa-download'/>
                            </button>
                        ))
                        .cls('text-center actions')
                        .width('20px')
                )
                .column((builder) =>
                    builder
                        .getter((model) => (
                            <RemoveButton onClick={(e: MouseEvent) => onDelete(e, model, attachmentType, parentId, attachments, onChange)}/>
                        ))
                        .cls('text-center actions')
                        .width('20px')
                )
                .id(id),
        [id, attachmentType, parentId, attachments, onChange]
    )};

const onDownload = (
    e: MouseEvent,
    row: AttachmentModel,
    attachmentType: AttachmentType,
    parentId: number,
) => {
    e.preventDefault();
    attachmentFiles.download({
        attachmentType,
        parentId,
        fileName: row.fileName,
    }).then();
};

const onDelete = (
    e: MouseEvent,
    row: AttachmentModel,
    attachmentType: AttachmentType,
    parentId: number,
    attachments: AttachmentModel[],
    onChange: (value: AttachmentModel[]) => void
) => {
    e.preventDefault();
    attachmentFiles
        .delete({
            attachmentType,
            parentId,
            fileName: row.fileName,
        })
        .then((res) => {
            if (!res.error) {
                const updatedAttachments = [...attachments].filter((a) =>
                    a.id === row.id ? a.fileName !== row.fileName : true
                );
                onChange(updatedAttachments);
            }
        });
};