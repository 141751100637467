import React, { useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { Config } from '../../table/config';
import { DictionaryModel, DictionaryNames, DictionaryType } from '../../control/option';
import dictionary from '../../../api/dictionary';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { PATH_CP_DICTIONARY_EDIT, PATH_VARIABLE_DICTIONARY_TYPE } from '../../../routerPaths';
import util from '../../../common/util';
import { RemoveButton } from '../../table/RemoveButton';

export const DictionaryList: React.FC = () => {
    const dictionaryType = useParams()[PATH_VARIABLE_DICTIONARY_TYPE] ?? '';
    const config = useMemo(
        () =>
            Config.builder<DictionaryModel>()
                .column((builder) => builder.id().filterable(false))
                .column((builder) => builder.text('Значение', 'value').filterable(false))
                .column((builder) =>
                    builder
                        .cls('text-center')
                        .sortable(false)
                        .filterable(false)
                        .getter((model, _, refresh) => (
                            <RemoveButton
                                onClick={() =>
                                    onRowDelete(refresh, () =>
                                        dictionary.delete(dictionaryType, model.id)
                                    )
                                }
                            />
                        ))
                        .width('45px')
                )
                .columnsSelector(false)
                .formUrl(
                    generatePath(PATH_CP_DICTIONARY_EDIT, {
                        dictionaryType: util.stringOrEmpty(dictionaryType),
                        dictionaryId: '',
                    })
                )
                .load((ctx) => dictionary.list(dictionaryType, ctx.state.request))
                .id('dictionary-' + dictionaryType),
        [dictionaryType]
    ).build();

    return (
        <div className='d-flex'>
            <DwTable
                header={DictionaryNames.get(dictionaryType as DictionaryType)!}
                config={config}
            />
        </div>
    );
};