import React, { type ReactElement } from 'react';

type Props = {
    onClick: (event: React.MouseEvent) => void;
    tooltip?: string;
};

export const RemoveButton: (props: Props) => ReactElement<Props> = ({ onClick, tooltip }: Props) => {
    return (
        <button className='btn btn-xs btn-danger' onClick={onClick} title={tooltip} >
            <i className='icon fas fa-times' />
        </button>
    );
};
