import React, { ReactElement, useState } from 'react';
import clsx from 'clsx';
import { Config } from './config';
import { SelectorItem } from './SelectorItem';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

type Props = {
    config: Config<any>;
};

export const ColumnsSelector: (props: Props) => ReactElement<Props> = (props) => {
    const [show, setShow] = useState(false);
    const [hover, setHover] = useState('');
    const columns = props.config.orderedColumns().filter((it) => !!it.field);
    return (
        <>
            <a
                style={{ display: 'inline-block' }}
                className='dropdown-toggle'
                href={'# '}
                onClick={() => setShow(!show)}
            >
                колонки
            </a>
            <div
                className={clsx(
                    'dropdown-menu dropdown-menu-right shadow animated--grow-in columns-selector',
                    { show: show }
                )}
            >
                <DndProvider backend={HTML5Backend}>
                    {columns.map((col) => (
                        <SelectorItem
                            config={props.config}
                            key={col.field}
                            column={col}
                            hover={hover}
                            setHover={setHover}
                        />
                    ))}
                </DndProvider>
            </div>
        </>
    );
};
