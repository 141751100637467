import React, { useState } from 'react';
import HoverWrapper from '../../layout/HoverWrapper';
import ShipmentDetailsInfoTable from '../../table/ShipmentDetailsInfoTable';

type Props = {
    id: number;
    children: React.ReactNode;
    className?: string;
};

const ShipmentDetailsOnHover: React.FC<Props> = ({ id, children, className }) => {
    const [showInfo, setShowInfo] = useState<boolean>(false);

    const handleMouseEnter = () => setShowInfo(true);
    const handleMouseLeave = () => setShowInfo(false);

    return (
        <HoverWrapper
            listen={true}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={className}
        >
            {children}
            {showInfo && <ShipmentDetailsInfoTable id={id} showInfo={showInfo} />}
        </HoverWrapper>
    );
};

export default ShipmentDetailsOnHover;
