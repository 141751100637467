import React from "react";
import { StorageAssemblyItemsProps, getValueToSet } from "./StorageAssemblyTable";
import { Outlet } from "react-router-dom";
import util from "../../../common/util";

const AssemblyFieldLabel: React.FC<{ label: string }> =
    ({ label }) => <div className="text-sm text-muted">{label}:</div>;

const AssemblyField: React.FC<{ label?: string, children: string | number | React.ReactElement | React.ReactElement[] }> =
    ({ label, children }) => {
        return (
            <div className="col">
                {label && <AssemblyFieldLabel label={label} />}
                {children}
            </div>
        );
    };

export const AssemblyCards: React.FC<StorageAssemblyItemsProps> = ({ assortment, tasks, setCompletedAssortment, onTaskRowClick }) => {
    return (
        <>
            {tasks.map(({
                tareTmc: {
                    value: tareId,
                    label: tareLabel
                },
                weight = 0,
                tareCollectedTotal = 0,
                tareDistributedShipped = 0,
                tareQuotesTotal = 0,
                client: {
                    label: clientLabel,
                    value: clientId
                }
            }) => (
                <div key={`task-item-${clientId}-${tareId}`} className="card border-left-primary mx-1 my-2" onClick={() => onTaskRowClick(clientId, tareId)}>
                    <div className="card-body">
                        <div >
                            <div className="row">
                                <div className="col h5">
                                    {clientLabel}
                                </div>
                            </div>
                            <div className="row mb-1">
                                <AssemblyField label="Тара">{tareLabel?.length ? tareLabel : '-'}</AssemblyField>
                                <AssemblyField label="Вес">{weight}</AssemblyField>
                                <AssemblyField label="В заказах">{tareQuotesTotal}</AssemblyField>
                                <AssemblyField label="Ед. измер.">-</AssemblyField>
                            </div>
                        </div>
                        <div className="row">
                            <AssemblyField>
                                <div className="d-flex align-items-center">
                                    <button className="btn btn-lg btn-danger" onClick={(e) => setCompletedAssortment(e, clientId, tareId, getValueToSet(tareCollectedTotal - 1))}>
                                        <i className="fas fa-minus" />
                                    </button>
                                    <span className="flex-fill text-lg text-center">
                                        <AssemblyFieldLabel label={"Готово (%)"} />
                                        {tareCollectedTotal - tareDistributedShipped} <span className="text-muted">({util.calcPercent(tareCollectedTotal - tareDistributedShipped, tareQuotesTotal)}%)</span>
                                    </span>
                                    <button className="btn btn-lg btn-success" onClick={(e) => setCompletedAssortment(e, clientId, tareId, getValueToSet(tareCollectedTotal + 1))}>
                                        <i className="fas fa-plus" />
                                    </button>
                                </div>
                            </AssemblyField>
                        </div>
                    </div>
                </div>
            ))}
            <Outlet />
        </>
    );
};