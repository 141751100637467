import React from 'react';
import { Option } from '../control/option';
import FormSelect from './FormSelect';
import accounts from '../../api/accounts';

type Props = {
    id: string;
    clientId: number;
};

const ClientAccounts: React.FC<Props> = ({ id, clientId }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        accounts
            .clientOptions({
                filter: inputValue,
                clientId: clientId,
            })
            .then((options) => callback(options));
    };
    return <FormSelect id={id} loadOptions={loadOptions} cacheOptions={false} />;
};

export default ClientAccounts;
