import { deleteRequest, post, postFile } from './rest';
import { ApiResponse } from './apiResponse';
import { AttachmentFileRequest } from '../model/attachment';
import fileUtil from '../common/fileUtil';

const attachments = {
    upload: (request: AttachmentFileRequest, file: File, signal: AbortSignal) => {
        const formData = new FormData();
        formData.append('file', file);
        const requestBlob = new Blob([JSON.stringify(request)], { type: 'application/json' });
        formData.append('request', requestBlob);
        return postFile<ApiResponse>('attachments/files/upload', formData, signal)
    },

    download: (request: AttachmentFileRequest) =>
        post<Blob>('attachments/files/download', request).then((blob) =>
            fileUtil.blob2File(blob, request.fileName ?? '')
        ),

    delete: (request: AttachmentFileRequest) =>
        deleteRequest<ApiResponse>('attachments/files/delete', request),
};

export default attachments;
