import React from 'react';

type ModalProps = {
    show: boolean;
    onClose: (event: React.MouseEvent) => void;
    title?: string;
    children: React.ReactElement | React.ReactElement[];
};

const Modal: React.FC<ModalProps> = ({ show, title, onClose, children }) => {
    return (
        <>
            <div className={`modal fade ${show ? 'show d-block' : 'd-none'}`}>
                <div className='modal-dialog modal-dialog-scrollable modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id={`label`}>
                                {title}
                            </h5>
                            <button className='close' onClick={onClose}>
                                <span aria-hidden='true'>
                                    <i className='fas fa-times' />
                                </span>
                            </button>
                        </div>
                        <div className='modal-body'>{children}</div>
                        <div className='modal-footer'>
                            <button className='btn btn-sm btn-outline-secondary' onClick={onClose}>
                                Закрыть
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`modal-backdrop fade ${show ? 'show d-block' : 'd-none'}`} />
        </>
    );
};

type BtnProps = {
    text: string;
    setShow: (val: boolean) => void;
};

const ModalBtn: React.FC<BtnProps> = ({ text, setShow }) => {
    return (
        <button
            type='button'
            className='btn btn-sm btn-link pl-0'
            data-toggle='modal'
            data-target='#modalComponent'
            onClick={() => setShow(true)}
        >
            {text}
        </button>
    );
};

export { Modal, ModalBtn };
