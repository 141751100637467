import { AssortmentListModel, AssortmentModel } from '../model/assortment';
import { Option } from '../component/control/option';
import { post, get, deleteRequest } from './rest';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { ApiResponse } from './apiResponse';
import { BaseOptionRequest } from './baseOptionRequest';

const assortments = {
    options: (request: BaseOptionRequest) => post<Option[]>('assortments/options', request),
    find: (id: number) => get<AssortmentModel>(`assortments/${id}`),
    save: (model: AssortmentModel) => post<ApiResponse>('assortments', model),
    delete: (id: number) => deleteRequest<ApiResponse>(`assortments/${id}`),
    list: (model: ListRequest) => post<PagedResponse<AssortmentListModel>>(`assortments/list`, model),
};

export default assortments;
