import React from 'react';
import { Option } from '../control/option';
import FormSelect from './FormSelect';
import addresses from '../../api/addresses';
import { FormatOptionLabelMeta } from 'react-select';
import { ClientAddressRequest } from '../../model/client/address';
import storages from '../../api/storages';

type Props = {
    id: string;
    clientId?: number;
    types?: number[];
    onChange?: (newValue: any) => void;
    readOnly?: boolean;
    showAddress?: boolean;
};
type PropsBase = Props & {
    loadOptionsFn: (request: ClientAddressRequest) => Promise<Option[]>;
};

export const formatOptionLabel = (option: Option, { context }: FormatOptionLabelMeta<Option>) => (
    <>
        {option.label}{context === "menu" && option.subOption?.label && <div className='sub-label'>{option.subOption?.label}</div>}
    </>
);

const BaseClientStorages: React.FC<PropsBase> = ({ loadOptionsFn, types, clientId, showAddress = false, ...rest }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        loadOptionsFn({
            filter: inputValue,
            clientId: clientId,
            typeIds: types
        })
            .then((options) => callback(options));
    };
    return (
        <FormSelect
            {...rest}
            loadOptions={loadOptions}
            cacheOptions={false}
            formatOptionLabel={showAddress ? formatOptionLabel : undefined}
        />
    );
};

export const ClientStorages: React.FC<Props> = (props) => (
    <BaseClientStorages {...props} loadOptionsFn={addresses.storageOptionsForClient} />
);
export const Storages: React.FC<Props> = (props) => (
    <BaseClientStorages {...props} loadOptionsFn={storages.options} />
);