export enum ClientField {
    ID = 'id',
    TYPE = 'type',
    KIND = 'kind',
    COMPANY = 'company',
    LOGISTIC_MANAGER = 'logisticManager',
    COMMERCIAL_MANAGER = 'commercialManager',
    ACTIVE = 'active',
    ACCREDITED = 'accredited',
    ALIEN = 'alien',
    CURRENCY_CONTROL = 'currencyControl',
    NAME = 'name',
    FULL_NAME = 'fullName',
    INN = 'inn',
    ALIEN_INN = 'alienInn',
    KPP = 'kpp',
    OGRN = 'ogrn',
    PHONE = 'phone',
    EMAIL = 'email',
    WEB = 'web',
    POSTAL_ADDRESS = 'postalAddress',
    ACTUAL_ADDRESS = 'actualAddress',
    ADDRESS = 'address',
    HEAD = 'head',
    HEAD_POSITION = 'headPosition',
    HEAD_GENITIVE = 'headGenitive',
    BASIS = 'basis',
    ATI_RATING = 'atiRating',
    ATI_CODE = 'atiCode',
    STATUS = 'status',
    COMMENT = 'comment',
    ATTACHMENTS = 'attachments',
}
