import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Outlet } from 'react-router';
import { generatePath, useParams } from 'react-router-dom';
import util from '../../../common/util';
import {
    PATH_CP_STORAGE,
    PATH_CP_STORAGE_CONTACTS,
    PATH_VARIABLE_STORAGE_ID
} from '../../../routerPaths';

import DwFormTabs from '../../form/DwFormTabs';
import FormSkeleton from '../../form/FormSkeleton';
import storages from '../../../api/storages';

import { useFormVersion } from '../../form/DwForm';
import { TableRefreshWrapper } from '../../table/TableRefreshWrapper';
import { ClientAddressModel } from '../../../model/client/address';

type SharedStorageContext = {
    storage: ClientAddressModel;
};

export const StorageFormContext = createContext<SharedStorageContext>(
    {} as SharedStorageContext
);

const StorageForm: React.FC = () => {
    const [storage, setStorage] = useState<ClientAddressModel>({} as ClientAddressModel);
    const storageId = util.toNumber(useParams()[PATH_VARIABLE_STORAGE_ID]!)!;
    const { formVersion } = useFormVersion();

    useEffect(() => {
        let shouldUpdate = true;
        if (storageId > 0) {
            storages.get(Number(storageId)).then((storageData) => shouldUpdate && setStorage(storageData));
        } else {
            setStorage({} as ClientAddressModel);
        }
        return () => { shouldUpdate = false; }
    }, [storageId, formVersion]);

    const storageFormContextValue = useMemo(() => ({ storage }), [storage]);

    const isNewStorage = !(storage.id > 0);
    const storageIdPath = util.stringOrEmpty(storageId);

    if (storageId > 0 && storage.id === undefined) {
        return (
            <div className='form-wrapper d-flex flex-column flex-sm-fill'>
                <div>
                    <FormSkeleton className={'col-1 mr-2'} />
                    <FormSkeleton className={'col-1 mr-2'} />
                </div>
                <div className='d-flex h-100 mt-2'>
                    <FormSkeleton className={'card-body'} />
                </div>
            </div>
        );
    }

    return (
        <StorageFormContext.Provider value={storageFormContextValue}>
            <TableRefreshWrapper>
                <div className='form-wrapper'>
                    <DwFormTabs
                        items={[
                            {
                                name: isNewStorage ? 'Новый склад' : storage.name,
                                path: generatePath(PATH_CP_STORAGE, {
                                    [PATH_VARIABLE_STORAGE_ID]: storageIdPath,
                                }),
                                end: true,
                            },
                            {
                                name: 'Контакты',
                                path: generatePath(PATH_CP_STORAGE_CONTACTS, {
                                    [PATH_VARIABLE_STORAGE_ID]: storageIdPath,
                                }),
                                disabled: isNewStorage,
                                end: true,
                            },
                        ]}
                    />
                    <Outlet />
                </div>
            </TableRefreshWrapper>
        </StorageFormContext.Provider>
    );
};

export default StorageForm;
