export enum EdiStatusValue {
    INITIALIZATION = 'INITIALIZATION',
    PROCESSING = 'PROCESSING',
    FINISHED = 'FINISHED'
}

export type EdiStatus = {
    status: EdiStatusValue;
    party: string;
    totalCount: number;
    currentIndex: number;
    countUpdated: number;
    countNotResolved: number;
    errors: string[];
};
