import React, { useContext, useMemo } from 'react';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { Config } from '../../../table/config';
import { NavigateFunction, Outlet, generatePath } from 'react-router-dom';
import {
    PATH_CP_COMPANY_ADDRESS,
    PATH_VARIABLE_COMPANY_ADDRESS_ID,
    PATH_VARIABLE_COMPANY_ID,
} from '../../../../routerPaths';
import util from '../../../../common/util';
import { RemoveButton } from '../../../table/RemoveButton';
import addresses from '../../../../api/addresses';
import { CompanyFormContext } from '../CompanyForm';
import { CompanyAddressField } from './companyAddressFields';
import { ClientAddressListModel } from '../../../../model/client/address';

const CompanyAddresses: React.FC = () => {
    const {
        company: { id: companyId },
    } = useContext(CompanyFormContext);
    const config = useMemo(() => {
        return Config.builder<ClientAddressListModel>()
            .column((builder) => builder.id().sortable(false).filterable(false))
            .column((builder) => builder.text('Название', CompanyAddressField.NAME).sortable(false))
            .column((builder) =>
                builder
                    .label('Город')
                    .getter((model) => model.city)
                    .sortable(false)
            )
            .column((builder) =>
                builder
                    .label('Адрес')
                    .getter((model) => model.street)
                    .sortable(false)
            )
            .column((builder) =>
                builder.text('Тип', 'type')
                    .sortable(false)
                    .filterable(false)
            )
            .column((builder) =>
                builder
                    .cls('text-center')
                    .sortable(false)
                    .filterable(false)
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () =>
                                    addresses.deleteClientAddress(companyId, model.id)
                                )
                            }
                        />
                    ))
                    .width('45px')
            )
            .columnsSelector(false)
            .load((ctx) => addresses.listForClient(ctx.state.request, companyId))
            .formUrl(
                generatePath(PATH_CP_COMPANY_ADDRESS, {
                    [PATH_VARIABLE_COMPANY_ID]: util.stringOrEmpty(companyId),
                    [PATH_VARIABLE_COMPANY_ADDRESS_ID]: '',
                })
            )
            .onClick((id: number, navigate: NavigateFunction) =>
                navigate(
                    generatePath(PATH_CP_COMPANY_ADDRESS, {
                        [PATH_VARIABLE_COMPANY_ID]: util.stringOrEmpty(companyId),
                        [PATH_VARIABLE_COMPANY_ADDRESS_ID]: util.stringOrEmpty(id),
                    })
                )
            )
            .id(`company_${companyId}_addresses`)
            .build();
    }, [companyId]);

    return (
        <>
            <div className='contracts d-flex mb-3'>
                <DwTable header='Адреса' config={config} />
            </div>
            <Outlet />
        </>
    );
};

export default CompanyAddresses;
