import { AttachmentModel } from '../../model/attachment';
import { PROMISE_OK } from '../const';

export const companyAttachmentsValidator = (attachments: AttachmentModel[]) => {
    const isValidKind = attachments?.every((a: AttachmentModel) => a.kind && a.kind.value > 0)
    if (!isValidKind) {
        return Promise.resolve('укажите "Вид файла" для всех прикрепленных документов');
    }
    const isUniqueKind = new Set(attachments?.map(a => a.kind?.value)).size === attachments.length;
    if (!isUniqueKind) {
        return Promise.resolve('"Вид файла" должен быть уникальным всех прикрепленных документов');
    }
    return Promise.resolve(PROMISE_OK);
};
