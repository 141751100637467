import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { generatePath, useMatch, useNavigate, useParams, useResolvedPath } from 'react-router-dom';
import {
    StorageDistributionData,
    StorageDistributionModel,
    StorageDistributionClientModel,
    StorageDistributionQuoteModel,
    StorageDistributionItemModel
} from './storageDistributionModel';
import {
    PATH_STORAGE_DISTRIBUTION,
    PATH_STORAGE_DISTRIBUTION_ASSORTMENT,
    PATH_STORAGE_DISTRIBUTION_ASSORTMENT_CLIENT,
    PATH_VARIABLE_ASSORTMENT_ID,
    PATH_VARIABLE_CLIENT_ID,
    PATH_VARIABLE_STORAGE_ID
} from '../../../routerPaths';
import util from '../../../common/util';
import './distribution.scss';
import { DistributionTable } from './DistributionTable';
import { DistributionCards } from './DistributionCards';
import { RootContext } from '../../layout/ContextWrapper';
import storages from '../../../api/storages';
import { StompWebSocketProvider } from '../../../websockets/StompWebSocketProvider';
import { StorageAssemblyNav } from '../StorageAssemblyNav';
import { Option } from '../../control/option';
import { SingleValue } from 'react-select';
import { StorageType } from '../../../model/enums/StorageType';
import { StorageSelect } from '../StorageSelect';
import { ViewModeToggle, VIEW_TYPE } from '../ViewModeToggle';
import { ReadyToLoadConfirmModal } from './ReadyToLoadConfirmModal';
import { useWsSubscription } from '../../../websockets/useWsSubscribtion';
import { WebSocketSubscriptionUrl } from '../../../common/const';

type SharedStorageDistributionContext = {
    distributionData: StorageDistributionData;
    setCompleted: (item: StorageDistributionItemModel) => void;
    incrementReload: () => void;
};

export const StorageDistributionContext = React.createContext<SharedStorageDistributionContext>(
    {} as SharedStorageDistributionContext
);

export const StorageDistributionTable: React.FC = () => {
    return (
        <StompWebSocketProvider>
            <StorageDistributionTableInner />
        </StompWebSocketProvider>
    );
};

const emptyModel = { distributionModels: [] as StorageDistributionModel[] } as StorageDistributionData;

const StorageDistributionTableInner: React.FC = () => {
    const [distributionData, setDistributionData] = useState(emptyModel),
        storageId = util.toNumber(useParams()[PATH_VARIABLE_STORAGE_ID] ?? '') ?? -1,
        navigate = useNavigate(),
        [reloadSignal, setReloadSignal] = useState(0),
        { state: { uiState: { assortmentViewModeTable: isTableView } }, } = useContext(RootContext);
        
    useWsSubscription(useMemo(() => ({
        endpoint: WebSocketSubscriptionUrl.STORAGE_DISTRIBUTION,
        onMessage: (_) => setReloadSignal((prev) => ++prev)
    }), []));

    const handleChangeStorage = (storage: SingleValue<Option>) =>
        navigate(generatePath(PATH_STORAGE_DISTRIBUTION, { [PATH_VARIABLE_STORAGE_ID]: util.toString(storage?.value) }));

    const setCompleted = useCallback((item: StorageDistributionItemModel) =>
            storages.setDistributionCompleted(item).then(() => setReloadSignal(reloadSignal + 1)),
        [reloadSignal]);

    const incrementReload = useCallback(() => setReloadSignal(reloadSignal + 1), [reloadSignal]);

    useEffect(() => {
        let shouldUpdate = true;
        if (storageId > 0) {
            storages.distributionData(storageId).then((res) => shouldUpdate && setDistributionData(res));
        }
        return () => { shouldUpdate = false; };
    }, [storageId, reloadSignal]);

    const ctx = useMemo(() => ({ distributionData, setCompleted, incrementReload }), [distributionData, incrementReload, setCompleted]);

    return (
        <StorageDistributionContext.Provider value={ctx}>
            <div className={`distribution view-${isTableView ? VIEW_TYPE.TABLE : VIEW_TYPE.CARDS}`}>
                <StorageAssemblyNav />
                <div className="px-2 px-md-0 pb-1 d-flex align-items-center">
                    <span className="pr-2">Склад:</span>
                    <div className="storage-selector-container">
                        <StorageSelect
                            value={distributionData?.storage}
                            types={[StorageType.STORAGE]}
                            onChange={handleChangeStorage}
                        />
                    </div>
                </div>
                {storageId > 0 &&
                    <>
                        <AssortmentsTable distributionList={distributionData.distributionModels} />
                        <ViewModeToggle />
                        <ReadyToLoadConfirmModal />
                    </>
                }
            </div>
        </StorageDistributionContext.Provider>
    );
};

const AssortmentsTable: React.FC<{ distributionList: StorageDistributionModel[] }> = (props) => {
    return (
        <div className='table-responsive'>
            <table className='table dwtable table-bordered table-assortment'>
                <thead>
                <tr>
                    <th>Наименование</th>
                    <th>
                        <div className='d-flex justify-content-center'>
                            Собрано/Распределено
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {props.distributionList
                    .filter((distribution) => distribution.assortment?.value)
                    .map((distribution) => <AssortmentRow key={`assortment-${distribution.assortment?.value}`} distribution={distribution} />)
                }
                </tbody>
            </table>
        </div>
    );
};

const AssortmentRow: React.FC<{ distribution: StorageDistributionModel }> = (props) => {
    const { distribution: { assortment, clients }} = props;
    const { state: { uiState: { assortmentViewModeTable: isTableView } } } = useContext(RootContext);
    const { [PATH_VARIABLE_STORAGE_ID]: storageIdParam } = useParams();

    const storageId = util.toNumber(storageIdParam ?? '') ?? -1,
        pathTo = generatePath(PATH_STORAGE_DISTRIBUTION_ASSORTMENT, {
            [PATH_VARIABLE_STORAGE_ID]: util.toString(storageId),
            [PATH_VARIABLE_ASSORTMENT_ID]: util.toString(assortment.value)
        }),
        resolved = useResolvedPath(pathTo),
        isActive = !!useMatch({ path: resolved.pathname, end: false }),
        navigate = useNavigate(),
        onRowClick = () => {
            const destination = isActive
                ? generatePath(PATH_STORAGE_DISTRIBUTION, { [PATH_VARIABLE_STORAGE_ID]: util.toString(storageId) })
                : pathTo;
            navigate(destination, { replace: true });
        };

    const assembledForAssortment = util.countTotal(clients, ({ tareAssembled = 0 }) => tareAssembled),
        allClientQuotes = clients.flatMap((c) => c.quotes),
        distributedForAssortment = util.countTotal(allClientQuotes, ({ tareDistributed = 0 }) => tareDistributed);

    return (
        <>
            <tr onClick={onRowClick} className={`assortment-row ${isActive ? 'active' : ''}`}>
                <td>
                    <div className='td-value-container d-flex justify-content-start align-items-center'>
                        <div className='btn btn-sm btn-circle btn-light assortment-row-btn'>
                            <i className={`fas ${isActive ? 'fa-arrow-left' : 'fa-plus'}`} />
                        </div>
                        <span className='pl-1'>{assortment.label}</span>
                    </div>
                </td>
                <td className='td-assortment-counters'>
                    <div className='td-value-container d-flex justify-content-center align-items-center'>
                        <span>{assembledForAssortment}</span>/<span>{distributedForAssortment}</span>
                    </div>
                </td>
            </tr>
            {isActive &&
                <tr className='client-list'>
                    <td colSpan={2} className={isTableView ? 'p-2' : 'p-1'}>
                        <ClientsTable key={`assortment-${assortment.value}-clients`} clients={clients} />
                    </td>
                </tr>
            }
        </>
    );
};

const ClientsTable: React.FC<{ clients: StorageDistributionClientModel[] }> = (props) => {
    return (
        <div className='table-responsive'>
            <table className='table dwtable table-bordered table-clients'>
                <thead>
                <tr>
                    <th>Заказчик</th>
                    <th>
                        <div className='d-flex justify-content-center'>
                            Собрано/Распределено
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                {props.clients
                    .filter((client) => client.id)
                    .map((client) => <ClientRow key={`client-${client.id}`} client={client} />)
                }
                </tbody>
            </table>
        </div>
    );
};

const ClientRow: React.FC<{ client: StorageDistributionClientModel }> = (props) => {
    const { client: { id, name, tareAssembled: assembledForClient, quotes }} = props;
    const { [PATH_VARIABLE_STORAGE_ID]: storageIdParam, [PATH_VARIABLE_ASSORTMENT_ID]: assortmentIdParam } = useParams();

    const storageId = util.toNumber(storageIdParam ?? '') ?? -1,
        assortmentId = util.toNumber(assortmentIdParam ?? '') ?? -1,
        pathTo = generatePath(PATH_STORAGE_DISTRIBUTION_ASSORTMENT_CLIENT, {
            [PATH_VARIABLE_STORAGE_ID]: util.toString(storageId),
            [PATH_VARIABLE_ASSORTMENT_ID]: util.toString(assortmentId),
            [PATH_VARIABLE_CLIENT_ID]: util.toString(id)
        }),
        resolved = useResolvedPath(pathTo),
        isActive = !!useMatch({ path: resolved.pathname, end: false }),
        navigate = useNavigate(),
        onRowClick = () => {
            const destination = isActive
                ? generatePath(PATH_STORAGE_DISTRIBUTION_ASSORTMENT, {
                    [PATH_VARIABLE_STORAGE_ID]: util.toString(storageId),
                    [PATH_VARIABLE_ASSORTMENT_ID]: util.toString(assortmentId),
                })
                : pathTo;
            navigate(destination, { replace: true });
        };

    const distributedForClient = util.countTotal(quotes, ({tareDistributed = 0}) => tareDistributed);

    return (
        <>
            <tr onClick={onRowClick} className={`client-row ${isActive ? 'active' : ''}`}>
                <td>
                    <div className='td-value-container d-flex justify-content-start align-items-center'>
                        <div className='btn btn-sm btn-circle btn-light client-row-btn'>
                            <i className={`fas ${isActive ? 'fa-arrow-left' : 'fa-plus'}`}/>
                        </div>
                        <span className='pl-1'>{name}</span>
                    </div>
                </td>
                <td className='td-client-counters'>
                    <div className='td-value-container d-flex justify-content-center align-items-center'>
                        <span>{assembledForClient}</span>/<span>{distributedForClient}</span>
                    </div>
                </td>
            </tr>
            {isActive &&
                <QuotesTable key={`client-${id}-quotes`} tareAssembled={assembledForClient} quotes={quotes} />
            }
        </>
    );
};

type QuotesTableProps = {
    tareAssembled: number,
    quotes: StorageDistributionQuoteModel[]
};

const QuotesTable: React.FC<QuotesTableProps> = (props) => {
    const { state: { uiState: { assortmentViewModeTable: isTableView } } } = useContext(RootContext);

    return (
        <tr className='no-gutters p-0 quote-list'>
            <td colSpan={2} className='no-gutters p-0'>
                {isTableView
                    ? <DistributionTable {...props} />
                    : <DistributionCards {...props} />
                }
            </td>
        </tr>
    );
};