import { deleteRequest, get, post, put } from './rest';
import { Option, DictionaryModel } from '../component/control/option';
import { PagedResponse } from './pagedResponse';
import { ListRequest } from '../component/table/listRequest';
import { DictionaryOptionsRequest } from '../component/control-panel/dictionary/dictionaryOptionsRequest';
import { ApiResponse } from './apiResponse';

const dictionary = {
    options: (request: DictionaryOptionsRequest) => post<Option[]>('dictionaries/options', request),
    get: (dictionaryType: string, id: number) =>
        get<DictionaryModel>(`dictionaries/${dictionaryType}/${id}`),
    save: (dictionaryType: string, model: DictionaryModel) => {
        const url = `dictionaries/${dictionaryType}`;
        return model.id > 0 ? put<number>(url, model) : post<number>(url, model);
    },
    list: (dictionaryType: string, request: ListRequest) =>
        post<PagedResponse<DictionaryModel>>(`dictionaries/${dictionaryType}/list`, request),
    delete: (dictionaryType: string, id: number) =>
        deleteRequest<ApiResponse>(`dictionaries/${dictionaryType}/${id}`),
};

export default dictionary;
