import {
    ClientAssortmentRequest,
    ClientAssortmentListModel,
    ClientAssortmentModel,
} from '../model/assortment';
import { Option } from '../component/control/option';
import { post, get, deleteRequest } from './rest';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { ApiResponse } from './apiResponse';

const PATH_BASE = 'assortments/client';

const clientAssortments = {
    get: (id: number) => get<ClientAssortmentModel>(`${PATH_BASE}/${id}`),
    save: (model: ClientAssortmentModel) =>
        post<ApiResponse>(`${PATH_BASE}`, model),
    delete: (clientAssortmentId: number) =>
        deleteRequest<ApiResponse>(`${PATH_BASE}/${clientAssortmentId}`),
    list: (model: ListRequest, clientId: number) =>
        post<PagedResponse<ClientAssortmentListModel>>(`${PATH_BASE}/${clientId}/list`, model),
    findByAssortment: (clientId: number, assortmentId: number) =>
        get<ClientAssortmentModel>(`${PATH_BASE}/${clientId}/${assortmentId}`),

    clientOptions: (request: ClientAssortmentRequest) =>
        post<Option[]>(`${PATH_BASE}/options`, request),
    companyOptions: (request: ClientAssortmentRequest) =>
        post<Option[]>(`${PATH_BASE}/options/company`, request),
    allCompanyOptions: (request: ClientAssortmentRequest) =>
        post<Option[]>(`${PATH_BASE}/options/company/all`, request),
};

export default clientAssortments;
