import { Position, Sort } from './context';
import { Filter } from './filter';
import { Column } from './column';

export enum ActionType {
    SORT,
    ADD_FILTER,
    DISPLAY_FILTER,
    REMOVE_FILTER,
    RESET_FILTERS,
    HIDE,
    REORDER,
    PAGE_SIZE,
    PAGE,
    SEARCH,
    REFRESH,
    UPDATE_REFRESH_SIGNAL,
    SELECT_ROW,
    DESELECT_ALL_ROWS,
}

export type Action = {
    type: ActionType;
    tableId?: string;
    sort?: Sort;
    column?: Column<any, any>;
    filter?: Filter;
    position?: Position;
    order?: number[];
    id?: number;
    value?: string;
};
