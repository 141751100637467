import React from 'react';
import { generatePath } from 'react-router-dom';
import runs from '../../../api/runs';
import { Direction } from '../../../common/const';
import util from '../../../common/util';
import { PATH_RUN, PATH_VARIABLE_RUN_ID } from '../../../routerPaths';
import { RunListModel } from '../../runs/model/runListModel';
import { RunModel } from '../../runs/model/runModel';
import { RunField } from '../../runs/runField';
import { ColumnType } from '../../table/columnType';
import { Config } from '../../table/config';
import { DwTable } from '../../table/DwTable';
import { StorageAssemblyNav } from '../StorageAssemblyNav';

const STATUS_COLUMN_POSITION = 3;
const config = Config.builder<RunListModel>()
    .column((builder) => builder.id())
    .column((builder) => builder.date('Дата погрузки', RunField.LOADING_DATE))
    .column((builder) =>
        builder
            .label('Статус')
            .field(RunField.STATUS)
            .type(ColumnType.RUN_LOADING_STATUS)
            .filterable(false)
            .editable()
            .width('170px')
            .columnPosition(STATUS_COLUMN_POSITION)
            .onChangeRow(
                async (value, _, row) => await runs.save({ id: row.id, status: value } as RunModel)
            )
    )
    .column((builder) => builder.client('Заказчик', RunField.CLIENT))
    .column((builder) => builder.carrier('Перевозчик', RunField.CARRIER))
    .column((builder) => builder.text('Тягач', RunField.TRUCK))
    .column((builder) => builder.text('Водитель', RunField.DRIVER))
    .column((builder) => builder.text('Телефон водителя', RunField.DRIVER_PHONE).sortable(false))
    .column((builder) => builder.text('Склад погрузки', RunField.LOADING_STORAGE).sortable(false))
    .column((builder) => builder.text('Адрес погрузки', RunField.LOADING_ADDRESS).sortable(false))
    .column((builder) =>
        builder.text('Адрес разгрузки', RunField.UNLOADING_ADDRESS).sortable(false)
    )
    .column((builder) => builder.decimal('Вес', RunField.CARGO_WEIGHT))

    .outerFilter((builder) => builder.field('kppRunStorage').type(ColumnType.STORAGE))

    .load((ctx) => runs.loadingRunList(ctx.state.request))
    .onClick((id, navigate, model) => {
        const direction = model?.client ? Direction.EXTERNAL : Direction.DOMESTIC;
        navigate(generatePath(PATH_RUN, { direction, [PATH_VARIABLE_RUN_ID]: util.toString(id) }));
    })
    .withAdd(false)
    .editable(true)
    .id('loading')
    .build();

const StorageLoading: React.FC = () => {
    return (
        <>
            <StorageAssemblyNav />
            <div className='loading d-flex'>
                <DwTable header={'АРМ Погрузка'} config={config} />
            </div>
        </>
    );
};

export default StorageLoading;
