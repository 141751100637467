import util from '../common/util';
import route from '../common/route';
import auth from './auth';
import { PATH_LOGIN } from '../routerPaths';
import { DEV_API_URL, DEV_UI_HOST, RestUrlPrefix } from '../common/const';

const HEADER_AUTHORIZATION = 'Authorization';
const DEFAULT_URL_PREFIX = RestUrlPrefix.API;

const defaultHeaders = new Headers({
    'content-type': 'application/json; charset=utf-8',
});

const isDevMode = () => window.location.host === DEV_UI_HOST;

const formatUrl = (url: string): string => {
    const host = isDevMode() ? DEV_API_URL : '';
    const urlPrefixes = Object.values(RestUrlPrefix);
    const path = urlPrefixes.some(prefix => url.startsWith(prefix))
        ? url
        : util.buildPath(DEFAULT_URL_PREFIX, url);
    return host + path;
};

const fetchPromise = <T>(url: string, init?: RequestInit) =>
    new Promise<T>((resolve) => {
        route.setBusy(true);
        const formattedUrl = formatUrl(url);
        fetch(formattedUrl, init).then(
            (response) => {
                route.setBusy(false);
                if (response.status === 200) {
                    const contentType = response.headers.get('Content-Type');
                    if (contentType?.includes('application/octet-stream')) {
                        resolve(response.blob() as T);
                    } else if (contentType?.includes('application/json')) {
                        resolve(response.json());
                    } else {
                        resolve({} as T);
                    }
                } else if (response.status === 401) {
                    route.navigate(PATH_LOGIN);
                } else {
                    route.pushError(`${response.status} - ${response.status === 409 ? 'Устаревшая версия' : 'Ошибка сервера'}`);
                }
            },
            (reason: TypeError) => {
                route.setBusy(false);
                route.pushError(reason.message);
            }
        );
    });

const headers = (initHeaders?: Headers) => {
    const token = localStorage.getItem(auth.TOKEN);
    if (token) {
        const result = new Headers(initHeaders ?? defaultHeaders);
        result.set(HEADER_AUTHORIZATION, 'Bearer ' + token);
        return result;
    }
    return defaultHeaders;
};

const formatDataFields = (obj: any): any => {
    if (Array.isArray(obj)) {
        return obj.map(formatDataFields);
    }

    if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
            if (Object.hasOwn(obj, key)) {
                obj[key] = formatDataFields(obj[key]);
            }
        }
    }

    if (obj instanceof Date || util.isValidISODateTime(obj)) {
        return util.toISODateTimeWithoutTimezone(obj);
    }

    return obj;
};

export const post = <T>(url: string, data: any): Promise<T> =>
    fetchPromise(url, {
        method: 'POST',
        headers: headers(),
        body: JSON.stringify(formatDataFields(data)),
    });

export const put = <T>(url: string, data: any): Promise<T> =>
    fetchPromise(url, {
        method: 'PUT',
        headers: headers(),
        body: JSON.stringify(formatDataFields(data)),
    });

export const postFile = <T>(url: string, data: FormData, signal?: AbortSignal): Promise<T> =>
    fetchPromise(url, {
        method: 'POST',
        headers: headers(new Headers()),
        body: data,
        signal: signal,
    });

export const get = <T>(url: string): Promise<T> =>
    fetchPromise(url, {
        method: 'GET',
        headers: headers(),
    });

export const deleteRequest = <T>(url: string, data?: any): Promise<T> =>
    fetchPromise(url, {
        method: 'DELETE',
        headers: headers(),
        body: data ? JSON.stringify(formatDataFields(data)) : data,
    });
