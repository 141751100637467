import React, { Dispatch, MouseEvent, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { createSearchParams, generatePath, useNavigate, useParams } from 'react-router-dom';
import edi from '../../api/edi';
import { Direction } from '../../common/const';
import { EdiToQuoteModel } from '../../model/edi/ediComparisonModel';
import { calcCargoPrice, calcTotalCost, ShipmentDetailModel } from '../quotes/model/shipmentDetailModel';
import { FormConfig } from '../form/formConfig';
import { EdiMessageField, EdiMessageModel } from '../../model/edi/ediMessageModel';
import { Option } from '../control/option';
import util from '../../common/util';
import { PATH_CP_CLIENT, PATH_EDI_MESSAGE_TO_QUOTE, PATH_EDI_MESSAGES, PATH_QUOTE, PATH_VARIABLE_CLIENT_ID, PATH_VARIABLE_EDI_MESSAGE_ID, SEARCH_PARAM_CLIENT_EDI_MESSAGE_ID, SEARCH_PARAM_CONSIGNEE_EDI_MESSAGE_ID, SEARCH_PARAM_EDI_MESSAGE_ID } from '../../routerPaths';
import { DwForm, FormContext, useFieldValue, useSetFieldValue } from '../form/DwForm';
import ContextError from '../form/ContextError';
import EdiQuotesOptions from '../form/EdiQuotesOptions';
import { MultiValue, SingleValue } from 'react-select';
import { useRefreshContextAction } from '../table/DwTable';
import DiffContainer from '../layout/DiffContainer';
import ComponentLink, { ComponentLinkType } from '../form/ComponentLink';
import CancelButton from '../form/CancelButton';
import AjaxSelect from '../control/AjaxSelect';
import { EdiQuoteLinkMode } from '../../model/enums/EdiQuoteLinkMode';
import CopyToClipboardWrapper from '../table/CopyToClipboardButton';

type EdiComparisonContextType = {
    ediToQuote: EdiToQuoteModel;
    tableRefresh: () => void;
    refreshSignal: number;
    setRefreshSignal: Dispatch<SetStateAction<number>>;
};

export const EdiComparisonContext = React.createContext<EdiComparisonContextType>(
    {} as EdiComparisonContextType
);

const emptyModel = {
    shipmentDetails: [] as ShipmentDetailModel[]
} as EdiToQuoteModel

const calcTotalWeight: (shipmentDetails: ShipmentDetailModel[]) => number =
    (shipmentDetails) => shipmentDetails?.map((detItem) => detItem.weight).reduce((acc, weight) => acc + (weight || 0), 0);

const getShipmentDetailMapKey: (detail: ShipmentDetailModel) => number = ({ clientAssortment: { value } }) => value;

const buildShipmentDetailsMap: (shipmentDetails: ShipmentDetailModel[]) => Map<number, ShipmentDetailModel> =
    (shipmentDetails) => new Map(Object.entries((shipmentDetails || []).filter(d => d.clientAssortment?.value > 0)).map(([_, value]) => [getShipmentDetailMapKey(value), value]));

const getQuoteOption: (ediToQuote: EdiToQuoteModel) => Option = (ediToQuote) => {
    return ediToQuote.quoteId ?
        {
            value: ediToQuote.quoteId,
            label: ediToQuote.clientQuoteNumber ?? ediToQuote.quoteId
        } : {
            value: 0,
            label: ''
        };
}

const useFormConfig = (ediToQuote: EdiToQuoteModel) => useMemo(() => {
    return FormConfig.builder<EdiMessageModel>()
        .number(
            EdiMessageField.ID,
            (m) => m.id,
            (m, v) => (m.id = v)
        )
        .option(
            EdiMessageField.QUOTE,
            (m) => m.quote,
            (m, v) => (m.quote = v)
        )
        .load((id) => Promise.resolve({
            id,
            quote: getQuoteOption(ediToQuote)
        }))
        .idPathVariableName(PATH_VARIABLE_EDI_MESSAGE_ID)
        .redirectUrl(PATH_EDI_MESSAGES)
        .build();
}, [ediToQuote]);

const DriverInfo: React.FC<{ model: EdiToQuoteModel }> = ({ model }) => {
    return (
        <div className='d-flex'>
            {model.driverInfo &&
                <CopyToClipboardWrapper value={model.driverInfo}>
                    <div className='cursor-pointer d-flex align-items-baseline flex-nowrap'>
                        <i className='fas fa-copy pr-1 text-secondary' />
                        {model.driverInfo}
                    </div>
                </CopyToClipboardWrapper>
            }
        </div>
    );
}

const DetailsItem: React.FC<{ target: ShipmentDetailModel, source: ShipmentDetailModel }> = ({ target, source }) => {
    return (
        <>
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.clientAssortment?.value || "-"} />
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.clientAssortment?.label} />
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.companyAssortment?.value}>
                {(t) => t?.companyAssortment?.label}
            </DiffContainer>
            <DiffContainer as={'td'} target={target} source={target} getter={(sd) => sd?.assortmentBuyerNumber || "-"} />
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.unit?.value}>
                {(t) => t?.unit?.label}
            </DiffContainer>
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.quantity || 0} />
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.quantum || 0} />
            <DiffContainer as={'td'} target={target} source={target} getter={(sd) => sd?.sellPriceNet || "-"} />
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.sellPrice || 0} />
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => sd?.weight || 0} />
            <DiffContainer as={'td'} target={target} source={target} getter={(sd) => (!sd.id && calcTotalCost(sd, sd.sellPriceNet)) || "-"} />
            <DiffContainer as={'td'} target={target} source={source} getter={(sd) => calcTotalCost(sd)} />
        </>
    )
}

const ClientDataRow: React.FC<{ id: number, name: string, inn: string, kpp: string, matchedId?: number, onAddClick?: () => void, onLinkClick?: () => void }> =
    ({ id, name, inn, kpp, matchedId, onAddClick, onLinkClick }) => (
        <div className="row">
            <div className="col">{name}</div>
            <div className="col-3">{inn && `ИНН ${inn}`}</div>
            <div className="col-3">{kpp && `КПП ${kpp}`}</div>
            <div className="col-2 d-flex justify-content-end align-items-center px-4">
                {!!matchedId && <button className="btn btn-xs btn-primary py-0 text-sm" onClick={onLinkClick}>привязать</button>}
                {(!matchedId && name && ((id ?? 0) <= 0) && onAddClick) && <button className="btn btn-xs btn-primary py-0 text-sm" onClick={onAddClick}>создать</button>}
            </div>
        </div>
    );

const Info: React.FC<{ target: EdiToQuoteModel, source: EdiToQuoteModel }> = ({ target, source }) => {
    const navigate = useNavigate();
    const { refreshSignal, setRefreshSignal, tableRefresh } = useContext(EdiComparisonContext);
    const getOnAddCallback = (searchParamName: string) =>
        (target.ediMessageId > 0 && searchParamName?.length) ?
            () => navigate({
                pathname: generatePath(PATH_CP_CLIENT, { [PATH_VARIABLE_CLIENT_ID]: '' }),
                search: createSearchParams({ [searchParamName]: util.toString(target.ediMessageId)! }).toString()
            }) : () => { };
    const getOnLinkCallback = (isClient: boolean) => () =>
        (isClient ? edi.linkClientToEdi(target.matchedClientId) : edi.linkConsigneeToEdi(target.matchedClientConsigneeId))
            .then(res => {
                if (res) {
                    setRefreshSignal(refreshSignal + 1);
                    tableRefresh();
                }
            });
    return (
        <>
            <div className="row">
                <div className="col-2">
                    <span>Поставщик:</span>
                </div>
                <div className="col">
                    <DiffContainer target={target} source={source} getter={(m) => m.clientCompanyId}>
                        {({ clientCompanyId: id, clientCompanyName: name, clientCompanyInn: inn, clientCompanyKpp: kpp }) =>
                            <ClientDataRow {...{ id, name, inn, kpp }} />}
                    </DiffContainer>
                </div>
            </div>
            <hr className="mt-0 mb-0" />
            <div className="row">
                <div className="col-2">
                    <span>Заказчик:</span>
                </div>
                <div className="col">
                    <DiffContainer target={target} source={source} getter={(m) => m.clientId}>
                        {({ clientId: id, clientName: name, clientInn: inn, clientKpp: kpp, matchedClientId: matchedId }) =>
                            <ClientDataRow {...{ id, name, inn, kpp, matchedId, onAddClick: getOnAddCallback(SEARCH_PARAM_CLIENT_EDI_MESSAGE_ID), onLinkClick: getOnLinkCallback(true) }} />}
                    </DiffContainer>
                </div>
            </div>
            <hr className="mt-0 mb-0" />
            <div className="row">
                <div className="col-2">
                    <span>Грузополучатель:</span>
                </div>
                <div className="col">
                    <DiffContainer target={target} source={source} getter={(m) => m.clientConsigneeId}>
                        {({ clientConsigneeId: id, clientConsigneeName: name, clientConsigneeInn: inn, clientConsigneeKpp: kpp, matchedClientConsigneeId: matchedId }) =>
                            <ClientDataRow {...{ id, name, inn, kpp, matchedId, onAddClick: getOnAddCallback(SEARCH_PARAM_CONSIGNEE_EDI_MESSAGE_ID), onLinkClick: getOnLinkCallback(false) }} />}
                    </DiffContainer>
                </div>
            </div>
            <hr className="mt-0 mb-0" />
            <div className="row">
                <div className="col-2">
                    <span>Дата разгрузки:</span>
                </div>
                <div className="col">
                    <DiffContainer target={target} source={source} getter={(m) => m.unloadingDate} />
                </div>
            </div>
            <hr className="mt-0 mb-0" />
            <div className="row">
                <div className="col-2">
                    <span>Адрес разгрузки:</span>
                </div>
                <div className="col">
                    {target.unloadingAddress ?? ''}
                </div>
            </div>
            {target.driverInfo &&
                <>
                    <hr className="mt-0 mb-0" />
                    <div className="row">
                        <div className="col-2">
                            <span>Водитель:</span>
                        </div>
                        <div className="col">
                            <DriverInfo model={target} />
                        </div>
                    </div>
                </>
            }
        </>
    )
}

const ShipmentDetails: React.FC<{ target: EdiToQuoteModel, source: EdiToQuoteModel }> = ({ target, source }) => {
    const detailsMapSource = buildShipmentDetailsMap(source.shipmentDetails),
        totalAmountTarget = target.ediTotalAmount ?? calcCargoPrice(target.shipmentDetails),
        totalAmountSource = source.ediTotalAmount ?? calcCargoPrice(source.shipmentDetails),
        totalNetAmount = target.ediTotalNetAmount;
    return (
        <div className="row">
            <div className="col">
                <table className="table table-sm table-striped table-bordered text-dark">
                    <thead>
                        <tr>
                            <th scope="col" className="col-auto">#</th>
                            <th scope="col" className="col-3">Наименование заказчика</th>
                            <th scope="col" className="col-3">Наименование склада</th>
                            <th scope="col" className="col-1">Артикул</th>
                            <th scope="col" className="col-1">Ед. изм.</th>
                            <th scope="col" className="col-1">Кол-во ед. изм.</th>
                            <th scope="col" className="col-1">Квант</th>
                            <th scope="col" className="col-1">Цена без НДС</th>
                            <th scope="col" className="col-1">Цена</th>
                            <th scope="col" className="col-1">Вес</th>
                            <th scope="col" className="col-1">Стоимость без НДС</th>
                            <th scope="col" className="col-1">Стоимость</th>
                        </tr>
                    </thead>
                    <tbody>
                        {target.shipmentDetails?.map((detailTarget, index) =>
                            <DiffContainer key={`crm-sd-${index}`} as={'tr'}
                                target={detailTarget}
                                source={detailsMapSource.get(detailTarget.clientAssortment?.value) ?? {} as ShipmentDetailModel}
                                getter={(v) => v.clientAssortment?.value}>
                                {(t, s) => <DetailsItem target={t} source={s} />}
                            </DiffContainer>
                        )}
                        <tr>
                            <td><b>Итого:</b></td>
                            <td colSpan={8} />
                            <DiffContainer as={'td'} target={calcTotalWeight(target.shipmentDetails)} source={calcTotalWeight(source.shipmentDetails)} />
                            <DiffContainer as={'td'} target={totalNetAmount} source={totalNetAmount} />
                            <DiffContainer as={'td'} target={totalAmountTarget} source={totalAmountSource} />
                        </tr>
                    </tbody>
                </table>
            </div>
        </div >
    )
}

const EdiComparison: React.FC = () => {
    const { ediMessageId } = useParams();
    const [ediToQuote, setEdiToQuote] = useState(emptyModel);
    const ediMessageIdNum = util.toNumber(ediMessageId!)!;
    const { refresh } = useRefreshContextAction();
    const config = useFormConfig(ediToQuote);
    const [refreshSignal, setRefreshSignal] = useState(0);

    useEffect(() => setRefreshSignal(1), [setRefreshSignal]);

    useEffect(() => {
        if (refreshSignal > 0) {
            edi.get(ediMessageIdNum).then((etq) => setEdiToQuote(etq));
        }
    }, [refreshSignal, ediMessageIdNum]);

    const context = useMemo(() => ({
        ediToQuote,
        tableRefresh: refresh,
        refreshSignal,
        setRefreshSignal
    }), [refresh, ediToQuote, refreshSignal]);

    return (
        <EdiComparisonContext.Provider value={context}>
            <DwForm config={config}>
                <FormInner />
            </DwForm>
        </EdiComparisonContext.Provider>
    );
};

const FormInner = () => {
    const navigate = useNavigate();
    const { ediToQuote: target, tableRefresh, refreshSignal, setRefreshSignal } = useContext(EdiComparisonContext);
    const { state: { model, model: { id: ediMessageId } } } = useContext(FormContext);
    const setValue = useSetFieldValue();
    const [source, setSource] = useState(emptyModel);
    const { value: quoteId } = (useFieldValue(EdiMessageField.QUOTE) || {}) as Option;

    useEffect(() => {
        let shouldUpdate = refreshSignal > 0;
        if (quoteId) {
            edi.getEdiFromQuote(quoteId).then((etq) => shouldUpdate && setSource(etq));
        } else {
            setSource(emptyModel);
        }
        return () => {
            shouldUpdate = false;
        }
    }, [refreshSignal, quoteId, setSource]);

    const navigateToVersion = useCallback(
        (newValue: SingleValue<Option>) => {
            if (newValue?.value) {
                newValue?.value && navigate(
                    generatePath(PATH_EDI_MESSAGE_TO_QUOTE, { [PATH_VARIABLE_EDI_MESSAGE_ID]: newValue.value.toString() }))
            }
        }, [navigate]);

    const onQuoteChange = useCallback(
        (newValue: SingleValue<Option>) => setValue(EdiMessageField.QUOTE, newValue?.value), [setValue]);

    const onUnlinkQuote = useCallback(
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            edi.unlinkQuote(ediMessageId).then(() => {
                setRefreshSignal(refreshSignal + 1);
                tableRefresh();
            });
        }, [tableRefresh, ediMessageId, refreshSignal, setRefreshSignal]);

    const getOnSave = useCallback((mode: EdiQuoteLinkMode) =>
        (e: MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            edi.save(model, mode).then(() => {
                setRefreshSignal(refreshSignal + 1);
                tableRefresh();
            })
        }, [tableRefresh, model, refreshSignal, setRefreshSignal]);
    return (
        <div className="edi-comparison">
            <div className='form card mt-2'>
                <div className='card-body pb-1'>
                    <div className="row">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col-2">
                                    <div className="form-group">
                                        <b className="col-form-label">CRM Заказ</b>
                                        {quoteId > 0 && quoteId === target?.quoteId && <ComponentLink className='text-md pl-1' type={ComponentLinkType.QUOTE} entityIdChain={[quoteId]} />}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <EdiQuotesOptions id={EdiMessageField.QUOTE} ediMessageId={ediMessageId} onChange={onQuoteChange} />
                                </div>
                                <div className="col" />
                            </div>
                            {!!source.quoteId &&
                                <>
                                    <Info target={source} source={target} />
                                    <ShipmentDetails target={source} source={target} />
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className='form card mt-2'>
                <div className='card-body'>
                    <div className="row">
                        <div className="col">
                            <div className="row align-items-center">
                                <div className="col-2">
                                    <div className="form-group">
                                        <b className="col-form-label">EDI</b>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <DiffContainer className="form-group" target={target} source={source} getter={(m) => m.ediQuoteNumber} />
                                </div>
                                <div className="col-4">
                                    <div className='form-group'>
                                        <AjaxSelect cacheOptions={false} value={target.thisVersion} loadOptions={(_, callback) => callback(target.versions)} onChange={navigateToVersion as (newValue: SingleValue<Option> | MultiValue<Option>) => void} />
                                    </div>
                                </div>
                                <div className='col text-xs text-muted d-flex flex-column align-items-end'>
                                    <div>
                                        Идентификатор события в EDI:
                                    </div>
                                    <div>
                                        {target.eventPointer}
                                    </div>
                                </div>
                            </div>
                            <Info target={target} source={source} />
                            <ShipmentDetails target={target} source={source} />
                            <ContextError />
                            <div className='text-center'>
                                {target.isNewVersion &&
                                    <>
                                        <button className='btn btn-sm btn-primary' onClick={getOnSave(EdiQuoteLinkMode.UPDATE)}>
                                            Принять
                                        </button>
                                        &nbsp;
                                        <button className='btn btn-sm btn-primary' onClick={getOnSave(EdiQuoteLinkMode.REJECT)}>
                                            Отклонить
                                        </button>
                                    </>
                                }
                                {!target.isNewVersion &&
                                    <>
                                        <button className='btn btn-sm btn-primary' onClick={getOnSave(EdiQuoteLinkMode.LINK)}>
                                            Связать
                                        </button>
                                        &nbsp;
                                        <button className='btn btn-sm btn-primary' onClick={onUnlinkQuote}>
                                            Отвязать
                                        </button>
                                    </>
                                }
                                &nbsp;
                                <button className='btn btn-sm btn-primary' onClick={(e) => {
                                    e.preventDefault();
                                    navigate({
                                        pathname: generatePath(PATH_QUOTE, { direction: Direction.EXTERNAL, quoteId: '' }),
                                        search: createSearchParams({
                                            [SEARCH_PARAM_EDI_MESSAGE_ID]: ediMessageId
                                        }).toString()
                                    });
                                }}>
                                    Заявка по EDI
                                </button>
                                &nbsp;
                                <CancelButton />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EdiComparison;
