import React, { ReactElement, useContext, useMemo, useState } from 'react';
import clsx from 'clsx';
import util from '../../common/util';
import { FormContext, useFieldValue, validateField } from '../form/DwForm';
import FormInvalidText from '../form/FormInvalidText';
import { Column } from './column';
import { ColumnType } from './columnType';
import { Config } from './config';
import EditableCell from './EditableCell';
import './dwtable.scss';
import './editable-dwtable.scss';
import { columnClsx, columnStyle } from './Header';
import { Context } from './context';
import { TableContext } from './DwTable';
import { FieldType } from '../form/fieldType';
import { ProxyModel } from '../../model/proxyModel';
import { FieldConfig } from '../form/fieldConfig';

type Props<T> = {
    config: Config<T>;
    header?: string;
    children?: React.ReactElement | React.ReactElement[];
    cellProps?: { [key: string]: any };
};

export type EditableTableContextType = {
    activeCellGetter: (field: string) => any;
    activeCellSetter: (field: string, value: any) => void;
    editingRowNum: number;
    setEditingRowNum: (rowNum: number) => void;
};

const EditableTableContext = React.createContext<EditableTableContextType>({} as EditableTableContextType);

const useCustomContext = <T extends object>(
    model: T,
    editingRowNum: number,
    listFieldConfig: FieldConfig<T, any[]>,
    setFieldValue: (listFieldConfig: FieldConfig<T, any>, value: any[]) => void,
    validateField: (formContext: any, listFieldConfig: FieldConfig<T, any>, model: T) => Promise<void>,
    formContext: any,
    setEditingRowNum: (num: number) => void
) => useMemo(() => {
        const activeCellGetter = (field: keyof T) => {
            const list = listFieldConfig.getter(model) ?? [];
            return list[editingRowNum]?.[field];
        };

        const activeCellSetter = async (field: keyof T, value: any) => {
            if (listFieldConfig.type === FieldType.PROXY_LIST) {
                const proxyList: ProxyModel<T>[] = listFieldConfig.getter(model) ?? [];
                const refs = proxyList[editingRowNum].refs;
                refs.forEach(ref => {
                    ref[field] = value;
                });
                setFieldValue(listFieldConfig, [...proxyList]);
            } else if (listFieldConfig.type === FieldType.LIST) {
                const list: T[] = listFieldConfig.getter(model) ?? [];
                list[editingRowNum][field] = value;
                setFieldValue(listFieldConfig, [...list]);
            }

            await validateField(formContext, listFieldConfig, model);
        };

        return {
            activeCellGetter,
            activeCellSetter,
            editingRowNum,
            setEditingRowNum,
        };
    }, [formContext, model, editingRowNum, listFieldConfig, setFieldValue, validateField, setEditingRowNum]
);

const EditableDwTable: (props: Props<any>) => ReactElement<Props<any>> = ({
    config,
    header,
    children,
    cellProps
}) => {
    const formContext = useContext(FormContext);
    const { state: { model }, setFieldValue } = formContext;
    const { id: tableId, captionControls, columns, defaultHidden } = config;
    if (!tableId) {
        throw new Error('config id is not defined');
    }

    const hasCardHeader = useMemo(() => captionControls.length || header, [captionControls, header]);
    const listFieldConfig = formContext.state.config.field(tableId);
    const fieldValue = useFieldValue(tableId);
    const data: any[] = useMemo(() => fieldValue ?? [], [fieldValue]);
    const error = formContext.state.errors.get(tableId);

    const [editingRowNum, setEditingRowNum] = useState<number>(-1);

    const getColumns = () => {
        return columns.filter((col) => defaultHidden.indexOf(col.field) === -1);
    };

    const formatValue = (col: Column<any, any>, row: any, index: number) => {
        if (col.type === ColumnType.POSITION) {
            return util.toString(++index);
        }
        const value = col.getter(row, index);
        if (value) {
            switch (col.type) {
                case ColumnType.CLIENT_CONTACT:
                case ColumnType.CLIENT_STORAGE:
                case ColumnType.CLIENT_ASSORTMENT:
                case ColumnType.CLIENT_CONSIGNEE_ASSORTMENT:
                case ColumnType.ASSORTMENT:
                case ColumnType.EXPENSE_ITEM:
                case ColumnType.TMC_PACKAGE_CATEGORY:
                case ColumnType.TMC_TARE_CATEGORY:
                case ColumnType.TMC:
                case ColumnType.CLIENT:
                case ColumnType.CARRIER:
                case ColumnType.ATTACHMENT_KIND:
                case ColumnType.UNIT:
                    return value.label;
                case ColumnType.DATE_TIME:
                    return util.formatDateTime(new Date(value));
                case ColumnType.DATE:
                    return util.formatDate(new Date(value));
                case ColumnType.TIME:
                    return util.formatTime(new Date(value));
                default:
                    return util.toString(value);
            }
        }
        return value;
    };

    const renderHeader = () => {
        return (
            <thead>
            <tr>
                {getColumns().map((col, idx) => (
                    <th key={`header_${col.field !== '' ? col.field : idx}`}>
                        {col.label}
                    </th>
                ))}
            </tr>
            </thead>
        );
    };
    const context = useContext(TableContext);
    const renderBody = () => {
        return (
            <tbody>
            {data.map((row, rowIdx) => (
                <tr key={`${tableId}_row_${rowIdx}`} onClick={() => setEditingRowNum(rowIdx)}>
                    {getColumns().map((col, colIdx) => {
                        const key = `${tableId}_col_${col.field !== '' ? col.field : colIdx}`;
                        const formattedValue = formatValue(col, row, rowIdx);
                        const isEditable = col.editable(row, rowIdx);
                        if (isEditable) {
                            return (
                                <EditableCell
                                    key={key}
                                    col={col}
                                    formattedValue={formattedValue}
                                    cellProps={cellProps ?? col.editableColProps(row, rowIdx, context)}
                                    editor={col.editor(row, rowIdx)}
                                    row={row}
                                />
                            );
                        }
                        return (
                            <td key={key} className={columnClsx(col)} style={columnStyle(col)}>
                                {formattedValue}
                            </td>
                        );
                    })}
                </tr>
            ))}
            {children}
            </tbody>
        );
    };

    const ctx = useCustomContext(
        model,
        editingRowNum,
        listFieldConfig,
        setFieldValue,
        validateField,
        formContext,
        setEditingRowNum
    );

    return (
        <div className='card dw-table editable-dw-table mb-3'>
            <EditableTableContext.Provider value={ctx}>
                {hasCardHeader
                    ? (
                        <div className='card-header d-flex flex-row'>
                            <div className='mr-auto align-self-start' />
                            <div className='align-self-center title'>{header}</div>
                            <div className='ml-auto align-self-end d-flex align-items-center'>
                                {captionControls.map((control, index) =>
                                    <React.Fragment key={index*index}>
                                        {control({} as Context)}
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                    )
                    : <></>
                }
                <div className={clsx('card-body', { 'rounded-top': (!hasCardHeader) })}>
                    <table className='table table-sm table-striped table-bordered table-hover'>
                        {renderHeader()}
                        {renderBody()}
                    </table>
                    <FormInvalidText error={error}/>
                </div>
            </EditableTableContext.Provider>
        </div>
    );
};

export { EditableDwTable, EditableTableContext };
