export enum QuoteField {
    ID = 'id',
    STATUS = 'status',
    SOURCE = 'source',
    MANAGER = 'manager',
    CREATED = 'created',
    CLIENT_COMPANY = 'clientCompany',
    CLIENT = 'client',
    CLIENT_CONTRACT = 'clientContract',
    CLIENT_CONTRACT_NO = 'clientContractNo',
    CLIENT_PAYMENT_METHOD = 'clientPaymentMethod',
    CLIENT_PAYMENT_TYPE = 'clientPaymentType',
    CLIENT_PAYMENT_DELAY = 'clientPaymentDelay',
    CLIENT_PAYMENT_DELAY_TYPE = 'clientPaymentDelayType',
    CLIENT_PLANNED_PAYMENT_DATE = 'clientPlannedPaymentDate',
    CLIENT_PAYMENT_DATETIME = 'clientPaymentDatetime',
    CLIENT_PAYMENT_DATE = 'clientPaymentDate',
    CLIENT_CONTACT = 'clientContact',
    CLIENT_QUOTE_NUMBER = 'clientQuoteNumber',
    CLIENT_CONSIGNEE = 'clientConsignee',
    CARRIER_COMPANY = 'carrierCompany',
    CARRIER = 'carrier',
    CARRIER_CONTRACT = 'carrierContract',
    CARRIER_CONTRACT_NO = 'carrierContractNo',
    CARRIER_PAYMENT_METHOD = 'carrierPaymentMethod',
    CARRIER_PAYMENT_TYPE = 'carrierPaymentType',
    CARRIER_PAYMENT_DELAY = 'carrierPaymentDelay',
    CARRIER_PAYMENT_DELAY_TYPE = 'carrierPaymentDelayType',
    CARRIER_PLANNED_PAYMENT_DATE = 'carrierPlannedPaymentDate',
    CARRIER_PAYMENT_DATETIME = 'carrierPaymentDatetime',
    CARRIER_PAYMENT_DATE = 'carrierPaymentDate',
    CARRIER_CONTACT = 'carrierContact',
    CARRIER_COMPANY_CONTACT = 'carrierCompanyContact',
    CARRIER_THIRD_PARTY = 'carrierThirdParty',
    LOADING_STORAGE = 'loadingStorage',
    LOADING_ADDRESS = 'loadingAddress',
    UNLOADING_ADDRESS = 'unloadingAddress',
    LOADING_DATE = 'loadingDate',
    UNLOADING_DATE = 'unloadingDate',
    SHIPMENT_DETAILS = 'shipmentDetails',
    DESCRIPTION = 'description',
    RUN = 'run',
    STORAGE_STATUS = 'storageStatus',
    EXPIRE = 'expire',
    SELL_PRICE = 'sellPrice',
    BUY_PRICE = 'buyPrice',
    RUN_STATUS = 'runStatus',
    UNLOADING_STORAGE = 'unloadingStorage',
    IS_DOMESTIC_QUOTE = 'isDomesticQuote',
    WEIGHT = 'weight',
    IS_BULK = 'isBulk',
}

export enum MockQuoteField {
    CONTRACT = 'contract',
    PAYMENT_TYPE = 'paymentType',
    PAYMENT_DELAY = 'paymentDelay',
    PAYMENT_DELAY_TYPE = 'paymentDelayType',
    PLANNED_PAYMENT_DATETIME = 'plannedPaymentDatetime',
    PAYMENT_METHOD = 'paymentMethod',
}

export const CLIENT_CONTRACT_DEPENDENT_FIELDS = new Map<string, string>([
    [MockQuoteField.CONTRACT, QuoteField.CLIENT_CONTRACT],
    [MockQuoteField.PAYMENT_METHOD, QuoteField.CLIENT_PAYMENT_METHOD],
    [MockQuoteField.PAYMENT_TYPE, QuoteField.CLIENT_PAYMENT_TYPE],
    [MockQuoteField.PAYMENT_DELAY, QuoteField.CLIENT_PAYMENT_DELAY],
    [MockQuoteField.PAYMENT_DELAY_TYPE, QuoteField.CLIENT_PAYMENT_DELAY_TYPE],
    [MockQuoteField.PLANNED_PAYMENT_DATETIME, QuoteField.CLIENT_PLANNED_PAYMENT_DATE],
]);

export const CARRIER_CONTRACT_DEPENDENT_FIELDS = new Map<string, string>([
    [MockQuoteField.CONTRACT, QuoteField.CARRIER_CONTRACT],
    [MockQuoteField.PAYMENT_METHOD, QuoteField.CARRIER_PAYMENT_METHOD],
    [MockQuoteField.PAYMENT_TYPE, QuoteField.CARRIER_PAYMENT_TYPE],
    [MockQuoteField.PAYMENT_DELAY, QuoteField.CARRIER_PAYMENT_DELAY],
    [MockQuoteField.PAYMENT_DELAY_TYPE, QuoteField.CARRIER_PAYMENT_DELAY_TYPE],
    [MockQuoteField.PLANNED_PAYMENT_DATETIME, QuoteField.CARRIER_PLANNED_PAYMENT_DATE],
]);

export const QuoteFieldTranslations: Partial<Record<QuoteField, string>> = {
    [QuoteField.IS_DOMESTIC_QUOTE]: 'Внутренний заказ',
    [QuoteField.CLIENT_COMPANY]: 'Организация для Заказчика',
    [QuoteField.CLIENT]: 'Заказчик',
    [QuoteField.CLIENT_CONTRACT]: 'Договор с Заказчиком',
    [QuoteField.CLIENT_PAYMENT_METHOD]: 'Форма оплаты Заказчика',
    [QuoteField.CLIENT_PAYMENT_TYPE]: 'Условия оплаты Заказчика',
    [QuoteField.CLIENT_PAYMENT_DELAY]: 'Отсрочка платежа Заказчика',
    [QuoteField.CLIENT_PLANNED_PAYMENT_DATE]: 'Плановая дата оплаты Заказчика',
    [QuoteField.CLIENT_PAYMENT_DATETIME]: 'Фактическая дата оплаты Заказчика',
    [QuoteField.CLIENT_CONSIGNEE]: 'Грузополучатель',
    [QuoteField.UNLOADING_STORAGE]: 'Склад разгрузки',
    [QuoteField.UNLOADING_DATE]: 'Дата разгрузки',
    [QuoteField.UNLOADING_ADDRESS]: 'Адрес разгрузки',
    [QuoteField.CLIENT_CONTACT]: 'Контактное лицо',
    [QuoteField.CLIENT_QUOTE_NUMBER]: 'Номер заказа Заказчика',
    [QuoteField.CARRIER_THIRD_PARTY]: 'Сторонний поставщик',
    [QuoteField.CARRIER_COMPANY]: 'Организация для Поставщика',
    [QuoteField.CARRIER]: 'Поставщик',
    [QuoteField.CARRIER_CONTRACT]: 'Договор с Поставщиком',
    [QuoteField.CARRIER_PAYMENT_METHOD]: 'Форма оплаты Поставщика',
    [QuoteField.CARRIER_PAYMENT_TYPE]: 'Условия оплаты Поставщика',
    [QuoteField.CARRIER_PAYMENT_DELAY]: 'Отсрочка платежа Поставщика',
    [QuoteField.CARRIER_PLANNED_PAYMENT_DATE]: 'Плановая дата оплаты Поставщика',
    [QuoteField.CARRIER_PAYMENT_DATETIME]: 'Фактическая дата оплаты Поставщика',
    [QuoteField.LOADING_STORAGE]: 'Склад',
    [QuoteField.LOADING_DATE]: 'Дата погрузки',
    [QuoteField.CARRIER_CONTACT]: 'Контактное лицо',
    [QuoteField.LOADING_ADDRESS]: 'Адрес погрузки',
    [QuoteField.SHIPMENT_DETAILS]: 'Грузы',
    [QuoteField.STATUS]: 'Статус заказа',
    [QuoteField.MANAGER]: 'Менеджер КО',
    [QuoteField.DESCRIPTION]: 'Описание',
};
