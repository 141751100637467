export enum StorageQuotesField {
    ASSORTMENT_NAME = 'assortmentName',
    CLIENT_NAME = 'clientName',
    DIRECTION = 'direction',
    DRIVER_CONTACT = 'driverContact',
    LOADING = 'loading',
    LOADING_DATE = 'loadingDate',
    QUOTE_DESCRIPTION = 'quoteDescription',
    QUOTE_NUMBER = 'quoteNumber',
    RUN_NUMBER = 'runNumber',
    RUN_STATUS = 'runStatus',
    STATUS = 'status',
    TARE_QUANTITY = 'tareQuantity',
    VEHICLE_NUMBER = 'vehicleNumber',
    WEIGHT = 'weight',
    WEIGHT_LOADED = 'weightLoaded'
}
