import React from 'react';
import { type MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import clients from '../../api/clients';
import { Option } from '../control/option';
import FormSelect from './FormSelect';

type Props = {
    id: string;
    onChange?: (newValue: SingleValue<Option>) => void;
};

const ClientBasisOptions: React.FC<Props> = ({ id, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clients
            .clientBasisOptions({
                filter: inputValue,
            })
            .then((options) => callback(options));
    };
    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
        />
    );
};

export default ClientBasisOptions;
