import React, { ReactElement } from 'react';
import './skeleton.scss';

type Props = {
    className?: string;
};

const FormSkeleton: (props: Props) => ReactElement<Props> = ({ className }) => {
    return <div className={`placeholder placeholder-wave ${className ?? ''}`} />;
};

export default FormSkeleton;
