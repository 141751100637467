import React from 'react';
import { generatePath } from 'react-router-dom';
import productionLines from '../../../api/productionLine';
import { ProductionLineListModel } from '../../../model/productionLine/productionLineListModel';
import { PATH_CP_PRODUCTION_LINE, PATH_VARIABLE_PRODUCTION_LINE_ID } from '../../../routerPaths';
import { Config } from '../../table/config';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { ProductionLineField } from './productionLineField';

const config = Config.builder<ProductionLineListModel>()
        .column((builder) => builder.id().filterable(false))
        .column((builder) =>
            builder.integer('Номер линии', ProductionLineField.LINE_NUMBER).sortable(false)
        )
        .column((builder) =>
            builder.text('Склад производства', ProductionLineField.STORAGE_NAME).sortable(false)
        )
        .column((builder) =>
            builder
                .cls('text-center')
                .sortable(false)
                .filterable(false)
                .getter((model, _, refresh) => (
                    <RemoveButton
                        onClick={() =>
                            onRowDelete(refresh, () => productionLines.delete(model.id))
                        }
                    />
                ))
                .width('45px')
        )
        .formUrl(generatePath(PATH_CP_PRODUCTION_LINE, { [PATH_VARIABLE_PRODUCTION_LINE_ID]: '' }))
        .load((ctx) => productionLines.list(ctx.state.request))
        .id('production-lines')
        .defaultHidden([])
        .build();

const ProductionLineList: React.FC = () => {
    return (
        <div className='production-lines d-flex'>
            <DwTable header='Производственные линии' config={config} />
        </div>
    );
};

export default ProductionLineList;
