import React, { useContext } from 'react';
import { FormContext } from './DwForm';

export const SaveButton: React.FC = () => {
    const { dwSubmit } = useContext(FormContext);
    return (
        <button className='btn btn-sm btn-primary' onClick={dwSubmit}>
            Сохранить
        </button>
    );
};

const SaveButtonGroup: React.FC = () => {
    const { dwSubmit, dwSubmitWithoutRedirect } = useContext(FormContext);
    return (
        <>
            <button className='btn btn-sm btn-primary' onClick={dwSubmitWithoutRedirect}>
                Сохранить
            </button>
            &nbsp;
            <button className='btn btn-sm btn-primary' onClick={dwSubmit}>
                Сохранить и выйти
            </button>
        </>
    );
};

export default SaveButtonGroup;