import React, { useContext } from 'react';
import MapComponent from '../../form/map/MapComponent';
import { RunFormContext } from '../RunForm';

const RunMap: React.FC = () => {
    const {
        run: {
            id: runId,
            created,
            driver: { value: driverId },
            truck: { label: truck },
            route: routePoints,
        },
    } = useContext(RunFormContext);

    return (
        <MapComponent
            runId={runId}
            truck={truck}
            driverId={driverId}
            created={created}
            routePoints={routePoints}
        />
    );
};

export default RunMap;
