import React from "react";
import {DwTable} from "../../table/DwTable";
import {Config} from "../../table/config";
import {StorageQuotesModel} from "./storageQuotesModel";
import {ColumnType} from "../../table/columnType";
import storages from "../../../api/storages";
import { StorageAssemblyNav } from "../StorageAssemblyNav";
import { StorageQuotesField } from "./storageQuotesField";


const config = Config.builder<StorageQuotesModel>()
    .column((builder) => builder.date('Дата погрузки', StorageQuotesField.LOADING_DATE))
    .column((builder) => builder.text('Наименование склада', StorageQuotesField.ASSORTMENT_NAME))
    .column((builder) =>
        builder.label('Статус').field(StorageQuotesField.STATUS).type(ColumnType.STORAGE_STATUS).width('120px').editable()
            .onChangeRow((value, idx, row) => storages.changeStatus(row.id, value.value))
    )
    .column((builder) => builder.client('Заказчик', StorageQuotesField.CLIENT_NAME))
    .column((builder) => builder.text('№ Рейса', StorageQuotesField.RUN_NUMBER))
    .column((builder) => builder.label('Статус рейса').field(StorageQuotesField.RUN_STATUS).type(ColumnType.RUN_STATUS))
    .column((builder) => builder.text('№ Заказа', StorageQuotesField.QUOTE_NUMBER))
    .column((builder) => builder.text('Номер ТС', StorageQuotesField.VEHICLE_NUMBER))
    .column((builder) => builder.text('Контакт водителя', StorageQuotesField.DRIVER_CONTACT))
    .column((builder) => builder.text('Направление/РЦ', StorageQuotesField.DIRECTION))
    .column((builder) => builder.text('Погрузка', StorageQuotesField.LOADING))
    .column((builder) => builder.integer('Вес', StorageQuotesField.WEIGHT))
    .column((builder) => builder.integer('Вес факт', StorageQuotesField.WEIGHT_LOADED))
    .column((builder) => builder.integer('Кол-во тары', StorageQuotesField.TARE_QUANTITY))
    .column((builder) => builder.description('Комментарий к заказу', StorageQuotesField.QUOTE_DESCRIPTION))
    .load((ctx) => storages.quotes(ctx.state.request))
    .id('storage-quotes')
    .withAdd(false)
    .editable(true)
    .onClick(() => null)
    .build();

const StorageQuotes: React.FC = () => {
    return (
        <div>
            <StorageAssemblyNav />
            <div className='quotes d-flex'>
                <DwTable header='Заказы склада' config={config} />
            </div>
        </div>
    );
};

export default StorageQuotes;
