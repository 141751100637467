import {get, put} from './rest';

import type {SettingModel} from '../model/settings/settingModel';

const PATH_BASE = 'settings';

const settings = {
    get: async (keys: string[]) => new Map<string, string>(
        Object.entries(await get<Object>(`${PATH_BASE}?keys=${keys.join(',')}`))
    ),
    update: (models: SettingModel[]) => put(`${PATH_BASE}/update`, models),
};

export default settings;
