import './app.scss';
import 'react-toastify/dist/ReactToastify.css';

import { Outlet, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import users from './api/users';
import route from './common/route';
import { RootContext } from './component/layout/ContextWrapper';
import Header from './component/layout/Header';
import Sidebar from './component/layout/Sidebar';
import { ToastContainer } from 'react-toastify';

const App: React.FC = () => {
    const { state, setBusy, setProfile, pushError } = useContext(RootContext);
    const navigate = useNavigate();
    const [contextLoading, setContextLoading] = useState(false);

    useEffect(() => {
        route.setBusy = setBusy;
    }, [setBusy]);

    useEffect(() => {
        route.pushError = pushError;
    }, [pushError]);

    useEffect(() => {
        route.navigate = navigate;
    }, [navigate]);

    if (state.profile.id <= 0 && !contextLoading) {
        setContextLoading(true);
        users.current().then(setProfile);
    }

    return state.profile.id > 0 ? (
        <div id='wrapper' data-busy={state.busy}>
            <Sidebar />
            <div id='content-wrapper' className='d-flex flex-column'>
                <div id='content' className='d-flex flex-column'>
                    <Header />
                    <div className='container-fluid d-flex flex-column main-area'>
                        <Outlet />
                    </div>
                </div>
                <footer className='sticky-footer'>
                    <div className='container my-auto'>
                        <div className='copyright text-center my-auto'>
                            <span>Copyright &copy; Dynamicweb RU</span>
                        </div>
                    </div>
                </footer>
            </div>

            <ToastContainer />
        </div>
    ) : (
        <></>
    );
};

export default App;
