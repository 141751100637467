import React, { useEffect, useMemo, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import { Profile } from '../../model/profile';
import { UiState } from '../../model/uiState';
import { ActionType } from './action';
import { reducer } from './reducer';
import { Modal } from './Modal';

export type State = {
    busy: boolean;
    profile: Profile;
    uiState: UiState;
    error: string;
};

export type Context = {
    state: State;
    setBusy: (busy: boolean) => void;
    setProfile: (profile: Profile) => void;
    setUiState: (uiState: UiState) => void;
    pushError: (error: string) => void;
};

type Props = {
    children: React.ReactElement | React.ReactElement[];
};

const RootContext = React.createContext<Context>({} as Context);

let overlayTimeout: string | number | NodeJS.Timeout | undefined = undefined;

const loadUiState = () => {
    const serialized = localStorage.getItem('uiState');
    try {
        if (serialized) {
            return JSON.parse(serialized) as UiState;
        }
    } catch (e) {
        console.error(e);
    }
    return new UiState();
};

const initialState = (): State => {
    return {
        busy: true,
        profile: new Profile(),
        uiState: loadUiState(),
        error: '',
    };
};

const ContextWrapper: React.FC<Props> = (props) => {
    const [state, dispatch] = React.useReducer(reducer, initialState());
    const [overlay, setOverlay] = useState(false);
    useEffect(() => {
        if (state.busy) {
            if (!overlayTimeout) {
                overlayTimeout = setTimeout(() => {
                    if (state.busy) {
                        setOverlay(true);
                    }
                }, 500);
            }
        } else {
            if (overlayTimeout) {
                clearTimeout(overlayTimeout);
                overlayTimeout = undefined;
            }
            setOverlay(false);
        }
    }, [state.busy]);

    const ctx = useMemo(
        () => ({
            state,
            setBusy: (busy: boolean) => dispatch({ type: ActionType.BUSY, busy }),
            setProfile: (profile: Profile) => dispatch({ type: ActionType.PROFILE, profile }),
            pushError: (error?: string) =>
                dispatch({ type: ActionType.PUSH_ERROR, error: error ?? '' }),
            setUiState: (uiState: UiState) =>
                dispatch({
                    type: ActionType.UI_STATE,
                    uiState: { ...state.uiState, ...uiState },
                }),
        }),
        [state, dispatch]
    );
    return (
        <RootContext.Provider value={ctx}>
            {!!state.error && (
                <Modal show={true} onClose={() => ctx.pushError()} title='Ошибка'>
                    <div>{state.error}</div>
                </Modal>
            )}
            <LoadingOverlay active={overlay} spinner text='Загрузка...' className='overlay'>
                {props.children}
            </LoadingOverlay>
        </RootContext.Provider>
    );
};

export { RootContext, ContextWrapper };
