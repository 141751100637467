import React from 'react';

const ChangeHistory: React.FC = () => {
    return (
        <>
            <div>История изменений</div>
        </>
    );
};

export default ChangeHistory;
