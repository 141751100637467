export const FIELD_ID = 'id';
export const FIELD_CLIENTS = 'clients';
export const FIELD_TYPE = 'type';
export const FIELD_OWNERSHIP = 'ownership';
export const FIELD_CARCASS = 'carcass';
export const FIELD_APPROVAL = 'approval';
export const FIELD_VIN = 'vin';
export const FIELD_MODEL = 'model';
export const FIELD_NUMBER = 'number';
export const FIELD_ALIEN = 'alien';
export const FIELD_VOLUME = 'volume';
export const FIELD_TONNAGE = 'tonnage';
export const FIELD_PTS_INFO = 'ptsInfo';
export const FIELD_PTS_DATE = 'ptsDate';
export const FIELD_OWNER = 'owner';
export const FIELD_COMMENT = 'comment';
export const FIELD_ATTACHMENTS = 'attachmentListModel';
