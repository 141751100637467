import { Action, ActionType } from './action';
import { RefreshState, State } from './context';
import Cache from '../../common/cache';
import { CACHE_KEY } from './DwTable';

const withSave = (state: State): State => {
    Cache.set(CACHE_KEY + state.id, { request: state.request, order: state.order });
    return state;
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.REORDER:
            return withSave({ ...state, order: [...action.order!] });
        case ActionType.HIDE: {
            const { hidden, filters } = state.request;
            const field = action.column!.field;
            const doHide: boolean = !hidden.includes(field);
            return withSave({
                ...state,
                request: {
                    ...state.request,
                    filters: doHide ? filters.filter((it) => it.field !== field) : filters,
                    hidden: doHide ? hidden.concat(field) : hidden.filter((it) => it !== field),
                },
            });
        }
        case ActionType.SORT:
            return withSave({ ...state, request: { ...state.request, sort: action.sort! } });
        case ActionType.ADD_FILTER:
            return withSave({
                ...state,
                request: {
                    ...state.request,
                    filters: state.request.filters
                        .filter((it) => it.field !== action.filter!.field)
                        .concat(action.filter!),
                },
            });
        case ActionType.DISPLAY_FILTER:
            return { ...state, showFilterFor: action.column, position: action.position };
        case ActionType.RESET_FILTERS:
            return withSave({ ...state, request: {...state.request, filters: []}});
        case ActionType.REMOVE_FILTER:
            return withSave({
                ...state,
                request: {
                    ...state.request,
                    filters: state.request.filters.filter(
                        (it) => it.field !== action.column!.field
                    ),
                },
            });
        case ActionType.PAGE_SIZE:
            return withSave({
                ...state,
                request: {
                    ...state.request,
                    pageInfo: { ...state.request.pageInfo, page: 0, pageSize: action.id! },
                },
            });
        case ActionType.PAGE:
            return withSave({
                ...state,
                request: {
                    ...state.request,
                    pageInfo: { ...state.request.pageInfo, page: action.id! },
                },
            });
        case ActionType.SEARCH:
            return withSave({
                ...state,
                request: { ...state.request, search: action.value ?? '' },
            });
        case ActionType.REFRESH:
            return { ...state };
        case ActionType.SELECT_ROW:
            const rowId = action.id!, selectedRows = state.selectedRows;
            if (state.selectedRows.includes(rowId)) {
                return { ...state, selectedRows: selectedRows.filter((r) => r !== rowId) };
            } else {
                return { ...state, selectedRows: [...selectedRows, rowId] };
            }
        case ActionType.DESELECT_ALL_ROWS:
            return { ...state, selectedRows: [] };
        default:
            throw new Error('Non-implemented action type in reducer');
    }
};

export const refreshReducer = (state: RefreshState, action: Action): RefreshState => {
    switch (action.type) {
        case ActionType.UPDATE_REFRESH_SIGNAL:
            return { signal: state.signal + 1 };
        default:
            throw new Error('Non-implemented action type in reducer');
    }
};
