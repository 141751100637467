import { RefObject, useEffect, useRef } from 'react';

type HoverEventCallback = () => void;

export const useHover = <T extends HTMLElement>(
    onMouseEnter: HoverEventCallback,
    onMouseLeave: HoverEventCallback,
    ref: RefObject<T>
) => {
    useEffect(() => {
        const node = ref.current;
        if (!node) {
            return;
        }

        node.addEventListener('mouseenter', onMouseEnter);
        node.addEventListener('mouseleave', onMouseLeave);

        return () => {
            node.removeEventListener('mouseenter', onMouseEnter);
            node.removeEventListener('mouseleave', onMouseLeave);
        };
    }, [onMouseEnter, onMouseLeave, ref]);

    return { ref };
};

export const useHoverRef = <T extends HTMLElement>(
    onMouseEnter: HoverEventCallback,
    onMouseLeave: HoverEventCallback
) => {
    return useHover(onMouseEnter, onMouseLeave, useRef<T>(null));
};
