import React, {useContext} from 'react';
import { StompWebSocketProvider } from '../../../websockets/StompWebSocketProvider';
import { EdiModal } from './edi/EdiModal';
import { EdiIntegrationProvider, EdiIntegrationContext } from './edi/EdiIntegrationProvider';
import {Dropdown, DropdownItem} from "../../layout/Dropdown";

const DropdownMenu: React.FC = () => {
    const { onShow: onShowEdi } = useContext(EdiIntegrationContext);

    return (
        <Dropdown label={'обновить'}>
            <DropdownItem label={'из EDI'} onClick={onShowEdi} />
        </Dropdown>
    )
};

const SourcesDropdown: React.FC = () => {
    return (
        <StompWebSocketProvider>
            <EdiIntegrationProvider>
                <DropdownMenu />
                <EdiModal />
            </EdiIntegrationProvider>
        </StompWebSocketProvider>
    );
};

export default SourcesDropdown;