import React from 'react';
import { Marker, Tooltip } from 'react-leaflet';
import { getMarkerIcon, IconType } from './getMarkerIcon';

type Props = {
    longitude: number;
    latitude: number;
    iconType: IconType;
    tooltip?: string;
};

const RouteMarker: React.FC<Props> = ({ longitude, latitude, iconType, tooltip }) => {
    return (
        <Marker position={[longitude, latitude]} icon={getMarkerIcon(iconType)}>
            {tooltip && (
                <Tooltip direction='right' permanent>
                    {tooltip}
                </Tooltip>
            )}
        </Marker>
    );
};

export default RouteMarker;
