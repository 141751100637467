import { positiveIntValidator, requiredValidator } from './simpleValidators';
import type { VehicleModel } from '../../model/vehicle/vehicleModel';
import { PROMISE_OK } from '../const';

const isAlien = (model: VehicleModel) => {
    return model?.alien || false;
};

export const numberValidator = (model: VehicleModel) => {
    const number = model.number;
    if (!number) {
        return requiredValidator(number);
    }

    if (isAlien(model)) {
        return Promise.resolve(PROMISE_OK);
    }

    const lorryOrTruckRegexp = /^[А-Я]\d{3}[А-Я]{2}\d{2,3}$/;
    const trailerRegexp = /^[А-Я]{2}\d{4}\d{2,3}$/;
    const isValid = !number || number.match(lorryOrTruckRegexp) || number.match(trailerRegexp);
    return Promise.resolve(isValid ? PROMISE_OK : 'Некорректный формат (Б123ББ123 или ББ1234123)');
};

export const cubicCapacityValidator = (model: VehicleModel) => {
    const cubicCapacity = model.volume;

    if (!cubicCapacity) {
        return requiredValidator(cubicCapacity);
    }

    return positiveIntValidator(cubicCapacity);
};

export const tonnageValidator = (model: VehicleModel) => {
    const tonnage = model.tonnage;

    if (!tonnage) {
        return requiredValidator(tonnage);
    }

    return positiveIntValidator(tonnage);
};
