import React from 'react';
import { useFieldValue } from '../../form/DwForm';
import { Dropdown, DropdownItem } from '../../layout/Dropdown';
import { QuoteField } from '../quoteField';

type Props = {
    id: string;
    onAddDefaultSD: (e: React.UIEvent<HTMLElement> | React.KeyboardEvent) => void;
    onAddAssortmentBased: () => void;
    onAddTmcBased: () => void;
};

const ShipmentDetailsBtns: React.FC<Props> = (props) => {
    const isDomesticQuote = useFieldValue(QuoteField.IS_DOMESTIC_QUOTE);

    return (
        <>
            {isDomesticQuote ? (
                <DropdownBtn
                    label={'+позиция'}
                    onClickAssortment={props.onAddAssortmentBased}
                    onClickTmc={props.onAddTmcBased}
                />
            ) : (
                <button
                    type='button'
                    className='btn btn-outline-primary btn-sm'
                    onClick={props.onAddDefaultSD}
                >
                    добавить
                </button>
            )}
        </>
    );
};

type DropdownBtnProps = {
    label: string;
    onClickAssortment: () => void;
    onClickTmc: () => void;
};

const DropdownBtn: React.FC<DropdownBtnProps> = (props) => {
    return (
        <Dropdown label={props.label}>
            <DropdownItem label={'Номенклатура'} onClick={props.onClickAssortment} />
            <DropdownItem label={'ТМЦ'} onClick={props.onClickTmc} />
        </Dropdown>
    );
};

export default ShipmentDetailsBtns;
