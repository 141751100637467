import React, { useContext, useMemo } from 'react';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { Config } from '../../../table/config';
import { NavigateFunction, Outlet, generatePath } from 'react-router-dom';
import { ClientFormContext } from '../ClientForm';
import { PATH_CP_CLIENT_ACCOUNT, PATH_VARIABLE_CLIENT_ACCOUNT_ID } from '../../../../routerPaths';
import util from '../../../../common/util';
import { RemoveButton } from '../../../table/RemoveButton';
import { AccountModel } from '../../../../model/account';
import accounts from '../../../../api/accounts';
import { AccountField } from '../../account/accountField';

const ClientAccount: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const config = useMemo(() => {
        return Config.builder<AccountModel>()
            .column((builder) => builder.id().filterable(false))
            .column((builder) => builder.text('Р/Счет', AccountField.NUMBER).sortable(false))
            .column((builder) => builder.text('Банк', AccountField.BANK).sortable(false))
            .column((builder) => builder.text('БИК', AccountField.BIK).sortable(false))
            .column((builder) => builder.text('Корр. счет', AccountField.CORRESPONDENT_NUMBER).sortable(false))
            .column((builder) =>
                builder
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () => accounts.delete(model.id))
                            }
                        />
                    ))
                    .sortable(false)
                    .filterable(false)
                    .width('45px')
                    .cls('text-center')
            )
            .columnsSelector(false)
            .load((ctx) => accounts.listForClient(ctx.state.request, clientId))
            .formUrl(
                generatePath(PATH_CP_CLIENT_ACCOUNT, {
                    clientId: util.stringOrEmpty(clientId),
                    [PATH_VARIABLE_CLIENT_ACCOUNT_ID]: '',
                })
            )
            .onClick((id: number, navigate: NavigateFunction) =>
                navigate(
                    generatePath(PATH_CP_CLIENT_ACCOUNT, {
                        clientId: util.stringOrEmpty(clientId),
                        [PATH_VARIABLE_CLIENT_ACCOUNT_ID]: util.stringOrEmpty(id),
                    })
                )
            )
            .id(`client_${clientId}_accounts`)
            .build();
    }, [clientId]);

    return (
        <>
            <div className='accounts d-flex mb-3'>
                <DwTable header='Расчетные счета' config={config} />
            </div>
            <Outlet />
        </>
    );
};

export default ClientAccount;
