export enum FieldType {
    TEXT,
    NUMBER,
    EMAIL,
    PASSWORD,
    DATE,
    DATE_TIME,
    OPTION,
    OBJECT,
    LIST,
    PROXY_LIST,
    BOOLEAN,
}
