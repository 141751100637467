import React from 'react';
import { generatePath } from 'react-router-dom';
import vehicles from '../../../api/vehicles';
import { PATH_CP_VEHICLE } from '../../../routerPaths';
import { ColumnType } from '../../table/columnType';
import { Config } from '../../table/config';
import { DwTable, onRowDelete } from '../../table/DwTable';
import { RemoveButton } from '../../table/RemoveButton';
import * as VehiclesFields from './vehicleFields';

import type { VehicleListModel } from '../../../model/vehicle/vehicleListModel';

const config = Config.builder<VehicleListModel>()
        .column((builder) => builder.id())
        .column((builder) => builder.text('Модель ТС', VehiclesFields.FIELD_MODEL).sortable(false))
        .column((builder) => builder.text('Номер ТС', VehiclesFields.FIELD_NUMBER).sortable(false))
        .column((builder) =>
            builder
                .label('Контрагенты')
                .field(VehiclesFields.FIELD_CLIENTS)
                .type(ColumnType.CARRIER)
                .sortable(false)
                .cls('client')
        )
        .column((builder) =>
            builder
                .label('Тип ТС')
                .field(VehiclesFields.FIELD_TYPE)
                .type(ColumnType.VEHICLE_TYPE)
                .cls('type')
        )
        .column((builder) =>
            builder
                .label('Тип собственности')
                .field(VehiclesFields.FIELD_OWNERSHIP)
                .type(ColumnType.VEHICLE_OWNERSHIP)
                .sortable(false)
                .filterable(false)
                .cls('ownership')
        )
        .column((builder) =>
            builder
                .label('Тип кузова')
                .field(VehiclesFields.FIELD_CARCASS)
                .type(ColumnType.CARCASS)
                .cls('carcass')
        )
        .column((builder) =>
            builder
                .label('Согласование')
                .field(VehiclesFields.FIELD_APPROVAL)
                .type(ColumnType.APPROVAL_STATUS)
                .cls('approval')
        )
        .column((builder) =>
            builder.text('VIN номер', VehiclesFields.FIELD_VIN).sortable(false).filterable(false)
        )
        .column((builder) =>
            builder.boolean('Не РФ', VehiclesFields.FIELD_ALIEN).sortable(false).cls('boolean')
        )
        .column((builder) => builder.text('Кубатура', VehiclesFields.FIELD_VOLUME).sortable(false))
        .column((builder) => builder.text('Тоннаж', VehiclesFields.FIELD_TONNAGE).sortable(false))
        .column((builder) =>
            builder
                .text('Серия номер ПТС', VehiclesFields.FIELD_PTS_INFO)
                .sortable(false)
                .filterable(false)
        )
        .column((builder) =>
            builder
                .date('Дата выдачи ПТС', VehiclesFields.FIELD_PTS_DATE)
                .sortable(false)
                .filterable(false)
        )
        .column((builder) =>
            builder.text('ФИО Собственника', VehiclesFields.FIELD_OWNER).sortable(false)
        )
        .column((builder) =>
            builder.text('Заметки', VehiclesFields.FIELD_COMMENT).sortable(false).filterable(false)
        )
        .column((builder) =>
            builder
                .cls('text-center')
                .sortable(false)
                .filterable(false)
                .getter((model, _, refresh) => (
                    <RemoveButton
                        onClick={() => onRowDelete(refresh, () => vehicles.delete(model.id))}
                    />
                ))
                .width('45px')
        )
        .formUrl(generatePath(PATH_CP_VEHICLE, { vehicleId: '' }))
        .load((ctx) => vehicles.list(ctx.state.request))
        .id('vehicles')
        .defaultHidden([
            VehiclesFields.FIELD_CLIENTS,
            VehiclesFields.FIELD_OWNERSHIP,
            VehiclesFields.FIELD_VIN,
            VehiclesFields.FIELD_ALIEN,
            VehiclesFields.FIELD_PTS_INFO,
            VehiclesFields.FIELD_PTS_DATE,
            VehiclesFields.FIELD_OWNER,
            VehiclesFields.FIELD_COMMENT,
        ])
        .build();

const VehicleList: React.FC = () => {
    return (
        <div className='vehicles d-flex'>
            <DwTable header='Транспорт' config={config} />
        </div>
    );
};

export default VehicleList;
