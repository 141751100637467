import {get, post, put} from './rest';

import type {PagedResponse} from './pagedResponse';
import type {ListRequest} from '../component/table/listRequest';
import { EdiMessageListModel, EdiQuoteOptionsRequest } from '../model/edi/ediMessageListModel';
import { Option } from '../component/control/option';
import { EdiToQuoteModel } from '../model/edi/ediComparisonModel';
import { EdiMessageModel } from '../model/edi/ediMessageModel';
import { showSuccessToast } from '../component/control/showToast';
import { QuoteModel } from '../component/quotes/model/quoteModel';
import { ClientModel } from '../model/client/clientModel';
import { BaseOptionRequest } from './baseOptionRequest';
import { EdiPartyListModel } from '../model/edi/ediPartyListModel';
import { EdiLinkQuoteResponse } from '../model/edi/ediLinkQuoteResponse';
import { EdiQuoteLinkMode } from '../model/enums/EdiQuoteLinkMode';

const PATH_BASE = 'edi';

const edi = {
    quoteOptions: (request: EdiQuoteOptionsRequest) => post<Option[]>(`${PATH_BASE}/options/quote`, request),
    partyOptions: (request: BaseOptionRequest) => post<Option[]>(`${PATH_BASE}/options/party`, request),
    unlinkQuote: (ediMessageId: number) => put(`${PATH_BASE}/unlink/${ediMessageId}`, null),
    list: (model: ListRequest) => post<PagedResponse<EdiMessageListModel>>(`${PATH_BASE}/list`, model),
    listParties: (model: ListRequest) => post<PagedResponse<EdiPartyListModel>>(`${PATH_BASE}/party/list`, model),
    get: (id: number) => get<EdiToQuoteModel>(`${PATH_BASE}/${id}`),
    getEdiFromQuote: (quoteId: number) => get<EdiToQuoteModel>(`${PATH_BASE}/edi-from-quote/${quoteId}`),
    getQuoteFromEdi: (ediMessageId: number) => get<QuoteModel>(`${PATH_BASE}/quote-from-edi/${ediMessageId}`),
    getClientFromEdi: (ediMessageId: number) => get<ClientModel>(`${PATH_BASE}/client-from-edi/${ediMessageId}`),
    getConsigneeFromEdi: (ediMessageId: number) => get<ClientModel>(`${PATH_BASE}/consignee-from-edi/${ediMessageId}`),
    linkClientToEdi: (clientId: number) => put(`${PATH_BASE}/link/client-to-edi/${clientId}`, null),
    linkConsigneeToEdi: (clientId: number) => put(`${PATH_BASE}/link/consignee-to-edi/${clientId}`, null),
    setPartyChainConfig: (id: number, value: boolean) => put(`${PATH_BASE}/party/${id}/ordrsp/${value}`, null),
    save: (model: EdiMessageModel, mode: EdiQuoteLinkMode = EdiQuoteLinkMode.LINK) =>
        put<EdiLinkQuoteResponse>(`${PATH_BASE}?mode=${mode}`, model).then(({id, accepted}) => {
            if (accepted) {
                showSuccessToast("Сообщение о принятии отправлено в EDI", 3000);
            }
        }),
};

export default edi;
