import React from 'react';
import { Outlet } from 'react-router-dom';
import { DistributionButtons } from './DistributionButtons';
import { StorageDistributionQuoteModel } from './storageDistributionModel';
import util from "../../../common/util";

type Props = {
    tareAssembled: number,
    quotes: StorageDistributionQuoteModel[]
};

export const DistributionCards: React.FC<Props> = ({ tareAssembled, quotes }) => {
    const tareTotalDistributed = util.countTotal(quotes, ({tareDistributed = 0}) => tareDistributed);
    return (
        <>
            {quotes.map(quote => {
                const {
                    id: quoteId,
                    number: quoteNumber,
                    tare: {
                        value: tareId,
                        label: tareLabel
                    },
                    tareTotal = 0,
                    tareDistributed = 0,
                    weight = 0,
                } = quote;

                return (
                    <div key={`quote-${quoteId}`} className='card border-left-primary mx-1 my-2'>
                        <div className='card-body'>
                            <div>
                                <div className='row mb-1'>
                                    <div className='col'>
                                        <div className='text-sm text-muted'>Заказ:</div>
                                        {quoteNumber?.length ? quoteNumber : '-'}
                                    </div>
                                    <div className='col'>
                                        <div className='text-sm text-muted'>Тара:</div>
                                        {tareLabel?.length ? tareLabel : '-'}
                                    </div>
                                    <div className='col'>
                                        <div className='text-sm text-muted'>В заказе:</div>
                                        {tareTotal}
                                    </div>
                                    <div className='col'>
                                        <div className='text-sm text-muted'>Вес:</div>
                                        {weight}
                                    </div>
                                </div>
                            </div>
                            <div className='row distribution-btns'>
                                <div className='col'>
                                    <DistributionButtons
                                        quoteId={quoteId}
                                        tareId={tareId}
                                        tareQuoteTotal={tareTotal}
                                        tareQuoteDistributed={tareDistributed}
                                        tareTotalDistributed={tareTotalDistributed}
                                        tareTotalAssembled={tareAssembled}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
            <Outlet />
        </>
    );
};