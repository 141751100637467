import React from 'react';
import { Option } from '../control/option';
import vehicles from '../../api/vehicles';
import FormSelect from './FormSelect';
import { VehicleType } from '../../model/enums/VehicleType';

type Props = {
    id: string;
    clientId: number;
    vehicleTypes: VehicleType[];
    nullable?: boolean;
};

const Vehicles: React.FC<Props> = ({ id, clientId, vehicleTypes, nullable }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        vehicles
            .options({
                filter: inputValue,
                clientId,
                vehicleTypes,
            })
            .then((options) => callback(options));
    };
    return <FormSelect id={id} loadOptions={loadOptions} nullable={nullable} />;
};

export default Vehicles;
