import React, {HTMLAttributes, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import clients from '../../api/clients';
import { Direction } from '../../common/const';
import {FormContext, useFieldValue, useIsLoaded, useSetFieldValue, validateField} from '../form/DwForm';
import ClientConsignees from '../form/ClientConsignees';
import Clients from '../form/Clients';
import {ClientContact, ClientType} from '../../model/client/clientModel';
import DtPicker from '../form/DtPicker';
import GroupedErrorMessages from '../form/GroupedErrorMessages';
import StorageContacts from '../form/StorageContacts';
import TextInput from '../form/TextInput';
import StringInput from '../form/StringInput';
import Companies from '../form/Companies';
import Options from '../form/Options';
import Contracts from '../form/Contracts';
import ClientContacts from '../form/ClientContacts';
import onChangeContract from './model/onChangeContract';
import ShipmentDetails from './shipment-details/ShipmentDetails';
import {DEFAULT_OPTION, DictionaryType, type Option} from '../control/option';
import './quote.scss';
import CheckboxInput from '../form/CheckboxInput';
import Users from '../form/Users';
import {Role} from '../../model/role';
import util from '../../common/util';
import {ClientStorages, Storages} from '../form/ClientStorages';
import addresses from '../../api/addresses';
import ComponentLink, {ComponentLinkType} from '../form/ComponentLink';
import {type ContractRequest} from '../../model/contract/contractRequest';
import contracts from '../../api/contracts';
import {type ContractModel} from '../../model/contract/contractModel';
import {type SingleValue} from 'react-select/dist/declarations/src/types';
import {ContractType} from '../../model/enums/ContractType';
import {
    CARRIER_CONTRACT_DEPENDENT_FIELDS,
    CLIENT_CONTRACT_DEPENDENT_FIELDS,
    MockQuoteField,
    QuoteField,
    QuoteFieldTranslations,
} from './quoteField';
import NumberInput from '../form/NumberInput';
import {StorageType} from '../../model/enums/StorageType';
import {Context} from '../form/context';
import {QuoteModel} from './model/quoteModel';
import SaveAndCancelButtonGroup from '../form/SaveAndCancelButtonGroup';
import {ShipmentDetailModel} from './model/shipmentDetailModel';
import {quoteStatusEDIFilterCallback, quoteStatusFilterCallback} from '../../model/enums/QuoteStatus';
import { generatePath, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { PATH_QUOTE, PATH_VARIABLE_QUOTE_ID, SEARCH_PARAM_EDI_MESSAGE_ID } from '../../routerPaths';
import FormInvalidText from '../form/FormInvalidText';
import storages from '../../api/storages';

const ExternalOnlyWrapper: React.FC<HTMLAttributes<HTMLDivElement> & {isDomestic: boolean}> = ({isDomestic, ...rest}) => (
    !isDomestic ? <div {...rest}>{rest.children}</div> : <></>
);

const FieldAddress = ({ id, label }: { id: string, label: string }) => {
    const context = useContext(FormContext) as Context<QuoteModel>;
    const {state: {model, errors, config}} = context;
    const fieldConfig = config.field(id);
    const addressError = errors.get(id);
    const valueAddress = useFieldValue(id) as Option;
    useEffect(() => {
        if (valueAddress?.value) {
            validateField(context, fieldConfig, model).then();
        }
    }, [valueAddress?.value]);
    return (
        <div className='col'>
            <span className='pr-1'>{label}:</span>
            <span className='pr-1'>{valueAddress?.label}</span>
            <FormInvalidText error={addressError} inline />
        </div>
    );
};

const Quote: React.FC = () => {
    const [doInitialUpdates, setDoInitialUpdates] = useState(true);
    const context = useContext(FormContext) as Context<QuoteModel>;
    const {
        state: { model, errors },
    } = context;
    const setValue = useSetFieldValue();
    const isFormLoaded = useIsLoaded();
    const [searchParams] = useSearchParams();
    const ediMessageId = util.toNumber(searchParams.get(SEARCH_PARAM_EDI_MESSAGE_ID) ?? '');
    const { direction } = useParams<{ direction: string }>();
    const navigate = useNavigate();

    const isCarrierThirdParty = useFieldValue(QuoteField.CARRIER_THIRD_PARTY);
    const valueClientCompany = useFieldValue(QuoteField.CLIENT_COMPANY);
    const valueClient = useFieldValue(QuoteField.CLIENT);
    const valueClientContact = useFieldValue(QuoteField.CLIENT_CONTACT);
    const valueClientContract = useFieldValue(QuoteField.CLIENT_CONTRACT);
    const valueCarrierCompany = useFieldValue(QuoteField.CARRIER_COMPANY);
    const valueCarrier = useFieldValue(QuoteField.CARRIER);
    const valueCarrierContact = useFieldValue(QuoteField.CARRIER_CONTACT);
    const valueCreated = useFieldValue(QuoteField.CREATED);
    const valueRun = useFieldValue(QuoteField.RUN);
    const valueUnloadingDate = useFieldValue(QuoteField.UNLOADING_DATE);
    const valueClientPaymentDelay = useFieldValue(QuoteField.CLIENT_PAYMENT_DELAY);
    const valueClientDelayType = useFieldValue(QuoteField.CLIENT_PAYMENT_DELAY_TYPE);
    const valueCarrierPaymentDelay = useFieldValue(QuoteField.CARRIER_PAYMENT_DELAY);
    const valueCarrierDelayType = useFieldValue(QuoteField.CARRIER_PAYMENT_DELAY_TYPE);
    const valueStorage = useFieldValue(QuoteField.LOADING_STORAGE);
    const valueStorageContact = useFieldValue(QuoteField.CARRIER_COMPANY_CONTACT);
    const valueClientConsignee = useFieldValue(QuoteField.CLIENT_CONSIGNEE);
    const valueLoadingStorage = useFieldValue(QuoteField.LOADING_STORAGE);
    const valueLoadingAddress = useFieldValue(QuoteField.LOADING_ADDRESS);
    const valueCarrierCompanyContact = useFieldValue(QuoteField.CARRIER_COMPANY_CONTACT);
    const valueCarrierContract = useFieldValue(QuoteField.CARRIER_CONTRACT);
    const valueCarrierPaymentMethod = useFieldValue(QuoteField.CARRIER_PAYMENT_METHOD);
    const valueCarrierPaymentType = useFieldValue(QuoteField.CARRIER_PAYMENT_TYPE);
    const valueCarrierPaymentDatetime = useFieldValue(QuoteField.CARRIER_PAYMENT_DATETIME);
    const valueCarrierPlannedPaymentDate = useFieldValue(QuoteField.CARRIER_PLANNED_PAYMENT_DATE);
    const valueIsDomesticQuote = useFieldValue(QuoteField.IS_DOMESTIC_QUOTE);
    const valueShipmentDetails = useFieldValue(QuoteField.SHIPMENT_DETAILS);
    const { value: clientCompanyId } = useMemo(() => valueClientCompany ?? {}, [valueClientCompany]);
    const { value: clientConsigneeId } = useMemo(() => valueClientConsignee ?? {}, [valueClientConsignee]);
    const addressLookupClientId = valueIsDomesticQuote ? clientCompanyId : clientConsigneeId;
    const shipmentDetailsData: ShipmentDetailModel[] = useMemo(
        () => valueShipmentDetails ?? [],
        [valueShipmentDetails]
    );

    const onChangeClientCompany = useCallback((newValue: SingleValue<Option>) => {
        setValue(QuoteField.CLIENT_CONTRACT, {});
    }, [setValue]);

    const resetShipmentDetailsClientAssortment = useCallback(() => {
        if (shipmentDetailsData.length) {
            const newShipmentDetails = [...shipmentDetailsData];
            newShipmentDetails.forEach(shipmentDetail => {
                shipmentDetail.clientAssortment = {} as Option;
            });
            setValue(QuoteField.SHIPMENT_DETAILS, newShipmentDetails);
        }
    }, [setValue, shipmentDetailsData]);

    const updatePlannedPaymentDatetime = useCallback((
        unloadingDate: Date,
        paymentDelay: number,
        delayType: Option,
        fieldMap: Map<string, string>
    ) => {
        if (unloadingDate && delayType?.value) {
            const plannedPaymentDt = util.calculatePlannedPaymentDate(
                unloadingDate,
                paymentDelay,
                delayType.value
            );
            setValue(fieldMap.get(MockQuoteField.PLANNED_PAYMENT_DATETIME)!, plannedPaymentDt);
        }
    }, [setValue]);

    const updateFieldsFromContract = useCallback((contract: ContractModel, fieldMap: Map<string, string>) => {
        const {id, number, paymentMethod, paymentType, paymentDelay, delayType} = contract;

        setValue(fieldMap.get(MockQuoteField.CONTRACT)!, {value: id, label: number} as Option);
        setValue(fieldMap.get(MockQuoteField.PAYMENT_METHOD)!, paymentMethod);
        setValue(fieldMap.get(MockQuoteField.PAYMENT_TYPE)!, paymentType);
        setValue(fieldMap.get(MockQuoteField.PAYMENT_DELAY)!, paymentDelay);
        setValue(fieldMap.get(MockQuoteField.PAYMENT_DELAY_TYPE)!, delayType);
        updatePlannedPaymentDatetime(valueUnloadingDate, paymentDelay, delayType, fieldMap);
    }, [setValue, updatePlannedPaymentDatetime, valueUnloadingDate]);

    const setDefaultMainContract = useCallback((request: ContractRequest, fieldMap: Map<string, string>) => {
        contracts
            .getDefaultMain(request)
            .then((contract) => updateFieldsFromContract(contract, fieldMap));
    }, [updateFieldsFromContract]);

    const setManagerFromClient = useCallback((clientId: number, role: Role, dependentField: QuoteField) => {
        clients
            .findClientManager({
                filter: '',
                clientId,
                role,
            })
            .then((manager) => setValue(dependentField, manager));
    }, [setValue]);

    const onChangeClient = useCallback(async (newValue: SingleValue<Option>) => {
        const contact = newValue?.value
            ? await clients.contact(newValue.value)
            : {clientAddress: {}, contact: {}} as ClientContact;
        // clear all the fields except company
        setValue(QuoteField.CLIENT_CONTRACT, {});
        setValue(QuoteField.CLIENT_PAYMENT_METHOD, {});
        setValue(QuoteField.CLIENT_PAYMENT_TYPE, {});
        setValue(QuoteField.CLIENT_PAYMENT_DELAY, null);
        setValue(QuoteField.CLIENT_PAYMENT_DATETIME, null);
        setValue(QuoteField.CLIENT_PLANNED_PAYMENT_DATE, null);
        setValue(QuoteField.UNLOADING_STORAGE, contact.clientAddress ?? {});
        setValue(QuoteField.UNLOADING_ADDRESS, contact.clientAddress?.subOption ?? {});
        setValue(QuoteField.CLIENT_CONTACT, contact.contact ?? {});
        setValue(QuoteField.CLIENT_CONSIGNEE, {});
        if (newValue && valueClientCompany) {
            setDefaultMainContract(
                {
                    clientId: newValue.value,
                    clientTypeId: ClientType.CLIENT,
                    companyId: valueClientCompany.value,
                },
                CLIENT_CONTRACT_DEPENDENT_FIELDS
            );
        }

        if (newValue?.value && newValue?.value > 0 && isFormLoaded) {
            setValue(QuoteField.CLIENT_CONSIGNEE, newValue);
            setManagerFromClient(newValue.value, Role.COMMERCIAL, QuoteField.MANAGER);
        }
        resetShipmentDetailsClientAssortment();
    }, [setValue, isFormLoaded, resetShipmentDetailsClientAssortment, setDefaultMainContract, setManagerFromClient, valueClientCompany]);

    const onChangeClientConsignee = useCallback((newValue: SingleValue<Option>) => {
        if (valueRun?.value) {
            setValue(QuoteField.UNLOADING_STORAGE, {});
            setValue(QuoteField.UNLOADING_ADDRESS, {});
        } else {
            addresses
                .storageOptionsForClient({clientId: newValue?.value})
                .then((storages) => {
                    setValue(QuoteField.UNLOADING_STORAGE, storages.at(0) ?? DEFAULT_OPTION);
                    setValue(QuoteField.UNLOADING_ADDRESS, storages.at(0)?.subOption ?? DEFAULT_OPTION);
                });
        }
        resetShipmentDetailsClientAssortment();
    }, [setValue, resetShipmentDetailsClientAssortment, valueRun]);

    const setContract = useCallback((contract: Option, client: Option, fieldMap: Map<string, string>) => {
        const contractId = contract?.value;
        const clientId = client?.value;
        if (contractId && clientId) {
            contracts
                .getForClient(contractId, clientId)
                .then((contract) => updateFieldsFromContract(contract, fieldMap));
        }
    }, [updateFieldsFromContract]);

    const onChangeCarrierCompany = useCallback((newValue: SingleValue<Option>) => {
        setValue(QuoteField.CARRIER_CONTRACT, {});
    }, [setValue]);

    const quoteCarrierFieldValues = useMemo(() =>
            new Map<QuoteField, object | null>([
                [QuoteField.CARRIER, valueCarrier],
                [QuoteField.CARRIER_CONTRACT, valueCarrierContract],
                [QuoteField.CARRIER_PAYMENT_METHOD, valueCarrierPaymentMethod],
                [QuoteField.CARRIER_PAYMENT_TYPE, valueCarrierPaymentType],
                [QuoteField.CARRIER_PAYMENT_DELAY, valueCarrierPaymentDelay],
                [QuoteField.CARRIER_PAYMENT_DATETIME, valueCarrierPaymentDatetime],
                [QuoteField.CARRIER_PLANNED_PAYMENT_DATE, valueCarrierPlannedPaymentDate],
                [QuoteField.LOADING_STORAGE, valueLoadingStorage],
                [QuoteField.LOADING_ADDRESS, valueLoadingAddress],
                [QuoteField.CARRIER_CONTACT, valueCarrierContact],
                [QuoteField.CARRIER_COMPANY_CONTACT, valueCarrierCompanyContact],
            ]),
        [
            valueCarrier,
            valueCarrierContract,
            valueCarrierPaymentMethod,
            valueCarrierPaymentType,
            valueCarrierPaymentDelay,
            valueCarrierPaymentDatetime,
            valueCarrierPlannedPaymentDate,
            valueLoadingStorage,
            valueLoadingAddress,
            valueCarrierContact,
            valueCarrierCompanyContact,
        ]
    );

    const resetCarrierDependentFields = useCallback(() => {
        const shouldBeNullValues = [
            QuoteField.CARRIER_PAYMENT_DELAY,
            QuoteField.CARRIER_PAYMENT_DATETIME,
            QuoteField.CARRIER_PLANNED_PAYMENT_DATE,
        ];

        quoteCarrierFieldValues.forEach((currentValue, field) => {
            if (currentValue !== undefined) {
                const defaultValue = shouldBeNullValues.includes(field) ? null : {};
                setValue(field, defaultValue);
            }
        });
    }, [quoteCarrierFieldValues, setValue]);

    const onChangeCarrierOrCompany = useCallback(async (newValue: SingleValue<Option>) => {
        resetCarrierDependentFields();

        if (newValue && valueCarrierCompany) {
            if (isCarrierThirdParty) {
                const contact = newValue?.value
                    ? await clients.contact(newValue.value)
                    : {clientAddress: {}, contact: {}} as ClientContact;
                setValue(QuoteField.LOADING_STORAGE, contact.clientAddress ?? {});
                setValue(QuoteField.LOADING_ADDRESS, contact.clientAddress?.subOption ?? {});
                setValue(QuoteField.CARRIER_CONTACT, contact.contact ?? {});
            }
            setDefaultMainContract(
                {
                    clientId: newValue.value,
                    clientTypeId: ClientType.CLIENT_OR_CARRIER,
                    companyId: valueCarrierCompany.value,
                },
                CARRIER_CONTRACT_DEPENDENT_FIELDS
            );
        }
    }, [resetCarrierDependentFields, setDefaultMainContract, valueCarrierCompany]);

    const onDomesticQuoteSwitching = useCallback(() => {
        setValue(QuoteField.SHIPMENT_DETAILS, []);
    }, [setValue]);

    const onChangeUnloadingDate = (newDate: Date) => {
        updatePlannedPaymentDatetime(
            newDate,
            valueClientPaymentDelay,
            valueClientDelayType,
            CLIENT_CONTRACT_DEPENDENT_FIELDS
        );
        updatePlannedPaymentDatetime(
            newDate,
            valueCarrierPaymentDelay,
            valueCarrierDelayType,
            CARRIER_CONTRACT_DEPENDENT_FIELDS
        );
    };

    useEffect(() => {
        if (doInitialUpdates && !model.id && valueClient?.value && valueClientCompany?.value && valueClientContract?.value) {
            setDoInitialUpdates(false);
            onChangeContract({
                newValue: valueClientContract,
                valueType: ContractType.CLIENT,
                setContract,
                valueClient,
            })
        }
    }, [doInitialUpdates, model.id, setContract, valueClient, valueClientCompany, valueClientContract]);

    useEffect(() => {
        if (isFormLoaded && model.id && valueClientCompany?.value && !valueClient?.value) {
            setValue(QuoteField.IS_DOMESTIC_QUOTE, true);
        }
    }, [isFormLoaded, valueClientCompany, valueClient, model.id]);

    useEffect(() => {
        if (model?.id > 0 && direction === Direction.EXTERNAL && valueIsDomesticQuote) {
            navigate(
                generatePath(PATH_QUOTE, {
                    direction: Direction.DOMESTIC,
                    [PATH_VARIABLE_QUOTE_ID]: model?.id,
                })
            );
        }
    }, [model?.id]);

    const quoteStatusFilter = useCallback((option: Option) => {
        return (ediMessageId ?? 0) > 0 ? quoteStatusEDIFilterCallback(option) : quoteStatusFilterCallback(option);
    }, [ediMessageId]);

    const renderClient = () => {
        return (
            <div className='card h-100'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <h5>
                                <strong>Получатель заказа</strong>
                            </h5>
                        </div>
                        <div className='col-lg-6 col-md-6 text-right'>
                            <div className='custom-control custom-switch'>
                                <CheckboxInput
                                    id={QuoteField.IS_DOMESTIC_QUOTE}
                                    label='Внутренний заказ'
                                    className='custom-control-input'
                                    onChange={onDomesticQuoteSwitching}
                                    disabled={valueClientCompany?.value > 0 && valueClient?.value > 0}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            Организация для Заказчика
                            <Companies
                                id={QuoteField.CLIENT_COMPANY}
                                onChange={onChangeClientCompany}
                            />
                        </div>
                        <ExternalOnlyWrapper isDomestic={valueIsDomesticQuote}  className='col-lg-6 col-md-6'>
                            Заказчик&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.CLIENT}
                                entityIdChain={[valueClient?.value]}
                            />
                            <Clients
                                id={QuoteField.CLIENT}
                                clientTypes={[
                                    ClientType.CLIENT,
                                    ClientType.CLIENT_OR_CARRIER,
                                    ClientType.CLIENT_OR_SUPPLIER,
                                ]}
                                companyId={valueClientCompany?.value}
                                onChange={onChangeClient}
                                readOnly={valueRun?.value}
                                nullable
                            />
                        </ExternalOnlyWrapper>
                    </div>
                    <ExternalOnlyWrapper isDomestic={valueIsDomesticQuote} className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Договор&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.CLIENT_CONTRACT}
                                entityIdChain={[
                                    valueClient?.value,
                                    valueClientContract?.value,
                                ]}
                            />
                            <Contracts
                                id={QuoteField.CLIENT_CONTRACT}
                                clientId={valueClient?.value}
                                companyId={valueClientCompany?.value}
                                contractTypes={[ContractType.CLIENT, ContractType.SUPPLIER]}
                                onChange={(newValue) =>
                                    onChangeContract({
                                        newValue,
                                        valueType: ContractType.CLIENT,
                                        setContract,
                                        valueClient,
                                    })
                                }
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Форма оплаты
                            <Options
                                id={QuoteField.CLIENT_PAYMENT_METHOD}
                                dictionaryType={DictionaryType.PAYMENT_METHOD}
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Условия оплаты
                            <Options
                                id={QuoteField.CLIENT_PAYMENT_TYPE}
                                dictionaryType={DictionaryType.PAYMENT_TYPE}
                            />
                        </div>
                    </ExternalOnlyWrapper>
                    <ExternalOnlyWrapper isDomestic={valueIsDomesticQuote} className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Отсрочка платежа
                            <NumberInput
                                id={QuoteField.CLIENT_PAYMENT_DELAY}
                                onChange={(paymentDelay) => {
                                    updatePlannedPaymentDatetime(
                                        valueUnloadingDate,
                                        paymentDelay,
                                        valueClientDelayType,
                                        CLIENT_CONTRACT_DEPENDENT_FIELDS
                                    );
                                }}
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Плановая дата оплаты
                            <DtPicker id={QuoteField.CLIENT_PLANNED_PAYMENT_DATE}/>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Фактическая дата оплаты
                            <DtPicker id={QuoteField.CLIENT_PAYMENT_DATETIME}/>
                        </div>
                    </ExternalOnlyWrapper>
                    <div className='row'>
                        <ExternalOnlyWrapper isDomestic={valueIsDomesticQuote}  className='col-lg-4 col-md-4'>
                            Грузополучатель
                            <ComponentLink
                                type={ComponentLinkType.CLIENT_CONSIGNEE}
                                entityIdChain={[
                                    valueClient?.value,
                                    valueClientConsignee?.value,
                                ]}
                            />
                            <ClientConsignees
                                id={QuoteField.CLIENT_CONSIGNEE}
                                clientId={valueClient?.value > 0 ? valueClient?.value : null}
                                onChange={onChangeClientConsignee}
                            />
                        </ExternalOnlyWrapper>
                        <div className='col-lg-4 col-md-4'>
                            Склад разгрузки
                            <ClientStorages
                                id={QuoteField.UNLOADING_STORAGE}
                                readOnly={valueRun?.value}
                                clientId={addressLookupClientId}
                                showAddress
                                types={valueIsDomesticQuote ? [StorageType.FIELD, StorageType.STORAGE] : undefined}
                                onChange={(option: Option) => {setValue(QuoteField.UNLOADING_ADDRESS, option.subOption)}}
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Дата разгрузки
                            <DtPicker
                                id={QuoteField.UNLOADING_DATE}
                                onChange={onChangeUnloadingDate}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <FieldAddress id={QuoteField.UNLOADING_ADDRESS} label='Адрес разгрузки'/>
                    </div>
                    <div className='row'>
                        <div className='col-6 col-md-6'>
                            Контактное лицо&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.CLIENT_CONTACT}
                                entityIdChain={[
                                    valueClient?.value,
                                    valueClientContact?.value,
                                ]}
                            />
                            <ClientContacts
                                id={QuoteField.CLIENT_CONTACT}
                                clientId={valueIsDomesticQuote ? valueClientCompany?.value : valueClient?.value}
                                readOnly={valueRun?.value}
                            />
                        </div>
                        <ExternalOnlyWrapper isDomestic={valueIsDomesticQuote}  className='col-lg-6 col-md-6'>
                            Номер заказа Заказчика
                            <StringInput id={QuoteField.CLIENT_QUOTE_NUMBER} />
                        </ExternalOnlyWrapper>
                    </div>
                </div>
            </div>
        );
    };

    const renderCarrier = () => {
        return (
            <div className='card h-100'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <h5>
                                <strong>Отправитель заказа</strong>
                            </h5>
                        </div>
                        <div className='col-lg-6 col-md-6 text-right'>
                            <div className='custom-control custom-switch'>
                                <CheckboxInput
                                    id={QuoteField.CARRIER_THIRD_PARTY}
                                    label={'сторонний поставщик'}
                                    className={'custom-control-input'}
                                    onChange={(_) => onChangeCarrierOrCompany(null)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            Организация для Поставщика
                            <Companies
                                id={QuoteField.CARRIER_COMPANY}
                                onChange={onChangeCarrierOrCompany}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Склад
                            <Storages
                                id={QuoteField.LOADING_STORAGE}
                                types={[StorageType.FIELD, StorageType.STORAGE]}
                                onChange={(storage: Option) => {
                                    setValue(QuoteField.LOADING_ADDRESS, storage.subOption);
                                    storages
                                        .contact(storage.value)
                                        .then((contact) => setValue(QuoteField.CARRIER_COMPANY_CONTACT, contact.contact ?? {}))
                                }}
                                readOnly={valueRun?.value}
                                showAddress
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Дата погрузки
                            <DtPicker id={QuoteField.LOADING_DATE} />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Контактное лицо&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.STORAGE_CONTACT}
                                entityIdChain={[valueStorage?.value, valueStorageContact?.value]}
                            />
                            <StorageContacts
                                id={QuoteField.CARRIER_COMPANY_CONTACT}
                                storageId={valueStorage?.value}
                                readOnly={valueRun?.value}
                            />
                        </div>
                    </div>
                    <div className='row mb-2'>
                        <FieldAddress id={QuoteField.LOADING_ADDRESS} label='Адрес погрузки'/>
                    </div>
                </div>
            </div>
        );
    };

    const renderThirdPartyCarrier = () => {
        return (
            <div className='card h-100'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            <h5>
                                <strong>Отправитель заказа</strong>
                            </h5>
                        </div>
                        <div className='col-lg-6 col-md-6 text-right'>
                            <div className='custom-control custom-switch'>
                                <CheckboxInput
                                    id={QuoteField.CARRIER_THIRD_PARTY}
                                    label={'сторонний поставщик'}
                                    className={'custom-control-input'}
                                    onChange={(_) => onChangeCarrierOrCompany(null)}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6'>
                            Организация для Поставщика
                            <Companies
                                id={QuoteField.CARRIER_COMPANY}
                                onChange={onChangeCarrierCompany}
                            />
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            Поставщик&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.CLIENT}
                                entityIdChain={[valueCarrier?.value]}
                            />
                            <Clients
                                id={QuoteField.CARRIER}
                                clientTypes={[ClientType.SUPPLIER, ClientType.CLIENT_OR_SUPPLIER]}
                                companyId={valueCarrierCompany?.value}
                                onChange={onChangeCarrierOrCompany}
                                readOnly={valueRun?.value}
                                nullable
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Договор с Поставщиком
                            <Contracts
                                id={QuoteField.CARRIER_CONTRACT}
                                clientId={valueCarrier?.value}
                                companyId={valueCarrierCompany?.value}
                                contractTypes={[ContractType.CLIENT, ContractType.SUPPLIER]}
                                onChange={(newValue) =>
                                    onChangeContract({
                                        newValue,
                                        valueType: ContractType.SUPPLIER,
                                        setContract,
                                        valueCarrier,
                                    })
                                }
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Форма оплаты
                            <Options
                                id={QuoteField.CARRIER_PAYMENT_METHOD}
                                dictionaryType={DictionaryType.PAYMENT_METHOD}
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Условия оплаты
                            <Options
                                id={QuoteField.CARRIER_PAYMENT_TYPE}
                                dictionaryType={DictionaryType.PAYMENT_TYPE}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Отсрочка платежа
                            <NumberInput
                                id={QuoteField.CARRIER_PAYMENT_DELAY}
                                onChange={(paymentDelay) => {
                                    updatePlannedPaymentDatetime(
                                        valueUnloadingDate,
                                        paymentDelay,
                                        valueCarrierDelayType,
                                        CARRIER_CONTRACT_DEPENDENT_FIELDS
                                    );
                                }}
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Плановая дата оплаты
                            <DtPicker id={QuoteField.CARRIER_PLANNED_PAYMENT_DATE}/>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Фактическая дата оплаты
                            <DtPicker id={QuoteField.CARRIER_PAYMENT_DATETIME}/>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4'>
                            Склад
                            <ClientStorages
                                id={QuoteField.LOADING_STORAGE}
                                clientId={valueCarrier?.value}
                                onChange={(option: Option) => {setValue(QuoteField.LOADING_ADDRESS, option.subOption)}}
                                readOnly={valueRun?.value}
                                showAddress
                            />
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Дата погрузки
                            <DtPicker id={QuoteField.LOADING_DATE} readOnly={valueRun?.value}/>
                        </div>
                        <div className='col-lg-4 col-md-4'>
                            Контактное лицо&nbsp;
                            <ComponentLink
                                type={ComponentLinkType.CLIENT_CONTACT}
                                entityIdChain={[valueCarrier?.value, valueCarrierContact?.value]}
                            />
                            <ClientContacts
                                id={QuoteField.CARRIER_CONTACT}
                                clientId={valueCarrier?.value}
                                readOnly={valueRun?.value}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <FieldAddress id={QuoteField.LOADING_ADDRESS} label='Адрес погрузки'/>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            <div className='row mb-3 no-gutters'>
                <div className='col mr-2'>{renderClient()}</div>
                <div className='col ml-2'>
                    {isCarrierThirdParty ? renderThirdPartyCarrier() : renderCarrier()}
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-12'>
                    <ShipmentDetails id={QuoteField.SHIPMENT_DETAILS}/>
                </div>
            </div>

            <div className='row mb-3 mx-3 align-items-center'>
                <div className='col-lg-5 col-md-4 col-sm-6'>
                    <div className='d-flex align-items-center'>
                        <div>
                            <div>Дата заказа: {util.formatDate(valueCreated ?? new Date())}</div>
                        </div>
                        <div className='col-lg-5'>
                            Статус заказа
                            <Options
                                id={QuoteField.STATUS}
                                dictionaryType={DictionaryType.QUOTE_STATUS}
                                filterCallback={quoteStatusFilter}
                            />
                        </div>
                    </div>
                </div>
                <div className='col-lg-5'/>
                <div className='col-lg-2 col-md-4 col-sm-6'>
                    Менеджер КО
                    <Users
                        id={QuoteField.MANAGER}
                        companyId={(valueClientCompany as Option)?.value}
                        role={Role.COMMERCIAL}
                        nullable
                    />
                </div>
            </div>

            <div className='row mb-3'>
                <div className='col-lg-12'>
                    <div className='card'>
                        <div className='card-body'>
                            <TextInput id={QuoteField.DESCRIPTION}/>
                        </div>
                    </div>
                </div>
            </div>

            <GroupedErrorMessages errors={errors} fieldTranslations={QuoteFieldTranslations} />

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup/>
            </div>
        </div>
    );
};

export default Quote;
