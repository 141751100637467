import L, { PointTuple } from 'leaflet';

const DEFAULT_ICON_SIZE = [30, 30] as PointTuple;
const DEFAULT_ICON_ANCHOR = [15, 15] as PointTuple;
const DEFAULT_TOOLTIP_ANCHOR = [15, 0] as PointTuple;

const PATH_BASE = '/map-icons';
const LOCATION_MARK_PATH = `${PATH_BASE}/location`;

export enum IconType {
    START = 'start',
    MIDDLE = 'middle',
    FINISH = 'finish',
    TRUCK = 'truck',
}

export const getIconType = (index: number, lastIndex: number): IconType => {
    if (index === 0) {
        return IconType.START;
    } else if (index === lastIndex) {
        return IconType.FINISH;
    } else {
        return IconType.MIDDLE;
    }
};

const markerIconPaths = new Map<string, string>([
    [IconType.START, `${LOCATION_MARK_PATH}-start.png`],
    [IconType.FINISH, `${LOCATION_MARK_PATH}-finish.png`],
    [IconType.MIDDLE, `${LOCATION_MARK_PATH}-middle.png`],
    [IconType.TRUCK, `${LOCATION_MARK_PATH}-truck.png`],
]);

export const getMarkerIcon = (icon: IconType) => {
    return new L.Icon({
        iconUrl: process.env.PUBLIC_URL + markerIconPaths.get(icon),
        iconSize: DEFAULT_ICON_SIZE,
        iconAnchor: DEFAULT_ICON_ANCHOR,
        tooltipAnchor: DEFAULT_TOOLTIP_ANCHOR,
    });
};
