import React from 'react';
import AjaxSelect from '../control/AjaxSelect';

import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../control/option';
import productionLines from '../../api/productionLine';

type Props = {
    value: Option;
    onChange?: (option: SingleValue<Option>) => void;
};

export const LineSelect = ({ value, onChange }: Props) => {

    const load = (inputValue: string, callback: (options: Option[]) => void) => {
        productionLines.allOptions({ storageId: -1, filter: inputValue }).then((options) => callback(options));
    };

    const onChangeHandler = async (option: SingleValue<Option>) => {
        if (onChange) {
            onChange(option);
        }
    };

    return (
        <AjaxSelect
            onChange={onChangeHandler as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
            loadOptions={load}
            value={value}
        />
    );
}