import React, {useCallback, useContext, useMemo} from 'react';
import {generatePath} from 'react-router-dom';
import {PATH_CP_COMPANY_ADDRESSES, PATH_VARIABLE_COMPANY_ADDRESS_ID} from '../../../../routerPaths';
import {CompanyFormContext} from '../CompanyForm';
import util from '../../../../common/util';
import { ClientAddressFormInner } from '../../client/addresses/ClientAddress';
import { ClientAddressModel } from '../../../../model/client/address';
import addresses from '../../../../api/addresses';

const CompanyAddress: React.FC = () => {
    const {
        company: {id: companyId},
    } = useContext(CompanyFormContext);
    const redirectUrl = useMemo(() => generatePath(PATH_CP_COMPANY_ADDRESSES, {
        companyId: util.stringOrEmpty(companyId),
    }), [companyId]);
    const onSubmit = useCallback((model: ClientAddressModel) => addresses.saveClientAddress({ ...model, clientId: companyId }), [companyId]);
    const load = useCallback((id: number) => id && companyId ? addresses.getForClient(id, companyId) : Promise.resolve({} as ClientAddressModel), [companyId]);
    return (
        <ClientAddressFormInner {...{onSubmit, load, redirectUrl, pathVariableName: PATH_VARIABLE_COMPANY_ADDRESS_ID, isCompany: true}}/>
    );
};


export default CompanyAddress;
