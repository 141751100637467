
import React, { MouseEvent, ReactNode } from 'react';
import { showToast, ToastType } from '../control/showToast';

type Props = {
    value: string;
    children?: ReactNode;
};

const CopyToClipboardWrapper: React.FC<Props> = ({ value, children }) => {
    const onClick = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        navigator.clipboard.writeText(value);
        showToast('Скопировано в буфер обмена', ToastType.INFO);
    }
    return (
        <div onClick={onClick} title='Скопировать в буфер обмена'>
            {children}
        </div>
    );
};

export default CopyToClipboardWrapper;
