import React from 'react';
import { EdiPartyField, EdiPartyListModel } from '../../../model/edi/ediPartyListModel';
import { Config } from '../../table/config';
import edi from '../../../api/edi';
import { DwTable } from '../../table/DwTable';

const config = Config.builder<EdiPartyListModel>()
    .column((builder) => builder.id())
    .column((builder) => builder.text('GLN', EdiPartyField.GLN))
    .column((builder) => builder.text('Название', EdiPartyField.NAME))
    .column((builder) => builder.text('ИНН', EdiPartyField.INN))
    .column((builder) => builder.text('КПП', EdiPartyField.KPP))
    .column((builder) => builder
        .boolean(EdiPartyField.ORDRSP.toUpperCase(), EdiPartyField.ORDRSP)
        .editable()
        .onChangeRow(async (value, _, row) => edi.setPartyChainConfig(row.id, value))
    )
    .load((ctx) => edi.listParties(ctx.state.request))
    .id('edi_party')
    .withAdd(false)
    .editable(true)
    .onClick(() => null)
    .build();

const EdiPartyList: React.FC = () => {
    return (
        <div className='edi-party d-flex'>
            <DwTable header='Торговые сети' config={config} />
        </div>
    );
};

export default EdiPartyList;
