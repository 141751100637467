import React from 'react';
import FormSelect from './FormSelect';

import type { Option } from '../control/option';
import users from '../../api/users';

type Props = {
    id: string;
    isMulti?: boolean;
};

const Roles: React.FC<Props> = (props) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        users.roles({ filter: inputValue })
            .then((options) => callback(options));
    };
    return <FormSelect {...{...props, loadOptions}}/>;
};

export default Roles;
