import React, { ReactElement, useContext, useMemo } from 'react';
import { TableContext } from './DwTable';
import { Filter } from './filter';
import { ActionType } from './action';
import { FormConfig } from '../form/formConfig';
import { FieldType } from '../form/fieldType';
import { DwForm, useDwSubmit } from '../form/DwForm';
import { PROMISE_OK } from '../../common/const';
import CheckboxInput from '../form/CheckboxInput';

type Props<T> = {
    type: FieldType;
    fields: string[];
    model: T;
    close: () => void;
    children: React.ReactElement[] | React.ReactElement;
    withEmptyValuesFilter?: boolean;
};

export const FilterFormInner: <T>(props: Props<T>) => ReactElement<Props<T>> = ({ children, close, withEmptyValuesFilter = false }) => {
    const dwSubmit = useDwSubmit();
    return (
        <>
            <span>{children}</span>
            {withEmptyValuesFilter &&
                <div className='custom-control custom-switch ml-3 p-0'>
                    <CheckboxInput
                        id={'empty'}
                        label={'Пустое значение'}
                    />
                </div>
            }
            <div className='text-center buttons'>
                <button onClick={dwSubmit} className='btn btn-sm btn-primary pull-right'>
                    Ок
                </button>
                &nbsp;
                <button
                    onClick={() => close()}
                    className='btn btn-sm btn-secondary pull-right'
                >
                    Отмена
                </button>
            </div>
        </>
    );
}

export const FilterForm: <T>(props: Props<T>) => ReactElement<Props<T>> = (props) => {
    const context = useContext(TableContext);
    const { model, fields: fieldsBase, type, withEmptyValuesFilter = false, close } = props;
    const fields = useMemo(() => withEmptyValuesFilter ? [...fieldsBase, 'empty'] : fieldsBase, [fieldsBase, withEmptyValuesFilter]);

    const onSubmit = (filter: Filter) =>
        new Promise((resolve) => {
            if (Filter.isNotEmpty(filter)) {
                context.dispatch({
                    type: ActionType.ADD_FILTER,
                    filter: filter,
                });
                close();
            }
            resolve(PROMISE_OK);
        });

    const config = FormConfig.builder<any>()
        .submit(onSubmit)
        .initialModelGetter((c) => Promise.resolve(model));
    fields.forEach((id) => {
        config.field(
            id,
            type,
            (m) => m[id],
            (m, v) => (m[id] = v)
        );
    });

    return (
        <DwForm config={config.build()}>
            <FilterFormInner {...props} />
        </DwForm>
    );
};
