import React, { useContext, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import contacts from '../../../../api/contacts';
import { mainContactUniqueValidator } from '../../../../common/validation/contactValidators';
import { emailValidator } from '../../../../common/validation/simpleValidators';
import { ContactModel } from '../../../../model/contact/contactModel';
import { PATH_CP_CLIENT_CONTACTS, PATH_VARIABLE_CLIENT_CONTACT_ID } from '../../../../routerPaths';
import CheckboxInput from '../../../form/CheckboxInput';
import ContextError from '../../../form/ContextError';
import { DwForm } from '../../../form/DwForm';
import StringInput from '../../../form/StringInput';
import { FormConfig } from '../../../form/formConfig';
import { ClientFormContext } from '../ClientForm';
import * as ClientContactFields from './clientContactFields';
import util from '../../../../common/util';
import { useRefreshContextAction } from '../../../table/DwTable';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';

const useFormConfig = (refresh: () => void, clientId: number) =>
    useMemo(
        () =>
            FormConfig.builder<ContactModel>()
                .number(
                    ClientContactFields.FIELD_ID,
                    (m) => m.id,
                    (m, v) => (m.id = v)
                )
                .requiredText(
                    ClientContactFields.FIELD_FIO,
                    (m) => m.fio,
                    (m, v) => (m.fio = v)
                )
                .text(
                    ClientContactFields.FIELD_EMAIL,
                    (m) => m.email,
                    (m, v) => (m.email = v),
                    (m) => emailValidator(m.email)
                )
                .text(
                    ClientContactFields.FIELD_PHONE,
                    (m) => m.phone,
                    (m, v) => (m.phone = v)
                )
                .boolean(
                    ClientContactFields.FIELD_MAIN,
                    (m) => m.main,
                    (m, v) => (m.main = v),
                    (m) => mainContactUniqueValidator(clientId, m)
                )
                .load((id) =>
                    id && clientId
                        ? contacts.getForClient(id, clientId)
                        : Promise.resolve({} as ContactModel)
                )
                .submit((model) => {
                    return contacts.saveForClient(model, clientId);
                })
                .idPathVariableName(PATH_VARIABLE_CLIENT_CONTACT_ID)
                .redirectUrl(
                    generatePath(PATH_CP_CLIENT_CONTACTS, {
                        clientId: util.stringOrEmpty(clientId),
                    })
                )
                .afterSubmit((_) => refresh())
                .build(),
        [refresh, clientId]
    );

const ClientContact: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const { refresh } = useRefreshContextAction();
    const formConfig = useFormConfig(refresh, clientId);
    return (
        <DwForm config={formConfig}>
            <ClientContactLayout />
            <ContextError />
        </DwForm>
    );
};

const ClientContactLayout: React.FC = () => {
    return (
        <div>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row align-items-center'>
                        <div className='col-lg-4'>
                            ФИО
                            <StringInput id={ClientContactFields.FIELD_FIO} />
                        </div>
                        <div className='col-lg-3'>
                            Email
                            <StringInput id={ClientContactFields.FIELD_EMAIL} />
                        </div>
                        <div className='col-lg-3'>
                            Телефон
                            <StringInput id={ClientContactFields.FIELD_PHONE} />
                        </div>
                        <div className='col-lg-2'>
                            <CheckboxInput
                                id={ClientContactFields.FIELD_MAIN}
                                label={'Основной'}
                                className='position-relative'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};

export default ClientContact;
