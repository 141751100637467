import {FieldConfig} from "./fieldConfig";

export enum ActionType {
    MODEL,
    SET,
    ERROR,
    ERRORS,
    FIELD_ERROR,
}

export type Action<T> = {
    id?: string;
    type: ActionType;
    model?: T;
    errors?: Map<string, string>;
    value?: any;
    config?: FieldConfig<T, any>;
    setter?: (model: T, value: any) => void;
};
