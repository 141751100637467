import { requiredValidator } from './simpleValidators';
import { PROMISE_OK } from '../const';
import { AddressModel } from '../../model/address';

type RegistryInfoModel = {
    inn: string;
    kpp: string;
    ogrn: string;
};

export const addressValidator = <T extends Partial<AddressModel>>(model: T): Promise<string> => {
    const { city, latitude, longitude } = model ?? {};
    const isValid = city || (latitude && longitude);
    return Promise.resolve(isValid ? PROMISE_OK : 'Укажите город/нас.пункт или координаты основного адреса');
};

export const latitudeValidator = (latitude: number | null | undefined): Promise<string> => {
    const isValid = !latitude || (latitude <= 90 && latitude >= -90);
    return Promise.resolve(isValid ? PROMISE_OK : 'некорректное значение широты');
};

export const longitudeValidator = (longitude: number | null | undefined): Promise<string> => {
    const isValid = !longitude || (longitude <= 180 && longitude >= -180);
    return Promise.resolve(isValid ? PROMISE_OK : 'некорректное значение долготы');
};

export const ogrnValidator = <T extends RegistryInfoModel>(
    model: T,
    isAlien?: boolean
): Promise<string> => {
    const { ogrn } = model;

    if (isAlien) {
        if (model?.ogrn) {
            const alienOrgnRegex = RegExp(/^[a-zA-Z0-9А-Яа-яёЁ]{1,15}$/);
            const isAlienOgrnValid = alienOrgnRegex.test(ogrn);

            return Promise.resolve(
                isAlienOgrnValid
                    ? PROMISE_OK
                    : 'ОГРН может содержать буквы и цифры длиной до 15 символов'
            );
        } else {
            return Promise.resolve(PROMISE_OK);
        }
    }

    if (!ogrn) {
        return requiredValidator(ogrn);
    }

    const isRusOgrnValid = !ogrn || ogrn.match(/^(?:\d{13}|\d{15})$/);
    return Promise.resolve(
        isRusOgrnValid ? PROMISE_OK : 'ОГРН должен состоять либо из 13 (ЮЛ), либо из 15 (ИП) цифр'
    );
};

export const kppValidator = <T extends RegistryInfoModel>(model: T): Promise<string> => {
    const { kpp, ogrn } = model;
    const isValid = !kpp || kpp.match(/^\d{9}$/);
    const isEmptyKPP = !kpp;

    if (ogrn?.length === 15) {
        return Promise.resolve(isEmptyKPP ? PROMISE_OK : 'у ИП не существует КПП');
    } else {
        return Promise.resolve(isValid ? PROMISE_OK : 'КПП должен состоять из 9 цифр');
    }
};

export const innValidator = <T extends RegistryInfoModel>(
    model: T,
    isAlien?: boolean
): Promise<string> => {
    const { inn } = model;

    if (!inn) {
        return requiredValidator(inn);
    }

    if (isAlien) {
        // valid inn: chars + digits 30 length
        const alienInnRegex = RegExp(/^[a-zA-Z0-9А-Яа-яёЁ]{1,20}$/);
        const isAlienInnValid = alienInnRegex.test(inn);

        return Promise.resolve(
            isAlienInnValid ? PROMISE_OK : 'ИНН может содержать буквы и цифры длиной до 20 символов'
        );
    }

    const isRusInnValid = inn?.match(/^\d+$/) && (inn.length === 10 || inn.length === 12);

    return Promise.resolve(
        isRusInnValid ? PROMISE_OK : 'ИНН должен состоять либо из 10 (ЮЛ), либо из 12 (ИП) цифр'
    );
};

export const individualInnValidator = <T extends { inn: string }>(model: T, required?: boolean): Promise<string> => {
    const { inn } = model;
    if (required) {
        if (!inn) {
            return requiredValidator(inn);
        }
    } else {
        if (!inn) {
            return Promise.resolve(PROMISE_OK);
        }
    }
    const isValid = inn?.match(/^\d+$/) && inn.length === 12;
    return Promise.resolve(
        isValid ? PROMISE_OK : 'ИНН физ. лица должен состоять из 12 цифр'
    );
};

export const passportValidator = <T extends { passportNumber: string }>(model: T, required?: boolean): Promise<string> => {
    const { passportNumber } = model;
    if (required) {
        if (!passportNumber) {
            return requiredValidator(passportNumber);
        }
    } else {
        if (!passportNumber) {
            return Promise.resolve(PROMISE_OK);
        }
    }
    const isValid = passportNumber?.match(/^\d+$/) && passportNumber.length === 10;
    return Promise.resolve(
        isValid ? PROMISE_OK : 'Номер паспорта РФ должен состоять из 10 цифр'
    );
};

export const phoneValidator = <T extends { phone: string }>(
    model: T,
    required?: boolean,
    isAlien?: boolean
): Promise<string> => {
    const { phone } = model;

    if (required && !phone) {
        return requiredValidator(phone);
    }

    if (!required && !phone) {
        return Promise.resolve(PROMISE_OK);
    }

    if (isAlien) {
        // valid numbers: 15 digits only with or without '+' at the beginning
        const alienMobileNumberRegex = RegExp(/^\+?\d{1,15}$/);
        const isAlienMobileNumberValid = alienMobileNumberRegex.test(phone);

        return Promise.resolve(
            isAlienMobileNumberValid
                ? PROMISE_OK
                : 'Номер телефона должен содержать не более 15 цифр и может начинаться с +'
        );
    }

    // valid numbers: +71234567890; 71234567890; 81234567890
    const rusMobileNumberRegex = RegExp(/^(\+7|7|8)?[\s\-]?(9\d{2}|8\d{2})\d{7}$/);
    const isRusMobileNumberValid = rusMobileNumberRegex.test(phone);

    return Promise.resolve(
        isRusMobileNumberValid
            ? PROMISE_OK
            : 'Допустимые форматы номера: +71234567890, 71234567890, 81234567890'
    );
};
