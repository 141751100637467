import { deleteRequest, get, post } from './rest';
import type { ClientAccountRequest, AccountModel } from '../model/account';
import type { Option } from '../component/control/option';
import { ListRequest } from '../component/table/listRequest';
import { PagedResponse } from './pagedResponse';
import { ApiResponse } from './apiResponse';

const PATH_BASE = 'accounts';
const PATH_BASE_CLIENT = 'accounts/client';
const PATH_BASE_COMPANY = 'accounts/company';

const accounts = {
    delete: (id: number) => deleteRequest<ApiResponse>(`${PATH_BASE}/${id}`),
    getSuggestion: (model: AccountModel) => post<AccountModel>(`${PATH_BASE}/suggestion`, model),
    isNumberUnique: (id: number, number: string) =>
        post<ApiResponse>(`${PATH_BASE}/number/unique`, { id, number }),

    clientOptions: (request: ClientAccountRequest) =>
        post<Option[]>(`${PATH_BASE}/options/client`, request),
    listForClient: (model: ListRequest, clientId: number) =>
        post<PagedResponse<AccountModel>>(`${PATH_BASE_CLIENT}/list?clientId=${clientId}`, model),
    getForClient: (id: number, clientId: number) =>
        get<AccountModel>(`${PATH_BASE_CLIENT}/${id}?clientId=${clientId}`),
    saveForClient: (model: AccountModel, clientId: number) =>
        post<ApiResponse>(`${PATH_BASE_CLIENT}?clientId=${clientId}`, model),

    listForCompany: (model: ListRequest, companyId: number) =>
        post<PagedResponse<AccountModel>>(`${PATH_BASE_COMPANY}/list?companyId=${companyId}`, model),
    getForCompany: (id: number, companyId: number) =>
        get<AccountModel>(`${PATH_BASE_COMPANY}/${id}?companyId=${companyId}`),
    saveForCompany: (model: AccountModel, companyId: number) =>
        post<ApiResponse>(`${PATH_BASE_COMPANY}?companyId=${companyId}`, model),

};

export default accounts;
