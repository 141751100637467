import React from 'react';
import settings from '../../../api/settings';
import ContextError from '../../form/ContextError';
import {DwForm} from '../../form/DwForm';
import {FormConfig} from '../../form/formConfig';
import StringInput from '../../form/StringInput';
import {ToastMessage} from "./const";
import {showSuccessToast} from "../../control/showToast";
import DtPicker from '../../form/DtPicker';
import util from '../../../common/util';
import {SaveButton} from '../../form/SaveButtonGroup';

type EdiModel = {
    login: string;
    password: string;
    apiKey: string;
    lastEventId: string;
    basePath: string;
    partyInn: string;
    cutoffDate: Date;
}

const EDI_LOGIN = 'edi.login';
const EDI_PASSWORD = 'edi.password';
const EDI_API_KEY = 'edi.apiKey';
const EDI_LAST_EVENT_ID = 'edi.lastEventId';
const EDI_BASE_PATH = 'edi.basePath';
const EDI_PARTY_INN = 'edi.partyInn';
const EDI_CUTOFF_DATE = 'edi.cutoffDate';
const MAX_LENGTH = 64;

const save = (model: EdiModel) => {
    return settings.update([
        {key: EDI_LOGIN, value: model.login},
        {key: EDI_PASSWORD, value: model.password},
        {key: EDI_API_KEY, value: model.apiKey},
        {key: EDI_LAST_EVENT_ID, value: model.lastEventId},
        {key: EDI_BASE_PATH, value: model.basePath},
        {key: EDI_PARTY_INN, value: model.partyInn},
        {key: EDI_CUTOFF_DATE, value: model.cutoffDate ? util.toISODateTimeWithoutTimezone(model.cutoffDate) : ''},
    ]);
};

const load = async (): Promise<EdiModel> => {
    const data = await settings.get([EDI_LOGIN, EDI_PASSWORD, EDI_API_KEY, EDI_LAST_EVENT_ID, EDI_BASE_PATH, EDI_PARTY_INN, EDI_CUTOFF_DATE]);
    return {
        login: data.get(EDI_LOGIN),
        password: data.get(EDI_PASSWORD),
        apiKey: data.get(EDI_API_KEY),
        lastEventId: data.get(EDI_LAST_EVENT_ID),
        basePath: data.get(EDI_BASE_PATH),
        partyInn: data.get(EDI_PARTY_INN),
        cutoffDate: data.get(EDI_CUTOFF_DATE) ? new Date(data.get(EDI_CUTOFF_DATE)!) : null,
    } as EdiModel;
}

const formConfig = FormConfig.builder<EdiModel>()
    .requiredText(
        'login',
        (model) => model.login,
        (model, value) => (model.login = value)
    )
    .requiredText(
        'password',
        (model) => model.password,
        (model, value) => (model.password = value)
    )
    .requiredText(
        'apiKey',
        (model) => model.apiKey,
        (model, value) => (model.apiKey = value)
    )
    .text(
        'lastEventId',
        (model) => model.lastEventId,
        (model, value) => (model.lastEventId = value)
    )
    .text(
        'basePath',
        (model) => model.basePath,
        (model, value) => (model.basePath = value)
    )
    .text(
        'partyInn',
        (model) => model.partyInn,
        (model, value) => (model.partyInn = value)
    )
    .dateTime(
        'cutoffDate',
        (model) => model.cutoffDate,
        (model, value) => (model.cutoffDate = value)
    )
    .load((_) => load())
    .submit(save)
    .afterSubmit(() => showSuccessToast(ToastMessage.SETTINGS_SAVED))
    .idPathVariableName('')
    .build();

const SettingsFormInner: React.FC = () => {
    return (
        <>
            <StringInput id='basePath' placeholder='адрес сервера' maxLength={255} />
            <StringInput id='login' placeholder='логин' maxLength={MAX_LENGTH} />
            <StringInput id='password' placeholder='пароль' maxLength={MAX_LENGTH} />
            <StringInput id='apiKey' placeholder='api-ключ' maxLength={MAX_LENGTH} />
            <div>ИНН контрагента, для которого выдан ключ</div>
            <StringInput id='partyInn' placeholder='ИНН' maxLength={MAX_LENGTH} />
            <div>Дата, начиная с которой загружать события</div>
            <div>(если отсутсвует параметр "ID последнего загруженного события", то загружаются только события после выбраной даты - для уменьшения объема данных)</div>
            <DtPicker id='cutoffDate' showTimeSelect={false} />
            <div>ID последнего загруженного события</div>
            <div>(оставить пустое значение для выборки всех записей из EDI при следующем запуске)</div>
            <StringInput id='lastEventId' placeholder='ID события' maxLength={MAX_LENGTH} />
            <ContextError />

            <div className='text-center'>
                <SaveButton />
            </div>
        </>
    )
}

const SettingsForm: React.FC = () => {
    return (
        <div className='form card edi-settings w-50 mb-3'>
            <div className='card-header d-flex flex-row'>
                <div className='align-self-center'>
                    Интеграция с сервисом EDI
                </div>
            </div>
            <div className='card-body'>
                <DwForm config={formConfig}>
                    <SettingsFormInner />
                </DwForm>
            </div>
        </div>
    )
};
export default SettingsForm;
