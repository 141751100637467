const fileUtil = {
    prepareFormData: (file: File) => {
        const data = new FormData();
        data.append('file', file);
        return data;
    },

    blob2File: (blob: Blob, fileName: string) => {
        const href = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    },
};

export default fileUtil;
