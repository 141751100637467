import React from 'react';
import FormDtPicker from './FormDtPicker';

type Props = {
    id: string;
    readOnly?: boolean;
    placeholder?: string;
    showTimeSelect?: boolean;
    onChange?: (newDate: Date) => void;
};

const DtPicker: React.FC<Props> = ({
    id,
    readOnly = false,
    placeholder = '',
    showTimeSelect = false,
    onChange,
}) => (
    <FormDtPicker
        id={id}
        readOnly={readOnly}
        placeholder={placeholder}
        showTimeSelect={showTimeSelect}
        onChange={onChange}
    />
);

export default DtPicker;
