import React from 'react';
import { PATH_CP_STORAGES, PATH_VARIABLE_STORAGE_ID } from '../../../routerPaths';
import storages from '../../../api/storages';
import { ClientAddressFormInner } from '../client/addresses/ClientAddress';


const Storage: React.FC = () => {
    return (
        <ClientAddressFormInner {...{onSubmit: storages.save, load: storages.get, redirectUrl: PATH_CP_STORAGES, pathVariableName: PATH_VARIABLE_STORAGE_ID, isCompany: true}}/>
    );
};

export default Storage;
