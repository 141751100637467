import React from 'react';
import ListInput from './base/ListInput';

type Props = {
    id: string;
    placeholder?: string;
};

const ListStringInput: React.FC<Props> = ({ id, placeholder }) => (
    <ListInput id={id} inputType={'text'} placeholder={placeholder} />
);

export default ListStringInput;
