import React from 'react';

import type { Option } from '../control/option';
import type { SingleValue } from 'react-select/dist/declarations/src/types';
import Clients from './Clients';
import { ClientType } from '../../model/client/clientModel';

type Props = {
    id: string;
    onChange?: (newValue: SingleValue<Option>) => void;
};

const Companies: React.FC<Props> = (props) => <Clients {...props} clientTypes={[ClientType.ORGANIZATION]} />;

export default Companies;
