import React, { ReactElement, useContext, useMemo } from 'react';
import clsx from 'clsx';
import { Column } from './column';
import { useDrag, useDrop } from 'react-dnd';
import { TableContext } from './DwTable';
import { ActionType } from './action';
import { Config } from './config';

type Props = {
    config: Config<any>;
    column: Column<any, any>;
    hover: string;
    setHover: (hover: string) => void;
};

export const SelectorItem: (props: Props) => ReactElement<Props> = (props) => {
    const { column, hover, setHover } = props;
    const context = useContext(TableContext);
    const hidden = useMemo(
        () => context.state.request.hidden.includes(column.field),
        [context.state.request.hidden, column.field]
    );
    const [{ opacity }, drag, preview] = useDrag(() => ({
        type: 'column',
        item: column,
        collect: (monitor) => ({
            opacity: monitor.isDragging() ? 0.3 : 1,
        }),
    }));

    const [, drop] = useDrop(() => ({
        accept: 'column',
        drop: (item) => onSort(item as Column<any, any>),
        hover: () => setHover(column.field),
    }));

    const onSort = (item: Column<any, any>) => {
        // drop item ${item.field} before ${column.field}
        setHover('');
        const config = props.config;
        const ordered = config.orderedColumns().filter((it) => it.field !== item.field);
        ordered.splice(ordered.indexOf(column), 0, item);
        const indexes = ordered.map((it) => config.columns.indexOf(it));
        context.dispatch({
            type: ActionType.REORDER,
            order: indexes,
        });
    };

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();
        context.dispatch({
            type: ActionType.HIDE,
            column,
        });
    };

    return (
        <div ref={drop}>
            <div
                ref={preview}
                className={clsx('dropdown-item', { hover: hover === column.field })}
                onClick={onClick}
                style={{ opacity }}
            >
                <i className={hidden ? 'hidden' : 'fas fa-fw fa-check'} />
                <span ref={drag}>{column.label}</span>
            </div>
        </div>
    );
};
