import React, { useContext, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import clients from '../../../../api/clients';
import util from '../../../../common/util';
import { ClientType } from '../../../../model/client/clientModel';
import {
    PATH_CP_CLIENT_CONSIGNEES,
    PATH_VARIABLE_CLIENT_CONSIGNEE_ID,
    PATH_VARIABLE_CLIENT_ID,
} from '../../../../routerPaths';
import { Option } from '../../../control/option';
import Consignees from '../../../form/Consignees';
import ContextError from '../../../form/ContextError';
import { DwForm } from '../../../form/DwForm';
import { FormConfig } from '../../../form/formConfig';
import { useRefreshContextAction } from '../../../table/DwTable';
import { ClientFormContext } from '../ClientForm';
import { ClientConsigneeField } from './clientConsigneeField';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';

const useFormConfig = (
    refresh: () => void,
    clientId: number,
    prevConsigneeId: number
) =>
    useMemo(
        () =>
            FormConfig.builder<{ consignee: Option }>()
                .requiredOption(
                    ClientConsigneeField.CONSIGNEE,
                    (m) => m.consignee,
                    (m, v) => (m.consignee = v)
                )
                .load((consigneeId) =>
                    consigneeId && clientId
                        ? clients.getClientConsignee(clientId, consigneeId)
                        : Promise.resolve({} as { consignee: Option })
                )
                .submit((model) =>
                    clients.saveClientConsignee({ ...model, clientId }, prevConsigneeId)
                )
                .idPathVariableName(PATH_VARIABLE_CLIENT_CONSIGNEE_ID)
                .redirectUrl(
                    generatePath(PATH_CP_CLIENT_CONSIGNEES, {
                        clientId: util.stringOrEmpty(clientId),
                    })
                )
                .afterSubmit(() => refresh())
                .build(),
        [clientId, prevConsigneeId, refresh]
    );

const ClientConsignee: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const { refresh } = useRefreshContextAction();
    const prevConsigneeId = util.toNumber(useParams()[PATH_VARIABLE_CLIENT_CONSIGNEE_ID]!) ?? -1;
    const formConfig = useFormConfig(refresh, clientId, prevConsigneeId);

    return (
        <DwForm config={formConfig}>
            <ClientConsigneeLayout />
            <ContextError />
        </DwForm>
    );
};

const ClientConsigneeLayout: React.FC = () => {
    const clientId = util.toNumber(useParams()[PATH_VARIABLE_CLIENT_ID]!) ?? -1;

    return (
        <>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            Грузополучатель
                            <Consignees
                                id={ClientConsigneeField.CONSIGNEE}
                                clientId={clientId}
                                clientTypes={[ClientType.CLIENT]}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </>
    );
};

export default ClientConsignee;
