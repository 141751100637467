import React from 'react';
import { DictionaryType, Option } from '../control/option';
import FormSelect from './FormSelect';
import options from '../../api/options';
import { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';

type Props = {
    id: string;
    dictionaryType: DictionaryType;
    readonly?: boolean;
    filterCallback?: (option: Option) => boolean;
    onChange?: (newValue: SingleValue<Option> | MultiValue<Option>) => void;
};

const Options: React.FC<Props> = ({ id, dictionaryType, readonly, filterCallback, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        options
            .options({
                filter: inputValue,
                dictionaryType: dictionaryType,
            })
            .then((options) => (filterCallback ? options.filter(filterCallback) : options))
            .then((options) => callback(options));
    };
    return <FormSelect id={id} loadOptions={loadOptions} onChange={onChange} readOnly={readonly} />;
};

export default Options;
