import React from 'react';
import { useDwCancel } from './DwForm';

const CancelButton: React.FC = () => {
    const dwCancel = useDwCancel();
    return (
        <button className='btn btn-sm btn-secondary' onClick={dwCancel}>
            Выйти
        </button>
    );
};

export default CancelButton;