import React from 'react';
import { DwTable } from '../../table/DwTable';
import { Config } from '../../table/config';
import { ColumnType } from '../../table/columnType';
import clients from '../../../api/clients';
import { ClientField } from './clientField';
import { generatePath } from 'react-router-dom';
import { PATH_CP_CLIENT } from '../../../routerPaths';

import type { ClientListModel } from '../../../model/client/clientListModel';

const config = Config.builder<ClientListModel>()
    .column((builder) => builder.id())
    .column((builder) => builder.text('Полное наименование', ClientField.FULL_NAME))
    .column((builder) => builder.text('Краткое наименование', ClientField.NAME))
    .column((builder) =>
        builder
            .label('Тип')
            .field(ClientField.TYPE)
            .type(ColumnType.CLIENT_TYPE)
            .sortable(false)
            .cls('type')
    )
    .column((builder) =>
        builder
            .label('Вид')
            .field(ClientField.KIND)
            .type(ColumnType.CLIENT_KIND)
            .sortable(false)
            .cls('kind')
    )
    .column((builder) => builder.text('Должность руководителя', ClientField.HEAD_POSITION))
    .column((builder) => builder.text('ФИО руководителя', ClientField.HEAD))
    .column((builder) => builder.text('В лице', ClientField.HEAD_GENITIVE))
    .column((builder) => builder.text('Действует на основании', ClientField.BASIS).sortable(false))
    .column((builder) => builder.text('ИНН', ClientField.INN).cls('inn'))
    .column((builder) =>
        builder.boolean('ИНН не РФ', ClientField.ALIEN_INN).sortable(false).cls('boolean')
    )
    .column((builder) => builder.text('КПП', ClientField.KPP).cls('kpp'))
    .column((builder) => builder.text('ОГРН', ClientField.OGRN).cls('ogrn'))
    .column((builder) =>
        builder.text('Почтовый адрес', ClientField.POSTAL_ADDRESS).sortable(false)
    )
    .column((builder) =>
        builder.text('Фактический адрес', ClientField.ACTUAL_ADDRESS).sortable(false)
    )
    .column((builder) =>
        builder.text('Юридический адрес', ClientField.ADDRESS).sortable(false)
    )
    .column((builder) =>
        builder.text('Телефон', ClientField.PHONE).sortable(false).cls('phone')
    )
    .column((builder) => builder.text('E-mail', ClientField.EMAIL).sortable(false))
    .column((builder) => builder.text('Сайт компании', ClientField.WEB).sortable(false))
    .column((builder) =>
        builder
            .label('Менеджер ОЛ')
            .field(ClientField.LOGISTIC_MANAGER)
            .type(ColumnType.LOGISTIC_MANAGER)
    )
    .column((builder) =>
        builder
            .label('Менеджер КО')
            .field(ClientField.COMMERCIAL_MANAGER)
            .type(ColumnType.COMMERCIAL_MANAGER)
    )
    .column((builder) => builder.text('Рейтинг', ClientField.ATI_RATING))
    .column((builder) => builder.text('Код АТИ', ClientField.ATI_CODE).sortable(false))
    .column((builder) =>
        builder
            .label('Статус')
            .field(ClientField.STATUS)
            .type(ColumnType.CLIENT_STATUS)
            .sortable(false)
            .cls('status')
    )
    .column((builder) =>
        builder.boolean('Контрагент не РФ', ClientField.ALIEN).sortable(false).cls('boolean')
    )
    .column((builder) =>
        builder
            .boolean('Аккредитован', ClientField.ACCREDITED)
            .sortable(false)
            .cls('boolean')
    )
    .column((builder) =>
        builder.boolean('Активен', ClientField.ACTIVE).sortable(false).cls('boolean')
    )
    .column((builder) =>
        builder
            .boolean('Подлежит валютному контролю', ClientField.CURRENCY_CONTROL)
            .sortable(false)
            .cls('boolean')
    )
    .column((builder) => builder.company('Организация', ClientField.COMPANY).sortable(false))
    .column((builder) => builder.description('Заметки', ClientField.COMMENT).sortable(false))
    .formUrl(generatePath(PATH_CP_CLIENT, { clientId: '' }))
    .load((ctx) => clients.list(ctx.state.request))
    .id('clients')
    .defaultHidden([
        ClientField.ACCREDITED,
        ClientField.ACTUAL_ADDRESS,
        ClientField.ADDRESS,
        ClientField.ALIEN,
        ClientField.ALIEN_INN,
        ClientField.ATI_CODE,
        ClientField.ATI_RATING,
        ClientField.COMMENT,
        ClientField.COMMERCIAL_MANAGER,
        ClientField.COMPANY,
        ClientField.CURRENCY_CONTROL,
        ClientField.EMAIL,
        ClientField.FULL_NAME,
        ClientField.HEAD,
        ClientField.HEAD_GENITIVE,
        ClientField.HEAD_POSITION,
        ClientField.BASIS,
        ClientField.KIND,
        ClientField.LOGISTIC_MANAGER,
        ClientField.OGRN,
        ClientField.PHONE,
        ClientField.POSTAL_ADDRESS,
        ClientField.STATUS,
        ClientField.WEB,
    ])
    .build();

const Clients: React.FC = () => {
    return (
        <div className='clients d-flex'>
            <DwTable header='Контрагенты' config={config} />
        </div>
    );
};

export default Clients;
