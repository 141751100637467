import React, { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import './formTabs.scss';

export type Tab = {
    name: string;
    path: string;
    disabled?: boolean;
    end?: boolean;
};

type TabsProp = {
    items: Tab[];
};

const DwFormTab: (p: Tab) => ReactElement<Tab> = (props) => {
    const disabled = !!props.disabled;
    const onClickSkip = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    return (
        <li className='nav-item'>
            <NavLink
                to={props.path}
                className={({ isActive }) =>
                    `nav-link ${isActive ? 'active' : ''} ${disabled ? 'disabled' : ''}`
                }
                {...(disabled ? { onClick: onClickSkip } : {})}
                end={props.end}
            >
                {props.name}
            </NavLink>
        </li>
    );
};

const DwFormTabs: (p: TabsProp) => ReactElement<TabsProp> = (props) => {
    return (
        <ul className='dw-form-tabs nav nav-pills'>
            {props.items?.map((item) => (
                <DwFormTab key={`${item.path}`} {...item} />
            ))}
        </ul>
    );
};

export default DwFormTabs;
