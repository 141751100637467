import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { DEV_SERVER_PORT, DEV_UI_HOST, RestUrlPrefix } from '../common/const';
import { Client as StompClient, StompSubscription } from '@stomp/stompjs';


const PROTOCOLS = {
    HTTPS: 'https:',
    WS: 'ws:',
    WSS: 'wss:'
};

const isDevMode = () => window.location.host === DEV_UI_HOST;

const formatUrl = () => {
    const protocol = window.location.protocol.startsWith(PROTOCOLS.HTTPS) ? PROTOCOLS.WSS : PROTOCOLS.WS;
    const port = isDevMode() ? DEV_SERVER_PORT : window.location.port;
    return `${protocol}//${window.location.hostname}:${port}${RestUrlPrefix.WS}`;
}

export interface WsContextType {
    subscribe: <T>(destination: string, callback: (msg: T) => void) => StompSubscription | undefined;
}

const WsContext = createContext<WsContextType>({} as WsContextType);

interface StompWebSocketProviderProps {
    children: React.ReactNode;
}

const StompWebSocketProvider: React.FC<StompWebSocketProviderProps> = ({ children }) => {
    const [stompClient, setStompClient] = useState<StompClient | null>(null);

    useEffect(() => {
        const client = new StompClient({
            brokerURL: formatUrl(),
            reconnectDelay: 5000,
            heartbeatIncoming: 4000,
            heartbeatOutgoing: 4000,
            connectionTimeout: 4000,
        });

        client.onConnect = () => {
            setStompClient(client);
        };

        client.onWebSocketError = (error) => {
            console.error('Error with websocket', error);
        };

        client.onStompError = (frame) => {
            console.error('Broker reported error: ' + frame.headers['message']);
            console.error('Additional details: ' + frame.body);
        };

        client.activate();
        return () => { client.deactivate(); };
    }, []);

    const subscribe = useCallback(<T,>(destination: string, callback: (msg: T) => void) => {
        if (stompClient) {
            if (!stompClient.active) {
                console.error('WebSocket is not active');
                return;
            }
            return stompClient.subscribe(destination, (message) => {
                const body: T = JSON.parse(message.body);
                callback(body);
            });
        }
        return;
    }, [stompClient]);

    const ctx = useMemo(() => ({ subscribe }), [subscribe]);
    return (
        <WsContext.Provider value={ctx}>
            {children}
        </WsContext.Provider>
    );
};

export { StompWebSocketProvider, WsContext };
