import React from 'react';
import clients from '../../api/clients';
import FormSelect from './FormSelect';

import type { ClientType } from '../../model/client/clientModel';
import type { Option } from '../control/option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';

type Props = {
    id: string;
    clientId: number;
    clientTypes: ClientType[];
    onChange?: (newValue: SingleValue<Option>) => void;
    readOnly?: boolean;
};

const Consignees: React.FC<Props> = ({ id, clientId, clientTypes, onChange, readOnly }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clients
            .getAvailableConsigneesForClient({
                filter: inputValue,
                clientId,
                clientTypes,
            })
            .then((options) => callback(options));
    };
    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
            readOnly={readOnly}
        />
    );
};

export default Consignees;
