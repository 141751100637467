import React from 'react';
import { Option } from '../../../control/option';
import addresses from '../../../../api/addresses';
import AjaxSelect from '../../../control/AjaxSelect';
import { MultiValue, SingleValue } from 'react-select';

type Props = {
    onChange?: (newValue: any) => void;
};

export const Addresses: React.FC<Props> = ({ onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        addresses
            .getAddresses({ filter: inputValue })
            .then((options) => callback(options));
    };
    return (
        <div className='form-group'>
            <AjaxSelect
                loadOptions={loadOptions}
                onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
            />
        </div>
    );
};