import React from 'react';
import { Outlet } from 'react-router-dom';
import { StorageDistributionQuoteModel } from './storageDistributionModel';
import { DistributionButtons } from './DistributionButtons';
import util from '../../../common/util';

type Props = {
    tareAssembled: number,
    quotes: StorageDistributionQuoteModel[]
};

export const DistributionTable: React.FC<Props> = ({ tareAssembled, quotes }) => {
    const tareTotalDistributed = util.countTotal(quotes, ({tareDistributed = 0}) => tareDistributed);
    return (
        <>
            <table className='table table-quotes table-striped table-hover'>
                <thead>
                    <tr>
                        <th>Заказ</th>
                        <th>Тара</th>
                        <th>В заказе</th>
                        <th>Вес</th>
                        <th>%</th>
                        <th>Готово</th>
                    </tr>
                </thead>
                <tbody>
                    {quotes.map(quote => {
                        const {
                            id: quoteId,
                            number: quoteNumber,
                            tare: {
                                value: tareId,
                                label: tareLabel 
                            },
                            tareTotal = 0,
                            tareDistributed = 0,
                            weight = 0,
                        } = quote;
                        
                        return <tr key={`quote-${quoteId}`}>
                            <td>{quoteNumber}</td>
                            <td>{tareLabel?.length ? tareLabel : '-'}</td>
                            <td>{tareTotal}</td>
                            <td>{weight}</td>
                            <td>{util.calcPercent(tareDistributed, tareTotal)}%</td>
                            <td className='distribution-btns'>
                                <DistributionButtons
                                    quoteId={quoteId}
                                    tareId={tareId}
                                    tareQuoteTotal={tareTotal}
                                    tareQuoteDistributed={tareDistributed}
                                    tareTotalDistributed={tareTotalDistributed}
                                    tareTotalAssembled={tareAssembled}
                                />
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <Outlet />
        </>
    );
};
