export enum RunType {
    DELIVERY = 1,
    SELF_DELIVERY = 2,
    DOMESTIC = 3,
}

export const RunTypeNames: Map<RunType, string> = new Map([
    [RunType.DELIVERY, 'Доставка'],
    [RunType.SELF_DELIVERY, 'Самовывоз'],
    [RunType.DOMESTIC, 'Внутренний'],
]);
