import {FormConfig} from '../form/formConfig';
import {QuoteModel} from './model/quoteModel';
import quotes from '../../api/quotes';
import React from 'react';
import ContextError from '../form/ContextError';
import {DwForm, useFieldValue} from '../form/DwForm';
import './quote.scss';
import {ShipmentDetailModel} from './model/shipmentDetailModel';
import {
    notNullablePositiveIntValidator,
    nullableOrPositiveIntValidator,
    requiredOptionValidator,
} from '../../common/validation/simpleValidators';
import {
    domesticShipmentDetailsValidator,
    notNullableLoadingDtValidator,
    notNullableUnloadingDtValidator,
    shipmentDetailsValidator,
} from '../../common/validation/quoteValidators';
import DwFormTabs from '../form/DwFormTabs';
import { generatePath, Outlet, useParams, useSearchParams } from 'react-router-dom';
import util from '../../common/util';
import {
    PATH_QUOTE,
    PATH_QUOTE_HISTORY,
    PATH_QUOTES_DIRECTION,
    PATH_VARIABLE_QUOTE_ID,
    SEARCH_PARAM_EDI_MESSAGE_ID,
    SEARCH_PARAM_SOURCE_ID,
} from '../../routerPaths';
import {QuoteField} from './quoteField';
import {PROMISE_OK} from '../../common/const';
import edi from '../../api/edi';
import {Option} from '../control/option';
import {QuoteSource} from '../../model/enums/QuoteSource';
import companies from "../../api/companies";

const useFormConfig = () => {
    const [searchParams] = useSearchParams();
    const ediMessageId = util.toNumber(searchParams.get(SEARCH_PARAM_EDI_MESSAGE_ID) ?? '');
    const sourceId = util.toNumber(searchParams.get(SEARCH_PARAM_SOURCE_ID) ?? '');
    const { direction } = useParams<{ direction: string }>();
    return FormConfig.builder<QuoteModel>()
        .number(
            QuoteField.ID,
            (m) => m.id,
            (m, v) => (m.id = v)
        )
        .text(
            QuoteField.DESCRIPTION,
            (m) => m.description,
            (m, v) => (m.description = v)
        )
        .dateTime(
            QuoteField.CREATED,
            (m) => m.created,
            (m, v) => (m.created = v)
        )
        .requiredOption(
            QuoteField.STATUS,
            (m) => m.status,
            (m, v) => (m.status = v)
        )
        .option(
            QuoteField.MANAGER,
            (m) => m.manager,
            (m, v) => (m.manager = v)
        )

        .option(
            QuoteField.CLIENT,
            (m) => m.client,
            (m, v) => (m.client = v),
            (m) =>
                m.isDomesticQuote ? Promise.resolve(PROMISE_OK) : requiredOptionValidator(m.client)
        )
        .requiredOption(
            QuoteField.CLIENT_COMPANY,
            (m) => m.clientCompany,
            (m, v) => (m.clientCompany = v)
        )
        .option(
            QuoteField.CLIENT_CONTRACT,
            (m) => m.clientContract,
            (m, v) => (m.clientContract = v),
            (m) =>
                m.isDomesticQuote
                    ? Promise.resolve(PROMISE_OK)
                    : requiredOptionValidator(m.clientContract)
        )
        .option(
            QuoteField.CLIENT_PAYMENT_METHOD,
            (m) => m.clientPaymentMethod,
            (m, v) => (m.clientPaymentMethod = v),
            (m) =>
                m.isDomesticQuote
                    ? Promise.resolve(PROMISE_OK)
                    : requiredOptionValidator(m.clientPaymentMethod)
        )
        .option(
            QuoteField.CLIENT_PAYMENT_TYPE,
            (m) => m.clientPaymentType,
            (m, v) => (m.clientPaymentType = v),
            (m) =>
                m.isDomesticQuote
                    ? Promise.resolve(PROMISE_OK)
                    : requiredOptionValidator(m.clientPaymentType)
        )
        .number(
            QuoteField.CLIENT_PAYMENT_DELAY,
            (m) => m.clientPaymentDelay,
            (m, v) => (m.clientPaymentDelay = v),
            (m) =>
                m.isDomesticQuote
                    ? Promise.resolve(PROMISE_OK)
                    : notNullablePositiveIntValidator(m.clientPaymentDelay)
        )
        .option(
            QuoteField.CLIENT_PAYMENT_DELAY_TYPE,
            (m) => m.clientPaymentDelayType,
            (m, v) => (m.clientPaymentDelayType = v)
        )
        .dateTime(
            QuoteField.CLIENT_PAYMENT_DATETIME,
            (m) => m.clientPaymentDatetime,
            (m, v) => (m.clientPaymentDatetime = v)
        )
        .dateTime(
            QuoteField.CLIENT_PLANNED_PAYMENT_DATE,
            (m) => m.clientPlannedPaymentDate,
            (m, v) => (m.clientPlannedPaymentDate = v)
        )
        .option(
            QuoteField.CLIENT_CONTACT,
            (m) => m.clientContact,
            (m, v) => (m.clientContact = v)
        )
        .text(
            QuoteField.CLIENT_QUOTE_NUMBER,
            (m) => m.clientQuoteNumber,
            (m, v) => (m.clientQuoteNumber = v)
        )

        .option(
            QuoteField.CARRIER,
            (m) => m.carrier,
            (m, v) => (m.carrier = v)
        )
        .requiredOption(
            QuoteField.CARRIER_COMPANY,
            (m) => m.carrierCompany,
            (m, v) => (m.carrierCompany = v)
        )
        .option(
            QuoteField.CARRIER_CONTRACT,
            (m) => m.carrierContract,
            (m, v) => (m.carrierContract = v)
        )
        .option(
            QuoteField.CARRIER_PAYMENT_METHOD,
            (m) => m.carrierPaymentMethod,
            (m, v) => (m.carrierPaymentMethod = v)
        )
        .option(
            QuoteField.CARRIER_PAYMENT_TYPE,
            (m) => m.carrierPaymentType,
            (m, v) => (m.carrierPaymentType = v)
        )
        .number(
            QuoteField.CARRIER_PAYMENT_DELAY,
            (m) => m.carrierPaymentDelay,
            (m, v) => (m.carrierPaymentDelay = v),
            (m) => nullableOrPositiveIntValidator(m.carrierPaymentDelay)
        )
        .option(
            QuoteField.CARRIER_PAYMENT_DELAY_TYPE,
            (m) => m.carrierPaymentDelayType,
            (m, v) => (m.carrierPaymentDelayType = v)
        )
        .dateTime(
            QuoteField.CARRIER_PAYMENT_DATETIME,
            (m) => m.carrierPaymentDatetime,
            (m, v) => (m.carrierPaymentDatetime = v)
        )
        .dateTime(
            QuoteField.CARRIER_PLANNED_PAYMENT_DATE,
            (m) => m.carrierPlannedPaymentDate,
            (m, v) => (m.carrierPlannedPaymentDate = v)
        )
        .option(
            QuoteField.CARRIER_CONTACT,
            (m) => m.carrierContact,
            (m, v) => (m.carrierContact = v)
        )
        .option(
            QuoteField.CARRIER_COMPANY_CONTACT,
            (m) => m.carrierCompanyContact,
            (m, v) => (m.carrierCompanyContact = v)
        )
        .boolean(
            QuoteField.CARRIER_THIRD_PARTY,
            (m) => m.carrierThirdParty,
            (m, v) => (m.carrierThirdParty = v)
        )

        .option(
            QuoteField.LOADING_STORAGE,
            (m) => m.loadingStorage,
            (m, v) => (m.loadingStorage = v),
            (m) =>
                m.carrierThirdParty
                    ? Promise.resolve(PROMISE_OK)
                    : requiredOptionValidator(m.loadingStorage)
        )
        .requiredOption(
            QuoteField.LOADING_ADDRESS,
            (m) => m.loadingAddress,
            (m, v) => (m.loadingAddress = v)
        )
        .requiredOption(
            QuoteField.UNLOADING_ADDRESS,
            (m) => m.unloadingAddress,
            (m, v) => (m.unloadingAddress = v)
        )
        .option(
            QuoteField.UNLOADING_STORAGE,
            (m) => m.unloadingStorage,
            (m, v) => (m.unloadingStorage = v),
            (m) =>
                m.isDomesticQuote
                    ? requiredOptionValidator(m.unloadingStorage)
                    : Promise.resolve(PROMISE_OK)
        )
        .dateTime(
            QuoteField.LOADING_DATE,
            (m) => m.loadingDate,
            (m, v) => (m.loadingDate = v),
            (m) => notNullableLoadingDtValidator(m.loadingDate, m.unloadingDate)
        )
        .dateTime(
            QuoteField.UNLOADING_DATE,
            (m) => m.unloadingDate,
            (m, v) => (m.unloadingDate = v),
            (m) => notNullableUnloadingDtValidator(m.unloadingDate, m.loadingDate)
        )

        .sortedList<ShipmentDetailModel>(
            QuoteField.SHIPMENT_DETAILS,
            (m) => m.shipmentDetails,
            (a: ShipmentDetailModel, b: ShipmentDetailModel) =>
                util.numberSort(a?.position, b?.position),
            (m, v) => (m.shipmentDetails = v),
            (m: QuoteModel) =>
                m?.isDomesticQuote
                    ? domesticShipmentDetailsValidator(m)
                    : shipmentDetailsValidator(m, ((ediMessageId ?? 0) > 0 || m.source?.value === QuoteSource.EDI))
        )

        .option(
            QuoteField.RUN,
            (m) => m.run,
            (m, v) => (m.run = v)
        )
        .option(
            QuoteField.CLIENT_CONSIGNEE,
            (m) => m.clientConsignee,
            (m, v) => (m.clientConsignee = v)
        )

        .boolean(
            QuoteField.IS_DOMESTIC_QUOTE,
            (m) => m.isDomesticQuote,
            (m, v) => (m.isDomesticQuote = v)
        )

        .load((id) => quotes.get(id))
        .initialModelGetter(async (c) => {
            if (sourceId && sourceId > 0) {
                const model = await quotes.get(sourceId);
                return {
                    ...model,
                    id: null as unknown as number,
                    loadingDate: null as unknown as Date,
                    unloadingDate: null as unknown as Date,
                    run: {} as Option,
                    source: null as unknown as Option,
                    clientQuoteNumber: null as unknown as string,
                    shipmentDetails: model.shipmentDetails
                        ?.filter((sd) => sd?.clientAssortment?.value > 0)
                        .map((sd_1) => {
                            return {
                                ...sd_1,
                                id: -1,
                                weightLoaded: null as unknown as number,
                                weightAccepted: null as unknown as number,
                                weightUnloaded: null as unknown as number,
                            };
                        }),
                } as QuoteModel;
            }
            if (ediMessageId && ediMessageId > 0) {
                return { ...(await edi.getQuoteFromEdi(ediMessageId)) };
            }
            const empty = c.getEmptyModel();
            const defaultCompany = await companies.getCurrent();
            return defaultCompany ? {
                ...empty,
                clientCompany: defaultCompany,
                carrierCompany: defaultCompany
            } as unknown as QuoteModel : empty;
        })
        .submit(quotes.save)
        .afterSubmit((respId, _, incrementFormVersion) => {
            if (ediMessageId && ediMessageId > 0 && respId > 0) {
                edi.save({
                    id: ediMessageId,
                    quote: {
                        value: respId,
                    } as Option,
                }).then(() => incrementFormVersion());
            }
        })
        .redirectUrl(generatePath(PATH_QUOTES_DIRECTION, { direction: direction! }))
        .idPathVariableName(PATH_VARIABLE_QUOTE_ID)
        .build();
};

const QuoteForm: React.FC = () => {
    const formConfig = useFormConfig();
    return (
        <DwForm config={formConfig}>
            <QuoteLayout/>
        </DwForm>
    );
};

/**
 *   Just a wrapper to access form context for further usage (e.g. adding field value to Tab name)
 */
const QuoteLayout: React.FC = () => {
    const id = useFieldValue(QuoteField.ID);
    const isNew = id == null || id < 1;
    const { direction } = useParams<{ direction: string }>();
    return (
        <>
            <DwFormTabs
                items={[
                    {
                        name: isNew ? 'Новый заказ' : `Заказ #${id}`,
                        path: generatePath(PATH_QUOTE, {
                            direction,
                            quoteId: util.stringOrEmpty(id),
                        }),
                        end: true,
                    },
                    {
                        name: `История изменений`,
                        path: generatePath(PATH_QUOTE_HISTORY, {
                            direction,
                            quoteId: util.stringOrEmpty(id),
                        }),
                        disabled: isNew,
                        end: true,
                    },
                ]}
            />
            <ContextError/>
            <Outlet/>
        </>
    );
};

export default QuoteForm;
