import React from 'react';
import { DwTable } from '../table/DwTable';
import runs from '../../api/runs';
import './css/runs.scss';
import { RunModel } from './model/runModel';
import { baseConfigBuilder } from './runsBaseConfigBuilder';
import { ColumnType } from '../table/columnType';
import { RunField } from './runField';

const STATUS_COLUMN_POSITION = 2;
const config = baseConfigBuilder()
    .column((builder) =>
        builder
            .label('Статус')
            .field(RunField.STATUS)
            .type(ColumnType.KPP_RUN_STATUS)
            .filterable(false)
            .editable()
            .width('170px')
            .columnPosition(STATUS_COLUMN_POSITION)
            .onChangeRow(
                async (value, idx, row) =>
                    await runs.save({ id: row.id, status: value } as RunModel)
            )
    )
    .outerFilter((builder) => builder.field('kppRunStorage').type(ColumnType.STORAGE))
    .load((ctx) => runs.kppRunList(ctx.state.request))
    .onClick(() => () => {})
    .withAdd(false)
    .editable(true)
    .id('kpp')
    .build();

const Kpp: React.FC = () => {
    return (
        <div className='kpp d-flex'>
            <DwTable header='АРМ КПП' config={config} />
        </div>
    );
};

export default Kpp;
