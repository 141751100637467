import React from 'react';
import assortments from '../../api/assortments';
import FormSelect from './FormSelect';
import { Option } from '../control/option';

type Props = {
    id: string;
};

const Assortments: React.FC<Props> = ({ id}) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        assortments
            .options({
                filter: inputValue,
            })
            .then((options) => callback(options));
    };
    return (
        <FormSelect id={id} loadOptions={loadOptions} />
    );
};

export default Assortments;