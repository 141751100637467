import React, { CSSProperties, ReactElement, useCallback, useContext } from 'react';
import { Column } from './column';
import clsx from 'clsx';
import { ColumnType } from './columnType';
import { TableContext } from './DwTable';
import { ActionType } from './action';
import { Direction, Sort } from './context';

type Props = {
    columns: Column<any, any>[];
};

const columnClsx = (col: Column<any, any>) =>
    clsx(col.cls, {
        'sortable': col.sortable,
        'id': col.type === ColumnType.ID,
        'date': col.type === ColumnType.DATE,
        'boolean': col.type === ColumnType.BOOLEAN,
        'date-time': col.type === ColumnType.DATE_TIME,
    });

const columnStyle = (col: Column<any, any>): CSSProperties | undefined => {
    return col.width ? { width: col.width } : undefined;
};

const reverseDirection = (sort: Sort) => {
    if (sort.dir === Direction.ASC) {
        return Direction.DESC;
    }
    return Direction.ASC;
};

const changeDirection = (col: Column<any, any>, sort: Sort) => {
    if (col.field === sort.field) {
        return reverseDirection(sort);
    }
    return Direction.ASC;
};

const Header: (props: Props) => ReactElement<Props> = (props) => {
    const context = useContext(TableContext);
    const { sort, filters } = context.state.request;

    const onClickHeader = (e: React.MouseEvent, col: Column<any, any>) => {
        e.preventDefault();
        if (col.sortable) {
            context.dispatch({
                type: ActionType.SORT,
                sort: {
                    field: col.field,
                    dir: changeDirection(col, sort),
                },
            });
        }
    };

    const onClickFilter = (e: React.MouseEvent, col: Column<any, any>) => {
        e.preventDefault();
        e.stopPropagation();
        const { bottom, left } = (e.target as any).offsetParent.getBoundingClientRect();
        context.dispatch({
            type: ActionType.DISPLAY_FILTER,
            column: col !== context.state.showFilterFor ? col : undefined,
            position: {
                x: Math.round(left),
                y: Math.round(bottom),
            },
        });
    };

    const onRemoveFilter = (e: React.MouseEvent, col: Column<any, any>) => {
        e.preventDefault();
        e.stopPropagation();
        context.dispatch({
            type: ActionType.REMOVE_FILTER,
            column: col,
        });
    };

    const isFiltered = useCallback(
        (col: Column<any, any>) => {
            return filters.some((filter) => filter.field === col.field);
        },
        [filters]
    );

    return (
        <>
            <thead>
                <tr>
                    {props.columns.map((col) => (
                        <th
                            key={col.label}
                            data-field={col.field}
                            className={columnClsx(col)}
                            style={columnStyle(col)}
                            onClick={(e) => onClickHeader(e, col)}
                        >
                            <div className='th'>
                                <span className='title'>
                                    {col.label}
                                    {col.sortable && (
                                        <i
                                            className={clsx(
                                                'fas fa-fw', {
                                                'fa-arrow-up': sort.field !== col.field,
                                                'fa-arrow-up visible': sort.field === col.field && sort.dir === Direction.ASC,
                                                'fa-arrow-down visible': sort.field === col.field && sort.dir !== Direction.ASC
                                            }
                                            )}
                                        />
                                    )}
                                </span>
                                <div className='actions'>
                                    {col.filterable && (
                                        <div className='header-icon btn btn-circle btn-xs btn-light th-filter-btn'
                                            onClick={(e) => onClickFilter(e, col)}>
                                            <i className={clsx('fas fa-fw fa-filter', {
                                                selected: isFiltered(col),
                                            })} />
                                        </div>
                                    )}
                                    {isFiltered(col) && (
                                        <div className='header-icon btn btn-circle btn-xs btn-light th-filter-btn'
                                            onClick={(e) => onRemoveFilter(e, col)}>
                                            <i className='fas fa-fw fa-times filter-remove' />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
        </>
    );
};

export { Header, columnClsx, columnStyle };
