import { BaseOptionRequest } from '../../api/baseOptionRequest';
import type { AbstractEntity } from '../abstractEntity';
import { Option } from '../../component/control/option';

export type TmcModel = AbstractEntity & {
    name: string;
    unit: Option;
    automatic: boolean;
    deductRate: number;
    category: Option;
    linkedTmc: Option;
};

export enum TmcCategory {
    PACKAGE = 1,
    TARE = 2,
    PACKAGE_OR_TARE = 3,
    OTHER = 4,
    BULK = 5,
}

export type TmcOptionsRequest = BaseOptionRequest & {
    tmcCategories?: TmcCategory[];
};
