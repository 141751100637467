import { get, post, put } from './rest';

import type { PagedResponse } from './pagedResponse';
import type { ListRequest } from '../component/table/listRequest';
import { AssortmentOperationListModel } from '../model/assortment-operation/assortmentOperationListModel';
import { AssortmentOperationModel } from '../model/assortment-operation/assortmentOperationModel';

const PATH_BASE = 'assortment-operations';

const assortmentOperations = {
    list: (model: ListRequest) =>
        post<PagedResponse<AssortmentOperationListModel>>(`${PATH_BASE}/list`, model),
    get: (id: number) => get<AssortmentOperationModel>(`${PATH_BASE}/${id}`),
    save: (model: AssortmentOperationModel) =>
        model.id > 0 ? put<number>(PATH_BASE, model) : post<number>(PATH_BASE, model),
};

export default assortmentOperations;
