import React, { MouseEvent, useCallback, useContext, useEffect, useMemo } from 'react';
import { generatePath, NavigateFunction, useNavigate } from 'react-router-dom';
import '../css/route.scss';
import { Direction } from '../../../common/const';
import util from '../../../common/util';
import { PATH_QUOTE } from '../../../routerPaths';
import { FormContext, useFieldValue, useIsLoaded, useSetFieldValue, validateField } from '../../form/DwForm';
import { Config } from '../../table/config';
import { EditableDwTable } from '../../table/EditableDwTable';
import { RemoveButton } from '../../table/RemoveButton';
import { RunField } from '../runField';
import { RunPointBase, RunPointModel, RunPointType, RunPointTypeNames } from '../model/runPointModel';
import type { Option } from '../../control/option';
import { DEFAULT_OPTION, DictionaryType } from '../../control/option';
import type { RunModel } from '../model/runModel';
import contacts from '../../../api/contacts';
import { RouteBtns } from './RouteBtns';
import { ClientType } from '../../../model/client/clientModel';
import ListClients from '../../form/list/ListClients';
import ListCompanies from '../../form/list/ListCompanies';
import ListClientContacts from '../../form/list/ListClientContacts';
import ListStorageContacts from '../../form/list/ListStorageContacts';
import { useIsMounted } from '../../../common/isMounted';
import ListDurationPicker from '../../form/list/ListDurationPicker';
import { ProxyModel } from '../../../model/proxyModel';
import { ListClientStorages, ListStorages } from '../../form/list/ListClientStorages';
import routeUtil from './routeUtil';
import { RUN_STATUS, RunStatus } from '../../../model/enums/RunStatus';
import options from '../../../api/options';
import { DictionaryOptionsRequest } from '../../control-panel/dictionary/dictionaryOptionsRequest';

enum RouteField {
    IS_LOADING = 'isLoading',
    QUOTE_ID = 'quoteId',
    DATE = 'date',
    TIME_FROM = 'timeFrom',
    DURATION = 'duration',
    CLIENT = 'client',
    COMPANY = 'company',
    ADDRESS = 'address',
    STORAGE = 'storage',
    CONTACT = 'contact',
    CONTACT_PHONE = 'contactPhone',
    WEIGHT = 'weight',
    TARE_QUANTITY = 'tareQuantity',
    DESCRIPTION = 'description',
    CARGO_PRICE = 'cargoPrice',
}

type Props = {
    id: string;
    proxyId: string;
};

const isEditable = (model: RunPointModel) => model.base !== RunPointBase.QUOTE;

const useFormConfig = (
    id: string,
    proxyId: string,
    proxyRoute: ProxyModel<RunPointModel>[],
    onChangeProxy: (route: ProxyModel<RunPointModel>[]) => void,
    onDeleteProxy: (e: MouseEvent, row: ProxyModel<RunPointModel>, index: number) => void,
    onChangeDate: () => void,
    navigate: NavigateFunction,
    valueIsDomesticRun: boolean
) =>
    useMemo(
        () =>
            Config.builder<ProxyModel<RunPointModel>>()
                .column((builder) =>
                    builder
                        .position('#')
                        .cls('id')
                )
                .column((builder) =>
                    builder
                        .text('Тип', RouteField.IS_LOADING)
                        .getter((model) => model.isLoading
                            ? RunPointTypeNames.get(RunPointType.LOADING)
                            : RunPointTypeNames.get(RunPointType.UNLOADING)
                        )
                        .cls('type')
                )
                .column((builder) =>
                    builder
                        .date('Дата', RouteField.DATE)
                        .editable()
                        .onChangeRow((value, rowNum, row) =>
                            new Promise<void>((resolve) => {
                                const refs = proxyRoute[rowNum].refs;
                                const isNew = refs.every(r => r.id <= 0);
                                if (!isNew && (util.formatDate(row.date) !== util.formatDate(value))) {
                                    const refs = proxyRoute[rowNum].refs;
                                    refs.forEach(ref => {
                                        const prevDescription = util.toString(ref.description);
                                        ref.description = (ref.description ? prevDescription + '.' : prevDescription)
                                            + ' дата изменена: ' + util.formatDate(row.date) + ' -> ' + util.formatDate(value);
                                    });
                                    onChangeProxy(proxyRoute);
                                    onChangeDate();
                                }
                                resolve();
                            })
                        )
                        .cls('date')
                )
                .column((builder) =>
                    builder
                        .time('Время с', RouteField.TIME_FROM)
                        .editable()
                        .cls('time')
                )
                .column((builder) => builder
                    .label('Длит-сть ч.')
                    .field(RouteField.DURATION)
                    .editor((model) =>
                        <ListDurationPicker id={RouteField.DURATION} dateFrom={model.date} timeFrom={model.timeFrom} />)
                    .editable())
                .column((builder) =>
                    builder
                        .text('Заказ', RouteField.QUOTE_ID)
                        .getter((model) => {
                            const quoteId = model.quoteId;
                            if (quoteId > 0) {
                                const direction = valueIsDomesticRun
                                    ? Direction.DOMESTIC
                                    : Direction.EXTERNAL;
                                const onClick = () =>
                                    navigate(
                                        generatePath(PATH_QUOTE, {
                                            direction,
                                            quoteId: util.stringOrEmpty(quoteId),
                                        })
                                    );
                                return (
                                    <button onClick={onClick} className='btn btn-sm btn-link'>
                                        №&nbsp;{model.quoteNumber?.length > 0 ? model.quoteNumber : quoteId}
                                    </button>
                                );
                            }
                            return <></>;
                        })
                        .cls('number quote-number')
                )
                .column((builder) =>
                    builder
                        .label('Контрагент')
                        .field(RouteField.CLIENT)
                        .getter((model) => {
                            switch (model.base) {
                                case RunPointBase.QUOTE:
                                case RunPointBase.CLIENT:
                                    return model.client?.label;
                                case RunPointBase.COMPANY:
                                    return model.company?.label;
                                default:
                                    return null;
                            }
                        })
                        .editor((model) => {
                            switch (model.base) {
                                case RunPointBase.CLIENT:
                                    return <ListClients
                                        id={RouteField.CLIENT}
                                        clientTypes={[ClientType.CLIENT, ClientType.SUPPLIER]}
                                        onChange={(newValue: any, rowNum: number) => {
                                            const refs = proxyRoute[rowNum].refs;
                                            refs.forEach(ref => {
                                                ref.address = DEFAULT_OPTION;
                                                ref.storage = DEFAULT_OPTION;
                                                ref.contact = DEFAULT_OPTION;
                                                ref.contactPhone = '';
                                            });
                                            onChangeProxy(proxyRoute);
                                        }}
                                    />;
                                case RunPointBase.COMPANY:
                                    return <ListCompanies
                                        id={RouteField.COMPANY}
                                        onChange={(newValue: any, rowNum: number) => {
                                            const refs = proxyRoute[rowNum].refs;
                                            refs.forEach(ref => {
                                                ref.address = DEFAULT_OPTION;
                                                ref.storage = DEFAULT_OPTION;
                                                ref.contact = DEFAULT_OPTION;
                                                ref.contactPhone = '';
                                            });
                                            onChangeProxy(proxyRoute);
                                        }}
                                    />;
                                default:
                                    return null;
                            }
                        })
                        .editable(isEditable)
                        .cls('client')
                )
                .column((builder) =>
                    builder
                        .label('Склад')
                        .field(RouteField.STORAGE)
                        .getter((model) => model.storage?.label)
                        .editor((model) => {
                            switch (model.base) {
                                case RunPointBase.CLIENT:
                                    return <ListClientStorages
                                        id={RouteField.STORAGE}
                                        clientId={model.client?.value}
                                        showAddress
                                        onChange={(newValue: Option, rowNum: number) => {
                                            const refs = proxyRoute[rowNum].refs;
                                            refs.forEach(ref => {
                                                ref.address = newValue.subOption ?? DEFAULT_OPTION;
                                            });
                                            onChangeProxy(proxyRoute);
                                        }}
                                    />;
                                case RunPointBase.COMPANY:
                                    return <ListStorages
                                        id={RouteField.STORAGE}
                                        showAddress
                                        onChange={(newValue: Option, rowNum: number) => {
                                            const refs = proxyRoute[rowNum].refs;
                                            refs.forEach(ref => {
                                                ref.address = newValue.subOption ?? DEFAULT_OPTION;
                                                ref.contact = DEFAULT_OPTION;
                                                ref.contactPhone = '';
                                            });
                                            onChangeProxy(proxyRoute);
                                        }}
                                    />;
                                default:
                                    return null;
                            }
                        })
                        .editable(isEditable)
                        .cls('address')
                )
                .column((builder) =>
                    builder
                        .label('Адрес')
                        .field(RouteField.ADDRESS)
                        .getter((model) => model.address?.label)
                        .cls('address')
                )
                .column((builder) =>
                    builder
                        .label('Контакт')
                        .field(RouteField.CONTACT)
                        .getter((model) => model.contact?.label)
                        .editor((model) => {
                            switch (model.base) {
                                case RunPointBase.CLIENT:
                                    return <ListClientContacts
                                        id={RouteField.CONTACT}
                                        clientId={model.client?.value}
                                        onChange={(newValue: Option, rowNum: number) => {
                                            const point = proxyRoute[rowNum];
                                            const refs = proxyRoute[rowNum].refs;
                                            contacts.getForClient(newValue.value, point.client?.value).then((contact) => {
                                                refs.forEach(ref => {
                                                    ref.contactPhone = contact.phone;
                                                });
                                                onChangeProxy(proxyRoute);
                                            });
                                        }}
                                    />;
                                case RunPointBase.COMPANY:
                                    return <ListStorageContacts
                                        id={RouteField.CONTACT}
                                        storageId={model.storage?.value}
                                        onChange={(newValue: Option, rowNum: number) => {
                                            const point = proxyRoute[rowNum];
                                            const refs = proxyRoute[rowNum].refs;
                                            contacts.getForStorage(newValue.value, point.storage?.value).then((contact) => {
                                                refs.forEach(ref => {
                                                    ref.contactPhone = contact.phone;
                                                });
                                                onChangeProxy(proxyRoute);
                                            });
                                        }}
                                    />;
                                default:
                                    return null;
                            }
                        })
                        .editable(isEditable)
                        .cls('contact')
                )
                .column((builder) => builder.text('Тел. Контакта', RouteField.CONTACT_PHONE).cls('phone'))
                .column((builder) => builder.integer('Вес', RouteField.WEIGHT).editable(isEditable).cls('number'))
                .column((builder) => builder.integer('Кол-во тары', RouteField.TARE_QUANTITY).editable(isEditable).cls('number'))
                .column((builder) =>
                    builder
                        .money('Стоимость груза', RouteField.CARGO_PRICE)
                        .editable((model) => isEditable(model) && !model.isLoading)
                        .cls('money')
                )
                .column((builder) => builder.text('Дополнительно', RouteField.DESCRIPTION).editable().cls('text'))
                .column((builder) =>
                    builder
                        .getter((model, index) => (
                            <RemoveButton onClick={(e: MouseEvent) => onDeleteProxy(e, model, index!)}/>
                        ))
                        .cls('text-center actions')
                        .width('40px')
                )
                .id(proxyId) // EditableDwTable will take data from the proxy list
                .captionControl(() => <RouteBtns id={id} />) // RouteBtns will take data from the original list and modify the original list
                .build(),
        [
            id,
            proxyId,
            proxyRoute,
            onChangeProxy,
            onDeleteProxy,
            onChangeDate,
            navigate,
            valueIsDomesticRun
        ]);

const Route: React.FC<Props> = ({ id, proxyId }) => {
    const context = useContext(FormContext);
    const navigate = useNavigate();
    const isFormLoaded = useIsLoaded();
    const model = context.state.model as RunModel;
    const setValue = useSetFieldValue();

    const fieldConfig = context.state.config.field(id);
    const fieldRouteValue = useFieldValue(id);
    const routeData: RunPointModel[] = useMemo(() => fieldRouteValue ?? [], [fieldRouteValue]);

    const fieldProxyRouteValue = useFieldValue(proxyId);
    const proxyRouteData: ProxyModel<RunPointModel>[] = useMemo(() => fieldProxyRouteValue ?? [], [fieldProxyRouteValue]);
    const onChangeProxy = useCallback((proxyList: ProxyModel<RunPointModel>[]) => setValue(proxyId, proxyList),[proxyId, setValue]);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [routeData]);

    const valueClientPaymentDelay = useFieldValue(RunField.CLIENT_PAYMENT_DELAY);
    const valueClientDelayType = useFieldValue(RunField.CLIENT_PAYMENT_DELAY_TYPE);
    const valueCarrierPaymentDelay = useFieldValue(RunField.CARRIER_PAYMENT_DELAY);
    const valueCarrierDelayType = useFieldValue(RunField.CARRIER_PAYMENT_DELAY_TYPE);

    const lastUnloadingDate = useMemo(() => routeUtil.findLastUnloading(proxyRouteData)?.date, [proxyRouteData]);
    const valueIsDomesticRun = useFieldValue(RunField.IS_DOMESTIC_RUN);

    useEffect(() => {
        if (lastUnloadingDate && isFormLoaded) {
            onChangeUnloadingDate(lastUnloadingDate);
        }
    }, [lastUnloadingDate]);

    const onChangeUnloadingDate = useCallback((newDate: Date) => {
        if (newDate) {
            if (valueClientDelayType?.value) {
                const plannedPaymentDate = util.calculatePlannedPaymentDate(
                    newDate,
                    valueClientPaymentDelay,
                    valueClientDelayType.value
                );
                setValue(RunField.CLIENT_PLANNED_PAYMENT_DATE, plannedPaymentDate);
            }
            if (valueCarrierDelayType?.value) {
                const plannedPaymentDate = util.calculatePlannedPaymentDate(
                    newDate,
                    valueCarrierPaymentDelay,
                    valueCarrierDelayType.value
                );
                setValue(RunField.CARRIER_PLANNED_PAYMENT_DATE, plannedPaymentDate);
            }
        }
    }, [setValue, valueCarrierDelayType, valueClientDelayType, valueClientPaymentDelay, valueCarrierPaymentDelay]);

    const onDeleteProxy = async (e: MouseEvent, row: ProxyModel<RunPointModel>, index: number) => {
        e.preventDefault();
        let proxyRoute = [...proxyRouteData];

        if (row.base === RunPointBase.QUOTE) {
            const quoteIdsToRemove = row.refs
                .filter(ref => ref.base === RunPointBase.QUOTE)
                .map(ref => ref.quoteId);

            proxyRoute = proxyRoute.map(proxyRow => {
                const filteredRefs = proxyRow.refs.filter(ref => {
                    return !quoteIdsToRemove.includes(ref.quoteId);
                });
                return {
                    ...proxyRow,
                    refs: filteredRefs,
                };
            }).filter(proxyRow => proxyRow.refs.length > 0);
        } else {
            proxyRoute.splice(index, 1);
        }

        onChangeProxy(proxyRoute);
    };

    const onChangeDate = useCallback(() => {
        options.options({
                filter: RUN_STATUS.get(RunStatus.DATE_CHANGED),
                dictionaryType: DictionaryType.RUN_STATUS,
            } as DictionaryOptionsRequest
        ).then((optionList) => setValue(RunField.STATUS, optionList[0]));
    }, [setValue]);

    const config = useFormConfig(
        id,
        proxyId,
        [...proxyRouteData],
        onChangeProxy,
        onDeleteProxy,
        onChangeDate,
        navigate,
        valueIsDomesticRun
    );
    return <EditableDwTable config={config} header='Маршрут'/>;
};

export default Route;
