import { get, post, put } from './rest';

import type { PagedResponse } from './pagedResponse';
import type { TmcOperationListModel } from '../model/tmcoperation/tmcOperationListModel';
import type { ListRequest } from '../component/table/listRequest';
import { TmcOperationModel } from '../model/tmcoperation/tmcOperationModel';

const PATH_BASE = 'tmc-operations';

const tmcOperations = {
    list: (model: ListRequest) =>
        post<PagedResponse<TmcOperationListModel>>(`${PATH_BASE}/list`, model),
    get: (id: number) => get<TmcOperationModel>(`${PATH_BASE}/${id}`),
    save: (model: TmcOperationModel) =>
        model.id > 0 ? put<number>(PATH_BASE, model) : post<number>(PATH_BASE, model),
};

export default tmcOperations;
