import React, {useContext, useEffect} from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {FormContext, useSetFieldValue, validateField} from './DwForm';
import {useIsMounted} from "../../common/isMounted";

type Props = {
    id: string;
};

const TextEditor: React.FC<Props> = ({id}) => {
    const context = useContext(FormContext);
    const model = context.state.model;
    const setValue = useSetFieldValue();
    const fieldConfig = context.state.config.field(id);
    const value: string = fieldConfig.getter(model) ?? '';

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [value]);

    const onChange = async (value: string) => {
        setValue(id, value);
    };

    const modules = {
        toolbar: [
            [{header: [false, 1, 2, 3, 4, 5, 6]}],
            [{font: []}],
            ['bold', 'italic', 'underline', 'strike', 'clean'],
            [
                {list: 'ordered'},
                {list: 'bullet'},
                {indent: '-1'},
                {indent: '+1'},
                {align: []},
            ],
            ['link'],
        ],
    };

    return <ReactQuill theme='snow' modules={modules} value={value} onChange={onChange}/>;
};

export default TextEditor;
