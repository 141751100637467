import { post } from './rest';

import type { ApiResponse } from './apiResponse';
import type { GeolocationModel, GeolocationListModel } from '../model/geolocation';

const PATH_BASE = 'geolocation';

const geolocations = {
    list: (request: GeolocationListModel) => post<GeolocationModel[]>(`${PATH_BASE}/list`, request),
    save: (model: GeolocationModel) => post<ApiResponse>(`${PATH_BASE}`, model),
};

export default geolocations;
