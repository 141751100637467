import React, {ReactElement, useContext, useMemo, useState} from 'react';
import {Config} from './config';
import {columnClsx} from './Header';
import {v4 as uuid} from 'uuid';
import {Column} from './column';
import {ColumnType} from './columnType';
import util from '../../common/util';
import {TableTooltip} from './TableTooltip';
import {useNavigate} from 'react-router-dom';
import {Option} from "../control/option";
import EditableCell from "./EditableCell";
import {EditableTableContext} from "./EditableDwTable";
import {TableContext} from "./DwTable";

type Props = {
    config: Config<any>;
    columns: Column<any, any>[];
    data: any[];
};

const formatValue = (col: Column<any, any>, row: any, refresh: () => void) => {
    const value = col.getter(row, 0, refresh);
    if (col.type === ColumnType.BOOLEAN) {
        return value ? 'да' : 'нет';
    }
    if (value) {
        switch (col.type) {
            case ColumnType.DATE_TIME:
                return util.formatDateTime(new Date(value));
            case ColumnType.DATE:
                return util.formatDate(new Date(value));
            case ColumnType.DESCRIPTION:
                return (
                    <TableTooltip
                        tableId={uuid()}
                        column={col.field}
                        rowId={row.id}
                        value={value?.toString()}
                    />
                );
            case ColumnType.STORAGE_STATUS:
            case ColumnType.KPP_RUN_STATUS:
            case ColumnType.RUN_STATUS:
            case ColumnType.QUOTE_STATUS:
            case ColumnType.PRODUCTION_LINE:
                const isObject = util.isObject(value);
                return isObject ? (value as Option)?.label : value;
        }
    }
    return value;
};

const Body: (props: Props) => ReactElement<Props> = (props) => {
    const {config, data, columns} = props;
    const navigate = useNavigate();
    const edit = config.onClick;
    const rowClassNameFn = config.rowClassNameFn;
    const [editingRowNum, setEditingRowNum] = useState<number>(-1);
    const context = useContext(TableContext);
    const {refresh} = context;

    const ctx = useMemo(() => {
        const fieldConfig = (f: string) => config.columns.find(it => it.field === f);
        const model = data[editingRowNum];
        return {
            activeCellGetter: (field: string) => fieldConfig(field)?.getter(model),
            activeCellSetter: async (field: string, value: any) => {
                const handler = fieldConfig(field)?.onChangeRow;
                const promise = handler ? handler(value, editingRowNum, model) : Promise.resolve(null);
                promise.then(() => refresh());
            },
            editingRowNum,
            setEditingRowNum,
        };
    }, [config, data, editingRowNum]);

    let editableContext = false;
    const body = (<tbody>
    {data.map((row, rowIdx) => (
        <tr key={`${config.id}_row_${rowIdx}`} onDoubleClick={() => edit && edit(row.id, navigate, row)}
            onClick={(_) => setEditingRowNum(rowIdx)} className={rowClassNameFn ? rowClassNameFn(row) : ''}>
            {columns.map((col, colIdx) => {
                const key = `${config.id}_col_${col.field !== '' ? col.field : colIdx}`;
                const editable = col.editable(row, rowIdx);
                if (editable) {
                    editableContext = true;
                }
                const formattedValue = formatValue(col, row, refresh);
                return editable
                    ? (<EditableCell
                        key={key}
                        col={col}
                        formattedValue={formattedValue}
                        cellProps={col.editableColProps(row, rowIdx, context)}
                        editor={col.editor(row, rowIdx)}
                        row={row}
                    />)
                    : (<td key={key} className={columnClsx(col)} data-field={col.field}>
                        {formattedValue}
                    </td>);
            })}
        </tr>
    ))}
    </tbody>);

    if (!editableContext) {
        return <React.Fragment>{body}</React.Fragment>
    }
    return <EditableTableContext.Provider value={ctx}>{body}</EditableTableContext.Provider>;
};

export {Body};
