import React from 'react';
import clients from '../../api/clients';
import FormSelect from './FormSelect';

import type { ClientType } from '../../model/client/clientModel';
import type { Option } from '../control/option';
import type {MultiValue, SingleValue} from 'react-select/dist/declarations/src/types';

type Props = {
    id: string;
    clientTypes?: ClientType[];
    companyId?: number;
    onChange?: (newValue: SingleValue<Option>) => void;
    readOnly?: boolean;
    nullable?: boolean;
};

const Clients: React.FC<Props> = ({ id, clientTypes, companyId, onChange, readOnly, nullable }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clients
            .options({
                filter: inputValue,
                clientTypes,
                companyId,
            })
            .then((options) => callback(options));
    };
    return <FormSelect id={id} loadOptions={loadOptions} onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void} readOnly={readOnly} nullable={nullable} />;
};

export default Clients;
