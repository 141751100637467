import React, { useContext, useMemo, useState } from 'react';
import { Modal } from '../../../layout/Modal';
import { WebSocketSubscriptionUrl } from '../../../../common/const';
import { useRefreshContextAction } from '../../../table/DwTable';
import { EdiIntegrationContext } from './EdiIntegrationProvider';
import { EdiStatus, EdiStatusValue } from './ediStatusModel';
import { useWsSubscription, WsMessage } from '../../../../websockets/useWsSubscribtion';

export const EdiModal: React.FC = () => {
    const { show, setShow } = useContext(EdiIntegrationContext);
    const {refresh} = useRefreshContextAction();
    const [ediStatus, setEdiStatus] = useState({} as EdiStatus);
        
    useWsSubscription(useMemo(() => ({
        endpoint: WebSocketSubscriptionUrl.EDI_INTEGRATION,
        onMessage: (message: WsMessage<EdiStatus>) => setEdiStatus(message.message)
    }), []));

    const onClose = (e: React.MouseEvent) => {
        e.preventDefault();
        setShow(false);
        refresh();
    };

    const processedText = `${ediStatus.currentIndex + 1} из ${ediStatus.totalCount}`;
    return (
        <Modal show={show} onClose={onClose} title='Обновление списка заказов из EDI'>
            <div>
                {ediStatus.party && <div>{ediStatus.party}</div>}
                {ediStatus.status === EdiStatusValue.INITIALIZATION && 'Инициализация...'}
                {ediStatus.status === EdiStatusValue.PROCESSING && `Обработка полученных данных: ${processedText}`}
                {ediStatus.status === EdiStatusValue.FINISHED &&
                    <>
                        <div>
                            {`Обработка завершена${ediStatus.errors?.length ? ' с ошибками' : ''}: ${processedText}`}
                        </div>
                        <div>
                            {`Переведено в статус "Готов": ${ediStatus.countUpdated}`}
                        </div>
                        <div>
                            {`Не найдено соответсвий: ${ediStatus.countNotResolved}`}
                        </div>
                    </>
                }
                {!!ediStatus.errors?.length &&
                    <div>
                        Ошибки:
                        {ediStatus.errors.map((e, i) => <div key={`di_process_error_${i.toString()}`}>{e}</div>)}
                    </div>
                }
            </div>
        </Modal>
    );
};
