import React, { useContext, useMemo } from 'react';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { Config } from '../../../table/config';
import { NavigateFunction, Outlet, generatePath } from 'react-router-dom';
import { ClientFormContext } from '../ClientForm';
import {
    PATH_CP_CLIENT_ADDRESS,
    PATH_VARIABLE_CLIENT_ADDRESS_ID,
    PATH_VARIABLE_CLIENT_ID,
} from '../../../../routerPaths';
import util from '../../../../common/util';
import { RemoveButton } from '../../../table/RemoveButton';
import { ClientAddressField } from './clientAddressFields';
import addresses from '../../../../api/addresses';
import { ClientAddressListModel } from '../../../../model/client/address';

const ClientAddresses: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const config = useMemo(() => {
        return Config.builder<ClientAddressListModel>()
            .column((builder) => builder.id().sortable(false).filterable(false))
            .column((builder) => builder.text('Название', ClientAddressField.NAME).sortable(false))
            .column((builder) =>
                builder
                    .label('Город')
                    .field(ClientAddressField.ADDRESS_CITY)
                    .getter((model) => model.city)
                    .sortable(false)
            )
            .column((builder) =>
                builder
                    .label('Адрес')
                    .field(ClientAddressField.ADDRESS_STREET)
                    .getter((model) => model.street)
                    .sortable(false)
            )
            .column((builder) =>
                builder
                    .cls('text-center')
                    .sortable(false)
                    .filterable(false)
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () =>
                                    addresses.deleteClientAddress(clientId, model.id)
                                )
                            }
                        />
                    ))
                    .width('45px')
            )
            .columnsSelector(false)
            .load((ctx) => addresses.listForClient(ctx.state.request, clientId))
            .formUrl(
                generatePath(PATH_CP_CLIENT_ADDRESS, {
                    [PATH_VARIABLE_CLIENT_ID]: util.stringOrEmpty(clientId),
                    [PATH_VARIABLE_CLIENT_ADDRESS_ID]: '',
                })
            )
            .onClick((id: number, navigate: NavigateFunction) =>
                navigate(
                    generatePath(PATH_CP_CLIENT_ADDRESS, {
                        [PATH_VARIABLE_CLIENT_ID]: util.stringOrEmpty(clientId),
                        [PATH_VARIABLE_CLIENT_ADDRESS_ID]: util.stringOrEmpty(id),
                    })
                )
            )
            .id(`client_${clientId}_addresses`)
            .build();
    }, [clientId]);

    return (
        <>
            <div className='contracts d-flex mb-3'>
                <DwTable header='Адреса' config={config} />
            </div>
            <Outlet />
        </>
    );
};

export default ClientAddresses;
