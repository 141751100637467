import { ProductionLineModel } from '../../model/productionLine/productionLineModel';
import { PROMISE_OK } from '../const';
import productionLine from '../../api/productionLine';
import { requiredValidator } from './simpleValidators';

const lineNumberValidator = async (model: ProductionLineModel): Promise<string> => {
    const { id, lineNumber, storage } = model;

    const checkUniqueness = async () => {
        const isLineStoragePairUnique = await productionLine.isLineAndStoragePairUnique(
            id,
            lineNumber,
            storage?.value
        );
        return Promise.resolve(
            isLineStoragePairUnique ? PROMISE_OK : 'Номер линии уже связан с выбранным складом'
        );
    };

    if (!lineNumber) {
        return requiredValidator(lineNumber);
    }

    return await checkUniqueness();
};

export { lineNumberValidator };
