import React from 'react';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import { EditableTableContext } from '../../../table/EditableDwTable';
import util from '../../../../common/util';

type Props = {
    id: string;
    readOnly?: boolean;
    placeholder?: string;
    showTimeSelect?: boolean;
    showTimeSelectOnly?: boolean;
    minDateTime?: Date;
    timeIntervals?: number;
    onChange?: (newValue: Date, rowNum: number) => void;
    valueToDateTransformer?: (value: any) => Date;
    dateToValueTransformer?: (date: Date) => any;
};

const BaseListDtPicker: React.FC<Props> = ({
    id,
    readOnly = false,
    placeholder = '',
    showTimeSelect = false,
    showTimeSelectOnly = false,
    minDateTime,
    timeIntervals = 30, // 30 minutes default
    onChange,
    valueToDateTransformer = (v) => v,
    dateToValueTransformer = (d) => d,
}) => (
    <EditableTableContext.Consumer>
        {(tableContext) => {
            const { activeCellGetter: getter, activeCellSetter: setter, editingRowNum } = tableContext;
            const value = util.toDateForDtPicker(valueToDateTransformer(getter(id)), showTimeSelectOnly);
            const timeFormat = 'HH:mm';
            const dateFormat = util.getDateFormats(showTimeSelect, showTimeSelectOnly);

            const onChangeHandler = async (date: Date) => {
                if (onChange) {
                    onChange(date, editingRowNum);
                }
                setter(id, dateToValueTransformer(date));
            };

            return (
                <div className='form-group'>
                    <DatePicker
                        placeholderText={placeholder}
                        showTimeSelect={showTimeSelect}
                        showTimeSelectOnly={showTimeSelectOnly}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                        onChange={onChangeHandler}
                        className={'form-control form-control-sm'}
                        selected={value}
                        readOnly={readOnly}
                        timeCaption={'Время'}
                        minDate={minDateTime}
                        filterTime={minDateTime ? (t) => (new Date(t) > minDateTime) : undefined}
                        timeIntervals={timeIntervals}
                        locale={ru}
                        popperProps={{
                            strategy: 'fixed'
                        }}
                    />
                </div>
            );
        }}
    </EditableTableContext.Consumer>
);

export default BaseListDtPicker;
