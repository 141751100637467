import React, { useMemo, useReducer } from "react";
import { refreshReducer } from "./reducer";
import { initialRefreshState } from "./context";
import { TableRefreshContext, TableRefreshContextAction } from "./DwTable";
import { ActionType } from "./action";


export const TableRefreshWrapper: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [refreshState, dispatchRefresh] = useReducer(refreshReducer, initialRefreshState);
    const ctx = useMemo(() => ({
        refreshState,
    }), [refreshState]);
    const ctxAction = useMemo(() => ({
        refresh: () => dispatchRefresh({ type: ActionType.UPDATE_REFRESH_SIGNAL })
    }), []);
    return (
        <TableRefreshContext.Provider value={ctx}>
            <TableRefreshContextAction.Provider value={ctxAction}>
                {children}
            </TableRefreshContextAction.Provider>
        </TableRefreshContext.Provider>
    );
}