import React from 'react';
import { SingleValue } from 'react-select/dist/declarations/src/types';
import ListSelect from './base/ListSelect';
import { ClientType } from '../../../model/client/clientModel';
import { Option } from '../../control/option';
import clients from '../../../api/clients';

type Props = {
    id: string;
    clientTypes?: ClientType[];
    onChange?: (newValue: SingleValue<Option>, rowNum: number) => void;
};

const ListClients: React.FC<Props> = ({ id, clientTypes, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clients
            .options({
                filter: inputValue,
                clientTypes,
            })
            .then((options) => callback(options));
    };
    return (
        <ListSelect id={id} loadOptions={loadOptions} onChange={onChange} />
    );
};

export default ListClients;
