import { ColumnType } from './columnType';
import { Context } from './context';

class Column<T, R> {
    label: string = '';
    field: string = '';
    type: ColumnType = ColumnType.TEXT;
    sortable: boolean = true;
    nullable: boolean = false;
    width: string = '';
    position: number = -1;
    cls: string = '';
    getter: (model: T, index?: number, refresh?: () => void) => R = Column.EMPTY_GETTER;
    editor: (model: T, index?: number) => R = Column.EMPTY_GETTER;
    filterable: boolean = true;
    withEmptyValuesFilter: boolean = false;
    onChangeRow: (newValue: any, rowNum: number, row: any) => Promise<any> = () => Promise.resolve();
    editableColProps: (row: any, rowNum: number, ctx: Context) => { [key: string]: any } = Column.EMPTY_GETTER;

    static EMPTY_GETTER: (model: any) => any = (_) => {
    };

    public static builder<T, R>() {
        return new ColumnBuilder<T, R>();
    }

    editable: (model: T, index?: number) => boolean = (_) => false;
}

class ColumnBuilder<T, R> {
    private column = new Column<T, R>();

    text(label: string, field: string) {
        return this.setField(label, field, ColumnType.TEXT);
    }

    description(label: string, field: string) {
        return this.setField(label, field, ColumnType.DESCRIPTION);
    }

    integer(label: string, field: string) {
        return this.setField(label, field, ColumnType.INTEGER);
    }

    decimal(label: string, field: string) {
        return this.setField(label, field, ColumnType.DECIMAL);
    }

    money(label: string, field: string) {
        return this.setField(label, field, ColumnType.MONEY).cls('money');
    }

    boolean(label: string, field: string) {
        return this.setField(label, field, ColumnType.BOOLEAN);
    }

    clientAssortment(label: string, field: string) {
        return this.setField(label, field, ColumnType.CLIENT_ASSORTMENT);
    }

    companyAssortment(label: string, field: string) {
        return this.setField(label, field, ColumnType.ASSORTMENT);
    }

    client(label: string, field: string) {
        return this.setField(label, field, ColumnType.CLIENT);
    }

    carrier(label: string, field: string) {
        return this.setField(label, field, ColumnType.CARRIER);
    }

    supplier(label: string, field: string) {
        return this.setField(label, field, ColumnType.SUPPLIER);
    }

    company(label: string, field: string) {
        return this.setField(label, field, ColumnType.COMPANY);
    }

    address(label: string, field: string) {
        return this.setField(label, field, ColumnType.ADDRESS);
    }

    storage(label: string, field: string) {
        return this.setField(label, field, ColumnType.STORAGE);
    }

    position(label: string) {
        return this.setField(label, 'position', ColumnType.POSITION);
    }

    tmc(label: string, field: string) {
        return this.setField(label, field, ColumnType.TMC);
    }

    id() {
        this.column.label = '#';
        this.column.type = ColumnType.ID;
        this.column.field = 'id';
        return this;
    }

    date(label: string, field: string) {
        return this.setField(label, field, ColumnType.DATE);
    }

    time(label: string, field: string) {
        return this.setField(label, field, ColumnType.TIME);
    }

    dateTime(label: string, field: string) {
        return this.setField(label, field, ColumnType.DATE_TIME);
    }

    label(val: string) {
        this.column.label = val;
        return this;
    }

    field(val: string) {
        this.column.field = val;
        return this;
    }

    width(val: string) {
        this.column.width = val;
        return this;
    }

    cls(val: string) {
        this.column.cls = val;
        return this;
    }

    type(val: ColumnType) {
        this.column.type = val;
        return this;
    }

    columnPosition(position: number) {
        this.column.position = position;
        return this;
    }

    sortable(val: boolean) {
        this.column.sortable = val;
        return this;
    }

    getter(val: (model: T, index?: number, refresh?: () => void) => R) {
        this.column.getter = val;
        return this;
    }

    filterable(val: boolean) {
        this.column.filterable = val;
        if (!val) {
            this.column.withEmptyValuesFilter = false;
        }

        return this;
    }

    withEmptyValuesFilter(val: boolean) {
        if (this.column.filterable) {
            this.column.withEmptyValuesFilter = val;
        }

        return this;
    }

    editable(val?: (model: T, index?: number) => boolean) {
        this.column.editable = val ?? ((_) => true);
        return this;
    }

    nullable(value: boolean) {
        this.column.nullable = value;
        return this;
    }

    onChangeRow(val: (newValue: any, rowNum: number, row: any) => Promise<any>) {
        this.column.onChangeRow = val;
        return this;
    }

    editableColProps(val: (newValue: any, rowNum: number, context: Context) => { [key: string]: any }) {
        this.column.editableColProps = val;
        return this;
    }

    editor(val: (model: T, index?: number) => R) {
        this.column.editor = val;
        return this;
    }

    build() {
        if (Column.EMPTY_GETTER === this.column.getter && this.column.field) {
            this.column.getter = (model: any) => {
                return model[this.column.field] as R;
            };
        }
        return this.column;
    }

    private setField(label: string, field: string, type: ColumnType) {
        this.column.label = label;
        this.column.type = type;
        this.column.field = field;
        return this;
    }
}

export {Column, ColumnBuilder};
