export const PATH_VARIABLE_CLIENT_ID = 'clientId';
export const PATH_VARIABLE_CLIENT_CONTACT_ID = 'contactId';
export const PATH_VARIABLE_CLIENT_CONTRACT_ID = 'contractId';
export const PATH_VARIABLE_CLIENT_ADDRESS_ID = 'clientAddressId';
export const PATH_VARIABLE_ASSORTMENT_ID = 'assortmentId';
export const PATH_VARIABLE_ASSORTMENT_OPERATION_ID = 'assortmentOperationId';
export const PATH_VARIABLE_DICTIONARY_TYPE = 'dictionaryType';
export const PATH_VARIABLE_DICTIONARY_ID = 'dictionaryId';
export const PATH_VARIABLE_QUOTE_ID = 'quoteId';
export const PATH_VARIABLE_SHIPMENT_DETAIL_ID = 'shipmentDetailId';
export const PATH_VARIABLE_RUN_ID = 'runId';
export const PATH_VARIABLE_COMPANY_ID = 'companyId';
export const PATH_VARIABLE_COMPANY_ADDRESS_ID = 'companyAddressId';
export const PATH_VARIABLE_VEHICLE_ID = 'vehicleId';
export const PATH_VARIABLE_STORAGE_ID = 'storageId';
export const PATH_VARIABLE_STORAGE_CONTACT_ID = 'contactId';
export const PATH_VARIABLE_TMC_ID = 'tmcId';
export const PATH_VARIABLE_TMC_OPERATION_ID = 'tmcOperationId';
export const PATH_VARIABLE_CLIENT_ACCOUNT_ID = 'clientAccountId';
export const PATH_VARIABLE_COMPANY_ACCOUNT_ID = 'companyAccountId';
export const PATH_VARIABLE_DRIVER_ID = 'driverId';
export const PATH_VARIABLE_USER_ID = 'userId';
export const PATH_VARIABLE_CLIENT_CONSIGNEE_ID = 'clientConsigneeId';
export const PATH_VARIABLE_EDI_MESSAGE_ID = 'ediMessageId';
export const PATH_VARIABLE_PRODUCTION_LINE_ID = 'productionLineId';

export const PATH_BASE = '/';
export const PATH_LOGIN = '/login';

/**
 *      CONTROL PANEL
 */
export const PATH_CP = '/control-panel';

export const PATH_CP_CLIENTS = `${PATH_CP}/clients`;
export const PATH_CP_CLIENT = `${PATH_CP_CLIENTS}/client/:${PATH_VARIABLE_CLIENT_ID}`;
export const PATH_CP_CLIENT_CONTACTS = `${PATH_CP_CLIENT}/contacts`;
export const PATH_CP_CLIENT_CONTACT = `${PATH_CP_CLIENT_CONTACTS}/contact/:${PATH_VARIABLE_CLIENT_CONTACT_ID}`;
export const PATH_CP_CLIENT_CONTRACTS = `${PATH_CP_CLIENT}/contracts`;
export const PATH_CP_CLIENT_ASSORTMENT = `${PATH_CP_CLIENT}/assortment`;
export const PATH_CP_CLIENT_ASSORTMENT_FORM = `${PATH_CP_CLIENT_ASSORTMENT}/form/:${PATH_VARIABLE_ASSORTMENT_ID}`;
export const PATH_CP_CLIENT_CONTRACT = `${PATH_CP_CLIENT_CONTRACTS}/contract/:${PATH_VARIABLE_CLIENT_CONTRACT_ID}`;
export const PATH_CP_CLIENT_ADDRESSES = `${PATH_CP_CLIENT}/addresses`;
export const PATH_CP_CLIENT_ADDRESS = `${PATH_CP_CLIENT_ADDRESSES}/address/:${PATH_VARIABLE_CLIENT_ADDRESS_ID}`;
export const PATH_CP_CLIENT_HISTORY = `${PATH_CP_CLIENT}/history`;
export const PATH_CP_CLIENT_ACCOUNTS = `${PATH_CP_CLIENT}/accounts`;
export const PATH_CP_CLIENT_ACCOUNT = `${PATH_CP_CLIENT_ACCOUNTS}/account/:${PATH_VARIABLE_CLIENT_ACCOUNT_ID}`;
export const PATH_CP_CLIENT_CONSIGNEES = `${PATH_CP_CLIENT}/consignees`;
export const PATH_CP_CLIENT_CONSIGNEE = `${PATH_CP_CLIENT_CONSIGNEES}/consignee/:${PATH_VARIABLE_CLIENT_CONSIGNEE_ID}`;

export const PATH_CP_DICTIONARY = `${PATH_CP}/dictionary`;
export const PATH_CP_DICTIONARY_LIST = `${PATH_CP_DICTIONARY}/:${PATH_VARIABLE_DICTIONARY_TYPE}`;
export const PATH_CP_DICTIONARY_EDIT = `${PATH_CP_DICTIONARY_LIST}/edit/:${PATH_VARIABLE_DICTIONARY_ID}`;

export const PATH_CP_ASSORTMENTS = `${PATH_CP}/assortments`;
export const PATH_CP_ASSORTMENT = `${PATH_CP_ASSORTMENTS}/form/:${PATH_VARIABLE_ASSORTMENT_ID}`;
export const PATH_CP_ASSORTMENT_HISTORY = `${PATH_CP_ASSORTMENT}/history`;

export const PATH_CP_INTEGRATIONS = `${PATH_CP}/integrations`;
export const PATH_CP_INTEGRATIONS_DADATA = `${PATH_CP_INTEGRATIONS}/dadata`;
export const PATH_CP_INTEGRATIONS_EDI = `${PATH_CP_INTEGRATIONS}/edi`;
export const PATH_CP_INTEGRATIONS_EDI_SETTINGS = `${PATH_CP_INTEGRATIONS_EDI}/settings`;
export const PATH_CP_INTEGRATIONS_EDI_PARTY = `${PATH_CP_INTEGRATIONS_EDI}/party`;

export const PATH_CP_COMPANIES = `${PATH_CP}/companies`;
export const PATH_CP_COMPANY = `${PATH_CP_COMPANIES}/company/:${PATH_VARIABLE_COMPANY_ID}`;
export const PATH_CP_COMPANY_ADDRESSES = `${PATH_CP_COMPANY}/addresses`;
export const PATH_CP_COMPANY_ADDRESS = `${PATH_CP_COMPANY_ADDRESSES}/address/:${PATH_VARIABLE_COMPANY_ADDRESS_ID}`;
export const PATH_CP_COMPANY_ACCOUNTS = `${PATH_CP_COMPANY}/accounts`;
export const PATH_CP_COMPANY_ACCOUNT = `${PATH_CP_COMPANY_ACCOUNTS}/account/:${PATH_VARIABLE_COMPANY_ACCOUNT_ID}`;
export const PATH_CP_COMPANY_FACSIMILE = `${PATH_CP_COMPANY}/facsimile`;
export const PATH_CP_COMPANY_HISTORY = `${PATH_CP_COMPANY}/history`;

export const PATH_CP_VEHICLES = `${PATH_CP}/vehicles`;
export const PATH_CP_VEHICLE = `${PATH_CP_VEHICLES}/vehicle/:${PATH_VARIABLE_VEHICLE_ID}`;
export const PATH_CP_VEHICLE_HISTORY = `${PATH_CP_VEHICLE}/history`;

export const PATH_CP_STORAGES = `${PATH_CP}/storages`;
export const PATH_CP_STORAGE = `${PATH_CP_STORAGES}/form/:${PATH_VARIABLE_STORAGE_ID}`;
export const PATH_CP_STORAGE_CONTACTS = `${PATH_CP_STORAGE}/contacts`;
export const PATH_CP_STORAGE_CONTACT = `${PATH_CP_STORAGE_CONTACTS}/contact/:${PATH_VARIABLE_CLIENT_CONTACT_ID}`;

export const PATH_CP_TMCS = `${PATH_CP}/tmcs`;
export const PATH_CP_TMC = `${PATH_CP_TMCS}/tmc/:${PATH_VARIABLE_TMC_ID}`;
export const PATH_CP_TMC_HISTORY = `${PATH_CP_TMC}/history`;

export const PATH_KPP = '/kpp';

export const PATH_CP_DRIVERS = `${PATH_CP}/drivers`;
export const PATH_CP_DRIVER = `${PATH_CP_DRIVERS}/driver/:${PATH_VARIABLE_DRIVER_ID}`;
export const PATH_CP_DRIVER_HISTORY = `${PATH_CP_DRIVER}/history`;

export const PATH_CP_USERS = `${PATH_CP}/users`;
export const PATH_CP_USER = `${PATH_CP_USERS}/form/:${PATH_VARIABLE_USER_ID}`;
export const PATH_CP_USER_HISTORY = `${PATH_CP_USER}/history`;

export const PATH_CP_PRODUCTION_LINES = `${PATH_CP}/production-lines`;
export const PATH_CP_PRODUCTION_LINE = `${PATH_CP_PRODUCTION_LINES}/form/:${PATH_VARIABLE_PRODUCTION_LINE_ID}`;
export const PATH_CP_PRODUCTION_LINE_HISTORY = `${PATH_CP_PRODUCTION_LINE}/history`;

/**
 *      QUOTES / RUNS
 */
export const PATH_QUOTES = '/quotes';
export const PATH_QUOTES_DIRECTION = `${PATH_QUOTES}/:direction`;
export const PATH_QUOTE = `${PATH_QUOTES_DIRECTION}/quote/:${PATH_VARIABLE_QUOTE_ID}`;
export const PATH_QUOTE_HISTORY = `${PATH_QUOTE}/history`;

export const PATH_RUNS = '/runs';
export const PATH_RUNS_DIRECTION = `${PATH_RUNS}/:direction`;
export const PATH_RUN = `${PATH_RUNS_DIRECTION}/run/:${PATH_VARIABLE_RUN_ID}`;
export const PATH_RUN_HISTORY = `${PATH_RUN}/history`;
export const PATH_RUN_MAP = `${PATH_RUN}/map`;
export const PATH_RUN_ATTACHMENTS = `${PATH_RUN}/attachments`;
export const PATH_RUN_TALMANS = `${PATH_RUN}/talmans`;
export const PATH_RUN_TALMAN = `${PATH_RUN_TALMANS}/talman/:${PATH_VARIABLE_SHIPMENT_DETAIL_ID}`;

/**
 *      EDI
 */
export const PATH_EDI_MESSAGES = '/edi/messages';
export const PATH_EDI_MESSAGE_TO_QUOTE = `${PATH_EDI_MESSAGES}/compare/:${PATH_VARIABLE_EDI_MESSAGE_ID}`;

/**
 *      STORAGE
 */
export const PATH_STORAGE = '/storage';

export const PATH_STORAGE_QUOTES = `${PATH_STORAGE}/quotes`;
export const PATH_STORAGE_ASSEMBLY_PLAN = `${PATH_STORAGE}/assembly-plan`;

export const PATH_STORAGE_ASSORTMENT_OPERATIONS = `${PATH_STORAGE}/assortment-operations`;
export const PATH_STORAGE_ASSORTMENT_OPERATION = `${PATH_STORAGE_ASSORTMENT_OPERATIONS}/form/:${PATH_VARIABLE_ASSORTMENT_OPERATION_ID}`;
export const PATH_STORAGE_ASSORTMENT_OPERATION_HISTORY = `${PATH_STORAGE_ASSORTMENT_OPERATION}/history`;

export const PATH_STORAGE_TMC_OPERATIONS = `${PATH_STORAGE}/tmc-operations`;
export const PATH_STORAGE_TMC_OPERATION = `${PATH_STORAGE_TMC_OPERATIONS}/tmc-operation/:${PATH_VARIABLE_TMC_OPERATION_ID}`;
export const PATH_STORAGE_TMC_OPERATION_HISTORY = `${PATH_STORAGE_TMC_OPERATION}/history`;

export const PATH_STORAGE_ASSEMBLY = `${PATH_STORAGE}/assembly/:${PATH_VARIABLE_PRODUCTION_LINE_ID}`;
export const PATH_STORAGE_ASSEMBLY_ASSORTMENT = `${PATH_STORAGE_ASSEMBLY}/view/:${PATH_VARIABLE_ASSORTMENT_ID}`;
export const PATH_STORAGE_ASSEMBLY_ASSORTMENT_CLIENT_VIEW = `${PATH_STORAGE_ASSEMBLY_ASSORTMENT}/:${PATH_VARIABLE_CLIENT_ID}/:${PATH_VARIABLE_TMC_ID}`;

export const PATH_STORAGE_DISTRIBUTION = `${PATH_STORAGE}/distribution/:${PATH_VARIABLE_STORAGE_ID}`;
export const PATH_STORAGE_DISTRIBUTION_ASSORTMENT = `${PATH_STORAGE_DISTRIBUTION}/assortment/:${PATH_VARIABLE_ASSORTMENT_ID}`;
export const PATH_STORAGE_DISTRIBUTION_ASSORTMENT_CLIENT = `${PATH_STORAGE_DISTRIBUTION_ASSORTMENT}/client/:${PATH_VARIABLE_CLIENT_ID}`;

export const PATH_STORAGE_LOADING = `${PATH_STORAGE}/storage-loading`;

/**
 *      LINKS TO EXTERNAL RESOURCES
 */
export const EXTERNAL_LINK_BOOKSTACK = 'http://91.210.171.141:8089/';
export const EXTERNAL_LINK_SUPERSET = 'https://erp.agrorom.ru:444/superset/welcome/';

/**
 *      SEARCH PARAM NAMES
 */

export const SEARCH_PARAM_FORM_VERSION = "v";
export const SEARCH_PARAM_SOURCE_ID = "sourceId";
export const SEARCH_PARAM_EDI_MESSAGE_ID = "ediMessageId";
export const SEARCH_PARAM_CLIENT_EDI_MESSAGE_ID = "clientEdiMessageId";
export const SEARCH_PARAM_CONSIGNEE_EDI_MESSAGE_ID = "consigneeEdiMessageId";
