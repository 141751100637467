import { Option } from '../../component/control/option';
import { PROMISE_OK } from '../const';
import storages from '../../api/storages';
import { StorageType } from '../../model/enums/StorageType';
import { OperationType } from '../../model/enums/OperationType';

const emptyValidator = (_model: any) => Promise.resolve(PROMISE_OK);

type Validator<T> = (value: T) => Promise<string>;

const requiredValidator = (value: any) => Promise.resolve(value ? PROMISE_OK : 'обязательное поле');

const requiredListValidator = (value: any[]) => Promise.resolve(value?.length ? PROMISE_OK : 'обязательное поле');

const requiredOptionValidator = (option: Option) => {
    return Promise.resolve(option?.value > 0 ? PROMISE_OK : 'обязательное поле');
};

const nullableOrPositiveIntValidator = (value: any) => {
    if (value === null) {
        return Promise.resolve(PROMISE_OK);
    }
    return positiveIntValidator(value);
};

const notNullablePositiveIntValidator = (value: any) => {
    if (!value) {
        return requiredValidator(value);
    }
    return positiveIntValidator(value);
};

const positiveIntValidator = (value: any) => {
    const isValid =
        !value ||
        (!isNaN(value) && value >= 0 && value < 999999999 && Math.round(value) === Number(value));
    return Promise.resolve(isValid ? PROMISE_OK : 'значение должно быть положительным');
};

const notNullablePositiveDecimalValidator = (value: any) => {
    if (!value) {
        return requiredValidator(value);
    }
    return decimalValidator(value);
};

const decimalValidator = (value: any) => {
    const valid = !value || (!isNaN(value) && value >= 0 && value < 999999999);
    return Promise.resolve(valid ? PROMISE_OK : 'некорректный формат');
};

const emailValidator = (value: any) => {
    const REGEX_EMAIL = /^[a-zA_Z0-9._%+-]+@[a-zA_Z0-9.-]+\.[a-zA_Z]{2,6}$/;
    const valid = !value || value.match(REGEX_EMAIL);
    return Promise.resolve(valid ? PROMISE_OK : 'некорректный формат email');
};

const requiredOperationTypeValidator = async (storageTo: Option, operationType: Option) => {
    if (!operationType) {
        return requiredValidator(operationType);
    }
    return operationTypeValidator(storageTo, operationType);
};

const operationTypeValidator = async (storageTo: Option, operationType: Option) => {
    if (storageTo?.value) {
        const storageToModel = await storages.get(storageTo?.value);
        const { type: storageType } = storageToModel;
        const isField = storageType?.value === StorageType.FIELD;
        const isReceiptOrPosting = [OperationType.RECEIPT, OperationType.POSTING].includes(operationType?.value);
        return Promise.resolve(isReceiptOrPosting && isField
            ? 'для склада с типом "Поле" недоступны операции прихода и оприходования'
            : PROMISE_OK
        );
    }
    return Promise.resolve(PROMISE_OK);
};

const lengthValidator = async (str: string, validLength: number) => {
    return Promise.resolve(str.length === validLength ? PROMISE_OK : 'некорректный формат');
};

export {
    emptyValidator,
    requiredValidator,
    requiredListValidator,
    requiredOptionValidator,
    nullableOrPositiveIntValidator,
    notNullablePositiveIntValidator,
    positiveIntValidator,
    notNullablePositiveDecimalValidator,
    decimalValidator,
    emailValidator,
    requiredOperationTypeValidator,
    operationTypeValidator,
    lengthValidator,
};
export type { Validator };
