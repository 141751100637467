import React, { useContext, useMemo } from 'react';
import { generatePath, NavigateFunction, Outlet } from 'react-router-dom';
import clients from '../../../../api/clients';
import util from '../../../../common/util';
import { ClientConsigneeListModel } from '../../../../model/client/clientConsigneeListModel';
import {
    PATH_CP_CLIENT_CONSIGNEE,
    PATH_VARIABLE_CLIENT_CONSIGNEE_ID,
    PATH_VARIABLE_CLIENT_ID,
} from '../../../../routerPaths';
import { Config } from '../../../table/config';
import { DwTable, onRowDelete } from '../../../table/DwTable';
import { RemoveButton } from '../../../table/RemoveButton';
import { ClientFormContext } from '../ClientForm';
import { ClientConsigneeField } from './clientConsigneeField';

const ClientConsignees: React.FC = () => {
    const {
        client: { id: clientId },
    } = useContext(ClientFormContext);
    const config = useMemo(() => {
        return Config.builder<ClientConsigneeListModel>()
            .column((builder) => builder.id().filterable(false))
            .column((builder) =>
                builder.text('Грузополучатель', ClientConsigneeField.CONSIGNEE).sortable(false)
            )
            .column((builder) =>
                builder.text('ИНН', ClientConsigneeField.INN).sortable(false).cls('inn')
            )
            .column((builder) =>
                builder.text('ОГРН', ClientConsigneeField.OGRN).sortable(false).cls('ogrn')
            )
            .column((builder) =>
                builder.text('КПП', ClientConsigneeField.KPP).sortable(false).cls('kpp')
            )
            .column((builder) =>
                builder
                    .text('Фактический адрес', ClientConsigneeField.ACTUAL_ADDRESS)
                    .sortable(false)
            )
            .column((builder) =>
                builder.text('Телефон', ClientConsigneeField.PHONE).sortable(false).cls('phone')
            )
            .column((builder) => builder.text('E-mail', ClientConsigneeField.EMAIL).sortable(false))
            .column((builder) =>
                builder
                    .cls('text-center')
                    .sortable(false)
                    .filterable(false)
                    .getter((model, _, refresh) => (
                        <RemoveButton
                            onClick={() =>
                                onRowDelete(refresh, () =>
                                    clients.deleteClientConsignee(clientId, model.id)
                                )
                            }
                        />
                    ))
                    .width('45px')
            )
            .columnsSelector(false)
            .load((ctx) => clients.clientConsigneeList(ctx.state.request, clientId))
            .formUrl(
                generatePath(PATH_CP_CLIENT_CONSIGNEE, {
                    [PATH_VARIABLE_CLIENT_ID]: util.stringOrEmpty(clientId),
                    [PATH_VARIABLE_CLIENT_CONSIGNEE_ID]: '',
                })
            )
            .onClick((id: number, navigate: NavigateFunction) =>
                navigate(
                    generatePath(PATH_CP_CLIENT_CONSIGNEE, {
                        [PATH_VARIABLE_CLIENT_ID]: util.stringOrEmpty(clientId),
                        [PATH_VARIABLE_CLIENT_CONSIGNEE_ID]: util.stringOrEmpty(id),
                    })
                )
            )
            .id(`client_${clientId}_consignees`)
            .build();
    }, [clientId]);

    return (
        <>
            <div className='contracts d-flex mb-3'>
                <DwTable header='Грузополучатели' config={config} />
            </div>
            <Outlet />
        </>
    );
};

export default ClientConsignees;
