import util from "../../../common/util";
import ListDtPicker from "./ListDtPicker";

type Props = {
    id: string;
    dateFrom?: Date;
    timeFrom?: Date;
};

const DEFAULT_DURATION_HOURS = 1;

const ListDurationPicker: React.FC<Props> = ({
    id,
    dateFrom = new Date(),
    timeFrom = new Date()
}) => {
    const minDateTime = util.toDateForDtPicker(dateFrom, false);
    if (timeFrom) {
        minDateTime.setHours(util.toDateForDtPicker(timeFrom, true)?.getHours() ?? 0);
    }
    const valueToDateTransformer = (v: number) => {
        const date = new Date(minDateTime);
        date.setHours(date.getHours() + (v ?? DEFAULT_DURATION_HOURS));
        return date;
    };
    const dateToValueTransformer = (v: Date) => {
        const res = Math.ceil((v.getTime() - minDateTime.getTime()) / 1000 / 60 / 60); // to hours
        return (res && res > 0) ? res : DEFAULT_DURATION_HOURS; // 1 hour default
    };
    return (<ListDtPicker {...{ id, valueToDateTransformer, dateToValueTransformer, minDateTime, timeIntervals: 60 }} showTimeSelect />);
};

export default ListDurationPicker;
