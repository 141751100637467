import React, {useContext, useEffect, useMemo} from 'react';
import clsx from 'clsx';
import DatePicker from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import util from '../../common/util';
import 'react-datepicker/dist/react-datepicker.css';
import {FormContext, useSetFieldValue, validateField} from './DwForm';
import FormInvalidText from './FormInvalidText';
import {useIsMounted} from "../../common/isMounted";

type Props = {
    id: string;
    readOnly?: boolean;
    placeholder?: string;
    showTimeSelect?: boolean;
    showTimeSelectOnly?: boolean;
    onChange?: (newValue: Date) => void;
};

const FormDtPicker: React.FC<Props> = ({
    id,
    readOnly = false,
    placeholder = '',
    showTimeSelect = false,
    showTimeSelectOnly = false,
    onChange,
}) => {
    const context = useContext(FormContext);
    const {config, model, errors} = context.state;
    const setValue = useSetFieldValue();

    const fieldConfig = config.field(id),
        errorId = id,
        error = errors.get(errorId),
        fieldValue = fieldConfig.getter(model),
        value = useMemo(() => util.toDateForDtPicker(fieldValue, showTimeSelectOnly), [fieldValue, showTimeSelectOnly]),
        timeFormat = 'HH:mm',
        dateFormat = util.getDateFormats(showTimeSelect, showTimeSelectOnly);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [value]);

    const onChangeHandler = async (date: Date) => {
        if (onChange) {
            onChange(date);
        }
        setValue(id, date);
    };

    return (
        <div className='form-group'>
            <DatePicker
                placeholderText={placeholder}
                showTimeSelect={showTimeSelect}
                showTimeSelectOnly={showTimeSelectOnly}
                timeFormat={timeFormat}
                dateFormat={dateFormat}
                onChange={onChangeHandler}
                className={clsx('form-control form-control-sm', {
                    'is-invalid': !!error,
                })}
                selected={value}
                readOnly={readOnly}
                timeCaption={'Время'}
                locale={ru}
            />
            <FormInvalidText error={error}/>
        </div>
    );
}
export default FormDtPicker;
