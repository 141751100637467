import React, { useContext, useEffect, useRef, useState } from 'react';
import { COLUMN_TO_OPTION_TYPE, ColumnType } from './columnType';
import ListNumberInput from '../form/list/ListNumberInput';
import ListCheckboxInput from '../form/list/ListCheckboxInput';
import ListStringInput from '../form/list/ListStringInput';
import ListTimePicker from '../form/list/ListTimePicker';
import ListDtPicker from '../form/list/ListDtPicker';
import {
    ListAllCompanyAssortments,
    ListClientAssortments
} from '../form/list/ListAssortments';
import { columnClsx, columnStyle } from './Header';
import { Column } from './column';
import { DictionaryType } from '../control/option';
import ListOptions from '../form/list/ListOptions';
import ListTmc from '../form/list/ListTmc';
import ListClientContacts from '../form/list/ListClientContacts';
import { ListClientStorages } from '../form/list/ListClientStorages';
import ListClients from '../form/list/ListClients';
import { ClientType } from '../../model/client/clientModel';
import ClickOutsideWrapper from '../layout/ClickOutsideWrapper';
import ScrollWrapper from '../layout/ScrollWrapper';
import { EditableTableContext } from './EditableDwTable';
import { RunStatus } from '../../model/enums/RunStatus';
import { TmcCategory } from '../../model/tmc/tmcModel';
import ListLines from '../form/list/ListLines';
import ListDwStringInput from '../form/list/ListDwStringInput';

type Props = {
    col: Column<any, any>;
    formattedValue: any;
    cellProps: { [key: string]: any };
    editor: any;
    row: any;
};

const EditableCell: React.FC<Props> = (props) => {
    const { formattedValue, col } = props;
    const { setEditingRowNum } = useContext(EditableTableContext);
    const [cellHeight, setCellHeight] = useState(0);
    const [isEditing, setIsEditing] = useState(false);
    const cellRef = useRef<HTMLTableCellElement>(null);

    useEffect(() => {
        if (!isEditing && cellRef.current) {
            const height = cellRef.current.offsetHeight;
            setCellHeight(height);
        }
    }, [isEditing, cellRef, setCellHeight]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLTableCellElement>) => {
        switch (event.key) {
            case 'Tab':
                setIsEditing(true);
                break;
            case 'ArrowRight':
                handleArrowKey(true);
                break;
            case 'ArrowLeft':
                handleArrowKey(false);
                break;
            default:
                break;
        }
    };

    const handleArrowKey = (isRight: boolean) => {
        setIsEditing(true);
        if (cellRef.current) {
            const targetCell = isRight
                ? cellRef.current.nextElementSibling
                : cellRef.current.previousElementSibling;

            if (targetCell instanceof HTMLElement) {
                targetCell.focus();
            }
        }
    };

    const handleStopEditing = () => {
        setIsEditing(false);
        setEditingRowNum(-1);
    };

    return (
        <td
            ref={cellRef}
            tabIndex={0}
            onClick={() => setIsEditing(true)}
            onKeyDown={handleKeyDown}
            className={`${columnClsx(col)} ${isEditing ? 'td-editable' : ''}`}
            style={{
                ...columnStyle(col),
                height: isEditing ? cellHeight : 'auto'
            }}
        >
            {isEditing &&
                <ScrollWrapper listen={isEditing} callback={handleStopEditing}>
                    <ClickOutsideWrapper listen={isEditing} callback={handleStopEditing}>
                        <EditComponent {...props} />
                    </ClickOutsideWrapper>
                </ScrollWrapper>
            }
            {!isEditing &&
                (formattedValue ?? '')}
        </td>
    );
};

const EditComponent: React.FC<Props> = (props) => {
    const { cellProps, editor, row, col: { field: id, type, onChangeRow, nullable }} = props;

    if (editor) {
        return editor;
    }

    const onChange = (newValue: any, rowNum: number) => {
        onChangeRow(newValue, rowNum, row);
    };

    switch (type) {
        case ColumnType.INTEGER:
        case ColumnType.MONEY:
        case ColumnType.DECIMAL:
            return <ListNumberInput id={id} onChange={onChange}/>;
        case ColumnType.BOOLEAN:
            return <ListCheckboxInput id={id} label={'да'}/>;
        case ColumnType.ASSORTMENT:
            return <ListAllCompanyAssortments id={id} clientId={cellProps?.clientId}/>;
        case ColumnType.CLIENT_CONSIGNEE_ASSORTMENT:
            return <ListClientAssortments
                        id={id}
                        clientId={cellProps?.clientId}
                        consigneeId={cellProps?.consigneeId}
                        onChange={onChange}
                    />;
        case ColumnType.DATE_TIME:
            return <ListDtPicker id={id} onChange={onChange} showTimeSelect />;
        case ColumnType.DATE:
            return <ListDtPicker id={id} onChange={onChange}/>;
        case ColumnType.TIME:
            return <ListTimePicker id={id} onChange={onChange}/>;
        case ColumnType.TMC_PACKAGE_CATEGORY:
            return (
                <ListTmc
                    id={id}
                    tmcCategories={[TmcCategory.PACKAGE, TmcCategory.PACKAGE_OR_TARE]}
                />
            );
        case ColumnType.TMC_TARE_CATEGORY:
            return (
                <ListTmc
                    id={id}
                    tmcCategories={[
                        TmcCategory.TARE,
                        TmcCategory.PACKAGE_OR_TARE,
                        TmcCategory.BULK,
                    ]}
                    nullable={nullable}
                />
            );
        case ColumnType.CLIENT_CONTACT:
            return <ListClientContacts id={id} clientId={cellProps?.clientId} onChange={onChange}/>;
        case ColumnType.CLIENT_STORAGE:
            return <ListClientStorages id={id} clientId={cellProps?.clientId} onChange={onChange}/>;
        case ColumnType.CARRIER:
            return <ListClients id={id} clientTypes={[ClientType.CARRIER, ClientType.SUPPLIER]} onChange={onChange}/>;
        case ColumnType.CLIENT:
            return <ListClients id={id} clientTypes={[ClientType.CLIENT]} onChange={onChange}/>;
        case ColumnType.PRODUCTION_LINE:
            return <ListLines id={id} storageId={cellProps?.storageId}/>;
        case ColumnType.KPP_RUN_STATUS:
            return <ListOptions
                        id={id}
                        dictionaryType={DictionaryType.RUN_STATUS}
                        filterCallback={(option) => option.value === RunStatus.LOADING}
                    />;
        case ColumnType.RUN_LOADING_STATUS:
            return (
                <ListOptions
                    id={id}
                    dictionaryType={DictionaryType.RUN_STATUS}
                    filterCallback={(option) =>
                        [RunStatus.LOADED, RunStatus.CANCELLED, RunStatus.ON_WAY].includes(option.value)
                    }
                />
            );
        case ColumnType.STORAGE_STATUS:
        case ColumnType.RUN_STATUS:
        case ColumnType.QUOTE_STATUS:
        case ColumnType.EXPENSE_ITEM:
        case ColumnType.ATTACHMENT_KIND:
        case ColumnType.UNIT:
            return <ListOptions id={id} dictionaryType={COLUMN_TO_OPTION_TYPE.get(type)!}/>;
        case ColumnType.DW_TEXT:
            return <ListDwStringInput id={id}/>;
        default:
            return <ListStringInput id={id}/>;
    }
};

export default EditableCell;
