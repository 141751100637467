import React, { MouseEvent, useContext, useMemo } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { TalmanModel } from '../model/talman/talmanModel';
import {
    PATH_RUN_TALMANS,
    PATH_VARIABLE_SHIPMENT_DETAIL_ID
} from '../../../routerPaths';
import util from '../../../common/util';
import { useRefreshContextAction } from '../../table/DwTable';
import './talman.scss';
import talmans from '../../../api/talman';
import { TalmanField } from './talmanField';
import { FormConfig } from '../../form/formConfig';
import { PalletPassportListModel } from '../model/talman/palletPassportModel';
import { DwForm, useFieldValue } from '../../form/DwForm';
import { RunFormContext } from '../RunForm';
import StringInput from '../../form/StringInput';
import DtPicker from '../../form/DtPicker';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';
import { PalletPassportTable } from './PalletPassportTable';
import docs from '../../../api/docs';
import { FileType } from '../../../model/fileType';
import { palletPassportsValidator } from '../../../common/validation/runValidators';

const emptySetter = (m: TalmanModel, v: any) => {};

const useFormConfig = (
    refresh: () => void,
    shipmentDetailId: number,
    runId: number,
    direction: string
) =>
    useMemo(
        () =>
            FormConfig.builder<TalmanModel>()
                .number(
                    TalmanField.QUOTE_ID,
                    (m) => m.quoteId,
                    emptySetter
                )
                .text(
                    TalmanField.QUOTE_NUMBER,
                    (m) => m.quoteNumber,
                    emptySetter
                )
                .text(
                    TalmanField.UNLOADING_STORAGE,
                    (m) => m.unloadingStorage,
                    emptySetter
                )
                .text(
                    TalmanField.SEAL,
                    (m) => m.seal,
                    (m, v) => m.seal = v
                )
                .text(
                    TalmanField.INSPECTION_PLACE,
                    (m) => m.inspectionPlace,
                    emptySetter
                )
                .dateTime(
                    TalmanField.INSPECTION_DATETIME,
                    (m) => m.inspectionDatetime,
                    (m, v) => m.inspectionDatetime = v
                )
                .text(
                    TalmanField.VEHICLES_INFO,
                    (m) => m.vehiclesInfo,
                    emptySetter
                )
                .dateTime(
                    TalmanField.LOADING_DATETIME,
                    (m) => m.loadingDatetime,
                    emptySetter
                )
                .text(
                    TalmanField.ASSORTMENT,
                    (m) => m.assortment,
                    emptySetter
                )
                .number(
                    TalmanField.TARE_QUANTITY,
                    (m) => m.tareQuantity,
                    emptySetter
                )
                .number(
                    TalmanField.TARE_DISTRIBUTED,
                    (m) => m.tareDistributed,
                    emptySetter
                )
                .number(
                    TalmanField.CLIENT_ASSORTMENT_BOXES,
                    (m) => m.clientAssortmentBoxes,
                    emptySetter
                )
                .text(
                    TalmanField.CLIENT,
                    (m) => m.client,
                    emptySetter
                )
                .number(
                    TalmanField.MAX_PALLET_PASSPORTS,
                    (m) => m.maxPalletPassports,
                    emptySetter
                )
                .sortedList<PalletPassportListModel>(
                    TalmanField.PALLET_PASSPORTS,
                    (m) => m.palletPassports,
                    (a: PalletPassportListModel, b: PalletPassportListModel) => util.numberSort(a?.id, b?.id),
                    (m, v) => (m.palletPassports = v),
                    (m) => palletPassportsValidator(m.maxPalletPassports, m.palletPassports)
                )

                .load((id) => id > 0 ? talmans.get(id) : Promise.resolve({} as TalmanModel))
                .submit((model) => talmans.save(shipmentDetailId, model))
                .redirectUrl(
                    generatePath(PATH_RUN_TALMANS, { direction, runId: util.toString(runId) })
                )
                .idPathVariableName(PATH_VARIABLE_SHIPMENT_DETAIL_ID)
                .afterSubmit((_) => refresh())
                .build(),
        [direction, runId, shipmentDetailId, refresh]
    );

export const TalmanForm: React.FC = () => {
    const { [PATH_VARIABLE_SHIPMENT_DETAIL_ID]: shipmentDetailIdParam } = useParams();
    const shipmentDetailId = util.toNumber(shipmentDetailIdParam ?? '') ?? -1;
    const { run: { id: runId } } = useContext(RunFormContext);
    const { refresh } = useRefreshContextAction();
    const { direction } = useParams<{ direction: string }>();
    const formConfig = useFormConfig(refresh, shipmentDetailId, runId, direction!);

    return (
        <DwForm config={formConfig}>
            <TalmanFormLayout />
        </DwForm>
    );
};

const downloadTalman = (e: MouseEvent, shipmentDetailId: number, fileType: FileType) => {
    e.preventDefault();
    void docs.talman(shipmentDetailId, fileType);
};

const TalmanFormLayout: React.FC = () => {
    const { [PATH_VARIABLE_SHIPMENT_DETAIL_ID]: shipmentDetailIdParam } = useParams();
    const shipmentDetailId = util.toNumber(shipmentDetailIdParam ?? '') ?? -1;
    const quoteId = useFieldValue(TalmanField.QUOTE_ID);
    const quoteNumber = useFieldValue(TalmanField.QUOTE_NUMBER);
    const client = useFieldValue(TalmanField.CLIENT);
    const unloadingStorage = useFieldValue(TalmanField.UNLOADING_STORAGE);
    const inspectionPlace = useFieldValue(TalmanField.INSPECTION_PLACE);
    const vehiclesInfo = useFieldValue(TalmanField.VEHICLES_INFO);
    const loadingDatetime = useFieldValue(TalmanField.LOADING_DATETIME);
    const assortment = useFieldValue(TalmanField.ASSORTMENT);

    return (
        <div className='talman'>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col'>
                            Номер заказа
                            <div className='font-weight-bold form-group'>{quoteNumber ?? quoteId}</div>
                        </div>
                        <div className='col'>
                            Заказчик
                            <div className='form-group'>{client ?? '-'}</div>
                        </div>
                        <div className='col'>
                            Скдад разгрузки
                            <div className='form-group'>{unloadingStorage ?? '-'}</div>
                        </div>
                        <div className='col'>
                            Пломба
                            <StringInput id={TalmanField.SEAL} />
                        </div>
                        <div className='col'>
                            Номенклатура
                            <div className='form-group'>{assortment ?? '-'}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            Место инспекции
                            <div className='form-group'>{inspectionPlace ?? '-'}</div>
                        </div>
                        <div className='col'>
                            Дата и время инспекции
                            <DtPicker id={TalmanField.INSPECTION_DATETIME} showTimeSelect />
                        </div>
                        <div className='col'>
                            Транспорт
                            <div className='form-group'>{vehiclesInfo ?? '-'}</div>
                        </div>
                        <div className='col'>
                            Дата и время погрузки
                            <div className='form-group'>{util.formatDateTime(loadingDatetime)}</div>
                        </div>
                    </div>
                    <div className='row'>
                        <PalletPassportTable id={TalmanField.PALLET_PASSPORTS} />
                    </div>
                </div>
            </div>
            <div className='row mb-3'>
                <div className='col-lg-4 text-left'/>
                <div className='col-lg-4 text-center'>
                    <SaveAndCancelButtonGroup />
                </div>
                <div className='col-lg-4 text-right'>
                    <button className='btn btn-sm btn-primary' onClick={(e: MouseEvent) => downloadTalman(e, shipmentDetailId, FileType.PDF)}>
                        Скачать в pdf
                    </button>
                </div>
            </div>
        </div>
    );
};
