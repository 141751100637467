import React from 'react';
import runs from '../../api/runs';
import AjaxSelect from '../control/AjaxSelect';

import type { Option } from '../control/option';
import type { MultiValue, SingleValue } from 'react-select/dist/declarations/src/types';

type Props = {
    isCarrierThirdParty: boolean;
    clientId: number;
    loadingDate: Date;
    loadingAddress: Option;
    onChange: (newValue: SingleValue<Option>) => void;
    isDomesticQuote: boolean;
};

const RunsOptions: React.FC<Props> = ({
    isCarrierThirdParty,
    clientId,
    loadingDate,
    loadingAddress,
    onChange,
    isDomesticQuote,
}) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        runs.quoteOptions({
            isCarrierThirdParty,
            clientId,
            loadingDate,
            loadingAddress,
            isDomesticQuote,
            filter: inputValue,
        }).then((options) => callback(options));
    };
    return (
        <div className='form-group run-options'>
            <AjaxSelect
                onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
                loadOptions={loadOptions}
                cacheOptions={false}
                value={{ value: -1, label: '(добавить к рейсу)' }}
            />
        </div>
    );
};

export default RunsOptions;
