import { ElementType, HTMLAttributes, ReactNode } from "react";
import "./diff-container.scss";

type DiffContainerPrimitiveAndDateValueTypes = Date | string | number | boolean | undefined;

type RemoveChildrenField<Type> = {
    [Property in keyof Type as Exclude<Property, "children">]: Type[Property]
};

interface DiffContainerProps<T> extends RemoveChildrenField<HTMLAttributes<HTMLOrSVGElement>> {
    readonly as?: ElementType;
    readonly target: T;
    readonly source: T;
    readonly getter?: (value: T) => DiffContainerPrimitiveAndDateValueTypes;
    readonly children?: (target: T, source: T) => ReactNode;
}

export default function DiffContainer<T>(props: DiffContainerProps<T>) {
    const {
        target,
        source,
        getter = (v) => v,
        children,
        as: Tag = 'div',
        ...otherProps
    } = props;
    const valueTarget = getter(target),
        valueSource = getter(source);
    return (
        <Tag {...otherProps} className={`${otherProps.className} diff-container ${valueTarget === valueSource ? '' : 'has-differencies'}`}>
            {children ? children(target, source) : valueTarget}
        </Tag>
    );
}