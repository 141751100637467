import React from 'react';
import ListSelect from './base/ListSelect';
import { Option } from '../../control/option';
import contacts from '../../../api/contacts';

type Props = {
    id: string;
    clientId: number;
    onChange?: (newValue: any, rowNum: number) => void;
};

const ListClientContacts: React.FC<Props> = ({ id, clientId, onChange }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        contacts
            .clientOptions({
                filter: inputValue,
                clientId: clientId,
            })
            .then((options) => callback(options));
    };
    return (
        <ListSelect id={id} loadOptions={loadOptions} onChange={onChange} />
    );
};

export default ListClientContacts;
