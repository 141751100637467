import React from 'react';
import {useFieldValue, useSetFieldValue} from '../../form/DwForm';
import NumberInput from '../../form/NumberInput';
import TextInput from '../../form/TextInput';
import util from '../../../common/util';
import Options from '../../form/Options';
import {DictionaryType} from '../../control/option';
import {AssortmentOperationField} from './assortmentOperationField';
import {QuotesForAssortmentOptions, RunsForAssortmentOptions} from './QuoteRunForAssortmentOptions';
import {ClientStorages, Storages} from '../../form/ClientStorages';
import {OperationType} from '../../../model/enums/OperationType';
import Assortments from '../../form/Assortments';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';

const AssortmentOperation: React.FC = () => {
    const setValue = useSetFieldValue();
    const valueDt = useFieldValue(AssortmentOperationField.DT);
    const valueType = useFieldValue(AssortmentOperationField.TYPE);

    return (
        <>
            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-3'>
                            Операция
                            <Options
                                id={AssortmentOperationField.TYPE}
                                dictionaryType={DictionaryType.OPERATION_TYPE}
                            />
                            Дата операции: {util.formatDate(valueDt ?? new Date())}
                        </div>
                        <div className='col-3'>
                            Откуда
                            <Storages
                                id={AssortmentOperationField.STORAGE_FROM}
                                onChange={() => setValue(AssortmentOperationField.CLIENT_ADDRESS_FROM, null)}/>
                            <ClientStorages
                                id={AssortmentOperationField.CLIENT_ADDRESS_FROM}
                                onChange={() => setValue(AssortmentOperationField.STORAGE_FROM, null)}
                            />
                        </div>
                        <div className='col-3'>
                            Куда
                            <Storages
                                id={AssortmentOperationField.STORAGE_TO}
                                onChange={() => setValue(AssortmentOperationField.CLIENT_ADDRESS_TO, null)}
                            />
                            <ClientStorages
                                id={AssortmentOperationField.CLIENT_ADDRESS_TO}
                                onChange={() => setValue(AssortmentOperationField.STORAGE_TO, null)}
                            />
                        </div>
                        <div className='col-3'>
                            Номенклатура
                            <Assortments id={AssortmentOperationField.ASSORTMENT}/>
                        </div>
                        <div className='col-3'>
                            Категория
                            <Options
                                id={AssortmentOperationField.CATEGORY}
                                dictionaryType={DictionaryType.TMC_CATEGORY}
                            />
                        </div>
                        <div className='col-3'>
                            Количество
                            <NumberInput id={AssortmentOperationField.QUANTITY}/>
                        </div>
                        <div className='col-3'>
                            Вес
                            <NumberInput id={AssortmentOperationField.WEIGHT}/>
                        </div>
                        <div className='col-3'>
                            Рейс:
                            <RunsForAssortmentOptions
                                fieldId={AssortmentOperationField.RUN}
                            />
                        </div>
                        <div className='col-3'>
                            Заказ:
                            <QuotesForAssortmentOptions
                                fieldId={AssortmentOperationField.QUOTE}
                            />
                        </div>
                        {valueType && valueType.value === OperationType.MISGRADING && (
                            <div className='col-3'>
                                Номенклатура назначения
                                <Assortments id={AssortmentOperationField.ASSORTMENT_TO}/>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className='card mb-3'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <TextInput id={AssortmentOperationField.COMMENT}/>
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </>
    );
};

export default AssortmentOperation;
