import clsx from 'clsx';
import React, { MouseEvent, useCallback, useEffect, useRef } from 'react';
import ClickOutsideWrapper from './ClickOutsideWrapper';

type DropdownProps = {
    label: string;
    children: React.ReactNode;
};

const MAX_MENU_HEIGHT = 200;
const AVG_OPTION_HEIGHT = 30;

const Dropdown: React.FC<DropdownProps> = (props) => {
    const [show, setShow] = React.useState(false);
    const [showUp, setShowUp] = React.useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const toggle = () => setShow(!show);

    const handleMenuResize = useCallback(() => {
        const dropdown = dropdownRef?.current;
        const dropdownMenu = dropdown?.querySelector('.dropdown-menu');
        if (dropdown && dropdownMenu) {
            const dropdownItems = dropdownMenu.querySelectorAll('a.dropdown-item');
            const menuHeight = Math.max(MAX_MENU_HEIGHT, (dropdownItems.length * AVG_OPTION_HEIGHT));
            const { bottom } = show ? dropdownMenu.getBoundingClientRect() : dropdown.getBoundingClientRect();
            const bottomOverflow = (bottom + menuHeight) >= window.innerHeight;
            setShowUp(bottomOverflow);
        }
    }, [dropdownRef, show]);

    useEffect(() => {
        handleMenuResize();
        window.addEventListener('resize', handleMenuResize);
        window.addEventListener('scroll', handleMenuResize);
        return () => {
            window.removeEventListener('resize', handleMenuResize);
            window.removeEventListener('scroll', handleMenuResize);
        };
    }, [handleMenuResize]);

    return (
        <div ref={dropdownRef} className={clsx(
                'dropdown',
                {
                    'dropup': showUp,
                    'show': show,
                },
                'd-inline-block',
                'ml-1',
            )}
            onClick={toggle}
        >
            <button
                className='btn btn-primary btn-sm dropdown-toggle'
                type='button'
                id='dropdownMenu'
                data-toggle='dropdown'
                aria-haspopup='true'
                aria-expanded='false'
            >
                {props.label}
            </button>
            <ClickOutsideWrapper
                listen={show}
                callback={() => setShow(false)}
                className={clsx(
                    'dropdown-menu', 'dropdown-menu-right', { 'show': show })
                }
                aria-labelledby='dropdownMenu'
            >
                {props.children}
            </ClickOutsideWrapper>
        </div>
    );
};

type DropdownItemProps = {
    label: string;
    onClick: () => void;
};

const DropdownItem: React.FC<DropdownItemProps> = (props) => {
    const onClick = (e: MouseEvent) => {
        e.preventDefault();
        props.onClick();
    };

    return (
        <a className='dropdown-item' href='#' onClick={onClick}>
            {props.label}
        </a>
    );
};

export { Dropdown, DropdownItem };
