export enum ContractType {
    CARRIER = 1,
    CLIENT = 2,
    SUPPLIER = 4,
}

export const CLIENT_CONTRACT_TYPES = [ContractType.CLIENT];
export const CARRIER_CONTRACT_TYPES = [ContractType.CARRIER];
export const CLIENT_OR_CARRIER_CONTRACT_TYPES = [ContractType.CLIENT, ContractType.CARRIER];
export const SUPPLIER_CONTRACT_TYPES = [ContractType.SUPPLIER];
export const CLIENT_OR_SUPPLIER_TYPES = [ContractType.CLIENT, ContractType.SUPPLIER];

export const ContractTypeNames: Map<string, ContractType> = new Map([
    ['С Перевозчиком', ContractType.CARRIER],
    ['С Заказчиком', ContractType.CLIENT],
    ['С Поставщиком', ContractType.SUPPLIER],
]);
