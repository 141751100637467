import React, {ChangeEvent, useContext, useEffect} from 'react';
import {FormContext, useSetFieldValue, validateField} from './DwForm';
import clsx from 'clsx';
import FormInvalidText from './FormInvalidText';
import {useIsMounted} from "../../common/isMounted";

type Props = {
    id: string;
    rows?: number;
};

const TextInput: React.FC<Props> = ({id, rows}) => {
    const context = useContext(FormContext);
    const {config, model, errors} = context.state;
    const setValue = useSetFieldValue();
    const fieldConfig = config.field(id),
        error = errors.get(id),
        value = fieldConfig.getter(model);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted) {
            validateField(context, fieldConfig, model).then();
        }
    }, [value]);

    const onChange = async (event: ChangeEvent<any>) => {
        const inputValue = event.target.value;
        setValue(id, inputValue);
    };

    return (
        <div className={clsx('form-group', {error: !!error})}>
            <textarea
                value={value ?? ''}
                onChange={onChange}
                className={'form-control form-control-sm'}
                rows={rows}
            />
            <FormInvalidText error={error}/>
        </div>
    );
}
export default TextInput;
