import { deleteRequest, get, post, put } from './rest';

import type { ApiResponse } from './apiResponse';
import type { PagedResponse } from './pagedResponse';
import type { ListRequest } from '../component/table/listRequest';
import type {
    ProductionLineRequest,
    ProductionLineModel,
} from '../model/productionLine/productionLineModel';
import type { ProductionLineListModel } from '../model/productionLine/productionLineListModel';
import { Option } from '../component/control/option';

const PATH_BASE = 'production-line';

const productionLines = {
    list: (model: ListRequest) =>
        post<PagedResponse<ProductionLineListModel>>(`${PATH_BASE}/list`, model),
    get: (id: number) => get<ProductionLineModel>(`${PATH_BASE}/${id}`),
    save: (model: ProductionLineModel) =>
        model.id > 0 ? put<number>(PATH_BASE, model) : post<number>(PATH_BASE, model),
    delete: (id: number) => deleteRequest<ApiResponse>(`${PATH_BASE}/${id}`),
    isLineAndStoragePairUnique: (id: number, lineNumber: number, storageId: number) =>
        post<ApiResponse>(`${PATH_BASE}/line-number-storage/unique`, { id, lineNumber, storageId }),
    allOptions: (request: ProductionLineRequest) =>
        post<Option[]>(`${PATH_BASE}/all-options`, request),
};

export default productionLines;
