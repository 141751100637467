import './quotes.scss';
import React from 'react';
import { Direction } from '../../common/const';
import { DwTable } from '../table/DwTable';
import { Config } from '../table/config';
import quotes from '../../api/quotes';
import { ColumnType } from '../table/columnType';
import { generatePath, useParams } from 'react-router-dom';
import { PATH_QUOTE, PATH_QUOTES_DIRECTION, PATH_VARIABLE_QUOTE_ID } from '../../routerPaths';
import type { QuoteListModel } from './model/quoteListModel';
import CopyButton from '../table/CopyButton';
import { QuoteField } from './quoteField';
import { Option } from '../control/option';
import { QuoteModel } from './model/quoteModel';
import ShipmentDetailsOnHover from './shipment-details/ShipmentDetailsOnHover';

const onChangeStatus = async (id: number, status: Option) => quotes.save({ id, status } as QuoteModel);

const loadFunctions = new Map<string, any>([
    [Direction.EXTERNAL, (ctx: any) => quotes.list(ctx.state.request)],
    [Direction.DOMESTIC, (ctx: any) => quotes.domesticQuoteList(ctx.state.request)],
]);

const Quotes: React.FC = () => {
    const { direction } = useParams<{ direction: string }>();
    const loadFunction = loadFunctions.get(direction!);

    const config = Config.builder<QuoteListModel>()
        .column((builder) =>
            builder
                .label('#')
                .type(ColumnType.ID)
                .field(QuoteField.ID)
                .getter((model) => (
                    <ShipmentDetailsOnHover id={model.id} className='fit-parent-size'>
                        {model.id}
                    </ShipmentDetailsOnHover>
                ))
        )
        .column((builder) => builder.date('Дата погрузки', QuoteField.LOADING_DATE))
        .column((builder) => builder.client('Заказчик', QuoteField.CLIENT))
        .column((builder) => builder.text('Номер заказа Заказчика', QuoteField.CLIENT_QUOTE_NUMBER))
        .column((builder) =>
            builder
                .label('Источник заказа')
                .field(QuoteField.SOURCE)
                .type(ColumnType.QUOTE_SOURCE)
                .cls('text-center')
                .sortable(false)
                .width('120px')
        )
        .column((builder) =>
            builder.text('Склад разгрузки', QuoteField.UNLOADING_STORAGE).getter((model) => {
                if (model.unloadDomesticStorage) {
                    return model.unloadDomesticStorage;
                } else if (model.unloadConsigneeStorage) {
                    return model.unloadConsigneeStorage;
                } else {
                    return model.unloadingStorage;
                }
            })
        )
        .column((builder) => builder.text('Адрес разгрузки', QuoteField.UNLOADING_ADDRESS))
        .column((builder) => builder.date('Дата разгрузки', QuoteField.UNLOADING_DATE))
        .column((builder) =>
            builder
                .label('Статус')
                .field(QuoteField.STATUS)
                .type(ColumnType.QUOTE_STATUS)
                .cls('status')
                .editable()
                .onChangeRow((value, idx, row) => onChangeStatus(row.id, value))
        )
        .column((builder) =>
            builder
                .text('Склад погрузки', QuoteField.LOADING_STORAGE)
                .getter((model) =>
                    model.carrierThirdParty ? model.loadThirdPartyStorage : model.loadingStorage
                )
        )
        .column((builder) => builder.text('Адрес погрузки', QuoteField.LOADING_ADDRESS))
        .column((builder) =>
            builder
                .label('Статус склада')
                .field(QuoteField.STORAGE_STATUS)
                .type(ColumnType.STORAGE_STATUS)
                .sortable(false)
        )
        .column((builder) => builder.description('Описание', QuoteField.DESCRIPTION))
        .column((builder) => builder.date('Создана', QuoteField.CREATED))
        .column((builder) => builder.date('Действует до', QuoteField.EXPIRE))
        .column((builder) =>
            builder.label('Менеджер').field(QuoteField.MANAGER).type(ColumnType.COMMERCIAL_MANAGER)
        )
        .column((builder) =>
            builder.company('Организация для заказчика', QuoteField.CLIENT_COMPANY)
        )
        .column((builder) => builder.text('Договор с заказчиком', QuoteField.CLIENT_CONTRACT_NO))
        .column((builder) =>
            builder
                .label('Форма оплаты заказчика')
                .field(QuoteField.CLIENT_PAYMENT_METHOD)
                .cls('nds')
                .type(ColumnType.PAYMENT_METHOD)
        )
        .column((builder) =>
            builder
                .label('Условия оплаты заказчика')
                .field(QuoteField.CLIENT_PAYMENT_TYPE)
                .type(ColumnType.PAYMENT_TYPE)
        )
        .column((builder) =>
            builder
                .integer('Отсрочка платежа заказчика', QuoteField.CLIENT_PAYMENT_DELAY)
                .cls('text-center')
                .getter((model) =>
                    model.clientPaymentDelay > 0 ? model.clientPaymentDelay + ' дн.' : '-'
                )
        )
        .column((builder) =>
            builder.date('Плановая дата оплаты заказчика', QuoteField.CLIENT_PLANNED_PAYMENT_DATE)
        )
        .column((builder) =>
            builder.date('Фактическая дата оплаты заказчика', QuoteField.CLIENT_PAYMENT_DATE)
        )
        .column((builder) => builder.text('Контактное лицо заказчика', QuoteField.CLIENT_CONTACT))
        .column((builder) =>
            builder.company('Организация для поставщика', QuoteField.CARRIER_COMPANY)
        )
        .column((builder) => builder.supplier('Поставщик', QuoteField.CARRIER))
        .column((builder) => builder.text('Договор с поставщиком', QuoteField.CARRIER_CONTRACT_NO))
        .column((builder) =>
            builder
                .label('Форма оплаты поставщика')
                .field(QuoteField.CARRIER_PAYMENT_METHOD)
                .cls('nds')
                .type(ColumnType.PAYMENT_METHOD)
        )
        .column((builder) =>
            builder
                .label('Условия оплаты поставщика')
                .field(QuoteField.CARRIER_PAYMENT_TYPE)
                .type(ColumnType.PAYMENT_TYPE)
        )
        .column((builder) =>
            builder
                .integer('Отсрочка платежа поставщика', QuoteField.CARRIER_PAYMENT_DELAY)
                .cls('text-center')
                .getter((model) =>
                    model.carrierPaymentDelay > 0 ? model.carrierPaymentDelay + ' дн.' : '-'
                )
        )
        .column((builder) =>
            builder
                .date('Плановая дата оплаты поставщика', QuoteField.CARRIER_PLANNED_PAYMENT_DATE)
                .sortable(false)
        )
        .column((builder) =>
            builder.date('Фактическая дата оплаты поставщика', QuoteField.CARRIER_PAYMENT_DATE)
        )
        .column((builder) => builder.text('Контактное лицо поставщика', QuoteField.CARRIER_CONTACT))
        .column((builder) => builder.money('Стоимость заказа', QuoteField.SELL_PRICE))
        .column((builder) => builder.money('Стоимость закупа', QuoteField.BUY_PRICE))
        .column((builder) =>
            builder.boolean('Навал', QuoteField.IS_BULK).sortable(false).cls('boolean')
        )
        .column((builder) =>
            builder
                .integer('Общий вес по заказу', QuoteField.WEIGHT)
                .sortable(false)
                .cls('text-center')
        )
        .column((builder) =>
            builder
                .integer('Номер рейса', QuoteField.RUN)
                .cls('text-center')
                .getter((model) => (model.run > 0 ? `Рейс №${model.run}` : ''))
                .withEmptyValuesFilter(true)
        )
        .column((builder) =>
            builder
                .label('Статус рейса')
                .field(QuoteField.RUN_STATUS)
                .type(ColumnType.RUN_STATUS)
                .cls('status')
                .sortable(false)
        )
        .column((builder) =>
            builder
                .label('Копировать заказ')
                .cls('text-center')
                .sortable(false)
                .filterable(false)
                .getter((model) => (
                    <ShipmentDetailsOnHover id={model.id}>
                        <CopyButton
                            id={model.id}
                            basePath={generatePath(PATH_QUOTE, {
                                direction,
                                [PATH_VARIABLE_QUOTE_ID]: '',
                            })}
                            idPathVariableName={PATH_VARIABLE_QUOTE_ID}
                        />
                    </ShipmentDetailsOnHover>
                ))
                .width('70px')
        )
        .formUrl(generatePath(PATH_QUOTE, { direction, [PATH_VARIABLE_QUOTE_ID]: '' }))
        .load(loadFunction)
        .id('quotes')
        .editable(true)
        .tabs([
            {
                name: 'Внешние заказы',
                path: generatePath(PATH_QUOTES_DIRECTION, { direction: Direction.EXTERNAL }),
                end: true,
            },
            {
                name: 'Внутренние заказы',
                path: generatePath(PATH_QUOTES_DIRECTION, { direction: Direction.DOMESTIC }),
                end: true,
            },
        ])
        .defaultHidden([
            'expire',
            'manager',
            'clientCompany',
            'clientContractNo',
            'clientNdsMode',
            'clientPaymentType',
            'clientPaymentDelay',
            'clientPlannedPaymentDate',
            'clientPaymentDate',
            'clientContact',
            'carrierCompany',
            'carrier',
            'carrierContractNo',
            'carrierNdsMode',
            'carrierPaymentType',
            'carrierPaymentDelay',
            'carrierPlannedPaymentDate',
            'carrierPaymentDate',
            'carrierContact',
            'sellPrice',
            'buyPrice',
            'created',
        ])
        .build();

    return (
        <div className='quotes d-flex'>
            <DwTable header='Заказы' config={config} />
        </div>
    );
};

export default Quotes;
