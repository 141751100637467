import React, { MouseEvent, useContext, useEffect } from 'react';
import clsx from 'clsx';
import { FormContext, useSetFieldValue, validateField } from './DwForm';
import { FieldType } from './fieldType';
import FormInvalidText from './FormInvalidText';
import { useIsMounted } from '../../common/isMounted';

type Props = {
    id: string;
    label?: string;
    placeholder?: string;
    maxLength?: number;
    classNames?: string[];
    autoFocus?: boolean;
    readOnly?: boolean;
    onChange?: (newValue: any) => void;
    disablePasswordAutoComplete?: boolean;
    clearable?: boolean;
};

const safeValue = (value?: string | ReadonlyArray<string> | number | undefined) => value ?? '';
const getType = (type: FieldType) => FieldType[type].toLowerCase();

const Input: React.FC<Props> = ({
    id,
    label = '',
    placeholder = '',
    maxLength,
    classNames = [],
    autoFocus = false,
    readOnly = false,
    onChange,
    disablePasswordAutoComplete,
    clearable = false
}) => {
    const context = useContext(FormContext);
    const { state: { config, model, errors }, dwSubmitWithoutRedirect } = context;
    const setValue = useSetFieldValue();
    const fieldConfig = config.field(id),
        error = errors.get(id),
        value = fieldConfig.getter(model);

    const isMounted = useIsMounted();
    useEffect(() => {
        if (isMounted && value!) {
            validateField(context, fieldConfig, model).then();
        }
    }, [value]);

    const onChangeHandler = (value: string | null) => {
        const typedValue = ('number' === getType(fieldConfig.type) && value !== null) ? Number(value) : value;
        if (onChange) {
            onChange(typedValue);
        }
        setValue(id, typedValue);
    };

    const onChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChangeHandler(e.target.value);
    };

    const onResetEventHandler = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        clearable && onChangeHandler(null);
    };

    const onKey = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            dwSubmitWithoutRedirect(e, { showSaveToast: false });
        }
    };

    return (
        <div className='form-group '>
            <div className={clearable ? 'input-group' : ''}>
                <input
                    type={getType(fieldConfig.type)}
                    value={safeValue(value)}
                    onChange={onChangeEventHandler}
                    maxLength={maxLength}
                    placeholder={placeholder}
                    className={clsx(
                        { 'is-invalid': !!error },
                        'form-control form-control-sm',
                        ...classNames
                    )}
                    autoFocus={autoFocus}
                    onKeyDown={onKey}
                    readOnly={readOnly}
                    autoComplete={
                        disablePasswordAutoComplete && getType(fieldConfig.type) === 'password'
                            ? 'new-password'
                            : ''
                    }
                />
                {clearable && value !== null && value !== undefined &&
                    <div className="input-group-append">
                        <button className='btn btn-sm btn-outline-secondary' onClick={onResetEventHandler} tabIndex={-1}>
                            <i className="fas fa-times" />
                        </button>
                    </div>}
            </div>
            <FormInvalidText error={error} />
        </div>
    );
};
export default Input;
