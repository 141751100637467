import { RefObject, useEffect, useRef } from 'react';

export const useScroll = <T extends HTMLElement>(callback: () => void, ref: RefObject<T>) => {

    const handleScroll = (e: Event) => {
        if (ref?.current && !ref?.current?.contains(e.target as Node)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('scroll', handleScroll, true);
        return () => {
            document.removeEventListener('scroll', handleScroll, true);
        };
    });

    return { ref };
}

export const useScrollRef = <T extends HTMLElement>(callback: () => void) => {
    return useScroll(callback, useRef<T>(null));
}