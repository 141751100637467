import { PageInfo } from '../../api/pageInfo';
import { BaseSyntheticEvent, ReactElement, useContext, useEffect } from 'react';
import { TableContext } from './DwTable';
import { ActionType } from './action';
import clsx from 'clsx';

type Props = {
    totalRecords: number;
    pageInfo: PageInfo;
};

type ButtonProps = {
    active: boolean;
    enabled: boolean;
    page: number;
    label: string;
};

const PAGE_SIZES: number[] = [10, 25, 50, 100];

const Button: (props: ButtonProps) => ReactElement<Props> = ({ active, enabled, page, label }) => {
    const context = useContext(TableContext);
    const onChangePage = (_: BaseSyntheticEvent) => {
        context.dispatch({
            type: ActionType.PAGE,
            id: page,
        });
    };
    return (
        <li className={clsx('page-item', { active: active })}>
            {enabled ? (
                <a className='page-link' role='button' href='#' onClick={onChangePage}>
                    {label}
                </a>
            ) : (
                <span className='page-link'>{label}</span>
            )}
        </li>
    );
};

const createButtons = (page: number, pages: number) => {
    const from = Math.max(1, page - 2),
        to = Math.min(pages - 1, from + 4),
        buttons = [],
        pushPage = (idx: number) => {
            buttons.push(
                <Button
                    key={idx}
                    page={idx}
                    active={page === idx}
                    enabled={true}
                    label={(idx + 1).toString()}
                />
            );
        };

    buttons.push(<Button key={-1} enabled={page > 0} page={page - 1} label={'‹'} active={false} />);

    pushPage(0);

    if (from > 1) {
        buttons.push(<Button key={-2} enabled={true} page={from} label={'…'} active={false} />);
    }

    for (let i = from; i < to; i++) {
        pushPage(i);
    }

    if (to < pages - 1) {
        buttons.push(<Button key={-3} enabled={true} page={to} label={'…'} active={false} />);
    }

    pushPage(pages - 1);

    buttons.push(
        <Button key={-4} enabled={page < pages - 1} page={page + 1} label={'›'} active={false} />
    );
    return buttons;
};

export const Pagination: (props: Props) => ReactElement<Props> = ({ totalRecords, pageInfo }) => {
    const context = useContext(TableContext);
    const pages = Math.ceil(totalRecords / pageInfo.pageSize);

    useEffect(() => {
        // if we have cached page number value, but actual number of records is less
        if (totalRecords > 0 && totalRecords <= pageInfo.page * pageInfo.pageSize) {
            context.dispatch({
                type: ActionType.PAGE,
                id: pages - 1 > 0 ? pages - 1 : 0,
            });
        }
    }, [totalRecords, pageInfo, pages, context]);

    const onChangePageSize = (e: BaseSyntheticEvent) => {
        context.dispatch({
            type: ActionType.PAGE_SIZE,
            id: Number(e.target.value),
        });
    };

    if (totalRecords < PAGE_SIZES[0]) {
        return <></>;
    }

    return (
        <div className='paging-control'>
        <div className='row'>
            <div className='col'>
                <div className='page-size'>
                    Показывать по{' '}
                    <select
                        className={'form form-control-sm'}
                        onChange={onChangePageSize}
                        defaultValue={pageInfo.pageSize}
                    >
                        {PAGE_SIZES.map((size) => (
                            <option key={size} value={size}>
                                {size}
                            </option>
                        ))}
                    </select>{' '}
                    записей
                </div>
            </div>
            {pages > 1 && (
                <div className='col'>
                    <ul className='float-right pagination pagination-sm'>
                        {createButtons(pageInfo.page, pages).map((it) => it)}
                    </ul>
                </div>
            )}
        </div>
        </div>
    );
};
