import {deleteRequest, get, post, put} from './rest';
import {ListRequest} from '../component/table/listRequest';
import {PagedResponse} from './pagedResponse';
import {ApiResponse} from './apiResponse';
import {Option} from '../component/control/option';
import {StorageQuotesModel} from '../component/storage/quotes/storageQuotesModel';
import {
    StorageAssemblyClientAssortmentModel,
    StorageAssemblyData,
    StorageAssemblyLineItemModel
} from '../component/storage/assembly-task/storageAssemblyModel';
import {AssemblyPlanModel} from '../component/storage/assembly-plan/assemblyPlanModel';
import {
    StorageDistributionData,
    StorageDistributionItemModel
} from '../component/storage/distribution/storageDistributionModel';
import {QuoteModel} from '../component/quotes/model/quoteModel';
import {ClientAddressListModel, ClientAddressModel, ClientAddressRequest,} from '../model/client/address';
import {ClientContact} from "../model/client/clientModel";

const BASE_PATH = 'storages';
const ASSEMBLY_BASE_PATH = 'storages/assembly';
const DISTRIBUTION_BASE_PATH = 'storages/distribution';

const storages = {
    options: (request: ClientAddressRequest) => post<Option[]>(`${BASE_PATH}/options`, request),

    get: (id: number) => get<ClientAddressModel>(`${BASE_PATH}/${id}`),
    contact: (id: number) => get<ClientContact>(`${BASE_PATH}/${id}/contact`),
    list: (model: ListRequest) => post<PagedResponse<ClientAddressListModel>>(`${BASE_PATH}/list`, model),
    save: (model: ClientAddressModel) => post<ApiResponse>(BASE_PATH, model),
    delete: (id: number) => deleteRequest<ApiResponse>(`${BASE_PATH}/${id}`),
    quotes: (model: ListRequest) => post<PagedResponse<StorageQuotesModel>>(`${BASE_PATH}/quotes`, model),
    changeStatus: (id: number, statusId: number) => put(`${BASE_PATH}/quotes/${id}/status/${statusId}`, {}),

    assemblyData: (assemblyLineId: number) => get<StorageAssemblyData>(`${ASSEMBLY_BASE_PATH}/${assemblyLineId}`),
    setAssemblyCompleted: (salItem: StorageAssemblyLineItemModel) => put<number>(`${ASSEMBLY_BASE_PATH}`, salItem),
    getClientAssortments: (salItem: StorageAssemblyLineItemModel) => post<StorageAssemblyClientAssortmentModel[]>(`${ASSEMBLY_BASE_PATH}/assortments`, salItem),
    changeAssemblyLine: (salItem: StorageAssemblyLineItemModel) => put(`${ASSEMBLY_BASE_PATH}/plan`, salItem),
    assemblyPlan: (model: ListRequest) => {
        const filter: any = model.filters.find(it => it.field === 'storage');
        const storageId = filter?.value.value ?? -1;
        return storageId > 0 ? get<AssemblyPlanModel[]>(`${ASSEMBLY_BASE_PATH}/plan/${storageId}`) : Promise.resolve([])
    },

    distributionData: (storageId: number) => get<StorageDistributionData>(`${DISTRIBUTION_BASE_PATH}/${storageId}`),
    setDistributionCompleted: (request: StorageDistributionItemModel) => post<number>(`${DISTRIBUTION_BASE_PATH}`, request),
    findAnyQuoteReadyToLoad: (storageId: number) => get<QuoteModel>(`${DISTRIBUTION_BASE_PATH}/${storageId}/quotes/ready-to-load`),
    setQuoteReadyToLoad: (quoteId: number) => post<number>(`${DISTRIBUTION_BASE_PATH}/quotes/${quoteId}/ready-to-load`, {}),
};

export default storages;
