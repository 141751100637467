import React, { useContext, useMemo } from 'react';
import { generatePath } from 'react-router-dom';
import contacts from '../../../../api/contacts';
import util from '../../../../common/util';
import { mainStorageUniqueValidator } from '../../../../common/validation/contactValidators';
import { emailValidator } from '../../../../common/validation/simpleValidators';
import {
    PATH_CP_STORAGE_CONTACTS,
    PATH_VARIABLE_STORAGE_CONTACT_ID,
} from '../../../../routerPaths';
import CheckboxInput from '../../../form/CheckboxInput';
import ContextError from '../../../form/ContextError';
import { DwForm } from '../../../form/DwForm';
import { FormConfig } from '../../../form/formConfig';
import StringInput from '../../../form/StringInput';
import { useRefreshContextAction } from '../../../table/DwTable';
import { StorageFormContext } from '../StorageForm';
import { StorageContactField } from './storageContactField';

import type { ContactModel } from '../../../../model/contact/contactModel';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';

const useFormConfig = (refresh: () => void, storageId: number) =>
    useMemo(
        () =>
            FormConfig.builder<ContactModel>()
                .number(
                    StorageContactField.ID,
                    (m) => m.id,
                    (m, v) => (m.id = v)
                )
                .requiredText(
                    StorageContactField.FIO,
                    (m) => m.fio,
                    (m, v) => (m.fio = v)
                )
                .text(
                    StorageContactField.EMAIL,
                    (m) => m.email,
                    (m, v) => (m.email = v),
                    (m) => emailValidator(m.email)
                )
                .text(
                    StorageContactField.PHONE,
                    (m) => m.phone,
                    (m, v) => (m.phone = v)
                )
                .boolean(
                    StorageContactField.MAIN,
                    (m) => m.main,
                    (m, v) => (m.main = v),
                    (m) => mainStorageUniqueValidator(storageId, m)
                )
                .load((id) =>
                    id && storageId
                        ? contacts.getForStorage(id, storageId)
                        : Promise.resolve({} as ContactModel)
                )
                .submit((model) => contacts.saveForStorage(model, storageId))
                .idPathVariableName(PATH_VARIABLE_STORAGE_CONTACT_ID)
                .redirectUrl(
                    generatePath(PATH_CP_STORAGE_CONTACTS, {
                        storageId: util.stringOrEmpty(storageId),
                    })
                )
                .afterSubmit((_) => refresh())
                .build(),
        [refresh, storageId]
    );

const StorageContact: React.FC = () => {
    const {
        storage: { id: storageId },
    } = useContext(StorageFormContext);
    const { refresh } = useRefreshContextAction();
    const formConfig = useFormConfig(refresh, storageId);

    return (
        <DwForm config={formConfig}>
            <StorageContactLayout />
            <ContextError />
        </DwForm>
    );
};

const StorageContactLayout: React.FC = () => {
    return (
        <>
            <div className='card h-100 mb-3'>
                <div className='card-body'>
                    <div className='row align-items-center'>
                        <div className='col-lg-4'>
                            ФИО
                            <StringInput id={StorageContactField.FIO} />
                        </div>
                        <div className='col-lg-3'>
                            Email
                            <StringInput id={StorageContactField.EMAIL} />
                        </div>
                        <div className='col-lg-3'>
                            Телефон
                            <StringInput id={StorageContactField.PHONE} />
                        </div>
                        <div className='col-lg-2'>
                            <CheckboxInput
                                id={StorageContactField.MAIN}
                                label={'Основной'}
                                className='position-relative'
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </>
    );
};

export default StorageContact;
