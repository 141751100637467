import React from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { FormConfig } from '../../form/formConfig';
import { DictionaryModel, DictionaryNames, DictionaryType } from '../../control/option';
import dictionary from '../../../api/dictionary';
import ContextError from '../../form/ContextError';
import { DwForm } from '../../form/DwForm';
import StringInput from '../../form/StringInput';
import { PATH_CP_DICTIONARY_LIST, PATH_VARIABLE_DICTIONARY_ID } from '../../../routerPaths';
import util from '../../../common/util';
import SaveAndCancelButtonGroup from '../../form/SaveAndCancelButtonGroup';

const DictionaryFormInner: React.FC = () => {
    return (
        <>
            <StringInput id='value' placeholder='значение' />
            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </>
    );
}

export const DictionaryForm: React.FC = () => {
    const { dictionaryType } = useParams();
    const config = FormConfig.builder<DictionaryModel>()
        .number(
            'id',
            (m) => m.id,
            (m, v) => (m.id = v)
        )
        .requiredText(
            'value',
            (m) => m.value,
            (m, v) => (m.value = v)
        )
        .load((id) => dictionary.get(dictionaryType!, id))
        .submit((model) =>
            model.value ? dictionary.save(dictionaryType!, model) : Promise.resolve()
        )
        .redirectUrl(
            generatePath(PATH_CP_DICTIONARY_LIST, {
                dictionaryType: util.stringOrEmpty(dictionaryType),
            })
        )
        .idPathVariableName(PATH_VARIABLE_DICTIONARY_ID)
        .build();
    return (
        <div className='form card dictionary'>
            <div className='card-header d-flex flex-row'>
                <div className='align-self-center'>
                    {DictionaryNames.get(dictionaryType! as DictionaryType)}
                </div>
            </div>
            <div className='card-body'>
                <DwForm className='user' config={config}>
                    <ContextError />
                    <DictionaryFormInner />
                </DwForm>
            </div>
        </div>
    );
};
