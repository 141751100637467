import {FieldConfig} from './fieldConfig';
import {FieldType} from './fieldType';
import {emptyValidator, requiredOptionValidator, requiredValidator,} from '../../common/validation/simpleValidators';
import {Option} from '../control/option';
import { ProxyModel } from '../../model/proxyModel';

class FormConfig<T> {
    static FormBuilder = class<T> {
        private form = new FormConfig<T>();

        public field<R>(
            id: string,
            type: FieldType,
            getter: (model: T) => R,
            setter: (model: T, value: R) => void,
            validator = emptyValidator
        ) {
            this.form.fields.push({
                id,
                type,
                getter,
                setter,
                validator,
            } as FieldConfig<T, R>);
            return this;
        }

        public number(
            id: string,
            getter: (model: T) => number,
            setter: (model: T, value: number) => void,
            validator = emptyValidator
        ) {
            return this.field<number>(id, FieldType.NUMBER, getter, setter, validator);
        }

        public text(
            id: string,
            getter: (model: T) => string,
            setter: (model: T, value: string) => void,
            validator = emptyValidator
        ) {
            return this.field<string>(id, FieldType.TEXT, getter, setter, validator);
        }

        public requiredField<R>(
            id: string,
            type: FieldType,
            getter: (model: T) => R,
            setter: (model: T, value: R) => void
        ) {
            return this.field(id, type, getter, setter, (model: T) =>
                requiredValidator(getter(model))
            );
        }

        public requiredNumber(
            id: string,
            getter: (model: T) => number,
            setter: (model: T, value: number) => void
        ) {
            return this.requiredField<number>(id, FieldType.NUMBER, getter, setter);
        }

        public option(
            id: string,
            getter: (model: T) => Option,
            setter: (model: T, value: Option) => void,
            validator = emptyValidator
        ) {
            return this.field<Option>(id, FieldType.OPTION, getter, setter, validator);
        }

        public requiredOption(
            id: string,
            getter: (model: T) => Option,
            setter: (model: T, value: Option) => void
        ) {
            return this.field<Option>(id, FieldType.OPTION, getter, setter, (model: T) =>
                requiredOptionValidator(getter(model))
            );
        }

        public requiredText(
            id: string,
            getter: (model: T) => string,
            setter: (model: T, value: string) => void
        ) {
            return this.requiredField<string>(id, FieldType.TEXT, getter, setter);
        }

        public dateTime(
            id: string,
            getter: (model: T) => Date,
            setter: (model: T, value: Date) => void,
            validator = emptyValidator
        ) {
            return this.field<Date>(id, FieldType.DATE_TIME, getter, setter, validator);
        }

        public requiredDateTime(
            id: string,
            getter: (model: T) => Date,
            setter: (model: T, value: Date) => void
        ) {
            return this.requiredField<Date>(id, FieldType.DATE_TIME, getter, setter);
        }

        public list<R>(
            id: string,
            getter: (model: T) => R[],
            setter: (model: T, value: R[]) => void,
            validator = emptyValidator,
        ) {
            return this.field<R[]>(id, FieldType.LIST, getter, setter, validator);
        }

        public sortedList<R>(
            id: string,
            getter: (model: T) => R[],
            sort: (a: R, b: R) => number,
            setter: (model: T, value: R[]) => void,
            validator = emptyValidator,
        ) {
            return this.field<R[]>(id, FieldType.LIST, (model: T) => getter(model)?.sort(sort), setter, validator);
        }

        public proxyList<R>(
            id: string,
            getter: (model: T) => ProxyModel<R>[],
            sort: (a: ProxyModel<R>, b: ProxyModel<R>) => number,
            setter: (model: T, value: ProxyModel<R>[]) => void,
            validator = emptyValidator,
        ) {
            return this.field<ProxyModel<R>[]>(id, FieldType.PROXY_LIST, (model: T) => getter(model)?.sort(sort), setter, validator);
        }

        public boolean(
            id: string,
            getter: (model: T) => boolean,
            setter: (model: T, value: boolean) => void,
            validator = emptyValidator
        ) {
            return this.field<boolean>(id, FieldType.BOOLEAN, getter, setter, validator);
        }

        public load(call: (id: number) => Promise<T>) {
            this.form.load = call;
            return this;
        }

        public initialModelGetter(call: (config: FormConfig<T>) => Promise<T>) {
            this.form.initialModelGetter = call;
            return this;
        }

        public submit(call: (model: T) => Promise<any>) {
            this.form.submit = call;
            return this;
        }

        public afterSubmit(call: (response: any, withRedirect: boolean, incrementFormVersion: () => void) => void) {
            this.form.afterSubmit = call;
            return this;
        }

        public redirectUrl(url: string) {
            this.form.redirectUrl = url;
            return this;
        }

        public emptyModel(model: T) {
            this.form.emptyModel = model;
            return this;
        }

        public idPathVariableName(idPathVariableName: string) {
            this.form.idPathVariableName = idPathVariableName;
            return this;
        }

        public validator(v: (model: T) => Promise<string>) {
            this.form.validator = v;
            return this;
        }

        public build(): FormConfig<T> {
            return this.form;
        }
    };

    private fields: FieldConfig<T, any>[] = [];
    private validator: (model: T) => Promise<string> = emptyValidator;
    private emptyModel: T = {} as T;
    private redirectUrl: string = '';
    private idPathVariableName: string = 'id';

    public static builder<T>() {
        return new FormConfig.FormBuilder<T>();
    }

    public field(id: string): FieldConfig<T, any> {
        return this.fields.find((it) => it.id === id)!;
    }

    public getEmptyModel(): T {
        return this.emptyModel;
    }

    public getRedirectUrl(): string {
        return this.redirectUrl;
    }

    public getIdPathVariableName(): string {
        return this.idPathVariableName;
    }

    public getValidators() {
        return this.fields.map((it) => {
            return {
                id: it.id,
                validator: it.validator,
            };
        });
    }

    public getSubmit<R>() {
        return this.submit<R>;
    }

    public getLoad() {
        return this.load;
    }

    public getInitialModelGetter() {
        return this.initialModelGetter;
    }

    public getAfterSubmit() {
        return this.afterSubmit;
    }

    public withAfterSubmit(call: (response: any) => void) {
        this.afterSubmit = call;
        return this;
    }

    private load: (id: number) => Promise<T> = (_id) => Promise.resolve({} as T);

    private initialModelGetter: (config: FormConfig<T>) => Promise<T> = (_c) => Promise.resolve(_c.getEmptyModel());

    private submit: <R>(model: T) => Promise<R> = (_m) => Promise.resolve({} as any);

    private afterSubmit: (response: any, withRedirect: boolean, incrementFormVersion: () => void) => void = (_r, _wr) => {
    };
}

export {FormConfig};
