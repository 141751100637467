import React from 'react';
import clients from '../../api/clients';

import type {Option} from '../control/option';
import type {MultiValue, SingleValue} from 'react-select/dist/declarations/src/types';
import type {ClientType} from '../../model/client/clientModel';
import FormSelect from './FormSelect';

type Props = {
    id: string;
    clientTypes?: ClientType[];
    onChange?: (newValue: MultiValue<Option>) => void;
    isMulti?: boolean;
};

const UserClients: React.FC<Props> = ({
                                          id,
                                          clientTypes,
                                          onChange,
                                          isMulti
                                      }) => {
    const loadOptions = async (inputValue: string, callback: (options: Option[]) => void) => {
        await clients
            .options({
                filter: inputValue,
                clientTypes,
            })
            .then((options) => callback(options));
    };

    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            onChange={onChange as (newValue: SingleValue<Option> | MultiValue<Option>) => void}
            isMulti={isMulti}
        />
    );
};

export default UserClients;
