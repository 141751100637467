import React, {ReactElement, useCallback} from 'react';
import {Config} from './config';
import {OuterFilterForm} from './OuterFilter';

type FiltersProps = {
    config: Config<any>;
};

export const OuterFilters: (props: FiltersProps) => ReactElement<FiltersProps> = (
    props
) => {
    const renderFilters = useCallback(
        () =>
            props.config.outerFilters.map((column) => {
                return (
                    <div key={column.field} className='col'>
                        <OuterFilterForm column={column}/>
                    </div>
                );
            }),
        [props.config.outerFilters]
    );

    return <div className='row'>{renderFilters()}</div>;
};
