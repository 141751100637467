import {RunPointBase, RunPointModel} from '../../component/runs/model/runPointModel';
import {RunModel} from '../../component/runs/model/runModel';
import {RunExpensesModel} from '../../component/runs/model/runExpensesModel';
import {ALL_RUN_STATUS_IDS} from '../../model/enums/RunStatus';
import {Option} from '../../component/control/option';
import {positiveIntValidator, requiredValidator} from './simpleValidators';
import {PROMISE_OK} from '../const';
import {PalletPassportListModel} from '../../component/runs/model/talman/palletPassportModel';

export const routeValidator = (run: RunModel) => {
    const route = run.route ?? [];
    const isValid = route.every((rp: RunPointModel) => rp.date && rp.timeFrom && rp.duration);
    if (!isValid) {
        return Promise.resolve('укажите "дату", "время от" и "кол-во часов" для всех точек маршрута');
    }

    const isValidClient = route.filter(rp => rp.base === RunPointBase.CLIENT).every((rp: RunPointModel) => rp.client?.value > 0);
    if (!isValidClient) {
        return Promise.resolve('укажите заказчика для всех точек маршрута по клиенту');
    }

    const isValidCompany = route.filter(rp => rp.base === RunPointBase.COMPANY).every((rp: RunPointModel) => rp.company?.value > 0);
    if (!isValidCompany) {
        return Promise.resolve('укажите организацию для всех точек маршрута по организации');
    }

    const isValidAddress = route.every((rp: RunPointModel) => rp.address && rp.address.value > 0);
    if (!isValidAddress) {
        return Promise.resolve('укажите "адрес" для всех точек маршрута');
    }

    return Promise.resolve(PROMISE_OK);
};

export const expensesValidator = (expenses: RunExpensesModel[]) => {
    const isValidRequired = (expenses ?? []).every(
        (re: RunExpensesModel) => re.item && re.amount > 0
    );
    if (!isValidRequired) {
        return Promise.resolve('укажите статью и сумму для всех доп. расходов');
    }

    const isValidLength = (expenses ?? []).every(
        (re: RunExpensesModel) => !re.comment || re.comment.length < 255
    );
    if (!isValidLength) {
        return Promise.resolve('комментарий не может превышать 255 символов в длину');
    }

    return Promise.resolve(PROMISE_OK);
};

export const requiredByStatusValidator = (value: any, runStatus: Option) => {
    if (!runStatus?.value || ALL_RUN_STATUS_IDS.includes(runStatus.value)) {
        return Promise.resolve(PROMISE_OK);
    }
    return requiredValidator(value);
};

export const notNullablePositiveIntByStatusValidator = (value: any, runStatus: Option) => {
    if (!value) {
        return requiredByStatusValidator(value, runStatus);
    }
    return positiveIntValidator(value);
};

export const palletPassportsValidator = (maxPalletPassports: number, palletPassports: PalletPassportListModel[]) => {
    if (!palletPassports) {
        return Promise.resolve(PROMISE_OK);
    }
    const isValid = (maxPalletPassports ?? 0) >= palletPassports.length;
    return Promise.resolve(isValid ? PROMISE_OK : `удалите лишние паспорта паллет (макс. ${maxPalletPassports ?? 0})`);
};

export const requiredByDomesticRunValidator = (
    client: Option,
    isDomesticRun: boolean
): Promise<string> => {
    return Promise.resolve(
        isDomesticRun || client?.value > 0 ? PROMISE_OK : 'обязательное поле для внешнего рейса'
    );
};
