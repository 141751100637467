import React, { useEffect, useState } from 'react';
import CheckboxInput from '../../../form/CheckboxInput';
import { useFieldValue } from '../../../form/DwForm';
import StringInput from '../../../form/StringInput';
import TextInput from '../../../form/TextInput';
import { CrmUserField } from '../crmUserField';
import DtPicker from '../../../form/DtPicker';
import AttachmentsArea from '../../../form/attachments/AttachmentsArea';
import { AttachmentType } from '../../../../model/attachment';
import Roles from '../../../form/Roles';
import SaveAndCancelButtonGroup from '../../../form/SaveAndCancelButtonGroup';

const User: React.FC = () => {
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const id = useFieldValue(CrmUserField.ID);
    useEffect(() => setShowPasswordInput(!id || id < 1), [id]);

    return (
        <div className='card h-100'>
            <div className='card-body'>
                <div className='row'>
                    <div className='col-lg-4'>
                        Фамилия
                        <StringInput id={CrmUserField.LAST_NAME} />
                    </div>

                    <div className='col-lg-4'>
                        Имя
                        <StringInput id={CrmUserField.FIRST_NAME} />
                    </div>

                    <div className='col-lg-4'>
                        Отчество
                        <StringInput id={CrmUserField.MID_NAME} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-4'>
                        E-mail
                        <StringInput id={CrmUserField.EMAIL} />
                    </div>

                    <div className='col-lg-4'>
                        Телефон
                        <StringInput id={CrmUserField.PHONE} />
                    </div>
                    <div className='col-lg-4'>
                        Пароль
                        {!showPasswordInput && (
                            <div>
                                <button
                                    className='btn btn-outline-primary btn-sm form-control-sm'
                                    onClick={() => setShowPasswordInput(!showPasswordInput)}
                                >
                                    сменить
                                </button>
                            </div>
                        )}
                        {showPasswordInput && (
                            <StringInput
                                id={CrmUserField.PASSWORD}
                                disablePasswordAutoComplete={true}
                            />
                        )}
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-3'>
                        Номер паспорта
                        <StringInput id={CrmUserField.PASSPORT_NUMBER} />
                    </div>

                    <div className='col-lg d-flex align-items-center'>
                        <CheckboxInput
                            id={CrmUserField.PASSPORT_ALIEN}
                            label='Не рф'
                            className='custom-control-input'
                        />
                    </div>

                    <div className='col-lg-4'>
                        Дата выдачи паспорта
                        <DtPicker id={CrmUserField.PASSPORT_DATE} />
                    </div>

                    <div className='col-lg-4'>
                        Кем выдан паспорт
                        <StringInput id={CrmUserField.PASSPORT_DEPARTMENT} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-4'>
                        Вод. удостоверение
                        <StringInput id={CrmUserField.LICENCE_NUMBER} />
                    </div>

                    <div className='col-lg-4'>
                        Дата выдачи вод. удостоверения
                        <DtPicker id={CrmUserField.LICENCE_DATE} />
                    </div>

                    <div className='col-lg-4'>
                        Дата рождения
                        <DtPicker id={CrmUserField.BIRTH_DATE} />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-4'>
                        ИНН
                        <StringInput id={CrmUserField.INN} />
                    </div>

                    <div className='col-lg-4'>
                        СНИЛС
                        <StringInput id={CrmUserField.SNILS} />
                    </div>
                    <div className='col-lg mb-auto'>
                        <CheckboxInput
                            id={CrmUserField.ACTIVE}
                            label='Активен'
                            className='custom-control-input'
                        />
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-4 mb-auto'>
                        Роль
                        <Roles id={CrmUserField.ROLES} isMulti/>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-6'>
                        <AttachmentsArea
                            id={CrmUserField.ATTACHMENTS}
                            attachmentType={AttachmentType.CRMUSER}
                            parentId={id}
                        />
                    </div>

                    <div className='col-lg-6 mt-2'>
                        Комментарий
                        <TextInput id={CrmUserField.COMMENT} rows={6} />
                    </div>
                </div>
            </div>

            <div className='text-center mb-3'>
                <SaveAndCancelButtonGroup />
            </div>
        </div>
    );
};

export default User;
