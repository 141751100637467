import React from 'react';
import clients from '../../api/clients';
import { Option } from '../control/option';
import FormSelect from './FormSelect';

type Props = {
    id: string;
    clientId: number;
    onChange?: (newValue: any) => void;
    readOnly?: boolean;
};

const ClientConsignees: React.FC<Props> = ({ id, clientId, onChange, readOnly }) => {
    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        clients
            .clientConsigneeOptions({
                filter: inputValue,
                clientId,
            })
            .then((options) => callback(options));
    };
    return (
        <FormSelect
            id={id}
            loadOptions={loadOptions}
            cacheOptions={false}
            onChange={onChange}
            readOnly={readOnly}
        />
    );
};

export default ClientConsignees;
