import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { StorageAssemblyClientAssortmentModel, StorageAssemblyData, StorageAssemblyLineItemModel, StorageAssemblyModel, StorageAssemblyTaskModel } from "./storageAssemblyModel";
import { generatePath, useMatch, useNavigate, useParams, useResolvedPath } from "react-router-dom";
import { PATH_STORAGE_ASSEMBLY, PATH_STORAGE_ASSEMBLY_ASSORTMENT, PATH_VARIABLE_CLIENT_ID, PATH_VARIABLE_ASSORTMENT_ID, PATH_VARIABLE_PRODUCTION_LINE_ID, PATH_STORAGE_ASSEMBLY_ASSORTMENT_CLIENT_VIEW, PATH_VARIABLE_TMC_ID } from "../../../routerPaths";
import util from "../../../common/util";
import "./assembly.scss";
import { AssemblyTable } from "./AssemblyTable";
import { AssemblyCards } from "./AssemblyCards";
import { RootContext } from "../../layout/ContextWrapper";
import storages from "../../../api/storages";
import { WebSocketSubscriptionUrl } from "../../../common/const";
import { StompWebSocketProvider } from "../../../websockets/StompWebSocketProvider";
import { StorageAssemblyNav } from "../StorageAssemblyNav";
import { LineSelect } from "../LineSelect";
import { Option } from "../../control/option";
import { SingleValue } from "react-select";
import { Modal } from "../../layout/Modal";
import { ViewModeToggle, VIEW_TYPE } from "../ViewModeToggle";
import { useWsSubscription } from "../../../websockets/useWsSubscribtion";

type StorageAssemblyAssortmentProps = {
    lineId: number;
    assemblyTask: StorageAssemblyModel;
    setCompleted: (salItem: StorageAssemblyLineItemModel) => void;
}

export type StorageAssemblyItemsProps = {
    assortment: Option;
    tasks: StorageAssemblyTaskModel[];
    setCompletedAssortment: (e: React.MouseEvent, clientId: number, tareId: number, value: number) => void;
    onTaskRowClick: (clientId: number, tareId: number) => void;
}

export const getValueToSet = (toSet: number) => toSet < 0 ? 0 : toSet;

const emptyModel = { assemblyModels: [] as StorageAssemblyModel[] } as StorageAssemblyData;

export const AssemblyTaskRow: React.FC<StorageAssemblyAssortmentProps> = ({ lineId, setCompleted, assemblyTask: { assortment, tasks } }) => {
    const pathTo = generatePath(PATH_STORAGE_ASSEMBLY_ASSORTMENT, { [PATH_VARIABLE_PRODUCTION_LINE_ID]: util.toString(lineId), [PATH_VARIABLE_ASSORTMENT_ID]: util.toString(assortment.value) }),
        resolved = useResolvedPath(pathTo),
        isActive = !!useMatch({ path: resolved.pathname, end: false }),
        { state: { uiState: { assortmentViewModeTable: isTableView } } } = useContext(RootContext),
        total = util.countTotal(tasks, ({ tareQuotesTotal = 0 }) => tareQuotesTotal),
        collected = tasks.map(t => Number(t.tareCollectedTotal ?? 0) - Number(t.tareDistributedShipped ?? 0)).reduce((acc, value) => acc + value, 0),
        navigate = useNavigate(),
        onRowClick = () => {
            if (isActive) {
                navigate(
                    generatePath(PATH_STORAGE_ASSEMBLY, { [PATH_VARIABLE_PRODUCTION_LINE_ID]: util.toString(lineId) }),
                    { replace: true }
                );
            } else {
                navigate(pathTo, { replace: true });
            }
        },
        setCompletedAssortment = useCallback((e: React.MouseEvent, clientId: number, tareId: number, value: number) => {
            e.preventDefault();
            e.stopPropagation();
            setCompleted({
                lineId,
                assortmentId: assortment.value,
                clientId,
                tareId,
                collected: value
            })
        }, [assortment.value, lineId, setCompleted]),
        onTaskRowClick = useCallback((clientId: number, tareId: number) => {
            navigate(generatePath(PATH_STORAGE_ASSEMBLY_ASSORTMENT_CLIENT_VIEW, {
                [PATH_VARIABLE_PRODUCTION_LINE_ID]: util.toString(lineId),
                [PATH_VARIABLE_ASSORTMENT_ID]: util.toString(assortment.value),
                [PATH_VARIABLE_CLIENT_ID]: util.toString(clientId),
                [PATH_VARIABLE_TMC_ID]: util.toString(tareId)
            }), { replace: true });
        }, [assortment.value, lineId, navigate]);
    return (
        <>
            <tr onClick={onRowClick} className={`assortment-row ${isActive ? 'active' : ''}`}>
                <td>
                    <div className="td-value-container d-flex justify-content-start align-items-center">
                        <div className="btn btn-sm btn-circle btn-light assembly-row-btn">
                            <i className={`fas ${isActive ? 'fa-arrow-left' : 'fa-plus'}`} />
                        </div>
                        <span className="pl-1">
                            {assortment.label}
                        </span>
                    </div>
                </td>
                <td className="td-assortment-counters">
                    <div className="td-value-container d-flex justify-content-center align-items-center">
                        <span>{total}</span>/<span>{collected}</span>
                    </div>
                </td>
            </tr>
            {isActive &&
                <tr className={`no-gutters p-0 assembly-task-list ${isActive ? 'active' : ''}`}>
                    <td colSpan={2} className="no-gutters p-0">
                        {isTableView ? <AssemblyTable {...{ setCompletedAssortment, tasks, assortment, onTaskRowClick }} /> : <AssemblyCards {...{ setCompletedAssortment, tasks, assortment, onTaskRowClick }} />}
                    </td>
                </tr>}
        </>
    );
}

export const ClientAssortmentView: React.FC = () => {
    const [clientAssortments, setClientAssortments] = useState([{}] as StorageAssemblyClientAssortmentModel[]),
        [currentAssortmentIndex, setCurrentAssortmentIndex] = useState(0),
        navigate = useNavigate(),
        { [PATH_VARIABLE_PRODUCTION_LINE_ID]: lineIdParam, [PATH_VARIABLE_ASSORTMENT_ID]: assortmentIdParam, [PATH_VARIABLE_CLIENT_ID]: clientIdParam, [PATH_VARIABLE_TMC_ID]: tareIdParam } = useParams(),
        lineId = util.toNumber(lineIdParam ?? '') ?? -1,
        clientId = util.toNumber(clientIdParam ?? '') ?? -1,
        assortmentId = util.toNumber(assortmentIdParam ?? '') ?? -1,
        tareId = util.toNumber(tareIdParam ?? '') ?? -1,
        showModal = clientId > 0 && assortmentId > 0,
        pathBack = generatePath(PATH_STORAGE_ASSEMBLY_ASSORTMENT, { [PATH_VARIABLE_PRODUCTION_LINE_ID]: util.toString(lineId), [PATH_VARIABLE_ASSORTMENT_ID]: util.toString(assortmentId) });
    useEffect(() => {
        showModal && storages
            .getClientAssortments({ lineId, assortmentId, clientId, tareId } as StorageAssemblyLineItemModel)
            .then((asmts) => {
                setClientAssortments(asmts);
                setCurrentAssortmentIndex(0);
            });
    }, [showModal, assortmentId, clientId, lineId, tareId]);
    const onClickNext = useCallback((forward: boolean) => {
        const next = currentAssortmentIndex + (forward ? 1 : - 1);
        setCurrentAssortmentIndex(next >= clientAssortments.length ? 0 : (next < 0 ? clientAssortments.length - 1 : next));
    }, [clientAssortments, currentAssortmentIndex])
    const currentAssortment = clientAssortments[currentAssortmentIndex] ?? {} as StorageAssemblyClientAssortmentModel;
    return (
        <Modal show={showModal} onClose={() => navigate(pathBack, { replace: true })}>
            <div className="d-flex justify-content-between align-items-center w-100 p-1">
                {clientAssortments.length > 1 && <button className="btn btn-sm btn-circle btn-light assembly-row-btn" onClick={() => onClickNext(false)}>
                    <i className={'fas fa-arrow-left'} />
                </button>}
                <div className="w-100 text-center">{`${currentAssortment.clientName || '-'} (в заказах ${currentAssortment.tareTotal})`} </div>
                {clientAssortments.length > 1 && <button className="btn btn-sm btn-circle btn-light assembly-row-btn" onClick={() => onClickNext(true)}>
                    <i className={'fas fa-arrow-right'} />
                </button>}
            </div>
            <table className="table table-striped text-dark">
                <tbody>
                    <tr><td>Наименование склада:</td><td>{currentAssortment.name || '-'}</td></tr>
                    <tr><td>Артикул:</td><td>{currentAssortment.number || '-'}</td></tr>
                    <tr><td>Штрих-код единицы:</td><td>{currentAssortment.unitBarcode || '-'}</td></tr>
                    <tr><td>Штрих-код упаковки:</td><td>{currentAssortment.packageBarcode || '-'}</td></tr>
                    <tr><td>Начальный калибр:</td><td>{currentAssortment.weightFrom || '-'}</td></tr>
                    <tr><td>Конечный калибр:</td><td>{currentAssortment.weightTo || '-'}</td></tr>
                    <tr><td>Упаковка:</td><td>{currentAssortment.packageTmc || '-'}</td></tr>
                    <tr><td>Квант:</td><td>{currentAssortment.weightMin || '-'}</td></tr>
                    <tr><td>Тара:</td><td>{currentAssortment.tareTmc || '-'}</td></tr>
                    <tr><td>Сетка:</td><td>{currentAssortment.useNet ? "да" : '-'}</td></tr>
                    <tr><td>Тальманская расписка:</td><td>{currentAssortment.useTalman ? "да" : '-'}</td></tr>
                    <tr><td>Паспорт паллет:</td><td>{currentAssortment.usePassport ? "да" : '-'}</td></tr>
                    <tr><td>Комментарий:</td><td>{currentAssortment.comment || '-'}</td></tr>
                </tbody>
            </table>
        </Modal>
    );
}

export const StorageAssemblyTableInner: React.FC = () => {
    const [assemblyData, setAssemblyData] = useState(emptyModel),
        [reloadSignal, setReloadSignal] = useState(0),
        assemblyLineId = util.toNumber(useParams()[PATH_VARIABLE_PRODUCTION_LINE_ID] ?? '') ?? -1,
        navigate = useNavigate(),
        { state: { uiState: { assortmentViewModeTable: isTableView } } } = useContext(RootContext),
        reload = () => setReloadSignal((prev) => ++prev);
    const handleChangeLine = (line: SingleValue<Option>) => navigate(generatePath(PATH_STORAGE_ASSEMBLY, { [PATH_VARIABLE_PRODUCTION_LINE_ID]: util.toString(line?.value) }));
    const selectedLineOption = useMemo(() => assemblyData?.productionLine?.value ?
        assemblyData.productionLine :
        ((assemblyLineId > 0 ? { value: assemblyLineId } : {}) as Option),
        [assemblyData.productionLine, assemblyLineId]);
        
    useWsSubscription(useMemo(() => ({
        endpoint: WebSocketSubscriptionUrl.STORAGE_ASSEMBLY,
        onMessage: (_) => reload()
    }), []));

    const setCompleted = useCallback((salItem: StorageAssemblyLineItemModel) => {
        storages.setAssemblyCompleted(salItem).then(reload)
    }, []);

    useEffect(() => {
        let shouldUpdate = true;
        if (assemblyLineId > 0) {
            storages.assemblyData(assemblyLineId).then((res) => shouldUpdate && setAssemblyData(res));
        }
        return () => { shouldUpdate = false; };
    }, [assemblyLineId, reloadSignal]); // reloadSignal is used to reload page after counters updated or on WS event
    return (
        <div className={`assembly view-${isTableView ? VIEW_TYPE.TABLE : VIEW_TYPE.CARDS}`}>
            <StorageAssemblyNav />
            <div className="px-2 px-md-0 pb-1 d-flex align-items-center">
                <span className="pr-2">Линия:</span>
                <div className="line-selector-container">
                    <LineSelect value={selectedLineOption} onChange={handleChangeLine} />
                </div>
            </div>
            {assemblyLineId > 0 &&
                <div className={'table-responsive'}>
                    <table className="table dwtable table-bordered table-assembly">
                        <thead>
                            <tr>
                                <th>Наименование</th>
                                <th><div className="d-flex justify-content-center">В заказах/Собрано</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {assemblyData.assemblyModels.filter((at) => at.assortment?.value).map((assemblyTask) => (
                                <AssemblyTaskRow key={assemblyTask.assortment.value} {...{ assemblyTask, setCompleted, lineId: assemblyLineId }} />
                            ))}
                        </tbody>
                    </table>
                    <ViewModeToggle />
                </div>
            }
        </div>
    );
}

export const StorageAssemblyTable: React.FC<{}> = () => {
    return (
        <StompWebSocketProvider>
            <StorageAssemblyTableInner />
        </StompWebSocketProvider>
    );
}
