import React from 'react';
import Input from './Input';

type Props = {
    id: string;
    placeholder?: string;
    maxLength?: number;
    disablePasswordAutoComplete?: boolean;
    onChange?: (newValue: string) => void;
    clearable?: boolean;
};

const StringInput: React.FC<Props> = (props) => <Input {...props}/>;

export default StringInput;
